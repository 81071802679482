import { ChevronDown, ChevronUp } from 'react-feather';
import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { pxToRem } from '../utils/style-functions';
import { shouldForwardProps } from '../utils/shouldForwardProp';

const Wrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: theme.cogShadows.base,
    borderRadius: theme.shape.borderRadius,
}));

export const InnerWrapper = styled('div', {
    ...shouldForwardProps('open'),
})<{ open?: boolean }>(({ open, theme }) => ({
    maxHeight: open ? 3000 : 0,
    overflow: 'hidden',
    transition: 'all .4s cubic-bezier(0.4, 0, 1, 1)',
    height: '100%',
    padding: open ? theme.spacing(0, 4, 4) : theme.spacing(0, 4, 0),
    '& p, & li': {
        marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down(744)]: {
        padding: open ? theme.spacing(0, 2, 2) : theme.spacing(0, 2, 0),
    },
}));

const Main = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    cursor: 'pointer',
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(2),
    },
}));

const Icon = styled('div')(() => ({
    height: 28,
    width: 28,
    background: '#cfd8e5',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flex: '0 0 28px',
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(20),
    fontWeight: 600,
    lineHeight: '150%',
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(16),
        flex: '1 1 0%',
    },
}));

const Right = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

interface IOwnProps {
    title: string;
    content: JSX.Element | null;
    titleContent?: JSX.Element;
    isOpen?: boolean;
}

export const Accordion: React.FC<IOwnProps> = ({
    title,
    content,
    titleContent,
    isOpen = false,
}) => {
    const [open, setOpen] = useState<boolean>(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Wrapper>
            <Main onClick={() => setOpen(!open)}>
                <Title variant='h1'>{title}</Title>
                <Right>
                    {titleContent}
                    <Icon>{open ? <ChevronUp /> : <ChevronDown />}</Icon>
                </Right>
            </Main>
            <InnerWrapper open={open}>{content}</InnerWrapper>
        </Wrapper>
    );
};
