import { Button } from '@cognassist/react-components';
import { StrategyStatus } from '../../../types';
import { ThumbsUp } from 'react-feather';
import { Userpilot } from 'userpilot';
import { endpoints } from '../../../api/endpoints';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const ActionNav = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3),
}));

interface IOwnProps {
    strategyStatus?: StrategyStatus;
    setStrategyStatus: React.Dispatch<
        React.SetStateAction<StrategyStatus | undefined>
    >;
}

export const ActionMenu: React.FC<IOwnProps> = ({
    strategyStatus,
    setStrategyStatus,
}) => {
    const { setTryingStrategy } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const {
        state: { activeLmsSourceId },
    } = useCognitiveMap();

    const [tryThisLoading, setTryThisLoading] = useState<boolean>(false);

    const tryStrategy = async (userId: string, lmsSourceId: string) => {
        const { error } = await setTryingStrategy(userId, lmsSourceId);
        if (error) {
            enqueueSnackbar(
                'Could not update status at this time, please try again later.',
                {
                    variant: 'error',
                }
            );
        } else {
            setStrategyStatus(StrategyStatus.TRYING);
            Userpilot.track('Trying a strategy');
        }
        setTryThisLoading(false);
    };

    const tryThisHandler = () => {
        setTryThisLoading(true);

        if (userId && activeLmsSourceId) {
            tryStrategy(userId, activeLmsSourceId);
        }
    };

    const getButtonText = () => {
        switch (strategyStatus) {
            case StrategyStatus.TRYING:
                return "I'm trying this";
            case StrategyStatus.NEW:
            case StrategyStatus.APPLIED:
            default:
                return "I'll try this";
        }
    };

    const isTrying = strategyStatus === StrategyStatus.TRYING;

    return (
        <ActionNav>
            <Button
                text={getButtonText()}
                startIcon={
                    <ThumbsUp size={18} fill={isTrying ? '#fff' : 'none'} />
                }
                onClick={tryThisHandler}
                loading={tryThisLoading}
                disabled={isTrying}
            />
        </ActionNav>
    );
};
