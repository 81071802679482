import { Card } from '../../styled-components';
import { PageWrapper } from '../../styled-components/Wrappers';
import { Typography } from '@mui/material';
import underDevelopment from '../../../assets/img/under-development.png';

export const DashboardUnderDevelopment: React.FC = () => {
    return (
        <PageWrapper id='under-development'>
            <Card
                sx={{
                    textAlign: 'center',
                    maxWidth: 575,
                    margin: '80px auto auto',
                }}
            >
                <img
                    src={underDevelopment}
                    alt='picture of a application under development'
                    style={{ marginTop: -80 }}
                />
                <Typography variant='h1' sx={{ mb: 2 }}>
                    Your dashboard is under development.
                </Typography>
                <Typography>
                    This area is still being worked on and will be with you
                    soon.
                </Typography>
            </Card>
        </PageWrapper>
    );
};

export default DashboardUnderDevelopment;
