import { Typography, TypographyProps, styled } from '@mui/material';
import {
    getFontSize,
    getLineHeight,
} from '../../../../components/main-app/cognitive-map-v2/helpers';

import DeepDiveSidebar from '../../../../components/main-app/cognitive-map-v2/DeepDiveSidebar';
import DomainCard from './DomainCard';
import SpineChart from '../../../../components/main-app/cognitive-map/SpineChart';
import { nanoid } from 'nanoid';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexFlow: 'row wrap',
}));

const InnerWrapper = styled('div')(() => ({
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 24,
}));

const Title = styled(
    ({ children, ...props }: TypographyProps) => (
        <Typography {...props} variant='h2'>
            {children}
        </Typography>
    ),
    {
        ...shouldForwardProps('isMobile', 'isTablet'),
    }
)<{ isMobile: boolean; isTablet: boolean }>(
    ({ isMobile, isTablet, theme }) => ({
        fontWeight: 500,
        lineHeight: getLineHeight(isMobile),
        fontSize: getFontSize(isMobile),
        display: 'inline',
        marginBottom: isMobile
            ? theme.spacing(2)
            : isTablet
              ? theme.spacing(3)
              : theme.spacing(4),
    })
);

const SpineChartWrapper = styled('div', {
    ...shouldForwardProps('isSmallOrMedium'),
})<{ isSmallOrMedium: boolean }>(({ isSmallOrMedium, theme }) => ({
    display: 'flex',
    flexFlow: isSmallOrMedium ? 'row wrap' : 'row nowrap',
    gap: theme.spacing(3),
    overflowX: isSmallOrMedium ? 'scroll' : 'visible',
}));

const RightColumn = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    flexFlow: 'column nowrap',
    flexBasis: '50%',
    flexGrow: 1,
}));

const LeftColumn = styled('div')(() => ({
    display: 'grid',
}));

const Explanation = styled('div', {
    ...shouldForwardProps('isMobile'),
})<{ isMobile: boolean }>(({ isMobile, theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: isMobile ? theme.spacing(2) : theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,

    '& p:not(:last-child)': {
        marginBottom: isMobile ? theme.spacing(2) : theme.spacing(3),
    },
}));

const CardTitle = styled(
    ({ children, ...props }: TypographyProps) => (
        <Typography {...props} variant='h3'>
            {children}
        </Typography>
    ),
    {
        ...shouldForwardProps('isMobile'),
    }
)<{ isMobile: boolean }>(({ isMobile, theme }) => ({
    fontWeight: 500,
    fontSize: getFontSize(isMobile),
    lineHeight: getLineHeight(isMobile),
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    includeSidebarTopGap?: boolean;
}

const CognitionDeepDive: React.FC<IOwnProps> = ({ includeSidebarTopGap }) => {
    const {
        state: {
            cognitiveMapV2,
            screenSize,
            openDomainDrawerId,
            activeDomainExplainer,
        },
        dispatch,
    } = useCognitiveMap();

    const onSpineChartClick = (domainId: number) => {
        dispatch({
            type: 'SET_ACTIVE_DOMAIN_EXPLAINER',
            payload:
                activeDomainExplainer === domainId
                    ? activeDomainExplainer
                    : domainId,
        });
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: 0,
        });
    };

    if (!cognitiveMapV2) {
        return null;
    }

    return (
        <Wrapper>
            <Title
                isMobile={screenSize.isMobile}
                isTablet={screenSize.isTablet}
            >
                {cognitiveMapV2.staticContent.chartTitle}
            </Title>
            <InnerWrapper>
                <LeftColumn>
                    <SpineChartWrapper
                        isSmallOrMedium={
                            screenSize.isMobile || screenSize.isTablet
                        }
                    >
                        <SpineChart
                            domains={cognitiveMapV2.domains}
                            onClick={onSpineChartClick}
                            legend={{
                                leftChartHeading:
                                    cognitiveMapV2.staticContent
                                        .leftChartHeading,
                                rightChartHeading:
                                    cognitiveMapV2.staticContent
                                        .rightChartHeading,
                                domainsTooltip:
                                    cognitiveMapV2.staticContent
                                        .domainResultsVisual?.tooltip,
                                leftTooltip:
                                    cognitiveMapV2.staticContent
                                        .domainResultsVisual?.leftTooltip,
                                rightTooltip:
                                    cognitiveMapV2.staticContent
                                        .domainResultsVisual?.rightTooltip,
                            }}
                        />
                    </SpineChartWrapper>
                </LeftColumn>
                <RightColumn>
                    {activeDomainExplainer !== 0 &&
                        cognitiveMapV2.domains.map((domain) => (
                            <DomainCard
                                key={domain.domainId}
                                domain={domain}
                                show={activeDomainExplainer === domain.domainId}
                            />
                        ))}
                    <Explanation isMobile={screenSize.isMobile}>
                        <CardTitle isMobile={screenSize.isMobile}>
                            {
                                cognitiveMapV2.staticContent.chartExplanation
                                    ?.title
                            }
                        </CardTitle>
                        {cognitiveMapV2.staticContent.chartExplanation?.content.map(
                            (paragraph) => (
                                <Typography key={nanoid()}>
                                    {paragraph}
                                </Typography>
                            )
                        )}
                        <Typography fontWeight='bold'>
                            {
                                cognitiveMapV2.staticContent.chartExplanation
                                    ?.instruction
                            }
                        </Typography>
                    </Explanation>
                </RightColumn>
            </InnerWrapper>

            {cognitiveMapV2?.domains.map((domain) => (
                <DeepDiveSidebar
                    isOpen={openDomainDrawerId === domain.domainId}
                    domain={domain}
                    key={domain.domainId}
                    includeTopGap={includeSidebarTopGap}
                />
            ))}
        </Wrapper>
    );
};

export default CognitionDeepDive;
