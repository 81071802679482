import { pxToRem } from '../../utils/style-functions';
import { styled } from '@mui/material';

export const HeroPageHeader = styled('h1')(({ theme }) => ({
    fontSize: pxToRem(60),
    lineHeight: pxToRem(60),
    fontWeight: 700,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(48),
    },
}));
