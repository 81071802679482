import { CogInput, ErrorText } from '../../styled-components';
import { useEffect, useRef, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../main-app/pre-assessment/Field';
import { NewPasswordStrength } from '../NewPasswordStrength';
import { styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { usePasswordStrength } from '../../../custom-hooks/usePasswordStrength';

const PasswordStrengthWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const Password: React.FC = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { newValidPassword, newPasswordStrength, newHandlePasswordStrength } =
        usePasswordStrength();

    const {
        formState: { errors },
        register,
        setValue,
        watch,
    } = useFormContext<{ password: string }>();

    const password = watch('password');

    const [inputType, setInputType] = useState<'password' | 'text'>('password');

    useEffect(() => {
        if (password.length) {
            setValue('password', password, {
                shouldValidate: true,
            });
        }
    }, [newValidPassword]);

    useEffect(() => {
        if (inputRef.current) {
            const element = inputRef.current;
            element.focus();
        }
    }, [inputRef.current]);

    const { ref, ...rest } = register('password', {
        required: 'Password is required',
        validate: () => {
            return newValidPassword === true || 'Please enter a valid password';
        },
        maxLength: {
            value: 230,
            message: 'Please enter a maximum of 230 characters.',
        },
    });

    const togglePasswordHandler = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    return (
        <Field
            label='Password'
            labelFor='password'
            inputAction={{
                name:
                    inputType === 'password'
                        ? 'Show password'
                        : 'Hide password',
                action: togglePasswordHandler,
            }}
            hasError={!!errors.password}
            hideTooltip={true}
        >
            <>
                <CogInput
                    {...rest}
                    id='password'
                    type={inputType}
                    fullWidth
                    hasError={!!errors.password}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('password', e.target.value, {
                            shouldValidate: true,
                        });
                        newHandlePasswordStrength(e.target.value);
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='password'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
                <PasswordStrengthWrapper>
                    <NewPasswordStrength
                        passwordStrength={newPasswordStrength}
                        value={password}
                    />
                </PasswordStrengthWrapper>
            </>
        </Field>
    );
};
