import { ArrowRight, XCircle } from 'react-feather';
import { Button, Dialog as CogDialog } from '@cognassist/react-components';
import {
    EnglishFirstLanguage,
    EthnicGroup,
    Gender,
    HasFormerNeurodiversityDiagnosis,
    IGetLearnerResponse,
    IHealthDataLegalDocumentsResponse,
    NeurodivergenceType,
} from '../../../types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getDob, parseDob } from '../../../utils/dobFunctions';
import { useEffect, useState } from 'react';

import { AdditionalInformation } from './additional-info';
import { DateOfBirth } from './fields/DateOfBirth';
import { DiagnosedNeurodiversitySelect } from './fields/DiagnosedNeurodiversitySelect';
import { NeurodiversitiesSelect } from './fields/NeurodiversitiesSelect';
import { OtherNeurodivergenceTypeDetails } from './fields/OtherNeurodivergenceTypeDetails';
import { TermsConditionsCheckbox } from './fields/TermsConditionsCheckbox';
import { endpoints } from '../../../api/endpoints';
import { parseEnglishSecondLanguage } from '../../../utils/parseEnglishSecondLanguage';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { styled } from '@mui/material';
import { useAreNeurodiversityQuestionsEnabled } from '../../../custom-hooks/useAreNeurodiversityQuestionsEnabled';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useConfig } from '../../../custom-providers/ConfigProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const ActiveFields = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
}));

const RequiredMessage = styled('p', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    color: hasError ? theme.palette.error.main : 'inherit',
}));

export interface IFormFields {
    dateOfBirth: Date;
    englishFirstLanguage: EnglishFirstLanguage;
    termsConditionsConsent: boolean;
    ethnicGroup: EthnicGroup | '';
    hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    neurodivergenceTypes: NeurodivergenceType[];
    otherNeurodivergenceTypeDetails: string | null;
    gender: Gender | '';
}

interface IOwnProps {
    learnerDetails: IGetLearnerResponse;
    legalDocuments: IHealthDataLegalDocumentsResponse;
    isSmall?: boolean;
}

export const Form: React.FC<IOwnProps> = ({
    learnerDetails,
    legalDocuments,
    isSmall = false,
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { putLearnerPreAssessmentDetails } = endpoints();
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const {
        state: {
            userConfig: { userId, assessmentAccessCode, hasPendingAssessment },
        },
    } = useAuth();

    const showNeurodiversityQuestions = useAreNeurodiversityQuestionsEnabled();

    const {
        state: {
            appConfig: { assessmentBaseUrl },
        },
    } = useConfig();

    const canSubmitLegalDocuments =
        !learnerDetails.hasConsentedToHealthDataLegalDocuments;

    const gender =
        learnerDetails.gender === Gender.None ? '' : learnerDetails.gender;

    const ethnicGroup = learnerDetails.ethnicGroup ?? '';

    const methods = useForm<IFormFields>({
        defaultValues: {
            dateOfBirth: getDob(learnerDetails.dateOfBirth),
            termsConditionsConsent:
                learnerDetails.hasConsentedToHealthDataLegalDocuments,
            englishFirstLanguage: parseEnglishSecondLanguage(
                learnerDetails.hasEnglishAsASecondLanguage
            ),
            ethnicGroup: ethnicGroup,
            hasFormerNeurodiversityDiagnosis:
                learnerDetails.hasFormerNeurodiversityDiagnosis,
            neurodivergenceTypes: learnerDetails.neurodivergenceTypes,
            otherNeurodivergenceTypeDetails:
                learnerDetails.otherNeurodivergenceTypeDetails,
            gender: gender,
        },
    });

    const {
        watch,
        handleSubmit,
        setValue,
        formState: { isDirty, errors },
    } = methods;

    const watchTermsConditionsConsent = watch('termsConditionsConsent');

    useEffect(() => {
        if (!canSubmitLegalDocuments) {
            setValue('termsConditionsConsent', true);
        }
    }, [watchTermsConditionsConsent]);

    const goToDashboard = () => navigate('/dashboard');

    const getRequestBody = (formData: IFormFields) => ({
        dateOfBirth: parseDob(new Date(formData.dateOfBirth)),
        hasEnglishAsASecondLanguage: formData.englishFirstLanguage !== 'yes',
        legalDocumentIds: canSubmitLegalDocuments
            ? legalDocuments.latestLegalDocuments.map((doc) => doc.id)
            : [],
        ethnicGroup: formData.ethnicGroup === '' ? null : formData.ethnicGroup,
        hasFormerNeurodiversityDiagnosis:
            formData.hasFormerNeurodiversityDiagnosis,
        neurodivergenceTypes: formData.neurodivergenceTypes,
        otherNeurodivergenceTypeDetails:
            formData.otherNeurodivergenceTypeDetails,
        gender: formData.gender === '' ? Gender.None : formData.gender,
    });

    const onContinueSubmit: SubmitHandler<IFormFields> = async (formData) => {
        if (!userId) {
            return;
        }

        const { error } = await putLearnerPreAssessmentDetails({
            learnerUserId: userId,
            data: getRequestBody(formData),
        });

        if (error) {
            enqueueSnackbar(
                'Could not update your information, please try again.',
                {
                    variant: 'error',
                }
            );
            return;
        }

        if (hasPendingAssessment && assessmentBaseUrl && assessmentAccessCode) {
            window.open(
                `${assessmentBaseUrl}/${assessmentAccessCode}`,
                '_blank',
                'noreferrer'
            );
        }

        goToDashboard();
    };

    const onBackSubmit: SubmitHandler<IFormFields> = async (formData) => {
        if (!userId) {
            return;
        }

        // Ignore any possible request errors because this is a 'silent submit',
        // which happens just before the user leaves the page.
        await putLearnerPreAssessmentDetails({
            learnerUserId: userId,
            data: getRequestBody(formData),
        });

        goToDashboard();
    };

    const backHandler = () => {
        if (isDirty) {
            setShowDialog(true);
        } else {
            navigate(-1);
        }
    };

    const backConfirmationHandler = () => {
        handleSubmit(onBackSubmit, goToDashboard)();
    };

    const hasRequiredError =
        !!errors.dateOfBirth ||
        !!errors.hasFormerNeurodiversityDiagnosis ||
        !!errors.neurodivergenceTypes ||
        !!errors.termsConditionsConsent;

    return (
        <FormProvider {...methods}>
            <ActiveFields>
                {isSmall && (
                    <RequiredMessage hasError={hasRequiredError}>
                        Fields marked with an asterisk (*) are required
                    </RequiredMessage>
                )}
                <DateOfBirth isSmall={isSmall} />
                {showNeurodiversityQuestions && (
                    <>
                        <DiagnosedNeurodiversitySelect
                            defaultValue={
                                learnerDetails.hasFormerNeurodiversityDiagnosis
                            }
                            isSmall={isSmall}
                        />
                        <NeurodiversitiesSelect isSmall={isSmall} />
                        <OtherNeurodivergenceTypeDetails />
                    </>
                )}
                <TermsConditionsCheckbox
                    disabled={!canSubmitLegalDocuments}
                    isSmall={isSmall}
                />
                <AdditionalInformation
                    gender={gender}
                    ethnicGroup={ethnicGroup}
                    isSmall={isSmall}
                />
                <ButtonWrapper>
                    <Button
                        color='secondary'
                        onClick={backHandler}
                        text='Back'
                        startIcon={<XCircle />}
                    />
                    <Button
                        onClick={handleSubmit(onContinueSubmit)}
                        text='Continue'
                        endIcon={<ArrowRight />}
                    />
                </ButtonWrapper>
            </ActiveFields>
            <CogDialog
                open={showDialog}
                title='Are you sure you want to return to the previous page?'
                content={<></>}
                confirmButtonProps={{
                    text: 'Yes',
                    onClick: backConfirmationHandler,
                }}
                cancelButtonProps={{
                    color: 'inherit',
                    text: 'No',
                    onClick: () => setShowDialog(false),
                }}
            />
        </FormProvider>
    );
};
