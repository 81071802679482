import { Roles } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';
import { useIsSelfOnboardingClient } from '../custom-hooks/useIsSelfOnboardingClient';

export enum DashboardType {
    SYSADMIN = 1,
    CLIENTADMIN = 2,
    TUTORMANAGER = 3,
    TUTOR = 4,
    LEARNER = 6,
    SELFONBOARDINGLEARNER = 7,
    SELFONBOARDINGOTHER = 8,
}

type UseHasDashboardAccess = () => {
    dashboardType: DashboardType | null;
};

export const useHasDashboardAccess: UseHasDashboardAccess = () => {
    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    const isSelfOnboardingClient = useIsSelfOnboardingClient();

    if (isSelfOnboardingClient) {
        if (roles?.includes(Roles.Learner)) {
            return {
                dashboardType: DashboardType.SELFONBOARDINGLEARNER,
            };
        }

        return {
            dashboardType: DashboardType.SELFONBOARDINGOTHER,
        };
    }

    if (roles?.includes(Roles.SysAdmin)) {
        return {
            dashboardType: DashboardType.SYSADMIN,
        };
    }

    if (roles?.includes(Roles.ClientAdmin)) {
        return {
            dashboardType: DashboardType.CLIENTADMIN,
        };
    }

    if (roles?.includes(Roles.TutorManager)) {
        return {
            dashboardType: DashboardType.TUTORMANAGER,
        };
    }

    if (roles?.includes(Roles.Tutor)) {
        return {
            dashboardType: DashboardType.TUTOR,
        };
    }

    if (roles?.includes(Roles.Learner)) {
        return {
            dashboardType: DashboardType.LEARNER,
        };
    }

    return {
        dashboardType: null,
    };
};
