import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ReferenceArea,
    ReferenceLine,
    Text,
    XAxis,
    YAxis,
} from 'recharts';
import React, { Fragment } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICognitiveDomain } from '../../../types';
import { barColour } from './bar-colors';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { pxToRem } from '../../../utils/style-functions';

const SpineChartWrapper = styled('div')(() => ({
    width: 640,
    margin: '0 auto',
}));

const GraphWrapper = styled('div')(({ theme }) => ({
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[100],
    background: 'white',
    padding: theme.spacing(2),
}));

const TitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const LegendWrapper = styled('div')(() => ({
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: 55,
}));

const LegendLeft = styled('div')(({ theme }) => ({
    background: '#FFEBCE',
    padding: theme.spacing(1, 2),
    borderRadius: 2,
    flexBasis: '50%',
    textAlign: 'center',
    fontSize: pxToRem(12),
}));

const LegendRight = styled('div')(({ theme }) => ({
    background: '#E9F9C5',
    padding: theme.spacing(1, 2),
    borderRadius: 2,
    flexBasis: '50%',
    textAlign: 'center',
    fontSize: pxToRem(12),
}));

const LegendText = styled('div')(() => ({
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
}));

interface ICustomYAxisTick {
    x: number;
    y: number;
    payload: {
        value: string;
    };
}

export const CustomYAxisTick = (props: ICustomYAxisTick): JSX.Element => {
    return (
        <Text
            width={120}
            x={props.x - 130}
            y={props.y}
            verticalAnchor='middle'
            style={{
                fontSize: pxToRem(13),
            }}
        >
            {props.payload.value}
        </Text>
    );
};

interface ISpineChartLegend {
    leftChartHeading: string;
    rightChartHeading: string;
    domainsTooltip?: string;
    leftTooltip?: string;
    rightTooltip?: string;
}

const CustomLegend: React.FC<ISpineChartLegend> = ({
    leftChartHeading,
    rightChartHeading,
    domainsTooltip,
    leftTooltip,
    rightTooltip,
}): JSX.Element => {
    return (
        <TitleWrapper>
            <Typography sx={{ fontWeight: 700, fontSize: pxToRem(13), mr: 1 }}>
                Domains
            </Typography>
            <Tooltip
                title={
                    <Typography
                        sx={{
                            p: 1,
                        }}
                    >
                        {domainsTooltip}
                    </Typography>
                }
                arrow
                placement='bottom'
                enterTouchDelay={0}
            >
                <FontAwesomeIcon
                    style={{
                        visibility: domainsTooltip ? 'visible' : 'hidden',
                    }}
                    width='14px'
                    height='14px'
                    icon={byPrefixAndName.far['circle-question']}
                />
            </Tooltip>

            <LegendWrapper>
                <LegendLeft>
                    <LegendText>
                        {leftTooltip ? (
                            <Tooltip
                                title={
                                    <Typography
                                        sx={{
                                            p: 1,
                                        }}
                                    >
                                        {leftTooltip}
                                    </Typography>
                                }
                                arrow
                                placement='bottom'
                                enterTouchDelay={0}
                            >
                                <p>{leftChartHeading}</p>
                            </Tooltip>
                        ) : (
                            <p>{leftChartHeading}</p>
                        )}
                    </LegendText>
                </LegendLeft>
                <LegendRight>
                    <LegendText>
                        {rightTooltip ? (
                            <Tooltip
                                title={
                                    <Typography
                                        sx={{
                                            p: 1,
                                        }}
                                    >
                                        {rightTooltip}
                                    </Typography>
                                }
                                arrow
                                placement='bottom'
                                enterTouchDelay={0}
                            >
                                <p>{rightChartHeading}</p>
                            </Tooltip>
                        ) : (
                            <p>{rightChartHeading}</p>
                        )}
                    </LegendText>
                </LegendRight>
            </LegendWrapper>
        </TitleWrapper>
    );
};

interface IOwnProps {
    domains: ICognitiveDomain[];
    legend: ISpineChartLegend;
    onClick: (domainId: number) => void;
}

const SpineChart: React.FC<IOwnProps> = ({ domains, legend, onClick }) => {
    return (
        <SpineChartWrapper>
            <GraphWrapper>
                <BarChart
                    width={600}
                    height={500}
                    data={domains}
                    layout='vertical'
                    barSize={24}
                >
                    <Legend
                        verticalAlign='top'
                        wrapperStyle={{
                            top: 0,
                        }}
                        content={
                            <CustomLegend
                                leftChartHeading={legend.leftChartHeading}
                                rightChartHeading={legend.rightChartHeading}
                                domainsTooltip={legend.domainsTooltip}
                                leftTooltip={legend.leftTooltip}
                                rightTooltip={legend.rightTooltip}
                            />
                        }
                    />

                    <CartesianGrid
                        strokeDasharray=''
                        horizontal={false}
                        fillOpacity={1}
                    />

                    <ReferenceLine
                        x='0'
                        stroke='#4A4C5E'
                        strokeDasharray='6 4'
                    />

                    <ReferenceArea
                        x1={-3}
                        x2={0}
                        fill={'#FFEBCE33'}
                    ></ReferenceArea>

                    <ReferenceArea
                        x1={0}
                        x2={3}
                        fill={'#77C0000D'}
                    ></ReferenceArea>

                    <XAxis
                        hide
                        type='number'
                        tickCount={13}
                        interval={0}
                        ticks={[
                            -3, -2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2,
                            2.5, 3,
                        ]}
                    />

                    <YAxis
                        dataKey='domainName'
                        type='category'
                        tickLine={false}
                        axisLine={false}
                        width={140}
                        tick={CustomYAxisTick}
                    />

                    <Bar
                        dataKey='differenceZScore'
                        radius={4}
                        onClick={(e) => onClick(e.domainId)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {domains.map((entry) => (
                            <Fragment key={`cell-${entry.domainName}`}>
                                <Cell fill={barColour(entry.domainId)}></Cell>
                            </Fragment>
                        ))}
                    </Bar>
                </BarChart>
            </GraphWrapper>
        </SpineChartWrapper>
    );
};

export default SpineChart;
