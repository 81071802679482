import {
    ICognitiveMapV1,
    ICognitiveMapV2,
    IScreenSize,
    StrategyStatus,
} from '../types';
import React, { useContext, useMemo } from 'react';

import { useImmerReducer } from 'use-immer';

interface ICognitiveMapProvider {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    cognitiveMapV1: ICognitiveMapV1 | null;
    cognitiveMapV2: ICognitiveMapV2 | null;
    openDomainDrawerId: number;
    activeLmsSourceId: string | null;
    activeStrategyStatus: StrategyStatus | null;
    caiDrawerOpen: boolean;
    roleDrawerOpen: boolean;
    activeDomainExplainer: number;
    screenSize: IScreenSize;
};

export const initialState = {
    cognitiveMapV1: null,
    cognitiveMapV2: null,
    openDomainDrawerId: 0,
    activeLmsSourceId: null,
    activeStrategyStatus: null,
    caiDrawerOpen: false,
    roleDrawerOpen: false,
    activeDomainExplainer: 0,
    screenSize: {
        isMobile: false,
        isTablet: false,
    },
};

type Action =
    | {
          type: 'SET_COGNITIVE_MAP_V1';
          payload: ICognitiveMapV1;
      }
    | {
          type: 'SET_COGNITIVE_MAP_V2';
          payload: ICognitiveMapV2;
      }
    | {
          type: 'SET_OPEN_DOMAIN_DRAWER_ID';
          payload: number;
      }
    | {
          type: 'SET_ACTIVE_LMS_SOURCE_ID';
          payload: string | null;
      }
    | {
          type: 'SET_CAI_DRAWER_OPEN';
          payload: boolean;
      }
    | {
          type: 'SET_ROLE_DRAWER_OPEN';
          payload: boolean;
      }
    | {
          type: 'SET_ACTIVE_DOMAIN_EXPLAINER';
          payload: number;
      }
    | {
          type: 'SET_SCREEN_SIZE';
          payload: IScreenSize;
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_COGNITIVE_MAP_V1':
            state.cognitiveMapV1 = action.payload;
            break;
        case 'SET_COGNITIVE_MAP_V2':
            state.cognitiveMapV2 = action.payload;
            break;
        case 'SET_OPEN_DOMAIN_DRAWER_ID':
            state.openDomainDrawerId = action.payload;
            break;
        case 'SET_ACTIVE_LMS_SOURCE_ID':
            state.activeLmsSourceId = action.payload;
            break;
        case 'SET_CAI_DRAWER_OPEN':
            state.caiDrawerOpen = action.payload;
            break;
        case 'SET_ROLE_DRAWER_OPEN':
            state.roleDrawerOpen = action.payload;
            break;
        case 'SET_ACTIVE_DOMAIN_EXPLAINER':
            state.activeDomainExplainer = action.payload;
            break;
        case 'SET_SCREEN_SIZE':
            state.screenSize = action.payload;
            break;
    }
};

export const CognitiveMap = React.createContext<ICognitiveMapProvider>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const CognitiveMapProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const CognitiveMapMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <CognitiveMap.Provider value={CognitiveMapMemo}>
            {children}
        </CognitiveMap.Provider>
    );
};

export const useCognitiveMap = (): ICognitiveMapProvider => {
    return useContext(CognitiveMap);
};
