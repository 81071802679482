import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { LoadingWrapper } from '../../../components/main-app/community/shared-components';
import OnboardingHandler from '../../../components/main-app/community/OnboardingHandler';
import PageNotFound from '../../PageNotFound';
import { endpoints } from '../../../api/endpoints';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const Community: React.FC = () => {
    const communityEnabled = useFeatureIsOn('new-community');

    const { getCommunityDisplayName, getOrganisationCommunityDisplayName } =
        endpoints();

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { dispatch } = useCommunityState();

    useEffect(() => {
        document.title = 'Community';
        requestData();
    }, []);

    const requestData = async () => {
        setLoading(true);

        const [{ data: aData, error: aError }, { data: bData, error: bError }] =
            await Promise.all([
                getCommunityDisplayName(),
                getOrganisationCommunityDisplayName(),
            ]);

        if (aError || bError) {
            setError(true);
        }

        if (aData) {
            dispatch({
                type: 'SET_COMMUNITY_DISPLAY_NAME',
                payload: { communityDisplayName: aData.displayName },
            });
        }

        if (bData) {
            dispatch({
                type: 'SET_ORGANISATION_COMMUNITY_DISPLAY_NAME',
                payload: {
                    organisationCommunityDisplayName:
                        bData.communityDisplayName,
                },
            });
        }

        setLoading(false);
    };

    if (!communityEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    if (loading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (error) {
        return <div>An error occurred.</div>;
    }

    return <OnboardingHandler />;
};

export default Community;
