import { ChampionStatus, Roles } from '../types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { endpoints } from '../api/endpoints';
import { useAuth } from './AuthProvider';
import { useInterval } from '../custom-hooks/useInterval';

const INTERVAL = 60000; // 1 minute

interface IChampionNotificationsProvider {
    state: State;
    refreshState: () => void;
}

type State = {
    hasNewSupportRequests: boolean;
    hasNewChampionVolunteers: boolean;
};

export const initialState = {
    state: {
        hasNewSupportRequests: false,
        hasNewChampionVolunteers: false,
    },
    refreshState: () => {},
};

export const ChampionNotificationsContext =
    React.createContext<IChampionNotificationsProvider>(initialState);

interface IOwnProps {
    children: React.ReactNode;
}

export const ChampionNotificationsProvider: React.FC<IOwnProps> = ({
    children,
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [userIsCertifiedChampion, setUserIsCertifiedChampion] =
        useState<boolean>(false);

    const [hasNewSupportRequests, setHasNewSupportRequests] =
        useState<boolean>(false);
    const [hasNewChampionVolunteers, setHasNewChampionVolunteers] =
        useState<boolean>(false);

    const {
        getNewChampionSupportRequestsCount,
        getNewChampionVolunteerCount,
        getUserChampionStatus,
    } = endpoints();

    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    const clearCheckForNewSupportRequestsInterval = useInterval(() => {
        checkForNewSupportRequests();
    }, INTERVAL);

    const clearCheckForNewChampionVolunteers = useInterval(() => {
        checkForNewChampionVolunteers();
    }, INTERVAL);

    useEffect(() => {
        checkForNewSupportRequests();
        checkForNewChampionVolunteers();
    }, []);

    const resetHasNewSupportRequests = () => setHasNewSupportRequests(false);
    const resetHasNewChampionVolunteers = () =>
        setHasNewChampionVolunteers(false);

    const checkForNewSupportRequests = async () => {
        // Clear the interval if the user is not a champion
        if (!roles?.includes(Roles.Champion)) {
            clearCheckForNewSupportRequestsInterval();
            return;
        }

        // Do not request data if it's already loading
        if (loading) {
            return;
        }

        // We already know that the user is a certified champion, so we do not need to call getUserChampionStatus
        if (userIsCertifiedChampion) {
            setLoading(true);
            await requestNewSupportRequestsCount();
            setLoading(false);
        } else {
            setLoading(true);

            const { data, error } = await getUserChampionStatus();

            if (error) {
                setLoading(false);
                clearCheckForNewSupportRequestsInterval();
                resetHasNewSupportRequests();
            }

            if (data?.status === ChampionStatus.Certified) {
                // "Cache" the fact that the user is a certified champion
                if (!userIsCertifiedChampion) {
                    setUserIsCertifiedChampion(true);
                }

                await requestNewSupportRequestsCount();
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    };

    const requestNewSupportRequestsCount = async () => {
        const { data, error } = await getNewChampionSupportRequestsCount();
        setLoading(true);

        if (error) {
            clearCheckForNewSupportRequestsInterval();
            resetHasNewSupportRequests();
            setLoading(false);
        }

        if (data) {
            // Only update hasNewSupportRequests when it is necessary (i.e it changed from false to false or from true to false)
            if (
                !(hasNewSupportRequests && data.count > 0) &&
                !(!hasNewSupportRequests && data.count === 0)
            ) {
                setHasNewSupportRequests(data.count > 0);
            }
            setLoading(true);
        }
    };

    const checkForNewChampionVolunteers = async () => {
        if (!roles?.includes(Roles.ClientAdmin)) {
            clearCheckForNewChampionVolunteers();
            return;
        }

        if (loading) {
            return;
        }

        if (roles?.includes(Roles.ClientAdmin)) {
            setLoading(true);
            await requestNewChampionVolunteerCount();
            setLoading(false);
        }
    };

    const requestNewChampionVolunteerCount = async () => {
        const { data, error } = await getNewChampionVolunteerCount();

        if (error) {
            clearCheckForNewChampionVolunteers();
            resetHasNewChampionVolunteers();
        }

        if (data) {
            if (
                !(hasNewChampionVolunteers && data.count > 0) &&
                !(!hasNewChampionVolunteers && data.count === 0)
            ) {
                setHasNewChampionVolunteers(data.count > 0);
            }
        }
    };

    const checkForNewChampionNotifications = () => {
        checkForNewSupportRequests();
        checkForNewChampionVolunteers();
    };

    const memo = useMemo(() => {
        return {
            state: { hasNewSupportRequests, hasNewChampionVolunteers },
            refreshState: checkForNewChampionNotifications,
        };
    }, [
        hasNewSupportRequests,
        hasNewChampionVolunteers,
        checkForNewChampionNotifications,
    ]);

    return (
        <ChampionNotificationsContext.Provider value={memo}>
            {children}
        </ChampionNotificationsContext.Provider>
    );
};

export const useChampionNotifications = (): IChampionNotificationsProvider =>
    useContext(ChampionNotificationsContext);
