import {
    Bullet,
    BulletList,
    Container,
    GraphContainer,
    Section,
} from './styled-components';
import { IAPIContent, IStandardScore } from '../../types';
import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import BannerLink from './BannerLink';
import DomainDrawer from './DomainDrawer';
import HowYouScoredGraph from './graphs/domain-graph';
import { cognitiveDomains } from '../../content/learner-assessment-report/cognitiveDomains';
import { domains } from '../../content/learner-assessment-report/domainLookup';
import { endpoints } from '../../api/endpoints';
import man from '../../assets/img/banner-man.jpg';
import { pxToRem } from '../../utils/style-functions';
import { useGrabLibraryLink } from '../../custom-hooks/useGrabLibraryLink';
import { useIsSelfOnboardingClient } from '../../custom-hooks/useIsSelfOnboardingClient';
import { useLearnerId } from '../../custom-hooks/useLearnerId';
import { useNavigate } from 'react-router-dom';

const SectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: pxToRem(24),
    lineHeight: '150%',
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(24),
        fontWeight: 600,
    },
}));

interface IDomainData {
    domainId: number;
    contents: IAPIContent[];
}

interface IDomainCard {
    domainId: number;
    domainName: string;
    domainExplanation: string[];
    assessmentExplanation: string[];
    assessmentScreenshot: { url: string; alt: string };
    links: { url: string; text: string }[];
    whatItMeans: string[];
    score: number;
}

export const CognitiveDomains: React.FC = () => {
    const { getDomainData, getLearnerDetails } = endpoints();
    const learnerUserId = useLearnerId();
    const navigate = useNavigate();
    const isSelfOnboardingClient = useIsSelfOnboardingClient();
    const { grabLibraryLink } = useGrabLibraryLink();
    const [standardScores, setStandardScores] = useState<IStandardScore[]>([]);
    const [domainData, setDomainData] = useState<IDomainData[]>([]);
    const [hasEnglishAsASecondLanguage, setHasEnglishAsASecondLanguage] =
        useState<boolean>(false);
    const [parsedDomainContent, setParsedDomainContent] = useState<
        IDomainCard[]
    >([]);

    useEffect(() => {
        if (learnerUserId) {
            fetchData();
        }
    }, [learnerUserId]);

    useEffect(() => {
        if (domainData && standardScores) {
            parseDomainData(domainData);
        }
    }, [domainData, standardScores]);

    const fetchData = async () => {
        const [
            { data: domainData, error: domainError },
            { data: learnerData, error: learnerError },
        ] = await Promise.all([
            getDomainData(learnerUserId),
            getLearnerDetails(learnerUserId),
        ]);

        if (domainError || learnerError) {
            navigate('/something-went-wrong', { replace: true });
        }

        if (domainData && learnerData) {
            setStandardScores(domainData.standardScores);
            setDomainData(domainData.domainData.domains);
            setHasEnglishAsASecondLanguage(
                learnerData.hasEnglishAsASecondLanguage
            );
        }
    };

    const parseDomainData = (domainData: IDomainData[]) => {
        const reducedDomains: IDomainCard[] = domains.reduce(
            (acc: IDomainCard[], current) => {
                const whatItMeans = domainData
                    .find((domain) => domain.domainId === current.id)
                    ?.contents.find(
                        (contents) => contents.key === 'WhatItMeans'
                    )?.value;

                const cognitiveDomainContent = cognitiveDomains.find(
                    (domain) => domain.id === current.id
                );

                const domainScore = standardScores.find(
                    (domain) => domain.domainId === current.id
                )?.standardScore;

                if (whatItMeans && cognitiveDomainContent && domainScore) {
                    acc.push({
                        domainName: current.name,
                        domainId: current.id,
                        domainExplanation:
                            cognitiveDomainContent?.domainExplanation,
                        assessmentExplanation:
                            cognitiveDomainContent.assessmentExplanation,
                        assessmentScreenshot:
                            cognitiveDomainContent.assessmentScreenshot,
                        whatItMeans,
                        links: cognitiveDomainContent.links,
                        score: domainScore,
                    });
                }
                return acc;
            },
            []
        );

        setParsedDomainContent(reducedDomains);
    };

    return (
        <>
            <Container id='cognitive-domains'>
                <Section>
                    <SectionTitle variant='h1'>Cognitive Domains</SectionTitle>
                    <Typography sx={{ mb: 3 }}>
                        Measuring cognition is a tricky business, but scientists
                        have come up with a relatively simple way to use
                        cognitive assessment scores to understand people&apos;s
                        strengths and any areas where workplace flexibilities
                        might be needed.
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        The graph shows you how you scored for each of the nine
                        cognitive domains measured during the assessment, with
                        the typical range represented by the yellow area and the
                        most common score of 100 by the dotted line in the
                        middle.
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        Remember, diversity is a natural part of life; no two
                        people are the same, so a &apos;normal&apos; cognitive
                        profile doesn&apos;t exist, there are just similarities
                        and differences.
                    </Typography>
                </Section>
                <Section>
                    <GraphContainer>
                        {domainData && (
                            <HowYouScoredGraph
                                standardScores={standardScores}
                                hasEnglishAsASecondLanguage={
                                    hasEnglishAsASecondLanguage
                                }
                            />
                        )}
                    </GraphContainer>
                </Section>
                <Section>
                    <SectionTitle variant='h1'>
                        What are the nine domains and how were they assessed?
                    </SectionTitle>
                    <Typography sx={{ mb: 3 }}>
                        Cognitive domains are simply some of the methods our
                        brains use to intake, process and make use of
                        information. Together, they help to build your knowledge
                        and develop your intellectual skills. Domains are not
                        just relevant for learning or working; everyday tasks
                        also require multiple domains to work at the same time,
                        so you can complete a specific task, like driving a car
                        or cooking a meal.
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        By assessing the following cognitive domains, we can
                        give you a better insight into the types of tasks you
                        are more likely to feel confident completing, and those
                        that you may find more challenging.
                    </Typography>
                </Section>
                <Section>
                    <SectionTitle variant='h1'>
                        In your cognition deep dive, you will find six relevant
                        daily activities:
                    </SectionTitle>
                    <BulletList sx={{ mb: 3, listStyleType: 'decimal' }}>
                        <Bullet>Learning & Memory</Bullet>
                        <Bullet>Organisation & Planning</Bullet>
                        <Bullet>Working Digitally</Bullet>
                        <Bullet>Working with Others</Bullet>
                        <Bullet>Communication</Bullet>
                        <Bullet>Problem-Solving</Bullet>
                    </BulletList>
                    <Typography sx={{ mb: 3 }}>
                        Each of these daily activities can be affected by
                        multiple cognitive domains.
                    </Typography>
                </Section>
                {parsedDomainContent?.map((domain) => {
                    return (
                        <DomainDrawer
                            domainId={domain.domainId}
                            key={domain.domainName}
                            domainName={domain.domainName}
                            score={domain.score}
                            domainExplanation={domain.domainExplanation}
                            assessmentExplanation={domain.assessmentExplanation}
                            whatItMeans={domain.whatItMeans}
                            assessmentScreenshot={domain.assessmentScreenshot}
                            links={domain.links}
                        />
                    );
                })}
            </Container>
            <BannerLink
                title='Ready to expand your knowledge?'
                body='Explore learning modules, articles, videos and more to put these insights into practice!'
                link={'Visit NeuroVersity'}
                location='https://cognassist.com/knowledge-hub/'
                image={man}
                alt='smiling man excited to learn about processing bias'
                {...(isSelfOnboardingClient
                    ? { onClick: grabLibraryLink }
                    : {})}
            />
        </>
    );
};

export default CognitiveDomains;
