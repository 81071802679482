import { FormControl, MenuItem, Select } from '@mui/material';

import { CogInput } from '../../../../styled-components';
import { EthnicGroup } from '../../../../../types';
import { Field } from '../../Field';
import { inputHelperText } from '../../../../../content/profile/input-helper-text';
import { useFormContext } from 'react-hook-form';
import { useProfileFocus } from '../../../../../custom-hooks/useProfileFocus';

interface IOwnProps {
    defaultValue: EthnicGroup | '';
    isSmall?: boolean;
}

export const EthnicGroupSelect: React.FC<IOwnProps> = ({
    defaultValue,
    isSmall = false,
}) => {
    const { onFocus, onBlur } = useProfileFocus();

    const { register } = useFormContext<{
        ethnicGroup: EthnicGroup | '';
    }>();

    const validate = (v: EthnicGroup | '') =>
        ['', 1, 2, 3, 4, 5, 6].includes(v);

    return (
        <Field
            label='What is your ethnic group?'
            labelId='ethnicGroupLabel'
            content={inputHelperText.find(
                (content) => content.key === 'ethnicGroup'
            )}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Select
                    {...register('ethnicGroup', {
                        validate: validate,
                    })}
                    onFocus={(e) => onFocus(e, 'ethnicGroup')}
                    onBlur={onBlur}
                    defaultValue={defaultValue}
                    labelId='ethnicGroupLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby': 'ethnicGroupLabel',
                            }}
                        />
                    }
                >
                    <MenuItem value={EthnicGroup.White}>White</MenuItem>
                    <MenuItem value={EthnicGroup.Asian}>Asian</MenuItem>
                    <MenuItem value={EthnicGroup.Black}>
                        Black, African, Caribbean
                    </MenuItem>
                    <MenuItem value={EthnicGroup.Mixed}>Mixed</MenuItem>
                    <MenuItem value={EthnicGroup.Other}>
                        Other ethnic group
                    </MenuItem>
                    <MenuItem value={EthnicGroup.PreferNotToSay}>
                        Prefer not to say
                    </MenuItem>
                </Select>
            </FormControl>
        </Field>
    );
};
