import {
    DashboardType,
    useHasDashboardAccess,
} from '../../../custom-hooks/useHasDashboardAccess';
import { FormFields, Tooltips } from '../../../components/styled-components';
import { useRef, useState } from 'react';

import { Form } from '../../../components/main-app/pre-assessment/Form';
import { InfoBox } from '../../../components/main-app/pre-assessment/InfoBox';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageNotFound from '../../PageNotFound';
import { ProfileProvider } from '../../../components/main-app/pre-assessment/ProfileProvider';
import { pxToRem } from '../../../utils/style-functions';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useLearnerProfile } from '../../../custom-hooks/useLearnerProfile';
import useResizeObserver from '@react-hook/resize-observer';

const ProfileWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const PageTitle = styled('h1')(() => ({
    fontSize: pxToRem(44),
    lineHeight: pxToRem(60),
    fontWeight: 'normal',
    marginBottom: 32,
}));

export const PreAssessment: React.FC = () => {
    const { dashboardType } = useHasDashboardAccess();
    const { learnerDetails, legalDocuments } = useLearnerProfile();

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [isSmall, setIsSmall] = useState<boolean>(false);

    const {
        state: {
            userConfig: { hasPendingAssessment },
        },
    } = useAuth();

    useResizeObserver(wrapperRef, (entry) => {
        const { width } = entry.contentRect;
        setIsSmall(width < 1015);
    });

    if (
        dashboardType !== DashboardType.SELFONBOARDINGLEARNER ||
        !hasPendingAssessment
    ) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <ProfileProvider negativeTopOffset={85}>
            <MainContentInner>
                <ProfileWrapper ref={wrapperRef}>
                    <FormFields>
                        <PageTitle>A few more details about you...</PageTitle>
                        {learnerDetails.data && legalDocuments.data && (
                            <Form
                                learnerDetails={learnerDetails.data}
                                legalDocuments={legalDocuments.data}
                                isSmall={isSmall}
                            />
                        )}
                    </FormFields>
                    <Tooltips>
                        <InfoBox isSmall={isSmall} />
                    </Tooltips>
                </ProfileWrapper>
            </MainContentInner>
        </ProfileProvider>
    );
};
