import Logo from '../../../assets/img/logo-new.svg';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';

const Wrapper = styled('div')(() => ({
    display: 'grid',
    height: '100vh',
    gridTemplateRows: 'auto 1fr',
}));

const Header = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    padding: theme.spacing(5, 4),
    boxShadow: '0px 8px 16px 0px #0000000A',
    position: 'relative',
}));

const SiteLogo = styled('img')(() => ({
    maxWidth: 140,
    display: 'flex',
}));

const Content = styled('div')(() => ({
    background: '#F6F7FB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const SimpleLayout: React.FC = () => {
    return (
        <Wrapper>
            <Header>
                <SiteLogo src={Logo} alt='Cognassist logo' />
            </Header>
            <Content>
                <Outlet />
            </Content>
        </Wrapper>
    );
};

export default SimpleLayout;
