import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    styled,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { IContextsualisedStrategy } from '../../../types';
import { Sidebar } from 'react-feather';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const StrategyNav = styled('div')(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    maxWidth: 320,
    width: '100%',
    padding: theme.spacing(3),
    overflow: 'auto',
}));

const HistoryCategory = styled(ListItemButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    fontWeight: '600',
    bgcolor: theme.palette.grey[100],
    '&:hover': { bgcolor: theme.palette.grey[100] },
}));

const HistoryStrategy = styled(ListItemButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    '&:hover': { bgcolor: 'inherit' },
}));

const HistoryStrategyListItem = styled(ListItemText, {
    ...shouldForwardProps('active'),
})<{ active?: boolean }>(({ active }) => ({
    '& .MuiTypography-root': {
        fontWeight: active ? 600 : 'normal',
    },
}));

interface IOwnProps {
    messageDone: boolean;
    strategies: IContextsualisedStrategy[];
}

export const HistoryNav: React.FC<IOwnProps> = ({
    messageDone,
    strategies,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [strategyMenuOpen, setStrategyMenuOpen] = useState<boolean>(true);

    const {
        state: { activeLmsSourceId },
        dispatch,
    } = useCognitiveMap();

    const handleClick = () => {
        setStrategyMenuOpen(!strategyMenuOpen);
    };

    const handleStrategyClick = (strat: IContextsualisedStrategy) => {
        if (!messageDone) {
            enqueueSnackbar(
                'Please wait for Cai to finish the current message',
                {
                    variant: 'error',
                }
            );

            return;
        }

        dispatch({
            type: 'SET_ACTIVE_LMS_SOURCE_ID',
            payload: strat.lmsSourceId,
        });
    };

    return (
        <StrategyNav>
            <Sidebar />

            <List component='nav'>
                <HistoryCategory onClick={handleClick}>
                    <ListItemText primary='My Cognitive Map' />
                    {strategyMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </HistoryCategory>
                <Collapse in={strategyMenuOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {strategies.map((strat) => (
                            <HistoryStrategy
                                key={strat.sessionId}
                                disableTouchRipple
                            >
                                <HistoryStrategyListItem
                                    primary={strat.title}
                                    active={
                                        strat.lmsSourceId === activeLmsSourceId
                                    }
                                    onClick={() => handleStrategyClick(strat)}
                                />
                            </HistoryStrategy>
                        ))}
                    </List>
                </Collapse>
            </List>
        </StrategyNav>
    );
};
