import { FormControl, MenuItem, Select } from '@mui/material';

import { CogInput } from '../../../../styled-components';
import { Field } from '../../Field';
import { Gender as IGender } from '../../../../../types';
import { inputHelperText } from '../../../../../content/profile/input-helper-text';
import { useFormContext } from 'react-hook-form';
import { useProfileFocus } from '../../../../../custom-hooks/useProfileFocus';

interface IGenderOption {
    label: string;
    value: IGender;
    disabled?: boolean;
}

const options: IGenderOption[] = [
    { label: 'Male', value: IGender.Male },
    { label: 'Female', value: IGender.Female },
    { label: 'Non-binary/Non-conforming', value: IGender.NonBinary },
    { label: 'Another', value: IGender.Another },
    {
        label: 'Prefer not to say',
        value: IGender.NotDisclosed,
    },
];

interface IOwnProps {
    defaultValue: IGender | '';
    isSmall?: boolean;
}

export const Gender: React.FC<IOwnProps> = ({
    defaultValue,
    isSmall = false,
}) => {
    const { onFocus, onBlur } = useProfileFocus();

    const {
        formState: { errors },
        register,
    } = useFormContext<{ gender: IGender | '' }>();

    const validate = (v: IGender | '') => ['', 1, 2, 3, 4, 5].includes(v);

    return (
        <Field
            label='How would you describe your gender?'
            labelId='sexLabel'
            required={false}
            hasError={!!errors.gender}
            content={inputHelperText.find(
                (content) => content.key === 'gender'
            )}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Select
                    {...register('gender', {
                        validate: validate,
                    })}
                    onFocus={(e) => onFocus(e, 'gender')}
                    onBlur={onBlur}
                    defaultValue={defaultValue}
                    labelId='sexLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby': 'sexLabel',
                            }}
                            hasError={!!errors.gender}
                        />
                    }
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Field>
    );
};
