import { IStrategy, StrategyStatus } from '../../../types';

import { Button } from '@cognassist/react-components';
import IconCognassistant from '../../../assets/img/icons/caicon.svg';
import React from 'react';
import { Userpilot } from 'userpilot';
import { endpoints } from '../../../api/endpoints';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';

const StrategiesButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

interface IOwnProps {
    course: IStrategy;
}

export const CaiStrategyButton: React.FC<IOwnProps> = ({ course }) => {
    const { getUserJobRole } = endpoints();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const { dispatch } = useCognitiveMap();

    const isAlreadyTrying = course.userStrategyStatus === StrategyStatus.TRYING;

    const buttonText = isAlreadyTrying
        ? 'View strategy'
        : 'Apply this to my role';

    const getJobRole = async (id: string) => {
        const { data } = await getUserJobRole(id);
        return data;
    };

    const strategyHandler = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        isAlreadyTrying: boolean
    ) => {
        e.stopPropagation();

        if (!isAlreadyTrying) {
            Userpilot.track('Applied strategy to role');
        }

        dispatch({
            type: 'SET_ACTIVE_LMS_SOURCE_ID',
            payload: course.lmsSourceId,
        });

        if (userId) {
            const data = await getJobRole(userId);

            if (data) {
                if (!data.jobRole) {
                    dispatch({ type: 'SET_ROLE_DRAWER_OPEN', payload: true });
                } else {
                    dispatch({ type: 'SET_CAI_DRAWER_OPEN', payload: true });
                }
            }
        }
    };

    return (
        <StrategiesButton
            text={buttonText}
            fullWidth
            onClick={(e) => strategyHandler(e, isAlreadyTrying)}
            startIcon={<img src={IconCognassistant} alt='' />}
        />
    );
};
