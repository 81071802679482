import { ButtonWrapper, ListItemCheck, Title } from './shared-components';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';

const BulletWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

const BulletList = styled('ul')(({ theme }) => ({
    paddingLeft: theme.spacing(0),
}));

const ListItem = styled('li')(({ theme }) => ({
    listStyleType: 'none',
    display: 'flex',
    marginBottom: theme.spacing(2),
}));

const CommunityExplainer: React.FC = () => {
    const { dispatch } = useCommunityState();

    return (
        <>
            <Title variant='h1'>Welcome to the Cognassist Community</Title>
            <Typography sx={{ mb: 3 }}>
                We&apos;re so glad you&apos;re here. This is a supportive
                community where we celebrate neuro-inclusion.
            </Typography>
            <BulletWrapper>
                <BulletList>
                    <ListItem>
                        <ListItemCheck color='white' />
                        <Typography>
                            <strong>
                                Connect with a Supportive Community:{' '}
                            </strong>
                            Join a welcoming network to share experiences and
                            learn from others who value neuro-inclusion.
                        </Typography>
                    </ListItem>

                    <ListItem>
                        <ListItemCheck color='white' />
                        <Typography>
                            <strong>Get Expert Advice:</strong> Access expert
                            advice and participate in regular customer-only
                            webinars.{' '}
                        </Typography>
                    </ListItem>

                    <ListItem>
                        <ListItemCheck color='white' />
                        <Typography>
                            <strong>Inclusive Communication:</strong> Help shape
                            our community&apos;s knowledge through surveys,
                            polls, and interactive feedback.
                        </Typography>
                    </ListItem>

                    <ListItem>
                        <ListItemCheck color='white' />
                        <Typography>
                            <strong>Celebrate & Stay Informed:</strong> Enjoy
                            curated content, event promotions, and weekly
                            updates on neuro-inclusion.
                        </Typography>
                    </ListItem>
                </BulletList>
            </BulletWrapper>
            <ButtonWrapper>
                <Button
                    text='Continue'
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 2 },
                        });
                    }}
                ></Button>
            </ButtonWrapper>
        </>
    );
};

export default CommunityExplainer;
