import { Typography, styled } from '@mui/material';

import { IDeepDivePageDomain } from '../../../../types';
import { parseApiContent } from '../../../../utils/displayFunctions';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    maxWidth: 1280,
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(8, 'auto'),
    padding: theme.spacing(0, 3),
    flexFlow: 'row wrap',
    gap: theme.spacing(8),
}));
const BallsWrapper = styled('div')(({ theme }) => ({
    height: 600,
    position: 'relative',
    flex: '1 1 220px',
    [theme.breakpoints.down(744)]: {
        height: 380,
    },
}));
const ContentWrapper = styled('div')(() => ({
    flex: '1 1 440px',
}));
const Content = styled('div')(() => ({
    display: 'flex',
}));
const TabsWrapper = styled('div')(({ theme }) => ({
    boxShadow: theme.cogShadows.base,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    padding: theme.spacing(1),
    display: 'flex',
    marginBottom: theme.spacing(3),
}));
export const TabItem = styled('div', {
    ...shouldForwardProps('selected'),
})<{ selected?: boolean }>(({ selected, theme }) => ({
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 4),
    borderRadius: 4,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(18),
    fontWeight: 600,
    cursor: 'pointer',
    background: selected ? '#9FD916' : 'none',
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        height: 'auto',
        minHeight: 56,
    },
}));
const TabsContent = styled('div')(({ theme }) => ({
    '& ul, & li, & p': {
        marginBottom: theme.spacing(2),
    },
}));

export const Ball = styled('div', {
    ...shouldForwardProps('big'),
})<{ big: boolean }>(({ big, theme }) => ({
    textAlign: 'center',
    borderRadius: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: 20,
    height: !big ? 300 : 350,
    width: !big ? 300 : 350,
    background: !big ? '#e4f0d1' : '#b8dd7d',
    bottom: !big ? 0 : 'initial',
    right: !big ? 0 : 'initial',
    position: !big ? 'absolute' : 'relative',
    zIndex: !big ? 1 : 2,
    [theme.breakpoints.down(744)]: {
        height: 200,
        width: 200,
    },
}));

const BallTitle = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(16),
        lineHeight: '150%',
        fontWeight: 600,
    },
}));

const BallText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(16),
        lineHeight: '150%',
    },
}));

interface IOwnProps {
    reverse?: boolean;
    domains: IDeepDivePageDomain[];
}

export const Domain: React.FC<IOwnProps> = ({ domains }) => {
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [sortedDomains] = useState<IDeepDivePageDomain[]>(
        [...domains].sort((a, b) => b.score - a.score)
    );

    return (
        <Wrapper id='domains'>
            <BallsWrapper>
                {sortedDomains.map((domain, index) => (
                    <Ball big={index === 0} key={domain.id}>
                        <BallTitle variant='h1'>{domain.name}</BallTitle>
                        <BallText variant='h2'>
                            You scored: {domain.score}
                        </BallText>
                    </Ball>
                ))}
            </BallsWrapper>
            <ContentWrapper>
                <Content>
                    <TabsWrapper id='domainTabs'>
                        {sortedDomains.map((domain, index) => (
                            <TabItem
                                selected={selectedTab === index + 1}
                                key={domain.id}
                                onClick={() => setSelectedTab(index + 1)}
                            >
                                <span>{domain.name}</span>
                            </TabItem>
                        ))}
                    </TabsWrapper>
                </Content>

                <TabsContent id='tabContent'>
                    <Typography variant='h1' sx={{ mb: 3 }}>
                        {sortedDomains[selectedTab - 1].header}
                    </Typography>
                    {parseApiContent(sortedDomains[selectedTab - 1].domainText)}
                    {parseApiContent(sortedDomains[selectedTab - 1].domainList)}
                </TabsContent>
            </ContentWrapper>
        </Wrapper>
    );
};

export default Domain;
