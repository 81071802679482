import {
    IDashboardGraph,
    IDashboardGraphItem,
    IParsedDashboardGraph,
} from '../types';
import { useCallback, useEffect, useState } from 'react';

import { graphConfig } from '../content/dashboard-config';
import { useTheme } from '@mui/material';

const COLORS = [
    '#7458FF',
    '#FEDB00',
    '#F08DB9',
    '#7FBFFF',
    '#B28FFF',
    '#CEE8A3',
    '#F59C4C',
    '#F9D1E3',
    '#B3D2FB',
    '#B7B7BF',
    '#77C000',
    '#FEF2A3',
    '#FBDCBF',
    '#E4002B',
];

export const useParseGraphs = (graphData?: IDashboardGraph[]) => {
    const theme = useTheme();

    const [parsedGraphs, setParsedGraphs] = useState<IParsedDashboardGraph[]>(
        []
    );

    useEffect(() => {
        if (graphData) {
            parseGraphs(graphData);
        }
    }, [graphData]);

    const getColors = useCallback((isBinary: boolean) => {
        if (isBinary) {
            return [theme.palette.primary.main, theme.palette.grey[100]];
        } else {
            return COLORS;
        }
    }, []);

    const parseGraphs = (graphs: IDashboardGraph[]) => {
        setParsedGraphs(
            graphs.reduce((accum: IParsedDashboardGraph[], current) => {
                const currentGraphConfig = graphConfig.find(
                    (graph) => graph.chartId === current.chartId
                );

                if (currentGraphConfig) {
                    accum.push({
                        ...currentGraphConfig,
                        graphItems: parseGraphItems(
                            current.chartDetail,
                            currentGraphConfig.isBinary ?? false
                        ),
                    });
                }
                return accum;
            }, [])
        );
    };

    const parseGraphItems = (
        graphItems: IDashboardGraphItem[],
        isBinary: boolean
    ) => {
        if (graphItems.length === 0) {
            return [];
        }

        const totalValue = getTotalValue(graphItems);

        let runningTotal = 0;

        const _graphItems = graphItems
            .map((graphItem: IDashboardGraphItem, index: number) => {
                const actualValue = (graphItem.value / totalValue) * 100;

                runningTotal = runningTotal + Math.floor(actualValue);

                return {
                    ...graphItem,
                    color: getColors(isBinary)[index],
                    actualValue,
                    remainderValue: actualValue % 1,
                    percentageValue: Math.floor(actualValue),
                    index,
                };
            })
            .sort((a, b) => {
                if (a.remainderValue < b.remainderValue) {
                    return 1;
                }

                if (b.remainderValue < a.remainderValue) {
                    return -1;
                }

                return 0;
            });

        for (let i = 0; i < 100 - runningTotal; i++) {
            _graphItems[i].percentageValue = _graphItems[i].percentageValue + 1;
        }

        return _graphItems.sort(
            (a, b) => b.percentageValue - a.percentageValue
        );
    };

    const getTotalValue = (items: IDashboardGraphItem[]) =>
        items.reduce((acc, current) => acc + current.value, 0);

    return parsedGraphs;
};
