import {
    AccountSettingsGroup,
    AccountSettingsHeader,
    AccountSettingsInput,
    AccountSettingsLabel,
    ButtonWrapper,
} from './shared-components';
import { Eye, EyeOff } from 'react-feather';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styled-components';
import { NewPasswordStrength } from '../../self-onboarding/NewPasswordStrength';
import axios from 'axios';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useLogoutUrl } from '../../../custom-hooks/useLogoutUrl';
import { usePasswordStrength } from '../../../custom-hooks/usePasswordStrength';
import { useSnackbar } from 'notistack';

interface IPasswordResetForm {
    oldPassword: string;
    newPassword: string;
}

interface IOwnProps {
    isSmall: boolean;
}

export const PasswordReset: React.FC<IOwnProps> = ({ isSmall }) => {
    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const { logoutHandler } = useLogoutUrl();

    const { newValidPassword, newPasswordStrength, newHandlePasswordStrength } =
        usePasswordStrength();

    const { enqueueSnackbar } = useSnackbar();

    const { updatePassword } = endpoints();

    const [inputType, setInputType] = useState<'password' | 'text'>('password');

    const methods = useForm<IPasswordResetForm>({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
        },
    });

    const {
        watch,
        handleSubmit,
        setValue,
        register,
        formState: { errors },
    } = methods;

    const newPasswordValue = watch('newPassword');
    const oldPasswordValue = watch('oldPassword');

    register('oldPassword', {
        required: 'You must enter your current password.',
    });

    register('newPassword', {
        required: 'You must enter your new password.',
        validate: () => {
            if (newValidPassword !== true) {
                return 'Please enter a valid password';
            } else if (newPasswordValue === oldPasswordValue) {
                return 'New password cannot be the same as old password.';
            }

            return true;
        },
    });

    const togglePasswordHandler = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    useEffect(() => {
        if (newPasswordValue.length) {
            setValue('newPassword', newPasswordValue, {
                shouldValidate: true,
            });
        }
    }, [newPasswordValue]);

    const getRequestBody = (formData: IPasswordResetForm) => {
        return {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
        };
    };

    const savePassword: SubmitHandler<IPasswordResetForm> = async (
        formData
    ) => {
        if (userId) {
            const { error } = await updatePassword({
                learnerUserId: userId,
                data: getRequestBody(formData),
            });

            if (error) {
                if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 401
                ) {
                    enqueueSnackbar('Password incorrect.', {
                        variant: 'error',
                    });
                } else if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 400 &&
                    error.response.data.errors['Password'][0]
                ) {
                    enqueueSnackbar(error.response.data.errors['Password'][0], {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('An unexpected error has occurred.', {
                        variant: 'error',
                    });
                }
            } else {
                enqueueSnackbar('Your password was changed successfully', {
                    variant: 'success',
                });
                logoutHandler();
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <AccountSettingsHeader>Change password</AccountSettingsHeader>

            <AccountSettingsGroup>
                <AccountSettingsLabel htmlFor='oldPassword'>
                    Current password
                </AccountSettingsLabel>
                <AccountSettingsInput
                    id='oldPassword'
                    type='password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('oldPassword', e.target.value, {
                            shouldValidate: true,
                        });
                    }}
                    hasError={!!errors.oldPassword}
                />
                <ErrorMessage
                    errors={errors}
                    name='oldPassword'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </AccountSettingsGroup>

            <AccountSettingsGroup>
                <AccountSettingsLabel htmlFor='newPassword'>
                    New password
                </AccountSettingsLabel>
                <AccountSettingsInput
                    id='newPassword'
                    type={inputType}
                    hasError={!!errors.newPassword}
                    endAdornment={
                        inputType === 'password' ? (
                            <Eye
                                style={{ cursor: 'pointer' }}
                                onClick={togglePasswordHandler}
                            />
                        ) : (
                            <EyeOff
                                style={{ cursor: 'pointer' }}
                                onClick={togglePasswordHandler}
                            />
                        )
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('newPassword', e.target.value, {
                            shouldValidate: true,
                        });
                        newHandlePasswordStrength(e.target.value);
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='newPassword'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </AccountSettingsGroup>

            <NewPasswordStrength
                passwordStrength={newPasswordStrength}
                value={newPasswordValue}
            />

            <ButtonWrapper isSmall={isSmall}>
                <Button
                    text='Save password'
                    onClick={handleSubmit(savePassword)}
                ></Button>
            </ButtonWrapper>
        </FormProvider>
    );
};
