import {
    CardMedia,
    Card as MuiCard,
    CardActions as MuiCardActions,
    CardContent as MuiCardContent,
    Typography,
    styled,
} from '@mui/material';

import { Button } from '@cognassist/react-components';
import { Check } from 'react-feather';
import React from 'react';
import { pxToRem } from '../../../../utils/style-functions';
import { useGrabLibraryLink } from '../../../../custom-hooks/useGrabLibraryLink';

const Card = styled(MuiCard)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.spacing(2),
    boxShadow: theme.cogShadows.base,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    padding: theme.spacing(3, 0),
    height: '100%',
}));

const CardActions = styled(MuiCardActions)(() => ({
    padding: 0,
}));

const CompletedDateText = styled(Typography)(() => ({
    fontSize: pxToRem(13),
    lineHeight: pxToRem(24),
    display: 'flex',
    alignItems: 'center',
}));

const CheckBackground = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 16,
    width: 16,
    backgroundColor: '#F1EEFF',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
}));

interface IOwnProps {
    courseId: string;
    courseTitle: string;
    image: string;
    dateCompleted: string | null;
}

const DashboardCourseCard: React.FC<IOwnProps> = ({
    courseId,
    courseTitle,
    image,
    dateCompleted,
}) => {
    const { grabLibraryLink, loading: linkLoading } = useGrabLibraryLink();

    return (
        <Card>
            <CardMedia
                component='img'
                image={image}
                alt={`${courseTitle} course cover image`}
            />
            <CardContent>
                <Typography variant='h4' component='p'>
                    {courseTitle}
                </Typography>
                {dateCompleted && (
                    <CompletedDateText variant='body1' color='primary'>
                        <CheckBackground>
                            <Check size={10} strokeWidth={5} />
                        </CheckBackground>
                        Completed: {dateCompleted}
                    </CompletedDateText>
                )}
            </CardContent>
            <CardActions>
                <Button
                    loading={linkLoading}
                    text='Go to training'
                    size='medium'
                    onClick={(e) => {
                        grabLibraryLink(e, `/course/${courseId}`);
                    }}
                />
            </CardActions>
        </Card>
    );
};

export default DashboardCourseCard;
