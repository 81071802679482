import { createStyles, makeStyles } from '@mui/styles';

import { SVGProps } from 'react';
import { Tooltip } from '@mui/material';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            background: '#33383F',
            padding: 16,
            fontSize: pxToRem(14),
            lineHeight: pxToRem(15),
            fontFamily: 'FS Me',
        },
        arrow: {
            color: '#33383F',
        },
    })
);

interface IOwnProps extends SVGProps<SVGTextElement> {
    isMobile: boolean;
}

export const WarningShapeTriangle: React.FC<IOwnProps> = ({
    isMobile = true,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Tooltip
            title='We did not capture enough data to provide this insight'
            arrow
            placement={window.innerWidth < 600 ? 'bottom' : 'right'}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            enterTouchDelay={0}
        >
            <svg
                width='27'
                height='24'
                viewBox='0 0 27 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                x={
                    isMobile
                        ? (props.cx as number) - 14
                        : (props.cx as number) + 14
                }
                y={
                    isMobile
                        ? (props.cy as number) - 34
                        : (props.cy as number) - 14
                }
            >
                <path
                    d='M11.2905 1.24397L0.34631 19.5144C0.120666 19.9052 0.00127358 20.3482 1.01329e-05 20.7994C-0.00125332 21.2507 0.115657 21.6944 0.339109 22.0864C0.562561 22.4784 0.884766 22.8051 1.27367 23.0339C1.66257 23.2628 2.10461 23.3858 2.55582 23.3908H24.4442C24.8954 23.3858 25.3374 23.2628 25.7263 23.0339C26.1152 22.8051 26.4374 22.4784 26.6609 22.0864C26.8843 21.6944 27.0013 21.2507 27 20.7994C26.9987 20.3482 26.8793 19.9052 26.6537 19.5144L15.7095 1.24397C15.4792 0.864229 15.1548 0.550263 14.7678 0.332367C14.3808 0.114471 13.9441 0 13.5 0C13.0559 0 12.6192 0.114471 12.2322 0.332367C11.8452 0.550263 11.5208 0.864229 11.2905 1.24397Z'
                    fill='#FF7777'
                />
                <path
                    d='M13.5 7.8855V13.0539'
                    stroke='#9F0000'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M13.5 18.2224H13.5124'
                    stroke='#9F0000'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </Tooltip>
    );
};
