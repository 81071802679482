import { createStyles, makeStyles } from '@mui/styles';

import NavBar from '../../../components/assessment-report/nav-bar';
import imgWarning from '../../../assets/img/warning.png';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            marginTop: '20vh',
            textAlign: 'center',
            '& h1': {
                marginTop: 24,
                textalign: 'center',
            },
            '& p': {
                marginTop: 24,
                textalign: 'center',
            },
        },
    })
);

export const InsufficientPermissionsError: React.FC = () => {
    const classes = useStyles();
    return (
        <div>
            <NavBar blank={true} />
            <div className={classes.wrapper}>
                <img src={imgWarning} alt='Triangle with exclaimation point' />
                <h1>Insufficient permissions</h1>
                <p>
                    Sorry, you do not have permission to complete this action.
                </p>
            </div>
        </div>
    );
};

export default InsufficientPermissionsError;
