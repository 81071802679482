import { Typography, styled } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { pxToRem } from '../../../../utils/style-functions';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const StrengthsWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
}));

const CogTypeText = styled('p')(({ theme }) => ({
    fontSize: pxToRem(16),
    marginBottom: theme.spacing(3),
}));

const TipWrapper = styled('div')(({ theme }) => ({
    background: '#F1EEFF',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

const TipIcon = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const TipName = styled('p')(() => ({
    fontWeight: 700,
    display: 'inline',
    marginRight: 4,
}));

const TipText = styled('p')(() => ({
    display: 'inline',
}));

const Strengths: React.FC = () => {
    const {
        state: { cognitiveMapV2 },
    } = useCognitiveMap();

    if (!cognitiveMapV2) {
        return null;
    }

    return (
        <StrengthsWrapper id='strengths-container'>
            <Typography
                variant='h3'
                sx={{ fontWeight: 500, fontSize: pxToRem(20) }}
                mb={1}
            >
                {cognitiveMapV2?.staticContent.whatDoesThisMean?.title}
            </Typography>
            <CogTypeText>
                As a <strong>{cognitiveMapV2?.cogType?.cogTypeName}</strong>,
                here&apos;s how you can use your strengths in your job:
            </CogTypeText>
            {cognitiveMapV2?.cogType?.tips.map((tip) => (
                <TipWrapper key={tip.title}>
                    <TipIcon>
                        <FontAwesomeIcon icon={byPrefixAndName.far[tip.icon]} />
                    </TipIcon>
                    <div>
                        <TipName>{tip.title}:</TipName>
                        <TipText>{tip.description}</TipText>
                    </div>
                </TipWrapper>
            ))}
        </StrengthsWrapper>
    );
};

export default Strengths;
