import { Pagination, Table } from '@cognassist/react-components';
import { SelectChangeEvent, styled } from '@mui/material';
import { initialState, reducer } from '../../state/table-state';
import { useEffect, useState } from 'react';

import format from 'date-fns/format';
import { getDefaultPageSize } from '../../utils/tables';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';
import { useFLPAPI } from '../../custom-hooks/useFLPAPI';
import { useImmerReducer } from 'use-immer';
import { useLearnerId } from '../../custom-hooks/useLearnerId';

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

export const CompletedModules: React.FC = () => {
    const learnerUserId = useLearnerId();
    const { getCompletedModules } = useFLPAPI({ learnerUserId });

    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('completedModulesPageSize', 10)
    );
    const [
        { startRecord, recordsLoaded, totalRecords, totalPages, currentPage },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const {
        state: { completedModules },
    } = useFLP();

    useEffect(() => {
        getCompletedModules();
    }, [pageSize]);

    useEffect(() => {
        getCompletedModules({
            pageIndex: currentPage,
        });
    }, [currentPage]);

    useEffect(() => {
        if (completedModules) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(
                        completedModules.totalRecords / pageSize
                    ),
                    endRecord:
                        startRecord + completedModules.modules.length - 1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: completedModules.totalRecords,
            });
        }
    }, [completedModules]);

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: value },
        });
    };

    const setRecordsPerPage = (evt: SelectChangeEvent<unknown>) => {
        setPageSize(parseInt(evt.target.value as string));
        localStorage.setItem(
            'completedModulesPageSize',
            evt.target.value as string
        );
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: 1 },
        });
    };

    if (!recordsLoaded || !completedModules) {
        return null;
    }

    return (
        <>
            <Table
                ariaTableLabel='Completed modules table'
                showTableInfo={true}
                showRecordsPerPage={true}
                visibleRows={totalRecords < pageSize ? totalRecords : pageSize}
                totalRows={totalRecords}
                rowsPerPage={pageSize}
                setRowsPerPage={setRecordsPerPage}
                tableHeadings={[
                    'Name',
                    'Date accessed',
                    'Skills',
                    'Categories',
                ]}
                rows={completedModules.modules.map((module) => ({
                    id: module.id,
                    rowData: [
                        module.title,
                        module.dateCompleted &&
                            format(
                                new Date(module.dateCompleted),
                                'dd/MM/yyyy HH:mm:ss'
                            ),
                        module.skills.map((skill) => skill.name).join(', '),
                        module.categories
                            .map((category) => category.name)
                            .join(', '),
                    ],
                }))}
                isDraggable={false}
            />

            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination
                        page={currentPage}
                        onChange={updatePageNumber}
                        count={totalPages}
                        boundaryCount={6}
                    />
                </PaginationWrapper>
            )}
        </>
    );
};
