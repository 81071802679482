import { useEffect } from 'react';
import { FlexibleLearningPlan } from '../../../../../../components/flexible-learning-plan';

export const FlexibleLearningPlanLayout: React.FC = () => {
    useEffect(() => {
        document.title = 'Flexible Learning Plan';
    }, []);

    return (
        <FlexibleLearningPlan
            sideOpen={true}
            setSideOpen={() => false}
            fullScreen={true}
        />
    );
};

export default FlexibleLearningPlanLayout;
