import { Button, ButtonProps, Theme, Typography, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { IParsedChartData } from './ChartCard';

const Pagination = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const paginationButtonProps = (theme: Theme) => ({
    cursor: 'pointer',
    borderRadius: 8,
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    minWidth: 0,
    color: theme.palette.text.primary,
});

export const PaginationLeftIcon = styled((props: ButtonProps) => (
    <Button {...props}>
        <>
            <ChevronLeft color={props.color} />
            <span className='visually-hidden'>Previous Page</span>
        </>
    </Button>
))(({ theme }) => ({
    ...paginationButtonProps(theme),
}));

export const PaginationRightIcon = styled((props: ButtonProps) => (
    <Button {...props}>
        <>
            <ChevronRight />
            <span className='visually-hidden'>Next Page</span>
        </>
    </Button>
))(({ theme }) => ({
    ...paginationButtonProps(theme),
}));

const PaginationText = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0),
    padding: theme.spacing(0, 1),
    fontWeight: 'normal',
}));

interface IOwnProps {
    togglePage: (next?: boolean) => void;
    currentPage: number;
    parsedKeys: IParsedChartData[][];
}

export const MiniPagination: React.FC<IOwnProps> = ({
    togglePage,
    currentPage,
    parsedKeys,
}) => {
    return (
        <Pagination id='pagination'>
            <PaginationLeftIcon
                id='previous'
                onClick={() => togglePage(false)}
                disabled={currentPage === 0}
            />
            <PaginationText variant='body2'>
                {currentPage + 1}/{parsedKeys.length}
            </PaginationText>
            <PaginationRightIcon
                id='next'
                onClick={() => togglePage()}
                disabled={parsedKeys[currentPage + 1] === undefined}
            />
        </Pagination>
    );
};
