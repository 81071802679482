import { CogInput, ErrorText } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

export const Email: React.FC = () => {
    const {
        formState: { errors },
        register,
        getValues,
    } = useFormContext<{ email: string }>();

    const email = getValues('email');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('email', {
        required: 'Please enter an email address',
        maxLength: {
            value: 230,
            message:
                'You have entered too many characters, please reduce it and try again.',
        },
        pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Must be a valid email address',
        },
    });

    return (
        <Field
            label='Email'
            labelFor='email'
            required={true}
            hasError={!!errors.email}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={email}
                    id='email'
                    fullWidth
                    hasError={!!errors.email}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='email'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
