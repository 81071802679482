import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { styled } from '@mui/material';

export const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto',
    [theme.breakpoints.down('md')]: {
        flexFlow: 'row wrap',
    },
}));

export const KeyWrapper = styled('div', {
    ...shouldForwardProps('isLocked'),
})<{ isLocked?: boolean }>(({ isLocked, theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 50%',
    marginRight: theme.spacing(2),
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(-2),
    filter: `blur(${isLocked ? '10px' : '0'})`,
    '> *': {
        marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
        order: 2,
        flex: '1 1 100%',
        marginRight: theme.spacing(0),
    },
}));

export const ChartWrapper = styled('div', {
    ...shouldForwardProps('isLocked'),
})<{ isLocked?: boolean }>(({ isLocked, theme }) => ({
    flex: '1 1 50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    filter: `blur(${isLocked ? '10px' : '0'})`,
    pointerEvents: isLocked ? 'none' : 'auto',
    [theme.breakpoints.down('md')]: {
        order: 1,
        flex: '1 1 100%',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        filter: `blur(${isLocked ? '20px' : '0'})`,
        minWidth: 220,
    },
}));
