import { Save, XCircle } from 'react-feather';
import { Typography, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@cognassist/react-components';
import CloseIcon from '@mui/icons-material/Close';
import CloseModal from './CloseModal';
import { CurrentModules } from './CurrentModules';
import Footer from '../main-app/Footer';
import { Loading } from '../Loading';
import { Modules } from './Modules';
import { PriorityModules } from './PriorityModules';
import capitaliseFirstLetter from '../../utils/transformFirstLetter';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';
import { useFLPAPI } from '../../custom-hooks/useFLPAPI';
import { useSnackbar } from 'notistack';

const FLPWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    background: theme.palette.grey[50],
    height: '100vh',
    overflow: 'hidden',
}));

const FLPInnerWrapper = styled('div')(({ theme }) => ({
    background: '#fff',
    height: 'calc(100vh - 100px)',
    boxSizing: 'border-box',
    borderRadius: 32,
    boxShadow: theme.cogShadows.lg,
    [theme.breakpoints.down(744)]: {
        height: 'calc(100vh - 130px)',
    },
}));

const HeaderSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 104,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: 24,
}));

const MainSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

const PrioritySection = styled('div')(({ theme }) => ({
    flex: '0 0 370px',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    height: 'calc(100vh - 104px - 100px)',
    overflowY: 'scroll',
    borderBottomRightRadius: 32,
    [theme.breakpoints.down(744)]: {
        height: 'calc(100vh - 104px - 130px)',
    },
}));

const ButtonsWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

interface IOwnProps {
    sideOpen: boolean;
    setSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fullScreen?: boolean;
}

export const FlexibleLearningPlan: React.FC<IOwnProps> = ({ setSideOpen }) => {
    const { learnerUserId = 'INVALID_ID' } = useParams<{
        learnerUserId: string;
    }>();

    const [searchParams] = useSearchParams();

    const [learnerName, setLearnerName] = useState<string>();
    const { enqueueSnackbar } = useSnackbar();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);

    const {
        getPriorityModules,
        getUpcomingModules,
        getCompletedModules,
        getCurrentModules,
        getFilters,
        updatePriorityModules,
    } = useFLPAPI({ learnerUserId });

    const {
        state: {
            initialPriorityModules,
            priorityModules,
            completedModules,
            priorityModuleTimestamp,
        },
        dispatch,
    } = useFLP();

    useEffect(() => {
        if (learnerUserId) {
            getData();
        }
    }, [learnerUserId]);

    useEffect(() => {
        if (completedModules?.learner) {
            setLearnerName(
                `${capitaliseFirstLetter(
                    completedModules.learner.firstName
                )} ${capitaliseFirstLetter(completedModules.learner.lastName)}`
            );
        }
    }, [completedModules]);

    const getData = async () => {
        await Promise.all([
            getPriorityModules(),
            getUpcomingModules({
                categories: [],
                skills: [],
            }),
            getCompletedModules(),
            getCurrentModules(),
            getFilters(),
        ]);
        setSaveButtonDisabled(false);
        setLoading(false);
    };

    const [isDirty, setIsDirty] = useState<boolean>(false);

    const unloadHandler = useCallback((e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
    }, []);

    useEffect(() => {
        if (isDirty) {
            window.addEventListener('beforeunload', unloadHandler);
        } else {
            window.removeEventListener('beforeunload', unloadHandler);
        }
    }, [isDirty]);

    useEffect(() => {
        if (JSON.stringify(priorityModules) !== initialPriorityModules) {
            setIsDirty(true);
            setSaveButtonDisabled(false);
        } else {
            setIsDirty(false);
        }
    }, [initialPriorityModules, priorityModules]);

    const closeHandler = () => {
        if (isDirty) {
            setDialogOpen(true);
        } else {
            setSideOpen(false);
            if (searchParams.get('returnurl')) {
                window.location.assign(searchParams.get('returnurl') as string);
            }
        }
    };

    const saveAndCloseDialog = async () => {
        if (saveButtonDisabled) {
            return;
        }
        window.removeEventListener('beforeunload', unloadHandler);
        setSaveButtonDisabled(true);
        const success = await updatePriorityModules({
            modules: priorityModules,
            timestamp: priorityModuleTimestamp,
        });

        if (success) {
            setDialogOpen(false);
            setSideOpen(false);
            if (searchParams.get('returnurl')) {
                window.location.assign(searchParams.get('returnurl') as string);
            }
        }
    };

    const continueNoSave = () => {
        window.removeEventListener('beforeunload', unloadHandler);
        setDialogOpen(false);
        setSideOpen(false);
        if (searchParams.get('returnurl')) {
            window.location.assign(searchParams.get('returnurl') as string);
        }
    };

    const saveHandler = async () => {
        if (saveButtonDisabled) {
            return;
        }

        setSaveButtonDisabled(true);
        const success = await updatePriorityModules({
            modules: priorityModules,
            timestamp: priorityModuleTimestamp,
        });
        if (success) {
            enqueueSnackbar('Your changes have been saved', {
                variant: 'success',
            });
        }
        dispatch({
            type: 'SET_INITIAL_PRIORITY_MODULES',
            payload: JSON.stringify(priorityModules),
        });
        setIsDirty(false);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <FLPWrapper>
                <FLPInnerWrapper>
                    <HeaderSection>
                        <Typography variant='h2'>
                            Flexible learning plan for {learnerName}
                        </Typography>
                        <ButtonsWrapper>
                            <Button
                                disabled={!isDirty}
                                text='Save'
                                onClick={saveHandler}
                                color='inherit'
                                sx={{ mr: 1 }}
                                endIcon={<Save size={18} />}
                                variant='outlined'
                            />
                            <Button
                                disabled={!isDirty}
                                sx={{
                                    mr: 1,
                                }}
                                onClick={saveAndCloseDialog}
                                text='Save and close'
                                endIcon={<XCircle size={18} />}
                            />

                            <CloseIcon
                                role='button'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => closeHandler()}
                            />
                        </ButtonsWrapper>
                    </HeaderSection>
                    <MainSection>
                        <Modules />
                        <PrioritySection>
                            <CurrentModules />
                            <PriorityModules />
                        </PrioritySection>
                    </MainSection>
                </FLPInnerWrapper>
                <Footer />
            </FLPWrapper>
            <CloseModal
                open={dialogOpen}
                handleOpenDialog={setDialogOpen}
                saveAndCloseDialog={saveAndCloseDialog}
                continueNoSave={continueNoSave}
            />
        </>
    );
};
