import { Controller, useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    styled,
} from '@mui/material';
import { useRef } from 'react';

import { EnglishFirstLanguage } from '../../../../../types';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../../../styled-components';
import { Field } from '../../Field';
import { inputHelperText } from '../../../../../content/profile/input-helper-text';
import { shouldForwardProps } from '../../../../../utils/shouldForwardProp';
import { useProfileFocus } from '../../../../../custom-hooks/useProfileFocus';

export const RadioOption = styled(FormControlLabel, {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    color: hasError ? theme.palette.error.main : 'inherit',
    '& .MuiFormControlLabel-label': {
        fontWeight: hasError ? 'bold' : 'inherit',
    },
}));

interface IOwnProps {
    isSmall?: boolean;
}

export const FirstLanguageRadio: React.FC<IOwnProps> = ({
    isSmall = false,
}) => {
    const { onFocus, onBlur } = useProfileFocus();
    const ref = useRef<HTMLInputElement>(null);

    const {
        formState: { errors },
        control,
        watch,
    } = useFormContext<{ englishFirstLanguage: EnglishFirstLanguage }>();

    const englishFirstLanguage = watch('englishFirstLanguage');

    return (
        <Field
            label='Is English your first language?'
            required={false}
            labelId='englishFirstLanguage'
            hasError={!!errors.englishFirstLanguage}
            content={inputHelperText.find(
                (content) => content.key === 'englishFirstLanguage'
            )}
            isSmall={isSmall}
        >
            <>
                <FormControl>
                    <Controller
                        control={control}
                        name={'englishFirstLanguage'}
                        defaultValue={englishFirstLanguage}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                aria-labelledby='englishFirstLanguage'
                                ref={ref}
                            >
                                <RadioOption
                                    value='yes'
                                    control={
                                        <Radio
                                            onFocus={(e) =>
                                                onFocus(
                                                    e,
                                                    'englishFirstLanguage'
                                                )
                                            }
                                            onBlur={onBlur}
                                        />
                                    }
                                    label='Yes'
                                    hasError={!!errors.englishFirstLanguage}
                                />
                                <RadioOption
                                    value='no'
                                    control={
                                        <Radio
                                            onFocus={(e) =>
                                                onFocus(
                                                    e,
                                                    'englishFirstLanguage'
                                                )
                                            }
                                            onBlur={onBlur}
                                        />
                                    }
                                    label='No'
                                    hasError={!!errors.englishFirstLanguage}
                                />
                            </RadioGroup>
                        )}
                    />
                </FormControl>
                <ErrorMessage
                    errors={errors}
                    name={'englishFirstLanguage'}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
