import IconClose from '../../../assets/img/icons/close.svg';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { styled } from '@mui/material';

const Expand = styled('button', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ theme }) => ({
    height: 35,
    width: 35,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 24,
    top: 38,
    cursor: 'pointer',
    border: '1px solid #D9D9D9',
    padding: '0 2px',
}));

interface IOwnProps {
    setNavExpand: React.Dispatch<React.SetStateAction<boolean>>;
    navExpand: boolean;
}

export const NavExpandButton: React.FC<IOwnProps> = ({
    navExpand,
    setNavExpand,
}) => {
    return (
        <Expand navExpand={navExpand} onClick={() => setNavExpand(!navExpand)}>
            {navExpand ? (
                <img src={IconClose} alt='expand' />
            ) : (
                <img
                    src={IconClose}
                    alt='collapse'
                    style={{ transform: 'rotate(180deg)' }}
                />
            )}
        </Expand>
    );
};
