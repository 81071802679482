import { Dialog } from '@cognassist/react-components';
import { Typography } from '@mui/material';

interface IOwnProps {
    open: boolean;
    loading: boolean;
    closeHandler: () => void;
    restartAssessmentHandler: () => Promise<void>;
}

export const AssessmentRestartDialog: React.FC<IOwnProps> = ({
    open,
    loading,
    closeHandler,
    restartAssessmentHandler,
}) => {
    return (
        <Dialog
            open={open}
            title='Assessment restart'
            content={
                <>
                    <Typography>
                        You have already started the assessment and need to
                        start it again.
                    </Typography>
                    <Typography component='ul' sx={{ pl: 4 }}>
                        <Typography component='li'>
                            Please ensure you do not exit until completion.
                        </Typography>
                    </Typography>
                    <Typography component='ul' sx={{ pl: 4 }}>
                        <Typography component='li'>
                            Clicking &apos;Restart&apos; will take you to the
                            assessment.
                        </Typography>
                    </Typography>
                    <Typography component='ul' sx={{ pl: 4 }}>
                        <Typography component='li'>
                            If you are encountering technical issues, please
                            contact support@cognassist.com.
                        </Typography>
                    </Typography>
                </>
            }
            confirmButtonProps={{
                text: 'Restart',
                loading: loading,
                onClick: restartAssessmentHandler,
                id: 'assessment-restart-confirm-button',
            }}
            cancelButtonProps={{
                color: 'inherit',
                text: 'Cancel',
                disabled: loading,
                onClick: closeHandler,
            }}
        />
    );
};
