import {
    Dialog as CogDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    styled,
} from '@mui/material';

import { Button } from '@cognassist/react-components';
import { IParsedLegalDoc } from '../../types';
import { pxToRem } from '../../utils/style-functions';
import { useEffect, useRef } from 'react';
import { useHasScrolledToBottom } from '../../custom-hooks/useHasScrolledToBottom';

const Dialog = styled(CogDialog)(({ theme }) => ({
    '& h1': {
        margin: theme.spacing(2.5, 0),
        fontSize: pxToRem(32),
    },
    '& p': {
        margin: theme.spacing(2, 0),
    },
    '& .MuiDialogContent-root > div > ul': {
        margin: theme.spacing(2, 0),
    },
}));

interface IOwnProps {
    legalDocument: IParsedLegalDoc;
}

export const LegalDocumentDialog: React.FC<IOwnProps> = ({ legalDocument }) => {
    return (
        <Dialog
            open={legalDocument.isOpen}
            onClose={legalDocument.closeDialog}
            maxWidth='md'
            aria-labelledby={legalDocument.name}
            aria-describedby={legalDocument.name}
        >
            <DialogTitle>{legalDocument.name}</DialogTitle>
            <LegalDocumentDialogContent
                html={legalDocument.body ?? ''}
                onBottomScroll={legalDocument.onScrollToBottom}
            />
            <DialogActions>
                <Button
                    text='Close'
                    autoFocus
                    onClick={legalDocument.closeDialog}
                />
            </DialogActions>
        </Dialog>
    );
};

interface IDialogContentProps {
    html: string;
    onBottomScroll: () => void;
}

const LegalDocumentDialogContent: React.FC<IDialogContentProps> = ({
    html,
    onBottomScroll,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const isOnBottom = useHasScrolledToBottom(ref);

    useEffect(() => {
        if (isOnBottom) {
            onBottomScroll();
        }
    }, [isOnBottom]);

    return (
        <DialogContent ref={ref}>
            <div
                dangerouslySetInnerHTML={{
                    __html: html,
                }}
            />
        </DialogContent>
    );
};
