import { ReactElement, useRef, useState } from 'react';
import { Typography, styled } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';

const AccordionWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    position: 'relative',
}));

const TopWrapper = styled('div', {
    ...shouldForwardProps('isExpanded'),
})<{ isExpanded: boolean }>(({ isExpanded, theme }) => ({
    background: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: isExpanded ? 'none' : `1px solid ${theme.palette.grey[100]}`,
    borderBottomRightRadius: isExpanded ? 0 : theme.shape.borderRadius,
    borderBottomLeftRadius: isExpanded ? 0 : theme.shape.borderRadius,
    position: 'relative',
    zIndex: 2,
}));

const ChevronWrapper = styled('div', {
    ...shouldForwardProps('isExpanded'),
})<{ isExpanded: boolean }>(({ isExpanded }) => ({
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s linear',
}));

const BottomWrapper = styled('div', {
    ...shouldForwardProps('isExpanded', 'addPadding'),
})<{ isExpanded: boolean; addPadding: boolean }>(
    ({ isExpanded, addPadding, theme }) => ({
        background: theme.palette.common.white,
        padding: addPadding ? theme.spacing(0, 2, 2, 2) : 0,
        transform: isExpanded ? 'translateY(0px)' : 'translateY(-1000px)',
        transition: 'transform 0.2s linear',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        zIndex: 1,
        height: isExpanded ? '100%' : 0,
        position: isExpanded ? 'relative' : 'absolute',
    })
);

interface IOwnProps {
    title: string;
    content: ReactElement;
    addPadding?: boolean;
}

const DeepDiveAccordion: React.FC<IOwnProps> = ({
    title,
    content,
    addPadding,
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const bottomRef = useRef<HTMLDivElement>(null);

    const handleExpansion = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <AccordionWrapper>
            <TopWrapper isExpanded={isExpanded} onClick={handleExpansion}>
                <Typography
                    variant={'h3'}
                    sx={{ fontSize: pxToRem(20), fontWeight: 500 }}
                >
                    {title}
                </Typography>
                <ChevronWrapper isExpanded={isExpanded}>
                    <FontAwesomeIcon
                        icon={byPrefixAndName.far['chevron-down']}
                    />
                </ChevronWrapper>
            </TopWrapper>
            <BottomWrapper
                isExpanded={isExpanded}
                addPadding={addPadding ?? true}
                ref={bottomRef}
            >
                {content}
            </BottomWrapper>
        </AccordionWrapper>
    );
};

export default DeepDiveAccordion;
