import { IContextsualisedStrategy, StrategyStatus } from '../../../types';
import { Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { ActionMenu } from './ActionMenu';
import { CaiMessages } from './CaiMessages';
import Caiconcolor from '../../../assets/img/icons/caiconcolor.svg';
import { Drawer } from '../../Drawer';
import { HistoryNav } from './HistoryNav';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCai } from '../../../custom-hooks/useCai';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useSnackbar } from 'notistack';

const TitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
}));

const Title = styled(Typography)(() => ({
    fontSize: pxToRem(24),
    fontWeight: 500,
    lineHeight: pxToRem(36),
}));

const Tag = styled('div')(({ theme }) => ({
    height: 23,
    padding: theme.spacing(0.5, 1),
    background: '#CCE5FF',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 700,
    lineHeight: pxToRem(15),
    fontSize: pxToRem(10),
}));

const Wrapper = styled('div')(() => ({
    display: 'flex',
    height: 'calc(100% - 69px)',
    overflow: 'hidden',
}));
const InnerWrapper = styled('div')(() => ({
    width: '100%',
}));

const CaiTextWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
}));

export const InitialQuery = {
    message: 'Apply this to my role',
};

export const CaiDrawer = () => {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    const {
        getExistingContextualisedStrategy,
        getUserContextualisedStrategies,
    } = endpoints();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const [strategies, setStrategies] = useState<IContextsualisedStrategy[]>(
        []
    );

    const {
        state: { activeLmsSourceId, caiDrawerOpen },
        dispatch,
    } = useCognitiveMap();

    const { messages, contextualisedStrategyQuery, setMessages, messageDone } =
        useCai(InitialQuery);

    const [strategyStatus, setStrategyStatus] = useState<StrategyStatus>();

    useEffect(() => {
        if (!caiDrawerOpen) {
            setMessages([InitialQuery]);
        }
    }, [caiDrawerOpen]);

    useEffect(() => {
        if (userId && activeLmsSourceId && caiDrawerOpen) {
            setupCognativeCai(userId, activeLmsSourceId);
        }
    }, [userId, activeLmsSourceId, caiDrawerOpen]);

    const setupCognativeCai = async (
        userId: string,
        activeLmsSourceId: string
    ) => {
        const strategies = await getExistingStrategies(userId);

        if (strategies) {
            const currentStrategy = checkIfStrategyExists(
                strategies,
                activeLmsSourceId
            );

            if (currentStrategy) {
                setStrategyStatus(currentStrategy.status);

                const strategyText = await getExistingStrategy(
                    userId,
                    currentStrategy.sessionId
                );

                if (strategyText) {
                    setMessages([
                        InitialQuery,
                        {
                            answer: strategyText,
                        },
                    ]);
                }
            } else {
                setStrategyStatus(undefined);

                const isDone = await getStrategy(userId, activeLmsSourceId);

                if (isDone) {
                    await getExistingStrategies(userId);
                }
            }
        }
    };

    const getExistingStrategies = async (userId: string) => {
        const { data } = await getUserContextualisedStrategies(userId);
        if (data) {
            setStrategies(data.userStrategies);
            return data?.userStrategies;
        }

        return null;
    };

    const checkIfStrategyExists = (
        strategies: IContextsualisedStrategy[],
        activeLmsSourceId: string
    ) => {
        return strategies.find(
            (strat) => strat.lmsSourceId === activeLmsSourceId
        );
    };

    const getStrategy = async (userId: string, activeLmsSourceId: string) => {
        return await contextualisedStrategyQuery(userId, activeLmsSourceId);
    };

    const getExistingStrategy = async (userId: string, sessionId: string) => {
        const { data, error } = await getExistingContextualisedStrategy(
            userId,
            sessionId
        );

        if (data) {
            return data.contents;
        }

        if (error) {
            enqueueSnackbar(
                'Could not load strategy, please try again later.',
                {
                    variant: 'error',
                }
            );
        }

        return null;
    };

    const handleClose = () => {
        if (!messageDone) {
            enqueueSnackbar(
                'Please wait for Cai to finish the current message',
                {
                    variant: 'error',
                }
            );

            return;
        }

        dispatch({ type: 'SET_ACTIVE_LMS_SOURCE_ID', payload: null });
        dispatch({ type: 'SET_CAI_DRAWER_OPEN', payload: false });
    };

    return (
        <Drawer
            maxWidth={1200}
            id='cai-drawer'
            open={caiDrawerOpen}
            close={handleClose}
            style={{
                padding: 0,
            }}
            topWrapperStyle={{
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
                padding: theme.spacing(2, 3),
                marginBottom: 0,
            }}
            TitleWrapper={
                <TitleWrapper>
                    <img src={Caiconcolor} alt='' />
                    <Title variant='h1'>Cai</Title>
                    <Tag>BETA</Tag>
                </TitleWrapper>
            }
        >
            <Wrapper>
                <HistoryNav messageDone={messageDone} strategies={strategies} />
                <InnerWrapper>
                    <ActionMenu
                        strategyStatus={strategyStatus}
                        setStrategyStatus={setStrategyStatus}
                    />
                    <CaiTextWrapper>
                        <CaiMessages messages={messages} />
                    </CaiTextWrapper>
                </InnerWrapper>
            </Wrapper>
        </Drawer>
    );
};
