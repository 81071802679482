import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    MenuItem,
    Select,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
    HasFormerNeurodiversityDiagnosis,
    NeurodivergenceType,
} from '../../../../types';
import { useEffect, useState } from 'react';

import { CogInput } from '../../../styled-components';
import { Field } from '../Field';
import { inputHelperText } from '../../../../content/profile/input-helper-text';
import { useProfileFocus } from '../../../../custom-hooks/useProfileFocus';

const neurodiversitiesList = [
    {
        key: NeurodivergenceType.Autism,
        value: 'Autism',
    },
    {
        key: NeurodivergenceType.ADHD,
        value: 'ADHD – Attention Deficit Hyperactivity Disorder (ADHD) or Attention Deficit Disorder (ADD)',
        shortName: 'ADHD',
    },
    {
        key: NeurodivergenceType.TourettesSyndrome,
        value: 'Tourette’s Syndrome',
    },
    { key: NeurodivergenceType.Dyslexia, value: 'Dyslexia' },
    { key: NeurodivergenceType.Dyspraxia, value: 'Dyspraxia' },
    { key: NeurodivergenceType.Dyscalculia, value: 'Dyscalculia' },
    { key: NeurodivergenceType.Dysgraphia, value: 'Dysgraphia' },
    {
        key: NeurodivergenceType.MearesIrlenSyndrome,
        value: 'Meares-Irlen Syndrome',
    },
    { key: NeurodivergenceType.Hyperlexia, value: 'Hyperlexia' },
    { key: NeurodivergenceType.Synaesthesia, value: 'Synaesthesia' },
    { key: NeurodivergenceType.PreferNotToSay, value: 'Prefer not to say' },
    { key: NeurodivergenceType.Other, value: 'Other' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IOwnProps {
    isSmall?: boolean;
}

export const NeurodiversitiesSelect: React.FC<IOwnProps> = ({
    isSmall = false,
}) => {
    const [diversitiesLabel, setDiversitiesLabel] = useState<string>('');
    const { onFocus, onBlur } = useProfileFocus();

    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext<{
        neurodivergenceTypes: NeurodivergenceType[];
        hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    }>();

    const hasFormerNeurodiversityDiagnosis = watch(
        'hasFormerNeurodiversityDiagnosis'
    );

    const neurodivergenceTypes = watch('neurodivergenceTypes');

    const showNeurodiversities =
        hasFormerNeurodiversityDiagnosis ===
            HasFormerNeurodiversityDiagnosis.Yes ||
        hasFormerNeurodiversityDiagnosis ===
            HasFormerNeurodiversityDiagnosis.NoButIBelieveIAmNeurodivergent;

    useEffect(() => {
        switch (hasFormerNeurodiversityDiagnosis) {
            case HasFormerNeurodiversityDiagnosis.No:
            case HasFormerNeurodiversityDiagnosis.PreferNotToSay:
                setValue('neurodivergenceTypes', []);
                break;
            case HasFormerNeurodiversityDiagnosis.Yes:
                setDiversitiesLabel(
                    'Please select from the following (Choose all that apply)'
                );
                break;
            case HasFormerNeurodiversityDiagnosis.NoButIBelieveIAmNeurodivergent:
                setDiversitiesLabel(
                    'Which neurodiversity or neurodiversities do you think you have? (Choose all that apply)'
                );
                break;
        }
    }, [hasFormerNeurodiversityDiagnosis]);

    const handleDelete = (key: NeurodivergenceType) => {
        setValue(
            'neurodivergenceTypes',
            neurodivergenceTypes.filter((value) => value !== key),
            { shouldDirty: true }
        );
    };

    if (!showNeurodiversities) {
        return null;
    }

    return (
        <Field
            label={diversitiesLabel}
            required
            hasError={!!errors.neurodivergenceTypes}
            labelId='neurodivergenceTypes'
            content={inputHelperText.find(
                (content) => content.key === 'neurodivergenceTypes'
            )}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Controller
                    control={control}
                    name='neurodivergenceTypes'
                    defaultValue={neurodivergenceTypes}
                    rules={{
                        validate: (v) => !!v.length,
                    }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            multiple
                            defaultValue={neurodivergenceTypes}
                            onFocus={(e) => onFocus(e, 'neurodivergenceTypes')}
                            onBlur={onBlur}
                            labelId='neurodivergenceTypes'
                            inputProps={{ 'aria-required': 'true' }}
                            input={
                                <CogInput
                                    inputProps={{
                                        'aria-labelledby':
                                            'neurodivergenceTypes',
                                    }}
                                    hasError={!!errors.neurodivergenceTypes}
                                />
                            }
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                    }}
                                >
                                    {selected.map((value) => {
                                        let chipLabel = '';
                                        const currentND =
                                            neurodiversitiesList[value - 1];

                                        if (currentND) {
                                            chipLabel =
                                                currentND.shortName ??
                                                currentND.value;
                                        }

                                        return (
                                            <Chip
                                                key={value}
                                                label={chipLabel}
                                                onMouseDown={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onDelete={() =>
                                                    handleDelete(currentND.key)
                                                }
                                                variant='outlined'
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {neurodiversitiesList.map((neuro) => (
                                <MenuItem
                                    key={neuro.key}
                                    value={neuro.key}
                                    sx={{
                                        whiteSpace: 'initial',
                                        padding: '8px 8px 8px 0px',
                                    }}
                                >
                                    <Checkbox
                                        checked={neurodivergenceTypes.includes(
                                            neuro.key
                                        )}
                                        inputProps={{
                                            'aria-labelledby': `nd-${neuro.key}`,
                                        }}
                                    />
                                    <span id={`nd-${neuro.key}`}>
                                        {neuro.value}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
        </Field>
    );
};
