import { Typography, alpha, styled } from '@mui/material';

import { pxToRem } from '../../../utils/style-functions';

export const StrategiesCard = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: theme.cogShadows.lg,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
        '& .strategiesTitle': {
            color: theme.palette.primary.main,
        },
        '& .strategiesImage': {
            transform: 'scale(1.1)',
        },
    },
}));

export const StrategiesImageWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: 240,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: theme.spacing(3),
}));

export const StrategiesImage = styled('img')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    transition: 'transform ease .4s',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

export const StrategiesTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontSize: pxToRem(18),
    fontWeight: 600,
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

export const LoadingWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha(theme.palette.grey[100], 0.5),
}));

export const Back = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

export const Forward = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));
