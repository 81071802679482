import {
    Cell,
    Pie,
    PieChart as RechartsPieChart,
    Sector,
    Tooltip,
} from 'recharts';
import { useEffect, useState } from 'react';

import { IDashboardGraphItem } from '../types';
import { pxToRem } from '../utils/style-functions';
import { styled } from '@mui/material';
import { useDebounce } from '../custom-hooks/useDebounce';

interface IOwnProps {
    cardLoading?: boolean;
    data: IDashboardGraphItem[];
}

export const PieChart: React.FC<IOwnProps> = ({
    data,
    cardLoading = false,
}) => {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const [pieDimensions, setPieDimensions] = useState<{
        outerHeight: number;
        outerWidth: number;
        outerRadius: number;
        innerRadius: number;
    }>({
        outerHeight: 0,
        outerWidth: 0,
        outerRadius: 0,
        innerRadius: 0,
    });

    const [activeIndex, setActiveIndex] = useState<number>();

    const debounceResize = useDebounce(function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', debounceResize);
    }, []);

    useEffect(() => {
        if (dimensions.width <= 464) {
            setPieDimensions({
                outerHeight: 213,
                outerWidth: 213,
                outerRadius: 103,
                innerRadius: 82,
            });
        } else {
            setPieDimensions({
                outerHeight: 159,
                outerWidth: 159,
                outerRadius: 73,
                innerRadius: 55,
            });
        }
    }, [dimensions]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
            props;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius - 2}
                    outerRadius={outerRadius + 2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    return (
        <RechartsPieChart
            height={pieDimensions.outerHeight}
            width={pieDimensions.outerWidth}
        >
            {!cardLoading && <Tooltip content={<CustomTooltip />} />}

            <Pie
                data={data}
                cx='50%'
                cy='50%'
                outerRadius={pieDimensions.outerRadius}
                dataKey='actualValue'
                innerRadius={pieDimensions.innerRadius}
                onMouseEnter={(_data, index) => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(undefined)}
                activeShape={!cardLoading ? renderActiveShape : undefined}
                activeIndex={activeIndex}
                startAngle={-270}
            >
                {data.map((entry) => (
                    <Cell key={`cell-${entry.title}`} fill={entry.color} />
                ))}
            </Pie>
        </RechartsPieChart>
    );
};

const Card = styled('div')(() => ({
    background: '#33383F',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    padding: '24px 16px',
    fontWeight: 'bold',
    fontSize: pxToRem(14),
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload?.length) {
        const value =
            payload[0].payload.percentageValue < 1
                ? '<1%'
                : `${payload[0].payload.percentageValue}%`;
        return (
            <Card>
                <span
                    style={{
                        height: 16,
                        width: 16,
                        backgroundColor: payload[0].payload.color,
                        marginRight: 8,
                        minWidth: 16,
                    }}
                ></span>
                <span>
                    {payload[0].payload.title}: {value}
                </span>
            </Card>
        );
    }

    return null;
};
