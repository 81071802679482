import {
    ChartWrapper,
    InnerWrapper,
    KeyWrapper,
    TopWrapper,
} from './styled-components';

import { Card } from '../../../styled-components';
import { Chart } from './Chart';
import KeyValuePair from './KeyValuePair';
import { animate } from './LoadingAnimation';
import { styled } from '@mui/material';

const LoaderTitle = styled('div')(() => ({
    borderRadius: '100px',
    height: '14px',
    width: '75%',
    ...animate('#7F8FA6'),
}));

interface IOwnProps {
    isLocked: boolean;
}

export const ChartLoading: React.FC<IOwnProps> = ({ isLocked }) => {
    return (
        <Card>
            <TopWrapper>
                <LoaderTitle />
            </TopWrapper>
            <InnerWrapper>
                <KeyWrapper isLocked={isLocked}>
                    <>
                        {[...Array(3).keys()].map((item: number) => (
                            <KeyValuePair key={item} isLoading={true} />
                        ))}
                    </>
                </KeyWrapper>
                <ChartWrapper>
                    <Chart loading={true} />
                </ChartWrapper>
            </InnerWrapper>
        </Card>
    );
};
