import { Typography, styled } from '@mui/material';

import { pxToRem } from '../utils/style-functions';

const PageHeaderOuterWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    flexFlow: 'row wrap',
    gap: theme.spacing(2),
}));

const PageHeaderMaxWidth = styled('div')(({ theme }) => ({
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(5, 4),
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(2, 3),
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(24),
    lineHeight: 1.5,
    flexBasis: '50%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(744)]: {
        flexBasis: '100%',
    },
}));

const SubText = styled(Typography)(() => ({
    flex: '1 1 400px',
}));

const PageHeaderInnerWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    gap: theme.spacing(3),
}));

interface IOwnProps {
    title: string;
    subText?: string | JSX.Element;
    action?: JSX.Element;
}

const PageHeader: React.FC<IOwnProps> = ({ title, subText, action }) => {
    return (
        <PageHeaderOuterWrapper>
            <PageHeaderMaxWidth>
                <Title variant='h1'>{title}</Title>
                <PageHeaderInnerWrapper>
                    {typeof subText === 'string' ? (
                        <SubText>{subText}</SubText>
                    ) : (
                        subText
                    )}
                    {action}
                </PageHeaderInnerWrapper>
            </PageHeaderMaxWidth>
        </PageHeaderOuterWrapper>
    );
};

export default PageHeader;
