import {
    IFormFields,
    SignupDetailsLocal,
} from '../../pages/unauthenticated/signup/[clientId]';
import { ILegalDocument, TrackingEvent } from '../../types';
import React, { useRef, useState } from 'react';
import {
    SubmitErrorHandler,
    SubmitHandler,
    useFormContext,
} from 'react-hook-form';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { ChevronRight } from 'react-feather';
import { FirstName } from './fields/FirstName';
import { LastName } from './fields/LastName';
import { Password } from './fields/Password';
import { TermsAndConditions } from './fields/TermsAndConditions';
import { endpoints } from '../../api/endpoints';
import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useParams } from 'react-router';
import { useRemoteValidation } from '../../custom-hooks/useRemoteValidation';
import useResizeObserver from '@react-hook/resize-observer';
import { useSnackbar } from 'notistack';
import { useTrackEvent } from '../../custom-hooks/useTrackEvent';

const Wrapper = styled('div')(({ theme }) => ({
    overflowY: 'scroll',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down(920)]: {
        paddingRight: theme.spacing(0),
        overflowY: 'inherit',
    },
}));

const PageTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(44),
    lineHeight: '150%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(28),
    },
}));

const FieldWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(6),
}));

const NameFieldWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
    gap: isSmall ? 0 : theme.spacing(3),
    marginBottom: isSmall ? theme.spacing(3) : 0,
    '& div': {
        flexGrow: 1,
    },
}));

const ActionWrapper = styled('div')(() => ({}));
const Continue = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(920)]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

const CreateUserRemoteValidationFields = {
    clientId: 'ClientId',
    password: 'Password',
} as const;

interface IOwnProps {
    signupDetails: SignupDetailsLocal;
    legalDocuments: ILegalDocument[];
}

export const DetailsEntry: React.FC<IOwnProps> = ({
    signupDetails,
    legalDocuments,
}) => {
    const { createLearnerOnboarding } = endpoints();
    const { enqueueSnackbar } = useSnackbar();
    const trackEvent = useTrackEvent();

    const parseAPIErrors = useRemoteValidation<
        typeof CreateUserRemoteValidationFields
    >(CreateUserRemoteValidationFields);

    const {
        clientId,
        selfOnBoardingDomains: validDomains,
        useSingleSignOn: isSSOUser,
    } = signupDetails;

    const { handleSubmit } = useFormContext<IFormFields>();

    const { clientId: friendlyClientId } = useParams<{ clientId: string }>();

    const fieldWrapperRef = useRef<HTMLDivElement>(null);

    const [isSmall, setIsSmall] = useState<boolean>(false);

    useResizeObserver(fieldWrapperRef, (entry) => {
        const { width } = entry.contentRect;
        setIsSmall(width < 420);
    });

    const errorHandler: SubmitErrorHandler<IFormFields> = (errors) => {
        if (errors.firstName) {
            trackEvent(TrackingEvent.SIGNUP_FIRST_NAME_ERROR, {
                clientId,
                details: errors.firstName.message,
            });
        }

        if (errors.lastName) {
            trackEvent(TrackingEvent.SIGNUP_LAST_NAME_ERROR, {
                clientId,
                details: errors.lastName.message,
            });
        }

        if (errors.password) {
            trackEvent(TrackingEvent.SIGNUP_PASSWORD_ERROR, {
                clientId,
                details: errors.password.message,
            });
        }

        if (errors.termsAndConditions) {
            trackEvent(TrackingEvent.SIGNUP_TERMS_AND_CONDITIONS_ACCEPTED, {
                clientId,
                details: errors.termsAndConditions.message,
            });
        }
    };

    const continueHandler: SubmitHandler<IFormFields> = async (formFields) => {
        if (friendlyClientId) {
            const { error } = await createLearnerOnboarding({
                clientId,
                friendlyClientId,
                email: formFields.email.trim(),
                firstName: formFields.firstName,
                lastName: formFields.lastName,
                password: !isSSOUser ? formFields.password : null,
                selfOnBoardingDomains: validDomains.join(','),
                legalDocumentIds: legalDocuments.map(
                    (legalDocument) => legalDocument.id
                ),
                useSingleSignOn: isSSOUser,
            });

            if (error) {
                const errors = parseAPIErrors(error);

                if (errors.length) {
                    errors.forEach((error) => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
                } else {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    });
                }
            } else {
                window.location.href = `${window.location.origin}/dashboard`;
            }
        }
    };

    return (
        <Wrapper>
            <PageTitle variant='h1'>
                We just need some of your details.
            </PageTitle>
            <FieldWrapper ref={fieldWrapperRef}>
                <NameFieldWrapper isSmall={isSmall}>
                    <FirstName />
                    <LastName />
                </NameFieldWrapper>
                {!isSSOUser && <Password />}
                <TermsAndConditions
                    legalDocuments={legalDocuments}
                    clientId={clientId}
                />
            </FieldWrapper>
            <ActionWrapper>
                <Continue
                    onClick={handleSubmit(continueHandler, errorHandler)}
                    text='Sign up'
                    endIcon={<ChevronRight />}
                />
            </ActionWrapper>
        </Wrapper>
    );
};
