import { Roles } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';

export const useIsAdmin = () => {
    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    return (
        roles?.includes(Roles.ClientAdmin) || roles?.includes(Roles.SysAdmin)
    );
};
