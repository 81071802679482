export const getDefaultPageSize = (
    tableName: string,
    defaultValue: number
): number => {
    const pageSize = localStorage.getItem(tableName);

    if (pageSize) {
        return parseInt(pageSize);
    }

    return defaultValue;
};
