import { useEffect, useState } from 'react';

import { GrowthBook } from '@growthbook/growthbook-react';
import { useAuth } from '../custom-providers/AuthProvider';
import { useConfig } from '../custom-providers/ConfigProvider';

export const useGrowthbookSetup = () => {
    const [growthbook, setGrowthbook] =
        useState<GrowthBook<Record<string, unknown>>>();

    const {
        state: {
            clientConfig: { clientId },
            userConfig: { userId },
        },
    } = useAuth();

    const {
        state: {
            appConfig: { growthbookKey, environment },
        },
    } = useConfig();

    useEffect(() => {
        if (growthbookKey && clientId && userId && environment) {
            const gb = new GrowthBook({
                apiHost: 'https://cdn.growthbook.io',
                clientKey: growthbookKey,
                enableDevMode: environment === 'local',
                trackingCallback: (experiment, result) => {
                    console.log('Viewed Experiment', {
                        experimentId: experiment.key,
                        variationId: result.variationId,
                    });
                },
            });

            gb.loadFeatures();
            gb.setAttributes({
                userId,
                clientId,
            });

            setGrowthbook(gb);
        }
    }, [growthbookKey, clientId, userId, environment]);

    return growthbook;
};
