import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { styled } from '@mui/material';

const FooterWrapper = styled('div', {
    ...shouldForwardProps('isNewFooter'),
})<{ isNewFooter?: boolean }>(({ isNewFooter, theme }) => ({
    background: isNewFooter ? theme.palette.text.primary : '#33383F',
    height: isNewFooter ? 84 : 140,
    display: 'flex',
    alignItems: isNewFooter ? 'center' : 'flex-end',
    justifyContent: 'center',
}));

const FooterCopy = styled('p', {
    ...shouldForwardProps('isNewFooter'),
})<{ isNewFooter?: boolean }>(({ isNewFooter }) => ({
    color: '#ffffff',
    fontWeight: 400,
    fontSize: isNewFooter ? pxToRem(20) : pxToRem(16),
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: isNewFooter ? 0 : 12,
}));

interface IOwnProps {
    isNewFooter?: boolean;
}

export const Footer: React.FC<IOwnProps> = ({ isNewFooter = false }) => {
    return (
        <FooterWrapper isNewFooter={isNewFooter}>
            <FooterCopy isNewFooter={isNewFooter}>
                © {new Date().getFullYear()} Cognassist. All rights reserved.
            </FooterCopy>
        </FooterWrapper>
    );
};

export default Footer;
