import { styled } from '@mui/material';

export const Card = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.cogShadows.base,
    minHeight: 290,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    flex: '1 1 50%',
    height: '100%',
    border: `1px solid ${theme.palette.grey[50]}`,
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
}));
