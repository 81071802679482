export interface IHealthDataLegalDocumentsResponse {
    latestLegalDocuments: ILegalDocument[];
}

export interface ILegalDocument {
    id: string;
    body: string;
    legalDocumentType: LegalDocumentType;
}

export enum LegalDocumentType {
    PrivacyPolicy = 1,
    TermsAndConditions = 2,
    HealthDataPrivacyPolicy = 3,
    HealthDataTermsAndConditions = 4,
}
