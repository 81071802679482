import { BusinessType, ICognitiveDomainV2 } from '../../../../types';
import { IconButton, Typography, styled } from '@mui/material';

import CheckPurpleIcon from '../../../../assets/img/icons/checkpurpledark.svg';
import { ContentHelper } from './ContentHelper';
import DeepDiveAccordion from './Accordion';
import { DomainStrategies } from './DomainStrategies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import imgExecutiveFunction from '../../../../assets/img/executive-function.png';
import imgLiteracy from '../../../../assets/img/literacy.png';
import imgNonVerbalMemory from '../../../../assets/img/non-verbal-memory.png';
import imgNumeracy from '../../../../assets/img/numeracy.png';
import imgReadingDecoding from '../../../../assets/img/reading-decoding.png';
import imgVerbalMemory from '../../../../assets/img/verbal-memory.png';
import imgVerbalReasoning from '../../../../assets/img/verbal-reasoning.png';
import imgVisualInformationProcessingSpeed from '../../../../assets/img/visual-information-processing-speed.png';
import imgVisualPerception from '../../../../assets/img/visual-perception.png';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const DeepDiveSidebarWrapper = styled('div', {
    ...shouldForwardProps('isOpen', 'includeTopGap'),
})<{ isOpen?: boolean; includeTopGap?: boolean }>(({
    isOpen,
    includeTopGap,
    theme,
}) => {
    const translateY = includeTopGap ? 'translateY(62px)' : 'translateY(0)';

    return {
        background: theme.palette.common.white,
        width: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        boxShadow: isOpen ? theme.cogShadows.lg : 'none',
        overflowY: 'auto',
        flexFlow: 'column nowrap',
        padding: theme.spacing(3),
        maxWidth: 600,
        transform: isOpen ? 'translateX(0)' : `translateX(${600}px)`,
        zIndex: 100,
        transition: 'transform 0.3s ease',
        height: '100vh',
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4, 8, 3),
            maxWidth: '100vw',
            transform: isOpen
                ? `translateX(0) ${translateY}`
                : `translateX(100vw) ${translateY}`,
        },
    };
});

const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const AssessmentImage = styled('img')(() => ({
    width: '100%',
}));

const AssessmentCard = styled('div')(({ theme }) => ({
    background: '#F6F7FB',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
}));

interface IOwnProps {
    isOpen: boolean;
    domain: ICognitiveDomainV2;
    includeTopGap?: boolean;
}

const DomainSidebar: React.FC<IOwnProps> = ({
    isOpen,
    domain,
    includeTopGap = true,
}) => {
    const { dispatch } = useCognitiveMap();

    const {
        state: {
            clientConfig: { businessType },
        },
    } = useAuth();

    const close = () => {
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: 0,
        });
    };

    const imageLookup = (domainId: number) => {
        switch (domainId) {
            case 1:
                return (
                    <AssessmentImage
                        src={imgVerbalMemory}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 2:
                return (
                    <AssessmentImage
                        src={imgLiteracy}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 3:
                return (
                    <AssessmentImage
                        src={imgNumeracy}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 4:
                return (
                    <AssessmentImage
                        src={imgVisualInformationProcessingSpeed}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 5:
                return (
                    <AssessmentImage
                        src={imgNonVerbalMemory}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 6:
                return (
                    <AssessmentImage
                        src={imgExecutiveFunction}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 7:
                return (
                    <AssessmentImage
                        src={imgVerbalReasoning}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 8:
                return (
                    <AssessmentImage
                        src={imgVisualPerception}
                        alt='image representing the domain in the assessment'
                    />
                );
            case 9:
                return (
                    <AssessmentImage
                        src={imgReadingDecoding}
                        alt='image representing the domain in the assessment'
                    />
                );
            default:
                return null;
        }
    };

    const strategiesTitle =
        domain.strategiesHeading.type === 'paragraph' &&
        domain.strategiesHeading.value.length > 0
            ? domain.strategiesHeading.value[0]
            : 'You may want to try the following:';

    return (
        <DeepDiveSidebarWrapper
            isOpen={isOpen}
            includeTopGap={includeTopGap}
            id='DeepDiveSideBar'
        >
            <TopWrapper>
                <Typography
                    variant='h2'
                    sx={{ fontSize: pxToRem(20), fontWeight: 500 }}
                >
                    {domain.domainName}
                </Typography>
                <IconButton onClick={close} aria-label='close-drawer'>
                    <FontAwesomeIcon icon={byPrefixAndName.far['xmark']} />
                </IconButton>
            </TopWrapper>
            <ContentWrapper>
                <ContentHelper
                    content={domain.content}
                    liIcon={
                        <img
                            alt=''
                            src={CheckPurpleIcon}
                            style={{ marginRight: 8, minWidth: 24 }}
                        />
                    }
                    pStyles={{
                        mb: 2,
                    }}
                    ulStyles={{
                        pl: 0,
                    }}
                    liStyles={{
                        mb: 2,
                        listStyle: 'none',
                        alignItems: 'center',
                        display: 'flex',
                        verticalAlign: 'middle',
                    }}
                    listWrapperStyles={{
                        mt: 3,
                    }}
                />
            </ContentWrapper>
            {businessType === BusinessType.APPS_ED && (
                <AssessmentCard>
                    <Typography
                        variant={'h3'}
                        sx={{ fontSize: pxToRem(20), fontWeight: 500, mb: 2 }}
                    >
                        How was this assessed?
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        {domain.assessmentInfo}
                    </Typography>
                    {imageLookup(domain.domainId)}
                </AssessmentCard>
            )}
            {businessType === BusinessType.ENTERPRISE && (
                <>
                    <DeepDiveAccordion
                        title={strategiesTitle}
                        content={<DomainStrategies domain={domain} />}
                        addPadding={false}
                    />
                    <DeepDiveAccordion
                        title='How was this assessed?'
                        content={
                            <>
                                <Typography sx={{ mb: 3 }}>
                                    {domain.assessmentInfo}
                                </Typography>
                                {imageLookup(domain.domainId)}
                            </>
                        }
                    />
                </>
            )}
        </DeepDiveSidebarWrapper>
    );
};

export default DomainSidebar;
