import { Typography, styled } from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { TextArea } from '../../../styled-components';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useFormContext } from 'react-hook-form';

const CHARACTER_LIMIT = 500;

const FeedbackText = styled(Typography, {
    ...shouldForwardProps('error'),
})<{ error?: boolean }>(({ error, theme }) => ({
    marginTop: theme.spacing(2),
    color: error ? theme.palette.error.main : theme.palette.text.primary,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
}));

export const RequestNote: React.FC = () => {
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext<{ note: string }>();

    const hasError = !!errors.note;

    const note = watch('note');
    const length = note.length;

    return (
        <Field label='Your note' labelFor='note' hasError={hasError}>
            <>
                <TextArea
                    {...register('note', {
                        required: 'Please enter a note.',
                        validate: (value) => {
                            if (value.length <= CHARACTER_LIMIT) {
                                return true;
                            }

                            return `Maximum character limit ${value.length}/${CHARACTER_LIMIT}.`;
                        },
                    })}
                    multiline
                    maxRows={4}
                    hasError={hasError}
                    id='note'
                    placeholder='Type your note...'
                />
                {!hasError && length > 400 && (
                    <FeedbackText>
                        Maximum character limit {length}/{CHARACTER_LIMIT}.
                    </FeedbackText>
                )}
                <ErrorMessage
                    errors={errors}
                    name='note'
                    render={({ message }) => (
                        <FeedbackText error>{message}</FeedbackText>
                    )}
                />
            </>
        </Field>
    );
};
