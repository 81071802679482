import { Typography, styled } from '@mui/material';
import { useRef, useState } from 'react';

import { Button as CogButton } from '@cognassist/react-components';
import { FindOutMoreDialog } from './FindOutMoreDialog';
import { VolunteerDialog } from './VolunteerDialog';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import useResizeObserver from '@react-hook/resize-observer';

const Background = styled('div')(({ theme }) => ({
    background:
        'linear-gradient(90deg, rgba(133,101,199,1) 0%, rgba(137,96,191,1) 25%, rgba(157,110,199,1) 50%, rgba(174,134,205,1) 75%, rgba(199,171,216,1) 100%)',
    backgroundColor: 'rgb(133,101,199)',
    borderRadius: theme.shape.borderRadius,
    boxShadow:
        '0px 10px 20px -1px rgba(0, 0, 0, 0.05), 0px 4px 8px -2px rgba(0, 0, 0, 0.02)',
}));

const Wrapper = styled('div', {
    ...shouldForwardProps('useFullWidth'),
})<{ useFullWidth: boolean }>(({ useFullWidth, theme }) => ({
    minHeight: 288,
    width: useFullWidth ? '100%' : '80%',
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.common.white,
}));

const Title = styled(Typography, {
    ...shouldForwardProps('useLargeText'),
})<{ useLargeText: boolean }>(({ useLargeText }) => ({
    fontWeight: 700,
    fontSize: useLargeText ? pxToRem(32) : pxToRem(24),
    lineHeight: pxToRem(36),
}));

const Text = styled(Typography, {
    ...shouldForwardProps('useLargeText'),
})<{ useLargeText: boolean }>(({ useLargeText }) => ({
    flexGrow: 1,
    fontSize: useLargeText ? pxToRem(24) : pxToRem(16),
}));

const ButtonWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexFlow: isSmall ? 'column' : 'row',
    gap: theme.spacing(2),
}));

const Button = styled(CogButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
        color: theme.palette.common.white,
    },
}));

interface IOwnProps {
    canVolunteer: boolean;
    refreshVolunteerStatus: () => Promise<void>;
}

export const Banner: React.FC<IOwnProps> = ({
    canVolunteer,
    refreshVolunteerStatus,
}) => {
    const backgroundRef = useRef<HTMLDivElement>(null);

    const [showFindOutMoreDialog, setShowFindOutMoreDialog] =
        useState<boolean>(false);
    const [showVolunteerDialog, setShowVolunteerDialog] =
        useState<boolean>(false);
    const [size, setSize] = useState<{
        isSmall: boolean;
        isLarge: boolean;
    }>({ isSmall: false, isLarge: false });

    useResizeObserver(backgroundRef, (entry) => {
        const { width } = entry.contentRect;

        setSize({ isSmall: width < 500, isLarge: width > 1200 });
    });

    return (
        <>
            <Background ref={backgroundRef}>
                <Wrapper useFullWidth={size.isSmall}>
                    <Title useLargeText={size.isLarge}>
                        Get support from a certified Neuro-inclusion Champion
                    </Title>
                    <Text useLargeText={size.isLarge}>
                        Asking for help can feel difficult, speak to a
                        Neuro-inclusion Champion to discover what support and
                        adjustments are available for you
                    </Text>
                    <ButtonWrapper isSmall={size.isSmall}>
                        <Button
                            text='Find out more'
                            size='medium'
                            onClick={() => setShowFindOutMoreDialog(true)}
                        />
                        {canVolunteer && (
                            <CogButton
                                text={
                                    size.isSmall
                                        ? 'Volunteer'
                                        : 'Volunteer to be a Champion'
                                }
                                size='medium'
                                color='secondary'
                                onClick={() => setShowVolunteerDialog(true)}
                            />
                        )}
                    </ButtonWrapper>
                </Wrapper>
            </Background>

            <FindOutMoreDialog
                open={showFindOutMoreDialog}
                setOpen={setShowFindOutMoreDialog}
            />

            <VolunteerDialog
                open={showVolunteerDialog}
                setOpen={setShowVolunteerDialog}
                refreshVolunteerStatus={refreshVolunteerStatus}
            />
        </>
    );
};
