import { Typography, TypographyProps, styled } from '@mui/material';
import { getFontSize, getLineHeight } from '../helpers';

import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpTooltip } from '../../../HelpTooltip';
import { IScreenSize } from '../../../../types';
import { PinkOutlineButton } from '../../PinkOutlineButton';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import highFive from '../../../../assets/img/high-five.svg';
import mask from '../../../../assets/img/rectangle-mask.svg';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useState } from 'react';

const Wrapper = styled('div', {
    ...shouldForwardProps('hideImages'),
})<{ hideImages: boolean }>(({ hideImages, theme }) => ({
    width: '100%',
    minHeight: hideImages ? 'initial' : 228,
    borderRadius: theme.shape.borderRadius,
    background: hideImages
        ? 'none'
        : `url(${highFive}) right bottom / contain no-repeat, url(${mask}) right bottom / contain no-repeat`,
    backgroundColor: '#FFEA9A',
    marginBottom: theme.spacing(3),
}));

const InnerWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(1),
    padding: isSmall ? theme.spacing(2) : theme.spacing(3),
}));

const TextWrapper = styled('div', {
    ...shouldForwardProps('fullWidth'),
})<{ fullWidth: boolean }>(({ fullWidth }) => ({
    maxWidth: fullWidth ? '100%' : '50%',
}));

const Title = styled(
    ({ children, ...props }: TypographyProps) => (
        <Typography {...props} variant='h2'>
            {children}
        </Typography>
    ),
    {
        ...shouldForwardProps('isSmall'),
    }
)<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    fontWeight: 500,
    lineHeight: getLineHeight(isSmall),
    fontSize: getFontSize(isSmall),
    marginBottom: theme.spacing(1),
}));

const CogTypeWrapper = styled('span')(({ theme }) => ({
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',

    '& svg': {
        marginLeft: theme.spacing(0.75),
        cursor: 'pointer',
        width: 14,
        height: 14,
    },
}));

const Description = styled(Typography)(() => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
}));

const CopyWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    marginTop: isSmall ? theme.spacing(1) : theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: isSmall ? 'stretch' : 'flex-start',
}));

interface IOwnProps {
    fullName?: string;
    tooltipText?: string | null;
    screenSize: IScreenSize;
    cogTypeName: string;
    cogTypeDescription: string;
}

export const CogTypeBanner: React.FC<IOwnProps> = ({
    fullName,
    tooltipText,
    screenSize,
    cogTypeName,
    cogTypeDescription,
}) => {
    const [wasCopied, setWasCopied] = useState<boolean>(false);

    const firstName = fullName?.split(' ')[0];
    const isSmallOrMedium = screenSize.isMobile || screenSize.isTablet;

    const copyText = `${cogTypeName}: ${cogTypeDescription}`;

    return (
        <Wrapper hideImages={isSmallOrMedium} id='banner-container'>
            <InnerWrapper isSmall={screenSize.isMobile}>
                <TextWrapper fullWidth={isSmallOrMedium}>
                    <Title isSmall={screenSize.isMobile}>
                        {firstName}, your CogType is {cogTypeName} &#128064;
                        <CogTypeWrapper>
                            {tooltipText && (
                                <HelpTooltip
                                    text={tooltipText}
                                    Icon={
                                        <FontAwesomeIcon
                                            icon={
                                                byPrefixAndName.fas[
                                                    'circle-question'
                                                ]
                                            }
                                        />
                                    }
                                />
                            )}
                        </CogTypeWrapper>
                    </Title>
                    <Description>{cogTypeDescription}</Description>
                </TextWrapper>
                <CopyWrapper isSmall={screenSize.isMobile}>
                    <CopyToClipboard
                        text={copyText}
                        arial-label='Copy CogType to clipboard'
                        onCopy={() => {
                            setWasCopied(true);
                            setTimeout(() => {
                                setWasCopied(false);
                            }, 2000);
                        }}
                    >
                        <PinkOutlineButton
                            text={
                                wasCopied
                                    ? 'Copied to clipboard'
                                    : 'Copy CogType'
                            }
                            startIcon={
                                wasCopied ? (
                                    <FontAwesomeIcon
                                        icon={byPrefixAndName.far['check']}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={byPrefixAndName.far['copy']}
                                    />
                                )
                            }
                        />
                    </CopyToClipboard>
                </CopyWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};
