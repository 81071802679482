import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ReferenceArea,
    ReferenceDot,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { Typography, styled } from '@mui/material';

import { CustomWarningShape } from './CustonWarningShape';
import { Fragment } from 'react';
import { IParsedDomainData } from '../../../../types/report.types';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';

const Wrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, -1),
}));

const LegendWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(6),
}));

const ColorBlock = styled('div', {
    ...shouldForwardProps('color', 'showBorder'),
})<{ color?: string; showBorder?: boolean }>(
    ({ color, showBorder, theme }) => ({
        borderRadius: 2,
        height: 16,
        width: 16,
        background: color,
        marginRight: theme.spacing(1),
        flexShrink: 0,
        border: showBorder ? '1px dashed #FFBE5E' : 'none',
    })
);

const LegendItem = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: '0 0 50%',
    marginBottom: theme.spacing(2),
}));

const LegendText = styled('div')(() => ({
    fontWeight: 600,
    fontSize: pxToRem(12),
    lineHeight: '150%',
}));

const LegendHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: pxToRem(24),
    lineHeight: '150%',
    margin: theme.spacing(1, 0, 2, 6),
}));

const CustomLegend = ({ payload }: { payload: Payload[] }) => {
    return (
        <>
            <LegendHeading>Key</LegendHeading>
            <LegendWrapper>
                {payload.map((entry) => (
                    <LegendItem key={entry.value}>
                        <ColorBlock
                            color={entry.color}
                            showBorder={entry.id === 'TR'}
                        />
                        <LegendText>{entry.value}</LegendText>
                    </LegendItem>
                ))}
            </LegendWrapper>
        </>
    );
};

interface IOwnProps {
    scores: IParsedDomainData[];
    hasEnglishAsASecondLanguage: boolean;
}

export const MobileGraph: React.FC<IOwnProps> = ({
    scores,
    hasEnglishAsASecondLanguage,
}) => {
    const legendData: Payload[] = scores.map((score) => ({
        value: score.name,
        id: score.keyName,
        color: score.barColor,
    }));
    legendData.push({
        value: 'Typical Range',
        id: 'TR',
        color: '#FFFACA',
    });
    return (
        <Wrapper>
            <ResponsiveContainer minWidth={280} height={600}>
                <BarChart
                    data={scores}
                    layout='horizontal'
                    margin={{ bottom: 0, top: 10, right: 10, left: -25 }}
                    barSize={20}
                >
                    <CartesianGrid
                        strokeDasharray='0'
                        horizontal={true}
                        vertical={false}
                    />

                    <ReferenceLine
                        y='100'
                        stroke='#7F8FA6'
                        strokeDasharray='10 5'
                        strokeWidth={1.5}
                    />
                    <ReferenceLine
                        y='115'
                        stroke='#FD9B00'
                        strokeDasharray='10 5'
                        strokeWidth={1.5}
                    />
                    <ReferenceLine
                        y='85'
                        stroke='#FD9B00'
                        strokeDasharray='10 5'
                        strokeWidth={1.5}
                    />

                    <ReferenceArea
                        y1={50}
                        y2={85}
                        fill='#FFEBCE'
                    ></ReferenceArea>
                    <ReferenceArea
                        y1={85}
                        y2={115}
                        fill='#F1EA9E'
                    ></ReferenceArea>
                    <ReferenceArea
                        y1={115}
                        y2={150}
                        fill='#E9F9C5'
                    ></ReferenceArea>

                    <XAxis
                        interval={0}
                        orientation='bottom'
                        dataKey='keyName'
                        type='category'
                        tickLine={false}
                        axisLine={false}
                    />

                    <YAxis
                        type='number'
                        tickCount={10}
                        interval={0}
                        axisLine={false}
                        tickLine={false}
                        ticks={[
                            50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
                        ]}
                        domain={[50, 'dataMax']}
                        style={{ fill: '#33383F' }}
                    />

                    <Legend
                        verticalAlign='bottom'
                        payload={legendData}
                        content={<CustomLegend payload={legendData} />}
                    />

                    <Bar dataKey='score' radius={[4, 4, 0, 0]}>
                        {scores.map((entry) => (
                            <Fragment key={`cell-${entry.name}`}>
                                <Cell
                                    style={
                                        entry.pvtFailure
                                            ? { display: 'none' }
                                            : {}
                                    }
                                    fill={entry.barColor}
                                ></Cell>
                            </Fragment>
                        ))}
                    </Bar>
                    {scores.map((data) => {
                        return (
                            <ReferenceDot
                                key={data.name}
                                x={data.keyName}
                                y={data.score}
                                shape={
                                    <CustomWarningShape
                                        isMobile={true}
                                        pvtFailure={data.pvtFailure}
                                        hasEnglishAsASecondLanguage={
                                            hasEnglishAsASecondLanguage
                                        }
                                        domainName={data.name}
                                    />
                                }
                            />
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        </Wrapper>
    );
};
