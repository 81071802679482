import {
    MatomoProvider as MProvider,
    createInstance,
} from '@jonkoops/matomo-tracker-react';

import { useAuth } from './AuthProvider';

interface IOwnProps {
    children: React.ReactNode;
}

export const MatomoProvider: React.FC<IOwnProps> = ({ children }) => {
    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    if (process.env.NODE_ENV !== 'development') {
        return (
            <MProvider
                value={createInstance({
                    urlBase: 'https://cognassist.matomo.cloud/',
                    siteId: 1,
                    userId,
                })}
            >
                {children}
            </MProvider>
        );
    }
    return <>{children}</>;
};
