import { Controller, useFormContext } from 'react-hook-form';

import DatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../Field';
import { differenceInYears } from 'date-fns';
import { inputHelperText } from '../../../../content/profile/input-helper-text';
import { useProfileFocus } from '../../../../custom-hooks/useProfileFocus';
import { CogInput, ErrorText } from '../../../styled-components';

interface IOwnProps {
    isSmall?: boolean;
}

export const DateOfBirth: React.FC<IOwnProps> = ({ isSmall = false }) => {
    const { onFocus, onBlur } = useProfileFocus();

    const {
        formState: { errors },
        control,
        watch,
    } = useFormContext<{ dateOfBirth: Date }>();

    const dob = watch('dateOfBirth');

    return (
        <Field
            label='Date of birth'
            labelFor='dateOfBirth'
            required
            hasError={!!errors.dateOfBirth}
            content={inputHelperText.find(
                (content) => content.key === 'dateOfBirth'
            )}
            isSmall={isSmall}
        >
            <>
                <Controller
                    name={'dateOfBirth'}
                    control={control}
                    defaultValue={dob}
                    rules={{
                        required: true,
                        validate: {
                            min: (v) =>
                                differenceInYears(new Date(), v) >= 14 ||
                                'Age cannot be under 14.',
                            max: (v) =>
                                differenceInYears(new Date(), v) <= 70 ||
                                'Age cannot be over 70.',
                        },
                    }}
                    render={({ field: { value, onChange } }) => (
                        <DatePicker
                            onFocus={(e) => onFocus(e, 'dateOfBirth')}
                            onBlur={onBlur}
                            id='dateOfBirth'
                            selected={value}
                            onChange={onChange}
                            dateFormat='dd MMM yyyy'
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            customInput={
                                <CogInput hasError={!!errors.dateOfBirth} />
                            }
                            showPopperArrow={false}
                            ariaRequired='true'
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name={'dateOfBirth'}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
