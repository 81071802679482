import { getBaseUrl } from './url-config';

export const callApiFetch = async (url: string, config: RequestInit) => {
    const baseURL = getBaseUrl();

    try {
        const response = await fetch(`${baseURL}${url}`, config);

        return {
            data: response.body,
            error: null,
        };
    } catch (error) {
        return {
            data: null,
            error,
        };
    }
};
