import { ChampionStatusLabel } from './fields/ChampionStatusLabel';
import { ClientReference } from './fields/ClientReference';
import { CombinedUser } from './Edit';
import { Manager } from './fields/Manager';
import { ProfilePicture } from './fields/ProfilePicture';
import { Roles } from './fields/Roles';
import { SecondaryManagers } from './fields/SecondaryManagers';
import { styled } from '@mui/material';
import { useLegacyEnterpriseClient } from '../../../custom-hooks/useLegacyEnterpriseClient';

const Wrapper = styled('div')(() => ({}));

interface IOwnProps {
    managerRoleEnabled: boolean;
    refreshData: () => Promise<void>;
    handleRemoveProfilePictureDialog: (user?: CombinedUser) => void;
    updateUsers: () => Promise<void>;
}

export const AdditionalDetails: React.FC<IOwnProps> = ({
    managerRoleEnabled,
    refreshData,
    handleRemoveProfilePictureDialog,
    updateUsers,
}) => {
    const legacyEnterpriseClient = useLegacyEnterpriseClient();

    return (
        <Wrapper>
            {legacyEnterpriseClient && <ClientReference />}
            {managerRoleEnabled && (
                <>
                    <Manager />
                    <SecondaryManagers />
                </>
            )}
            <Roles />
            <ChampionStatusLabel
                refreshData={refreshData}
                updateUsers={updateUsers}
            />
            <ProfilePicture
                handleRemoveProfilePictureDialog={
                    handleRemoveProfilePictureDialog
                }
            />
        </Wrapper>
    );
};
