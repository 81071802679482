import { lighten } from '@mui/material';

export const animate = (baseColor: string) => {
    return {
        animation: 'shimmer 2s infinite linear',
        background: `linear-gradient(to right, ${lighten(
            baseColor,
            0.4
        )} 4%, ${baseColor} 25%,${lighten(baseColor, 0.4)} 36%)`,
        backgroundSize: '1000px 100%',
    };
};
