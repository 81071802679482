import { styled } from '@mui/material';

export const TableWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    '& .MuiTableContainer-root > .MuiTable-root > .MuiTableHead-root > .MuiTableRow-head > th:nth-of-type(2)':
        {
            width: '20%',
        },
}));

export const LoadingWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
}));

export const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));
