import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { IUserFilter } from '../components/main-app/users/FilterDrawer';
import { UserFilterType } from '../types';
import { useUsers } from '../custom-providers/UsersProvider';

interface IOwnProps {
    filterType?: UserFilterType;
}

export const useUserFilters = ({ filterType }: IOwnProps) => {
    const {
        dispatch,
        state: { activeFilters },
    } = useUsers();

    const [inputValue, setInputValue] = useState<IUserFilter[]>([]);

    useEffect(() => {
        setInputValue(
            activeFilters.filter((filter) => filter.filterType === filterType)
        );
    }, [activeFilters]);

    const inputHandler = (
        _e: React.SyntheticEvent<Element, Event>,
        val: IUserFilter[],
        _reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<IUserFilter>
    ) => {
        setInputValue(val);
        if (details?.option) {
            dispatch({ type: 'ADD_ACTIVE_FILTER', payload: details.option });
        }
    };

    const parseActiveFilterByType = (type: UserFilterType) => {
        const filts = activeFilters.filter((f) => f.filterType === type);

        return filts.map((filt) => filt.value).join(',') || null;
    };

    return {
        inputHandler,
        inputValue,
        parseActiveFilterByType,
    };
};
