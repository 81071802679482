import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import ImageChat from '../../../assets/img/chat.jpg';
import { pxToRem } from '../../../utils/style-functions';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Wrapper = styled('div')(({ theme }) => ({
    background: 'linear-gradient(180deg, #E0D2FF 0%, #F6F7FB 100%)',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    maxWidth: 640,
    margin: theme.spacing(3, 'auto'),
    gap: theme.spacing(2),
    [theme.breakpoints.down(640)]: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
}));
const ImageWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    boxShadow: theme.cogShadows.lg,
    [theme.breakpoints.down(640)]: {
        width: '100%',
        display: 'flex',
    },
}));
const ChatImage = styled('img')(({ theme }) => ({
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down(640)]: {
        margin: '0 auto',
    },
}));

const TextWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 0),
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    [theme.breakpoints.down(640)]: {
        marginBottom: theme.spacing(2),
    },
}));

export const CognassistantBanner = () => {
    const showAIWidgetPromotion = useFeatureIsOn('ai-widget-promotion');

    if (!showAIWidgetPromotion) {
        return null;
    }

    return (
        <Wrapper>
            <ImageWrapper>
                <ChatImage src={ImageChat} alt='ai chat image' />
            </ImageWrapper>
            <TextWrapper>
                <Heading variant='h1'>
                    Get more personalised strategies specific to you by using
                    our expertly-trained AI.
                </Heading>
                <Button
                    text='Go to Cognassistant'
                    id='ai-widget-promotion-link'
                />
            </TextWrapper>
        </Wrapper>
    );
};
