import {
    AppInsightsContext,
    AppInsightsErrorBoundary,
    ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import {
    ApplicationInsights,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { useEffect, useState } from 'react';

import GeneralError from '../pages/unauthenticated/something-went-wrong';
import { useConfig } from './ConfigProvider';

interface IOwnProps {
    children: React.ReactElement;
}

export const AppInsightsProvider: React.FC<IOwnProps> = ({ children }) => {
    const {
        state: {
            appConfig: { applicationInsightsConnectionString },
        },
    } = useConfig();

    const [reactPlugin, setReactPlugin] = useState<ReactPlugin>(
        new ReactPlugin()
    );

    useEffect(() => {
        if (applicationInsightsConnectionString) {
            const reactPlugin = new ReactPlugin();
            setReactPlugin(reactPlugin);

            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: applicationInsightsConnectionString,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                },
            });

            appInsights.loadAppInsights();

            appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
                env.tags = env.tags || [];
                env.tags['ai.cloud.role'] = 'App';
            });
        }
    }, [applicationInsightsConnectionString]);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary
                onError={GeneralError}
                appInsights={reactPlugin}
            >
                {children}
            </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
    );
};
