import { Card, QuestionIcon } from '../../../styled-components';
import {
    ChartWrapper,
    InnerWrapper,
    KeyWrapper,
    TopWrapper,
} from './styled-components';
import { IDashboardGraphItem, IParsedDashboardGraph } from '../../../../types';
import { Tooltip, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { Chart } from './Chart';
import { ChartKeys } from './ChartKeys';
import { ChartLoading } from './ChartLoading';
import { ChartLocked } from './ChartLocked';
import { MiniPagination } from './MiniPagination';

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
}));

const TooltipWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
}));

const BinaryKeyWrapper = styled('div')(() => ({
    display: 'flex',
    flexFlow: 'column nowrap',
}));

export interface IParsedChartData {
    title: string;
    value: number;
    percentageValue: number;
    color: string;
    actualValue: number;
    remainderValue: number;
}

interface IOwnProps {
    graph: IParsedDashboardGraph;
    cardLoading?: boolean;
    assessmentsToUnlock?: number;
}

export const ChartCard: React.FC<IOwnProps> = ({
    graph,
    cardLoading = true,
    assessmentsToUnlock,
}) => {
    const { title, infoText, graphItems, isBinary, binaryKeyValue, happyKey } =
        graph;
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [paginatedKeys, setPaginatedKeys] = useState<IDashboardGraphItem[][]>(
        [[]]
    );
    const [currentPage, setCurrentPage] = useState<number>(0);
    const theme = useTheme();

    useEffect(() => {
        if (!cardLoading) {
            if (graphItems.length === 0) {
                setIsLocked(true);
            }
        }
    }, [cardLoading]);

    useEffect(() => {
        if (!cardLoading && graphItems && !isBinary) {
            let tempArr = [];
            const keys = [];
            for (let i = 0; i <= graphItems.length; i++) {
                if (i > 0 && i % 5 === 0 && i !== graphItems.length) {
                    keys.push(tempArr);
                    tempArr = [];
                    tempArr.push(graphItems[i]);
                } else if (i === graphItems.length) {
                    keys.push(tempArr);
                    tempArr = [];
                } else {
                    tempArr.push(graphItems[i]);
                }
            }
            setPaginatedKeys(keys);
        }
    }, [graphItems, isBinary]);

    const togglePage = (next = true) => {
        if (next) {
            if (currentPage < paginatedKeys.length - 1) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        } else {
            if (currentPage > 0) {
                setCurrentPage((prevPage) => prevPage - 1);
            }
        }
    };

    const getHappyKeyValue = () => {
        const item = graphItems.find((item) => item.title === happyKey);
        if (item) {
            return item.percentageValue;
        }

        return null;
    };

    if (cardLoading) {
        return <ChartLoading isLocked={isLocked} />;
    }

    return (
        <Card>
            <TopWrapper>
                <Typography variant='h5'>{title}</Typography>
                {infoText && (
                    <Tooltip
                        title={
                            <TooltipWrapper>
                                <Typography>{infoText}</Typography>
                            </TooltipWrapper>
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        sx={{ fontSize: '16px' }}
                    >
                        <div style={{ height: 24, width: 24, marginLeft: 8 }}>
                            <QuestionIcon
                                fill={theme.palette.text.primary}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Tooltip>
                )}
            </TopWrapper>
            <InnerWrapper>
                <KeyWrapper isLocked={isLocked}>
                    {isBinary ? (
                        <BinaryKeyWrapper>
                            <Typography variant='h1'>
                                {getHappyKeyValue()}%
                            </Typography>
                            <Typography>{binaryKeyValue}</Typography>
                        </BinaryKeyWrapper>
                    ) : (
                        <ChartKeys
                            isLocked={isLocked}
                            isLoading={cardLoading}
                            paginatedKeys={paginatedKeys}
                            currentPage={currentPage}
                        />
                    )}
                </KeyWrapper>
                {isLocked ? (
                    <ChartLocked assessmentsToUnlock={assessmentsToUnlock} />
                ) : (
                    <ChartWrapper>
                        <Chart parsedItems={graphItems} loading={false} />
                    </ChartWrapper>
                )}
            </InnerWrapper>
            {paginatedKeys.length > 1 && !isLocked && (
                <PaginationWrapper>
                    <MiniPagination
                        togglePage={togglePage}
                        currentPage={currentPage}
                        parsedKeys={paginatedKeys}
                    />
                </PaginationWrapper>
            )}
        </Card>
    );
};

export default ChartCard;
