import { IAPIContent } from '../types';

export const parseApiContent = (
    content: IAPIContent | null
): JSX.Element | null => {
    if (content === null) {
        return null;
    }

    switch (content.type) {
        case 'string':
            return (
                <>
                    {content.value.map((para, index) => (
                        <p key={index}>{para}</p>
                    ))}
                </>
            );
        case 'list':
            return (
                <ul>
                    {content.value.map((listItem, index) => (
                        <li key={index}>{listItem}</li>
                    ))}
                </ul>
            );
    }
};

export const parseParagraphs = (paras: string[]): JSX.Element => {
    return (
        <>
            {paras.map((para, index) => (
                <p key={index}>{para}</p>
            ))}
        </>
    );
};

export const parseList = (listItems: string[]): JSX.Element => {
    return (
        <>
            {listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
            ))}
        </>
    );
};
