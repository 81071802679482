import { Navigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../../custom-providers/AuthProvider';
import { useEffect } from 'react';

export const LoginRedirect = () => {
    const { dispatch } = useAuth();

    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');

    useEffect(() => {
        dispatch({ type: 'SET_HAS_CALLED_LOGIN' });
    }, []);

    if (redirectUrl) {
        return <Navigate to={redirectUrl} replace />;
    }

    return null;
};
