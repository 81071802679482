import { Typography, styled } from '@mui/material';

import { CogTypeBanner } from '../../../../components/main-app/cognitive-map-v2/overview/CogTypeBanner';
import ProcessingInformationVisual from '../../../../components/main-app/cognitive-map-v2/overview/ProcessingInformationVisual';
import Strengths from '../../../../components/main-app/cognitive-map-v2/overview/Strengths';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const OverviewInnerWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(3),
    flexFlow: 'row wrap',
}));

const ProcessingInformationVisualWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #EDEDEF`,
    flexGrow: 1,
    maxHeight: 330,
}));

const ProcessingInformationStrengthsWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #EDEDEF`,
    flexBasis: '34%',
    flexGrow: 1,
}));

const ProcessingInformationTitleWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

interface IOwnProps {
    fullName?: string;
}

const CognitiveMapOverview: React.FC<IOwnProps> = ({ fullName }) => {
    const {
        state: { cognitiveMapV2, screenSize },
    } = useCognitiveMap();

    if (!cognitiveMapV2) {
        return null;
    }

    return (
        <>
            <CogTypeBanner
                fullName={fullName}
                screenSize={screenSize}
                tooltipText={cognitiveMapV2.staticContent.cogTypeTooltip}
                cogTypeName={cognitiveMapV2.cogType.cogTypeName}
                cogTypeDescription={cognitiveMapV2.cogType.description}
            />
            <ProcessingInformationTitleWrapper>
                <Typography variant='h2' sx={{ fontWeight: 500 }}>
                    {
                        cognitiveMapV2?.staticContent?.processInformationVisual
                            ?.title
                    }
                </Typography>
            </ProcessingInformationTitleWrapper>
            <OverviewInnerWrapper>
                <ProcessingInformationVisualWrapper>
                    <ProcessingInformationVisual />
                </ProcessingInformationVisualWrapper>
                <ProcessingInformationStrengthsWrapper>
                    <Strengths />
                </ProcessingInformationStrengthsWrapper>
            </OverviewInnerWrapper>
        </>
    );
};

export default CognitiveMapOverview;
