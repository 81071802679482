import {
    CircularProgress,
    Pagination,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import CourseCard from '../../../components/CourseCard';
import { IStrategy } from '../../../types';
import { Input } from '@cognassist/react-components';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { Search } from 'react-feather';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useDebounce } from 'use-debounce';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSnackbar } from 'notistack';

const NeuroVersityCoursesWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down(1000)]: {
        gridTemplateColumns: '1fr',
    },
}));

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

const NoCoursesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    width: '100%',
    minHeight: 'calc(100vh - 400px)',
}));

const SearchWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(3),
}));

const SearchInput = styled(Input)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    minWidth: 300,
}));

export const Neuroversity = () => {
    const shopWindowEnabled = useFeatureIsOn('shop-window');
    const { getUserNeuroVersityCourses } = endpoints();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const [courses, setCourses] = useState<IStrategy[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [filterText, setFilterText] = useState<string>('');
    const [debouncedFilterText] = useDebounce(filterText, 500);
    const [loading, setLoading] = useState<boolean>(true);

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const {
        state: { caiDrawerOpen },
    } = useCognitiveMap();

    useEffect(() => {
        document.title = 'NeuroVersity';
    }, []);

    useEffect(() => {
        if (userId && !caiDrawerOpen) {
            setLoading(true);
            getData(userId, currentPage, debouncedFilterText);
        }
    }, [userId, currentPage, debouncedFilterText, caiDrawerOpen]);

    const getData = async (
        userId: string,
        pageIndex: number,
        searchText: string
    ) => {
        const { data, error } = await getUserNeuroVersityCourses({
            userId,
            pageIndex,
            filterText: searchText,
        });

        if (data) {
            setCourses(data.strategies);
            setTotalPages(data.totalPages);
        }

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
        setLoading(false);
    };

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    if (!shopWindowEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader title='NeuroVersity' />
            <MainContentInner>
                <SearchWrapper>
                    <SearchInput
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder='What are you looking for?'
                        endAdornment={
                            <Search color={theme.palette.text.primary} />
                        }
                    />
                </SearchWrapper>
                {courses.length ? (
                    <NeuroVersityCoursesWrapper id='courses'>
                        {courses.map((course) => (
                            <CourseCard
                                key={course.courseUrl}
                                course={course}
                                isNeuroversity={true}
                            />
                        ))}
                    </NeuroVersityCoursesWrapper>
                ) : (
                    <NoCoursesWrapper>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant='h2'>
                                No courses available
                            </Typography>
                        )}
                    </NoCoursesWrapper>
                )}

                {totalPages > 1 && (
                    <PaginationWrapper>
                        <Pagination
                            page={currentPage}
                            onChange={updatePageNumber}
                            count={totalPages}
                            boundaryCount={6}
                        />
                    </PaginationWrapper>
                )}
            </MainContentInner>
        </>
    );
};
