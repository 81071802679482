import {
    AssessmentStatus,
    ClientConfigItem,
    DashboardGraph,
    IDashboardData,
    IDashboardGraph,
    IGetLearnerAssessmentStatusResponse,
    Roles,
} from '../../../types';
import { useEffect, useState } from 'react';

import AssessmentBanner from './banners/AssessmentBanner';
import { Charts } from './pie-charts';
import CircularProgress from '@mui/material/CircularProgress';
import { Courses } from './courses';
import ReportBanner from './banners/ReportBanner';
import { endpoints } from '../../../api/endpoints';
import { mapAssessmentUserConfig } from '../../../utils/assessmentStatusLookup';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useInterval } from '../../../custom-hooks/useInterval';
import { useSnackbar } from 'notistack';

const getAssessmentStatusInterval = 30000; // 30 seconds

const LoadingWrapper = styled('div')(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ChartsWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

interface IAssessmentConfig {
    showBanner: boolean | null;
    started: boolean | null;
    complete: boolean | null;
}

const DashboardSelfOnboardingLearner: React.FC = () => {
    const { getLearnerAssessmentStatus, getDashboardGraphs } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(true);
    const [dashboardData, setDashboardData] = useState<IDashboardData>();
    const [neuroDifferenceChartsEnabled, setNeuroDifferenceChartsEnabled] =
        useState<boolean>();

    const [{ showBanner, started, complete }, setAssessmentConfig] =
        useState<IAssessmentConfig>({
            showBanner: null,
            started: null,
            complete: null,
        });

    const {
        state: {
            userConfig: { userId, roles },
            clientConfig: { items },
        },
        dispatch,
    } = useAuth();

    useEffect(() => {
        const ccEnabled = items?.find(
            (configItem) =>
                configItem.id === ClientConfigItem.DisableNeurodifferenceCharts
        );
        setNeuroDifferenceChartsEnabled(ccEnabled?.state ? false : true);
    }, [items]);

    useEffect(() => {
        if (userId) {
            getData(userId);
        }
    }, [userId]);

    const getAssessmentStatus = async (uid: string) => {
        const { data, error } = await getLearnerAssessmentStatus(uid);

        if (error) {
            resetAssessmentState();
            return;
        }

        if (data) {
            setupAssessmentState(data);
        }
    };

    const getData = async (userId: string) => {
        setLoading(true);
        const [{ data: aData, error: aError }, { data: dData, error: dError }] =
            await Promise.all([
                getLearnerAssessmentStatus(userId),
                getDashboardGraphs(),
            ]);

        if (!dError && dData) {
            setDashboardData(dData);
        }

        if (aError) {
            resetAssessmentState();
            setLoading(false);
            return;
        }

        if (aData) {
            setupAssessmentState(aData);
        }
    };

    const resetAssessmentState = () => {
        enqueueSnackbar(
            'Something went wrong. Please try refreshing the page.',
            {
                variant: 'error',
            }
        );
        setAssessmentConfig({
            showBanner: null,
            complete: null,
            started: null,
        });

        clearAssInterval();
    };

    const setupAssessmentState = (
        data: IGetLearnerAssessmentStatusResponse
    ) => {
        setAssessmentConfig({
            showBanner:
                data.status === null ||
                data.status === AssessmentStatus.Pending ||
                data.status === AssessmentStatus.Started,
            complete: data.status === AssessmentStatus.Complete,
            started: data.status === AssessmentStatus.Started,
        });

        dispatch({
            type: 'SET_USER_CONFIG',
            payload: {
                ...mapAssessmentUserConfig(data.status),
                assessmentAccessCode: data.accessCode ?? undefined,
            },
        });

        setLoading(false);
    };

    const clearAssInterval = useInterval(() => {
        if (userId && !loading && !complete) {
            getAssessmentStatus(userId);
        }
    }, getAssessmentStatusInterval);

    const getOrgAndColleagueDashboardData = (graphs: IDashboardGraph[]) =>
        graphs.filter(
            (graph) => graph.chartId !== DashboardGraph.AssessmentsCompleted
        );

    const getAllUsersDashboardData = (graphs: IDashboardGraph[]) =>
        graphs.filter(
            (graph) => graph.chartId === DashboardGraph.AssessmentsCompleted
        );

    return (
        <>
            {loading ? (
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>
            ) : (
                <>
                    {showBanner && (
                        <AssessmentBanner hasStartedAssessment={started} />
                    )}
                    {complete && <ReportBanner />}
                </>
            )}
            {dashboardData && (
                <>
                    <ChartsWrapper>
                        {neuroDifferenceChartsEnabled && (
                            <Charts
                                dashboardData={{
                                    ...dashboardData,
                                    charts: getOrgAndColleagueDashboardData(
                                        dashboardData.charts
                                    ),
                                }}
                                title='My organisation'
                            />
                        )}
                    </ChartsWrapper>
                    {(roles?.includes(Roles.ClientAdmin) ||
                        roles?.includes(Roles.SysAdmin)) && (
                        <ChartsWrapper>
                            <Charts
                                dashboardData={{
                                    ...dashboardData,
                                    charts: getAllUsersDashboardData(
                                        dashboardData.charts
                                    ),
                                }}
                                title='All users'
                            />
                        </ChartsWrapper>
                    )}
                    <Courses />
                </>
            )}
        </>
    );
};

export default DashboardSelfOnboardingLearner;
