import {
    APICall,
    ChampionStatus,
    IChampionUserProfileResponse,
    IGetLearnerResponse,
    Roles,
} from '../../../types';
import { CircularProgress, styled } from '@mui/material';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
    Tab,
    TabRowsMaxWidth,
    Tabs,
    TabsRow,
} from '../../styled-components/Tabs';

import { ChampionDetails } from './champion-details';
import { MainContentInner } from '../../styled-components/Wrappers';
import { PasswordReset } from './PasswordReset';
import { PersonalDetails } from './personal-details';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import useResizeObserver from '@react-hook/resize-observer';
import { useSnackbar } from 'notistack';

const LoadingWrapper = styled('div')(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const FormWrapper = styled('div')(() => ({
    maxWidth: 500,
}));

enum CurrentTab {
    PersonalDetails = 1,
    ChampionDetails = 2,
    Password = 3,
}

export interface IChampionDetails extends IChampionUserProfileResponse {
    profilePictureLastUpdateTimestamp: string | null;
}

export const LearnerAccountSettings = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { getLearnerDetails, getUserChampionStatus, getChampionUserProfile } =
        endpoints();

    const [tab, setTab] = useState<CurrentTab>(CurrentTab.PersonalDetails);
    const [learnerDetails, setLearnerDetails] = useState<
        APICall<IGetLearnerResponse>
    >({
        data: null,
        loading: false,
        error: null,
    });
    const [championDetails, setChampionDetails] = useState<
        APICall<IChampionDetails>
    >({
        data: null,
        loading: false,
        error: null,
    });
    const [championStatus, setChampionStatus] = useState<{
        userIsCertifiedChampion: boolean | null;
        loading: boolean;
    }>({ userIsCertifiedChampion: null, loading: true });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [isSmall, setIsSmall] = useState<boolean>(false);

    useResizeObserver(wrapperRef, (entry) => {
        const { width } = entry.contentRect;
        setIsSmall(width < 744);
    });

    const {
        state: {
            userConfig: { userId, useSingleSignOn, roles },
        },
    } = useAuth();

    useEffect(() => {
        if (userId) {
            requestLearnerDetails(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (roles?.includes(Roles.Champion)) {
            requestUserChampionStatus();
        } else {
            setUserIsNotChampion();
        }
    }, []);

    useEffect(() => {
        if (championStatus.userIsCertifiedChampion && !championDetails.data) {
            requestChampionProfile();
        }
    }, [championStatus.userIsCertifiedChampion, championDetails.data]);

    const requestUserChampionStatus = async () => {
        const { data } = await getUserChampionStatus();

        if (data?.status === ChampionStatus.Certified) {
            setChampionStatus({
                userIsCertifiedChampion: true,
                loading: false,
            });
        } else {
            setUserIsNotChampion();
        }
    };

    const setUserIsNotChampion = () => {
        setChampionStatus({
            userIsCertifiedChampion: false,
            loading: false,
        });
    };

    const requestChampionProfile = async () => {
        if (!championStatus.userIsCertifiedChampion) {
            return;
        }

        setChampionDetails((prev) => ({
            ...prev,
            loading: true,
        }));

        const { data, error } = await getChampionUserProfile();

        if (data) {
            setChampionDetails({
                error: null,
                data: {
                    bio: data.bio,
                    profilePictureFileName: data.profilePictureFileName,
                    profilePictureLastUpdateTimestamp: null,
                },
                loading: false,
            });
        }

        if (error) {
            enqueueSnackbar(
                'Could not retrieve data, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );

            setChampionDetails({
                data: null,
                error,
                loading: false,
            });
        }
    };

    const refreshChampionBio = async () => {
        if (!championStatus.userIsCertifiedChampion) {
            return;
        }

        const { error, data } = await getChampionUserProfile();

        if (error) {
            enqueueSnackbar(
                'Could not retrieve updated bio, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setChampionDetails((prev) => ({
                ...prev,
                data: {
                    bio: data.bio,
                    profilePictureLastUpdateTimestamp:
                        prev.data?.profilePictureLastUpdateTimestamp ?? null,
                    profilePictureFileName:
                        prev.data?.profilePictureFileName ??
                        data.profilePictureFileName,
                },
            }));
        }
    };

    const updateChampionProfilePicture = (
        profilePictureFileName: string | null,
        profilePictureLastUpdateTimestamp: string | null
    ) => {
        setChampionDetails((prev) => ({
            ...prev,
            data: {
                bio: prev.data?.bio ?? null,
                profilePictureLastUpdateTimestamp,
                profilePictureFileName,
            },
        }));
    };

    const requestLearnerDetails = async (userId: string) => {
        setLearnerDetails((prev) => ({
            ...prev,
            loading: true,
        }));

        const { data, error } = await getLearnerDetails(userId);

        if (data) {
            setLearnerDetails({
                error: null,
                data,
                loading: false,
            });
        }

        if (error) {
            enqueueSnackbar(
                'Could not retrieve data, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );

            setLearnerDetails({
                data: null,
                error,
                loading: false,
            });
        }
    };

    const handleTabChange = (_event: SyntheticEvent, newValue: CurrentTab) => {
        setTab(newValue);
    };

    const getTabContent = (tab: CurrentTab | null) => {
        if (!learnerDetails.data) {
            return null;
        }

        switch (tab) {
            case CurrentTab.PersonalDetails:
                return (
                    <PersonalDetails
                        learnerDetails={learnerDetails.data}
                        isSmall={isSmall}
                    />
                );
            case CurrentTab.ChampionDetails:
                return (
                    <ChampionDetails
                        isSmall={isSmall}
                        championDetails={championDetails.data}
                        refreshChampionBio={refreshChampionBio}
                        updateChampionProfilePicture={
                            updateChampionProfilePicture
                        }
                    />
                );
            case CurrentTab.Password:
                return <PasswordReset isSmall={isSmall} />;
            default:
                return null;
        }
    };

    return (
        <>
            <TabsRow>
                <TabRowsMaxWidth>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='account settings tab'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <Tab
                            value={CurrentTab.PersonalDetails}
                            label='Personal details'
                        />
                        {championStatus.userIsCertifiedChampion && (
                            <Tab
                                value={CurrentTab.ChampionDetails}
                                label='Champion details'
                            />
                        )}
                        {!useSingleSignOn && (
                            <Tab value={CurrentTab.Password} label='Password' />
                        )}
                    </Tabs>
                </TabRowsMaxWidth>
            </TabsRow>

            <MainContentInner>
                <div ref={wrapperRef}>
                    {learnerDetails.loading ||
                    championStatus.loading ||
                    championDetails.loading ? (
                        <LoadingWrapper>
                            <CircularProgress />
                        </LoadingWrapper>
                    ) : (
                        <FormWrapper>{getTabContent(tab)}</FormWrapper>
                    )}
                </div>
            </MainContentInner>
        </>
    );
};
