import { useEffect, useState } from 'react';

import { Charts } from './pie-charts';
import { IDashboardData } from '../../../types';
import { PageWrapper } from '../../styled-components/Wrappers';
import { endpoints } from '../../../api/endpoints';
import { useNavigate } from 'react-router';

export const DashboardOther: React.FC = () => {
    const { getDashboardGraphs } = endpoints();
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState<IDashboardData>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data, error } = await getDashboardGraphs();

        if (error) {
            navigate('/something-went-wrong', { replace: true });
        } else if (data) {
            setDashboardData(data);
        }
    };

    return (
        <PageWrapper>
            <Charts dashboardData={dashboardData} />
        </PageWrapper>
    );
};

export default DashboardOther;
