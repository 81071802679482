import { Button, Input } from '@cognassist/react-components';
import { ButtonWrapper, Title } from './shared-components';
import { Controller, useForm } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styled-components';
import { HelpCircle } from 'react-feather';
import { HelpTooltip } from '../../HelpTooltip';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { styled } from '@mui/material';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const FieldWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
}));

const LabelWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

const LabelIconWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const Label = styled('label')(({ theme }) => ({
    fontSize: pxToRem(16),
    fontWeight: 600,
    marginRight: theme.spacing(1),
}));

const GenerateButton = styled(Button)(({ theme }) => ({
    background: 'none',
    fontsize: pxToRem(13),
    lineHeight: pxToRem(14),
    color: theme.palette.primary.main,
    padding: '0 !important',
    '&:hover': {
        background: 'none !important',
    },
    '&:active': {
        color: theme.palette.primary.dark,
    },
}));

interface IFormFields {
    displayName: string;
    guidelinesConsent: boolean;
}

const cleanData = (displayName: string) =>
    displayName.replace(/\s\s+/g, ' ').trim();

export const DisplayNameForm: React.FC = () => {
    const { dispatch } = useCommunityState();
    const { enqueueSnackbar } = useSnackbar();
    const {
        createCommunityAccount,
        generateCommunityDisplayName,
        checkIfCommunityDisplayNameIsUsed,
    } = endpoints();

    const [hasGenerated, setHasGenerated] = useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const methods = useForm<IFormFields>({
        defaultValues: {
            displayName: '',
            guidelinesConsent: false,
        },
    });

    const {
        watch,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = methods;

    const showError = (error: string) =>
        enqueueSnackbar(error, {
            variant: 'error',
        });

    const submitDisplayName = async (displayName: string) => {
        setSubmitLoading(true);

        const { error } = await createCommunityAccount({
            displayName,
        });

        if (error) {
            showError('Something went wrong');
        } else {
            dispatch({
                type: 'SET_COMMUNITY_DISPLAY_NAME',
                payload: { communityDisplayName: displayName },
            });
            dispatch({
                type: 'SET_ONBOARDING_PAGE',
                payload: { onboardingPage: 5 },
            });
        }

        setSubmitLoading(false);
    };

    const onContinue = () => {
        handleSubmit((values: IFormFields) => {
            submitDisplayName(cleanData(values.displayName));
        })();
    };

    const generateDisplayName = async () => {
        setGenerateLoading(true);

        const { data, error } = await generateCommunityDisplayName();

        if (data) {
            setValue('displayName', data.displayName, { shouldValidate: true });
            setHasGenerated(true);
        }

        if (error) {
            showError(
                'We were unable to generate a new display name for you, please try again or choose your own display name'
            );
        }

        setGenerateLoading(false);
    };

    return (
        <>
            <Title variant='h1'>Setup your account</Title>
            <FieldWrapper>
                <LabelWrapper>
                    <LabelIconWrapper>
                        <Label htmlFor='displayName'>Display name</Label>
                        <HelpTooltip
                            text={`Feel free to identify yourself, or stay anonymous. If you
                    cannot think of a display name, click "Generate"
                    and we'll come up with a random one for you!`}
                            Icon={
                                <HelpCircle
                                    size={18}
                                    fill='black'
                                    color='white'
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                        />
                    </LabelIconWrapper>
                    <GenerateButton
                        disableRipple
                        text={hasGenerated ? 'Regenerate' : 'Generate'}
                        onClick={generateDisplayName}
                        loading={generateLoading}
                    />
                </LabelWrapper>
                <Controller
                    name='displayName'
                    control={control}
                    defaultValue={watch('displayName')}
                    rules={{
                        required: 'Please enter a display name.',
                        maxLength: {
                            value: 100,
                            message:
                                'You have gone over the 100 character limit, please reduce it and try again.',
                        },
                        validate: {
                            notEmpty: (value) => {
                                if (!value.trim()) {
                                    return 'Please enter a display name.';
                                }

                                return true;
                            },
                            unique: async (value) => {
                                const { data } =
                                    await checkIfCommunityDisplayNameIsUsed(
                                        cleanData(value)
                                    );

                                return data?.isUsed
                                    ? 'This display name has already been taken, please choose a different one.'
                                    : true;
                            },
                        },
                    }}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            id='displayName'
                            hasError={!!errors.displayName}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name='displayName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </FieldWrapper>
            <ButtonWrapper>
                <Button
                    color='inherit'
                    text='Back'
                    sx={{ mr: 2 }}
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 3 },
                        });
                    }}
                />
                <Button
                    text='Join Community'
                    onClick={onContinue}
                    loading={submitLoading}
                    disabled={generateLoading}
                />
            </ButtonWrapper>
        </>
    );
};
