import {
    CaiMessages,
    IMessage,
} from '../../../components/main-app/cai/CaiMessages';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Input as CogInput } from '@cognassist/react-components';
import { Send as FeatherSend } from 'react-feather';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { nanoid } from 'nanoid';
import { styled } from '@mui/material';
import { useCai } from '../../../custom-hooks/useCai';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Wrapper = styled('div')(({ theme }) => ({
    height: 'calc(100vh - 181px)',
    display: 'grid',
    gridTemplateRows: '1fr 100px',
    gap: theme.spacing(4),
}));

const InputWrapper = styled('div')(() => ({}));

const Input = styled(CogInput)(({ theme }) => ({
    background: theme.palette.common.white,
}));

const Send = styled(FeatherSend)(() => ({
    cursor: 'pointer',
}));

const InitialQuery: IMessage = {
    answer: "Hello, I'm Cognassistant. I can help you find strategies and tools to support neuro-inclusion in the workplace. How can I help?",
};

export const Cognassistant = () => {
    const showCognassistant = useFeatureIsOn('cognassistant');

    const [message, setMessage] = useState<string>('');
    const ref = useRef<HTMLInputElement>();
    const [sessionId, setSessionId] = useState<string>('');

    const { messages, setMessages, textQuery } = useCai(InitialQuery);

    useEffect(() => {
        setSessionId(nanoid());
    }, []);

    useEffect(() => {
        ref.current?.focus();
    }, [ref.current]);

    const checkKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (
                (e.code === 'Enter' || e.code === 'NumpadEnter') &&
                (e.metaKey || e.ctrlKey)
            ) {
                newMessageHandler();
            }
        },
        [message]
    );

    useEffect(() => {
        window.addEventListener('keydown', checkKeyPress);

        return () => {
            window.removeEventListener('keydown', checkKeyPress);
        };
    }, [checkKeyPress]);

    const newMessageHandler = async () => {
        setMessages((prevState) => [...prevState, { message }]);
        await textQuery(message, sessionId);
        setMessage('');

        ref.current?.focus();
    };

    if (!showCognassistant) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader title='Cognassistant' />
            <MainContentInner>
                <Wrapper>
                    <CaiMessages messages={messages} />
                    <InputWrapper>
                        <Input
                            inputProps={{
                                ref,
                            }}
                            fullWidth
                            endAdornment={<Send onClick={newMessageHandler} />}
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder='Type a question...'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </InputWrapper>
                </Wrapper>
            </MainContentInner>
        </>
    );
};
