import { Asterisk, Label, Required } from '../Field';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel as MuiFormControlLabel, styled } from '@mui/material';

import { Checkbox } from '@cognassist/react-components';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
        marginTop: `-${theme.spacing(0.75)}`,
    },
}));

interface IOwnProps {
    disabled: boolean;
    isSmall?: boolean;
}

export const TermsConditionsCheckbox: React.FC<IOwnProps> = ({
    disabled,
    isSmall = false,
}) => {
    const {
        formState: { errors },
        control,
    } = useFormContext<{ termsConditionsConsent: boolean }>();

    return (
        <>
            <Wrapper>
                <Label>
                    Consent
                    {isSmall && (
                        <Asterisk hasError={!!errors.termsConditionsConsent}>
                            *
                        </Asterisk>
                    )}
                </Label>
                {!isSmall && (
                    <Required hasError={!!errors.termsConditionsConsent}>
                        Required
                    </Required>
                )}
            </Wrapper>
            <FormControlLabel
                control={
                    <Controller
                        control={control}
                        name={'termsConditionsConsent'}
                        defaultValue={false}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { value, ref, name, onChange } }) => (
                            <Checkbox
                                checked={value}
                                inputRef={ref}
                                name={name}
                                onChange={onChange}
                                disabled={disabled}
                                inputProps={{ 'aria-required': 'true' }}
                            />
                        )}
                    />
                }
                label='I consent for any neuro-difference disclosure which may include health data and any future assessment results to be processed to personalise my profile.'
            />
        </>
    );
};
