import { Tooltip, Typography } from '@mui/material';

import { pxToRem } from '../utils/style-functions';

interface IOwnProps {
    text: string;
    Icon: React.ReactElement;
}

export const HelpTooltip: React.FC<IOwnProps> = ({ text, Icon }) => {
    return (
        <Tooltip
            title={
                <Typography
                    sx={{
                        p: '15px',
                        fontSize: pxToRem(14),
                        width: '95%',
                    }}
                >
                    {text}
                </Typography>
            }
            arrow
            placement='bottom'
            enterTouchDelay={0}
        >
            {Icon}
        </Tooltip>
    );
};
