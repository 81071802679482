import {
    FormControl as MuiFormControl,
    InputLabel as MuiInputLabel,
    Pagination,
    SelectChangeEvent,
    styled,
} from '@mui/material';

import { CaiDrawer } from '../../../../components/main-app/cai/CaiDrawer';
import { ContentHelper } from '../../../../components/main-app/cognitive-map/ContentHelper';
import CourseCard from '../../../../components/CourseCard';
import { DomainSelect } from '../../../../components/main-app/cognitive-map/DomainSelect';
import { Loading } from '../../../../components/Loading';
import { MainContentInner } from '../../../../components/styled-components/Wrappers';
import PageHeader from '../../../../components/PageHeader';
import { RoleDrawer } from '../../../../components/main-app/cognitive-map/RoleDrawer';
import { pxToRem } from '../../../../utils/style-functions';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';
import { useStrategies } from '../../../../custom-hooks/useStrategies';

const Wrapper = styled('div')(() => ({}));

const DomainDropdownWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(3),
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: pxToRem(20),
    fontWeight: 400,
    color: theme.palette.text.primary,
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
    minWidth: 350,
    [theme.breakpoints.down(744)]: {
        minWidth: '100%',
    },
}));

const SelectWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column nowrap',
}));

const StrategiesWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down(1000)]: {
        gridTemplateColumns: '1fr',
    },
}));

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

export const MyStrategies = () => {
    const {
        state: { cognitiveMapV1, caiDrawerOpen },
    } = useCognitiveMap();

    const {
        domain,
        setDomain,
        domains,
        strategies,
        currentPage,
        setCurrentPage,
        totalPages,
    } = useStrategies(caiDrawerOpen);

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    const updateDomain = (e: SelectChangeEvent<unknown>) => {
        setDomain(parseInt(e.target.value as string));
        setCurrentPage(1);
    };

    if (!cognitiveMapV1 || !domains) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader
                title='My Strategies'
                subText={
                    <ContentHelper
                        sx={{ flex: '1 1 400px' }}
                        content={
                            cognitiveMapV1.staticContent
                                .myStrategiesPageDescription
                        }
                    />
                }
            />
            <Wrapper>
                <MainContentInner>
                    <DomainDropdownWrapper>
                        <SelectWrapper>
                            <InputLabel id='select-domain-label'>
                                Select domain
                            </InputLabel>
                            <FormControl size='small'>
                                <DomainSelect
                                    domain={domain}
                                    domains={domains}
                                    updateDomain={updateDomain}
                                />
                            </FormControl>
                        </SelectWrapper>
                    </DomainDropdownWrapper>
                    <StrategiesWrapper id='strategy-cards'>
                        {strategies.map((strategy) => (
                            <CourseCard
                                key={strategy.courseUrl}
                                course={strategy}
                            />
                        ))}
                    </StrategiesWrapper>
                </MainContentInner>
                {totalPages > 1 && (
                    <PaginationWrapper>
                        <Pagination
                            page={currentPage}
                            onChange={updatePageNumber}
                            count={totalPages}
                            boundaryCount={6}
                        />
                    </PaginationWrapper>
                )}
            </Wrapper>
            <RoleDrawer />
            <CaiDrawer />
        </>
    );
};
