import { FormControl, MenuItem, Select } from '@mui/material';

import { CogInput } from '../../../styled-components';
import { Field } from '../Field';
import { HasFormerNeurodiversityDiagnosis } from '../../../../types';
import { inputHelperText } from '../../../../content/profile/input-helper-text';
import { useFormContext } from 'react-hook-form';
import { useProfileFocus } from '../../../../custom-hooks/useProfileFocus';

const neurodiversityDiagnosisOptions = [
    {
        label: 'Yes - diagnosed',
        value: HasFormerNeurodiversityDiagnosis.Yes,
    },
    {
        label: 'Yes - undiagnosed',
        value: HasFormerNeurodiversityDiagnosis.NoButIBelieveIAmNeurodivergent,
    },
    {
        label: 'No',
        value: HasFormerNeurodiversityDiagnosis.No,
    },
    {
        label: 'Prefer not to say',
        value: HasFormerNeurodiversityDiagnosis.PreferNotToSay,
    },
];

interface IOwnProps {
    defaultValue: HasFormerNeurodiversityDiagnosis;
    isSmall?: boolean;
}

export const DiagnosedNeurodiversitySelect: React.FC<IOwnProps> = ({
    defaultValue,
    isSmall = false,
}) => {
    const { onFocus, onBlur } = useProfileFocus();

    const {
        formState: { errors },
        register,
    } = useFormContext<{
        hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    }>();

    return (
        <Field
            label='Do you have a neuro-difference?'
            required
            hasError={!!errors.hasFormerNeurodiversityDiagnosis}
            labelId='diagnosedNeurodiversityLabel'
            content={inputHelperText.find(
                (content) => content.key === 'hasFormerNeurodiversityDiagnosis'
            )}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Select
                    {...register('hasFormerNeurodiversityDiagnosis', {
                        validate: (v) => {
                            return [1, 2, 3, 4].includes(v);
                        },
                    })}
                    onFocus={(e) =>
                        onFocus(e, 'hasFormerNeurodiversityDiagnosis')
                    }
                    onBlur={onBlur}
                    defaultValue={
                        defaultValue ===
                        HasFormerNeurodiversityDiagnosis.NotApplicable
                            ? ''
                            : defaultValue
                    }
                    labelId='diagnosedNeurodiversityLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby':
                                    'diagnosedNeurodiversityLabel',
                            }}
                            hasError={!!errors.hasFormerNeurodiversityDiagnosis}
                        />
                    }
                    inputProps={{ 'aria-required': 'true' }}
                >
                    {neurodiversityDiagnosisOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Field>
    );
};
