import { Typography, styled, useTheme } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { CombinedUser } from './Edit';
import { Email } from './fields/Email';
import { ExternalLink } from 'react-feather';
import { FirstName } from './fields/FirstName';
import { LastName } from './fields/LastName';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../utils/style-functions';
import { useLegacyEnterpriseClient } from '../../../custom-hooks/useLegacyEnterpriseClient';
import { useUsers } from '../../../custom-providers/UsersProvider';

const Wrapper = styled('div')(() => ({}));

const Text = styled('span')(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const ViewMapLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: pxToRem(16),
    textDecoration: 'none',
    fontWeight: 600,
    color: theme.palette.primary.main,
}));

const DangerZoneWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
}));

const DangerZoneTitle = styled('div')(({ theme }) => ({
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    fontWeight: 400,
    marginTop: theme.spacing(4),
}));

const DeleteUserTitle = styled('div')(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    marginTop: theme.spacing(3),
}));

interface IOwnProps {
    handleDeleteUserDialog: (user?: CombinedUser) => void;
}

export const PersonalDetails: React.FC<IOwnProps> = ({
    handleDeleteUserDialog,
}) => {
    const theme = useTheme();

    const legacyEnterpriseClient = useLegacyEnterpriseClient();

    const {
        state: { enterpriseUser },
    } = useUsers();

    const deleteUser = () => {
        handleDeleteUserDialog(enterpriseUser);
    };

    return (
        <Wrapper>
            <FirstName />
            <LastName />
            <Email />

            {legacyEnterpriseClient &&
                enterpriseUser?.consentGivenToShareDataWithEmployer && (
                    <ViewMapLink
                        to={`/learner/assessmentreport?learnerUserId=${enterpriseUser.id}`}
                        target='_blank'
                    >
                        <Text>View cognitive map</Text>
                        <ExternalLink
                            size={20}
                            color={theme.palette.primary.main}
                        />
                    </ViewMapLink>
                )}

            <DangerZoneWrapper>
                <DangerZoneTitle>Danger Zone</DangerZoneTitle>
                <DeleteUserTitle>Delete user</DeleteUserTitle>
                <Typography sx={{ mt: 2 }}>
                    Remove this user from your organisation
                </Typography>
                <Button
                    onClick={deleteUser}
                    sx={{ mt: 3 }}
                    text='Delete user'
                    color='error'
                />
            </DangerZoneWrapper>
        </Wrapper>
    );
};
