import { useEffect, useState } from 'react';

import { DesktopGraph } from './DesktopGraph';
import { IParsedDomainData } from '../../../../types/report.types';
import { IStandardScore } from '../../../../types';
import { MobileGraph } from './MobileGraph';
import { domains } from '../../../../content/learner-assessment-report/domainLookup';

interface IOwnProps {
    standardScores: IStandardScore[];
    hasEnglishAsASecondLanguage: boolean;
}

export const HowYouScoredGraph: React.FC<IOwnProps> = ({
    standardScores,
    hasEnglishAsASecondLanguage,
}) => {
    const [scores, setScores] = useState<IParsedDomainData[]>([]);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 600);
        parseScores();
    }, [standardScores]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth < 600 ? true : false);
    };

    const parseScores = () => {
        const sortedScores = domains.reduce(
            (accum: IParsedDomainData[], current) => {
                const currentScore = standardScores.find(
                    (score) => score.domainId === current.id
                );

                if (currentScore) {
                    accum.push({
                        name: current.name,
                        keyName: current.keyName,
                        score: currentScore?.performanceValidityThresholdFailure
                            ? 50
                            : currentScore?.standardScore,
                        pvtFailure:
                            currentScore?.performanceValidityThresholdFailure,
                        barColor: current.barColor,
                    });
                }

                return accum;
            },
            []
        );

        setScores(sortedScores);
    };

    return isMobile ? (
        <MobileGraph
            scores={scores}
            hasEnglishAsASecondLanguage={hasEnglishAsASecondLanguage}
        />
    ) : (
        <DesktopGraph
            scores={scores}
            hasEnglishAsASecondLanguage={hasEnglishAsASecondLanguage}
        />
    );
};

export default HowYouScoredGraph;
