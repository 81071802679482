export enum StrategyStatus {
    NEW = 10,
    APPLIED = 20,
    TRYING = 30,
}

export interface IContextsualisedStrategy {
    sessionId: string;
    title: string;
    status: StrategyStatus;
    lmsSourceId: string;
}

export interface IContextualisedStrategiesResponse {
    userStrategies: IContextsualisedStrategy[];
}

export interface IGetExistingContextualisedStrategyResponse {
    contents: string;
}
