import { Typography, styled } from '@mui/material';

import { X } from 'react-feather';
import { pxToRem } from '../../../utils/style-functions';

const Wrapper = styled('div')(({ theme }) => ({
    width: 500,
    height: '100%',
    position: 'relative',
    minWidth: 500,
    overflowY: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',
    [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        width: '100%',
    },
}));

const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4, 4, 0),
}));

const Title = styled(Typography)(() => ({
    fontSize: pxToRem(24),
    fontWeight: 600,
}));

const CloseIcon = styled(X)(() => ({
    cursor: 'pointer',
}));

interface IOwnProps {
    close: () => void;
    children: React.ReactNode;
    title: string;
    id: string;
}

export const UsersDrawer: React.FC<IOwnProps> = ({
    close,
    children,
    id,
    title,
}) => {
    return (
        <Wrapper id={id}>
            <TopWrapper>
                <Title variant='h1'>{title}</Title>
                <CloseIcon onClick={close} />
            </TopWrapper>
            {children}
        </Wrapper>
    );
};
