import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const useLearnerId = (): string => {
    const [searchParams] = useSearchParams();
    const { learnerUserId } = useParams<{ learnerUserId: string }>();
    const [id, setId] = useState<string>('');

    useEffect(() => {
        const paramId = searchParams.get('learnerUserId');
        if (learnerUserId) {
            setId(learnerUserId.toLowerCase());
        } else if (paramId) {
            setId(paramId.toLowerCase());
        }
    }, [learnerUserId]);

    return id;
};
