import {
    CartesianGrid,
    ReferenceArea,
    ReferenceLine,
    ReferenceLineProps,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    ScatterProps,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    IProcessingBiasData,
    IProcessingBiasReference,
} from '../../../../types';
import { Theme, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { pxToRem } from '../../../../utils/style-functions';
import { useDebounce } from '../../../../custom-hooks/useDebounce';

const sharedLabelStyles = (theme: Theme) => {
    return {
        margin: 0,
        padding: 0,
        fontSize: pxToRem(16),
        lineHeight: '20px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        alignSelf: 'center',
        justifySelf: 'center',
        [theme.breakpoints.down(600)]: {
            fontSize: pxToRem(12),
            width: '100%',
        },
    };
};

const GraphContainer = styled('div')(() => ({
    flexBasis: 550,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: `
        ". topLabel ."
        "leftLabel graph rightLabel"
        ". bottomLabel ."
        `,
}));

const GridContainer = styled('div')(() => ({
    gridArea: 'graph',
    minWidth: 220,
}));

const PurpleHighlight = styled('span')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    color: '#5E4F9C',
}));

const BlueHighlight = styled('span')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    color: '#408EF5',
}));

const TopLabel = styled('p')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    textAlign: 'center',
    gridArea: 'topLabel',
}));

const RightLabel = styled('p')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    justifySelf: 'left',
    textAlign: 'center',
    gridArea: 'rightLabel',
    writingMode: 'vertical-lr',
    width: '100%',
    [theme.breakpoints.down(600)]: {
        fontSize: pxToRem(12),
    },
}));

const BottomLabel = styled('p')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    textAlign: 'center',
    gridArea: 'bottomLabel',
}));

const LeftLabel = styled('p')(({ theme }) => ({
    ...sharedLabelStyles(theme),
    justifySelf: 'right',
    textAlign: 'center',
    gridArea: 'leftLabel',
    transform: 'rotate(180deg)',
    width: '100%',
    writingMode: 'vertical-lr',
    [theme.breakpoints.down(600)]: {
        fontSize: pxToRem(12),
    },
}));

const TooltipContainer = styled('div')(({ theme }) => ({
    minWidth: 77,
    minHeight: 55,
    backgroundColor: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const CustomTooltipText = styled('p')(() => ({
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: pxToRem(14),
    padding: '0 15px',
}));

const CustomScatter = (
    props: ScatterProps & { isSmall: boolean }
): JSX.Element => {
    const size = props.isSmall ? 15 : 25;
    const border = props.isSmall ? 3 : 4;
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill='none'
            x={(props.cx as number) - size / 2}
            y={(props.cy as number) - size / 2}
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                cx={`${size / 2}`}
                cy={`${size / 2}`}
                r={`${size / 2 - border / 2}`}
                fill='white'
                stroke='#33383F'
                strokeWidth={border}
            />
        </svg>
    );
};

const CustomReferenceLine = (
    props: ReferenceLineProps & { isRotated: boolean }
): JSX.Element => {
    return (
        <svg
            viewBox={`0 0 550 20`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            x={props.x}
            y={props.y}
        >
            <path
                style={{
                    transformOrigin: 'center',
                    transform: `rotate(${props.isRotated ? '90' : '0'}deg)`,
                }}
                d='M0 9.59998L15 18.2602L15 0.93972L0 9.59998ZM550.4 9.59993L535.4 0.939674L535.4 18.2602L550.4 9.59993ZM13.5 11.1L536.9 11.0999L536.9 8.09993L13.5 8.09997L13.5 11.1Z'
                fill='#33383F'
            />
        </svg>
    );
};

const CustomTooltip = (props: { name?: string }): JSX.Element => {
    return (
        <TooltipContainer>
            <CustomTooltipText>{props.name}</CustomTooltipText>
        </TooltipContainer>
    );
};

const graphDataLimiter = (num: number) => {
    const fixedTo1 = Math.round(num * 10) / 10;
    if (fixedTo1 < -3) {
        return -3;
    } else if (fixedTo1 > 3) {
        return 3;
    }
    return fixedTo1;
};

interface IOwnProps {
    name?: string;
    graphData: IProcessingBiasData;
    areas: IProcessingBiasReference[];
}

export const Graph: React.FC<IOwnProps> = ({ name, graphData, areas }) => {
    const [isSmall, setIsSmall] = useState(window.innerWidth < 450);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    const debounceResize = useDebounce(function handleResize() {
        setWindowWidth(window.innerWidth);
    }, 500);

    useEffect(() => {
        if (windowWidth < 450) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    }, [windowWidth]);

    return (
        <GraphContainer>
            <TopLabel>Benefits from longer and concentrated sessions</TopLabel>
            <LeftLabel>
                Increased <PurpleHighlight>Language and Verbal</PurpleHighlight>{' '}
                bias
            </LeftLabel>
            <GridContainer>
                <ResponsiveContainer aspect={1} width='99%'>
                    <ScatterChart
                        margin={
                            isSmall
                                ? {
                                      top: 12.5,
                                      right: 12.5,
                                      bottom: 12.5,
                                      left: 12.5,
                                  }
                                : {
                                      top: 24,
                                      right: 24,
                                      bottom: 24,
                                      left: 24,
                                  }
                        }
                    >
                        <XAxis
                            type='number'
                            dataKey='x'
                            name='ProcessingBiasZ'
                            allowDecimals={true}
                            tickCount={13}
                            domain={[-3, 3]}
                            min={-1}
                            hide={true}
                            interval={0}
                        />

                        <YAxis
                            type='number'
                            dataKey='y'
                            name='adjustedZ'
                            allowDecimals={true}
                            tickCount={13}
                            domain={[-3, 3]}
                            hide={true}
                            interval={0}
                        />

                        {areas.map((item) => (
                            <ReferenceArea
                                key={`${item.title}-refArea`}
                                x1={item.xStart}
                                x2={item.xEnd}
                                y1={-3}
                                y2={3}
                                fill={item.color}
                                fillOpacity={item.opacity}
                            />
                        ))}

                        <CartesianGrid
                            strokeDasharray='3 1'
                            stroke='#7F8FA6'
                            strokeWidth={1}
                        />

                        <ReferenceLine
                            y={0}
                            shape={<CustomReferenceLine isRotated={true} />}
                        />

                        <ReferenceLine
                            y={0}
                            shape={<CustomReferenceLine isRotated={false} />}
                        />

                        <Scatter
                            name={name}
                            shape={<CustomScatter isSmall={isSmall} />}
                            style={{ overflow: 'show' }}
                            data={[
                                {
                                    x: graphDataLimiter(
                                        graphData.processingBiasZ || 0
                                    ),
                                    y: graphDataLimiter(
                                        graphData.memoryIndexAdjustedZ || 0
                                    ),
                                },
                            ]}
                        />

                        <Tooltip
                            cursor={false}
                            isAnimationActive={false}
                            content={<CustomTooltip name={name} />}
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </GridContainer>
            <RightLabel>
                Increased <BlueHighlight>Visual and Fluid</BlueHighlight> bias
            </RightLabel>
            <BottomLabel>Benefits from short and regular sessions</BottomLabel>
        </GraphContainer>
    );
};

export default Graph;
