import { useEffect, useRef } from 'react';

import { IReportSection } from '../../types';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { styled } from '@mui/material';
import { useConfig } from '../../custom-providers/ConfigProvider';
import { useReportState } from '../../custom-providers/ReportProvider';

const TabSticker = styled('div', {
    ...shouldForwardProps('tabStuck'),
})<{ tabStuck?: boolean }>(({ tabStuck, theme }) => ({
    position: 'sticky',
    top: 85,
    zIndex: 1,
    background: tabStuck ? theme.palette.common.white : 'inherit',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    boxShadow: tabStuck ? theme.cogShadows.base : 'none',
}));

const TabContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1048,
    margin: 'auto',
    marginTop: -50,
}));

const Tab = styled('div', {
    ...shouldForwardProps('active'),
})<{ active?: boolean }>(({ active, theme }) => ({
    padding: theme.spacing(2),
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: active ? 600 : 500,
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
    background: active ? theme.palette.common.white : 'none',
    borderBottom: active ? `2px solid ${theme.palette.primary.main}` : 'none',
    borderRadius: `8px 8px 0 0`,
}));

const TabPanelContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(5, 4),
    margin: 'auto',
}));

interface IOwnProps {
    tabs: IReportSection[];
}

const Tabs: React.FC<IOwnProps> = ({ tabs }) => {
    const {
        state: { activeTab },
        dispatch: reportDispatch,
    } = useReportState();

    const ref = useRef<HTMLDivElement>(null);
    const {
        state: { reportStickyNav },
        dispatch: configDispatch,
    } = useConfig();

    useEffect(() => {
        if (!activeTab) {
            reportDispatch({
                type: 'SET_ACTIVE_TAB',
                payload: { activeTab: tabs[0].name },
            });
        }
    }, [activeTab]);

    const scrollListener = () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            if (rect.top <= 85) {
                configDispatch({
                    type: 'SET_REPORT_STICKY_NAV',
                    payload: true,
                });
            }

            if (rect.top > 85) {
                configDispatch({
                    type: 'SET_REPORT_STICKY_NAV',
                    payload: false,
                });
            }
        }
    };

    useEffect(() => {
        if (ref.current) {
            window.addEventListener('scroll', scrollListener);
        }

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, [ref.current]);

    return (
        <>
            <TabSticker ref={ref} tabStuck={reportStickyNav}>
                <TabContainer id='tabs'>
                    {tabs.length > 1 &&
                        tabs.map((item) => {
                            return (
                                <Tab
                                    key={item.name}
                                    onClick={() => {
                                        reportDispatch({
                                            type: 'SET_ACTIVE_TAB',
                                            payload: { activeTab: item.name },
                                        });
                                    }}
                                    active={activeTab === item.name}
                                >
                                    {item.name}
                                </Tab>
                            );
                        })}
                </TabContainer>
            </TabSticker>
            <TabPanelContainer>
                {tabs.map((item) => {
                    return (
                        <div key={item.name} hidden={activeTab !== item.name}>
                            {item.element}
                        </div>
                    );
                })}
            </TabPanelContainer>
        </>
    );
};

export default Tabs;
