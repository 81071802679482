import { IDraggableModule, IPriorityModule } from '../../types';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { useRef, useState } from 'react';

import { Close } from '../styled-components';
import ImgDots from '../../assets/img/dots.svg';
import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { styled } from '@mui/material';

export const PriorityModule = styled('div', {
    ...shouldForwardProps('isDragging', 'isHidden'),
})<{ isDragging?: boolean; isHidden?: boolean }>(
    ({ isDragging, isHidden, theme }) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.common.white,
        marginBottom: theme.spacing(1),
        minHeight: 60,
        boxShadow: 'none',
        border:
            isDragging || isHidden
                ? 'none'
                : `1px solid ${theme.palette.grey[300]}`,
        cursor: 'grab',
        transition: 'box-shadow .2s ease',
        '&:hover': {
            boxShadow: theme.cogShadows.md,
        },
    })
);

const NumIcon = styled('div')(({ theme }) => ({
    background: theme.palette.grey[100],
    height: 24,
    width: 24,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: pxToRem(12),
    flex: '0 0 20px',
    margin: theme.spacing(0, 1),
    fontWeight: 600,
}));

const CardContent = styled('div')(() => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    display: 'flex',
    alignItems: 'center',
}));

const EmptyModule = styled('div')(() => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: '#F5F6FA',
    left: 0,
}));

const CloseIconWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 25px',
    marginLeft: 10,
}));

interface IOwnProps {
    index: number;
    module: IPriorityModule;
    removeModule: (id: string) => void;
    moveModule: (
        dragIndex: number,
        hoverIndex: number,
        item?: IPriorityModule
    ) => void;
}

export const PriorityModuleItem: React.FC<IOwnProps> = ({
    index,
    removeModule,
    moveModule,
    module,
}) => {
    const [showClose, setShowClose] = useState<string>();
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop<IDraggableModule, void>({
        accept: 'ROW',
        hover(item: IDraggableModule, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            if (dragIndex === -1) {
                moveModule(dragIndex, hoverIndex, item);
            } else {
                moveModule(dragIndex, hoverIndex);
            }
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag<
        IDraggableModule,
        void,
        { isDragging: boolean }
    >({
        type: 'ROW',
        item: () => {
            return { id: module.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <PriorityModule
            ref={ref}
            onMouseLeave={() => setShowClose(undefined)}
            onMouseMove={() => setShowClose(module.id)}
            isDragging={isDragging}
            isHidden={module.hidden}
        >
            {(isDragging || module.hidden) && <EmptyModule />}
            <CardContent>
                <img style={{ height: 14 }} src={ImgDots} alt='Drag handle' />
                <div>
                    <NumIcon>{index + 1}</NumIcon>
                </div>
                <span>{module.name}</span>
            </CardContent>
            <CloseIconWrapper>
                {showClose === module.id && (
                    <Close
                        onClick={() => removeModule(module.id)}
                        sx={{
                            cursor: 'pointer',
                        }}
                    />
                )}
            </CloseIconWrapper>
        </PriorityModule>
    );
};
