import { Theme, styled } from '@mui/material';

import CogMenu from '../../CogMenu';
import { IReportMenuItem } from '../../../types';
import { NavLink } from 'react-router-dom';
import { pxToRem } from '../../../utils/style-functions';

const desktopMenuItemStyles = (theme: Theme) => {
    return {
        padding: theme.spacing(0, 1.5),
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
    };
};

const DesktopMenuItemNavLink = styled(NavLink)(({ theme }) =>
    desktopMenuItemStyles(theme)
);

const DesktopMenuItemAnchor = styled('a')(({ theme }) =>
    desktopMenuItemStyles(theme)
);

const MenuItemCogMenu = styled(CogMenu)(({ theme }) =>
    desktopMenuItemStyles(theme)
);

interface IDesktopNavLink {
    link: IReportMenuItem;
}

const DesktopNavBarLink: React.FC<IDesktopNavLink> = ({ link }) => {
    switch (link.type) {
        case 'navlink':
            return (
                <DesktopMenuItemNavLink to={link.location} rel={link.rel}>
                    {link.displayText}
                </DesktopMenuItemNavLink>
            );
        case 'anchor':
            return (
                <DesktopMenuItemAnchor
                    href={link.location}
                    target={link.target ? link.target : 'self'}
                    rel={link.rel}
                    aria-label={link.ariaLabel}
                >
                    {link.displayText}
                </DesktopMenuItemAnchor>
            );
        case 'dropdown':
            return (
                <MenuItemCogMenu
                    mainItem={{
                        displayText: link.displayText,
                        location: link.location,
                        type: 'navlink',
                    }}
                    menuItems={link.subMenu}
                ></MenuItemCogMenu>
            );
        default:
            return null;
    }
};

export default DesktopNavBarLink;
