import { IActiveFilters, IFilter } from '../../types';
import { Popover, Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { FilterDropdown } from './FilterDropdown';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';
import { useState } from 'react';

const PopperContent = styled('div')(({ theme }) => ({
    minWidth: 300,
    width: 340,
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
}));

interface IFilterPopperProps {
    anchorEl: HTMLButtonElement | null;
    handleCloseFilter: () => void;
    handleApplyFilters: (filters: IActiveFilters) => void;
}

export const FilterPopper: React.FC<IFilterPopperProps> = ({
    anchorEl,
    handleCloseFilter,
    handleApplyFilters,
}) => {
    const {
        state: { filters },
    } = useFLP();
    const [showSkills, setShowSkills] = useState<boolean>(false);
    const [showCategories, setShowCategories] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<IActiveFilters>({
        skills: [],
        categories: [],
    });

    const setFiltersHandler = (filters = activeFilters) => {
        handleApplyFilters(filters);
    };

    const clearFiltersHandler = () => {
        setActiveFilters({
            skills: [],
            categories: [],
        });
        handleApplyFilters({
            skills: [],
            categories: [],
        });
    };

    const checkboxHandler = (cat: IFilter, name: string) => {
        switch (name) {
            case 'Categories': {
                const exists = activeFilters.categories.find(
                    (item) => item === cat.id
                );
                if (exists) {
                    setActiveFilters((prevState) => {
                        return {
                            ...prevState,
                            categories: prevState.categories.filter(
                                (item) => item !== cat.id
                            ),
                        };
                    });
                } else {
                    setActiveFilters((prevState) => {
                        return {
                            ...prevState,
                            categories: [...prevState.categories, cat.id],
                        };
                    });
                }
                break;
            }
            case 'Skills': {
                const exists = activeFilters.skills.find(
                    (item) => item === cat.id
                );
                if (exists) {
                    setActiveFilters((prevState) => {
                        return {
                            ...prevState,
                            skills: prevState.skills.filter(
                                (item) => item !== cat.id
                            ),
                        };
                    });
                } else {
                    setActiveFilters((prevState) => {
                        return {
                            ...prevState,
                            skills: [...prevState.skills, cat.id],
                        };
                    });
                }
                break;
            }
            default:
                return;
        }
    };

    if (!filters) {
        return null;
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => handleCloseFilter()}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <PopperContent>
                <div id='applyFilterWrapper'>
                    <Typography variant='h4' sx={{ fontWeight: 600 }}>
                        Assign your filters
                    </Typography>
                    <div id='skills'>
                        <FilterDropdown
                            name='Skills'
                            showDropdown={showSkills}
                            setShowDropdown={setShowSkills}
                            dropdownItems={filters.skills}
                            checkboxHandler={checkboxHandler}
                            activeFilters={activeFilters.skills}
                        />
                    </div>
                    <div id='categories'>
                        <FilterDropdown
                            name='Categories'
                            showDropdown={showCategories}
                            setShowDropdown={setShowCategories}
                            dropdownItems={filters.categories}
                            checkboxHandler={checkboxHandler}
                            activeFilters={activeFilters.categories}
                        />
                    </div>
                    <div>
                        <Button
                            fullWidth
                            sx={{ mt: 3 }}
                            onClick={() => setFiltersHandler()}
                            text='Apply filters'
                        />

                        <Button
                            color='inherit'
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => clearFiltersHandler()}
                            text='Clear all'
                        />
                    </div>
                </div>
            </PopperContent>
        </Popover>
    );
};
