import {
    MenuItem,
    Select as MuiSelect,
    SelectChangeEvent,
    styled,
} from '@mui/material';

import { DomainsMap } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';

const Select = styled(MuiSelect)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
    '& .MuiSelect-nativeInput': {
        height: '100%',
    },
}));

const SelectIcon = styled('div')(() => ({
    right: '10px !important',
    top: 'calc(50% - .6em) !important',
    '&.MuiSelect-iconOpen': {
        top: 'calc(50% - .6em) !important',
    },
}));

interface IOwnProps {
    domains: DomainsMap;
    domain: number | undefined;
    updateDomain: (e: SelectChangeEvent<unknown>) => void;
}

export const DomainSelect: React.FC<IOwnProps> = ({
    domains,
    domain,
    updateDomain,
}) => {
    return (
        <Select
            labelId='select-domain-label'
            id='domain-select'
            value={domain}
            onChange={updateDomain}
            MenuProps={{
                onClick: (e) => {
                    e.preventDefault();
                },
            }}
            size='small'
            inputProps={{
                'aria-labelledby': 'select-domain-label',
            }}
            SelectDisplayProps={{
                style: { height: '100%' },
            }}
            IconComponent={ChevronDownIcon}
        >
            {domains.map((val) => (
                <MenuItem key={val.id} value={val.id}>
                    {val.name}
                </MenuItem>
            ))}
        </Select>
    );
};

const ChevronDownIcon = (props: object) => (
    <SelectIcon {...props}>
        <FontAwesomeIcon icon={byPrefixAndName.fas['chevron-down']} size='sm' />
    </SelectIcon>
);
