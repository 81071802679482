import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { Userpilot } from 'userpilot';
import { useConfig } from '../../custom-providers/ConfigProvider';
import { useEffect } from 'react';
import { useGrowthbookSetup } from '../../custom-hooks/useGrowthbookSetup';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSetUserState } from '../../custom-hooks/useSetUserState';

export const SiteLayout = () => {
    useSetUserState();
    const { trackPageView } = useMatomo();
    const growthbook = useGrowthbookSetup();
    const location = useLocation();

    useEffect(() => {
        document.title = 'Cognassist';
        trackPageView({});
    }, []);

    const {
        state: {
            appConfig: { environment },
        },
    } = useConfig();

    useEffect(() => {
        if (environment !== undefined) {
            if (environment === 'production') {
                Userpilot.initialize('NX-16f404a3');
            } else {
                Userpilot.initialize('STG-NX-16f404a3');
            }
        }
    }, [environment]);

    useEffect(() => {
        Userpilot.reload();
    }, [location]);

    if (growthbook) {
        return (
            <GrowthBookProvider growthbook={growthbook}>
                <ScrollRestoration />
                <Outlet />
            </GrowthBookProvider>
        );
    }

    return null;
};
