import { Typography, styled } from '@mui/material';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const Wrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(0, 3),
    maxWidth: 580,
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down(480)]: {
        maxWidth: 360,
    },
}));

const ConnectTeams: React.FC = () => {
    const {
        state: {
            userConfig: { userId },
            clientConfig: { clientId },
        },
    } = useAuth();

    const { registerWithTeams } = endpoints();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        performRegistration();
    }, []);

    const performRegistration = async () => {
        const { error } = await registerWithTeams(
            clientId,
            userId,
            searchParams.get('token')?.toString()
        );

        if (axios.isAxiosError(error)) {
            if (error?.response?.status === 403) {
                navigate('/insufficient-permissions', { replace: true });
            } else if (error?.response?.status === 400) {
                const message = error.response.data.errors['TeamsConnect'][0];
                navigate(`/general-error`, {
                    replace: true,
                    state: {
                        errorMessage: message,
                    },
                });
            } else {
                navigate('/something-went-wrong', { replace: true });
            }
        } else {
            setIsRegistered(true);
        }
    };

    return (
        <>
            {!isRegistered ? (
                <Loading />
            ) : (
                <Wrapper>
                    <Typography variant='h1' mb={3}>
                        Thank you for registering with teams.
                    </Typography>
                    <Typography variant='h2'>
                        Registration completed successfully.
                    </Typography>
                    <Typography variant='h4' component='h3' my={3}>
                        You may now close this window.
                    </Typography>
                </Wrapper>
            )}
        </>
    );
};

export default ConnectTeams;
