import { ClientConfigItem } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';

export const useAreNeurodiversityQuestionsEnabled = (): boolean => {
    const {
        state: {
            clientConfig: { items },
        },
    } = useAuth();

    const isDataCaptureDisabled =
        items?.find(
            (item) =>
                item.id === ClientConfigItem.DisableNeurodiversityDataCapture
        )?.state === 1;

    return !isDataCaptureDisabled;
};
