import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Typography, TypographyProps, styled, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { QuestionIcon } from '../../styled-components';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useProfile } from './ProfileProvider';

const Wrapper = styled('div')(() => ({}));

export const Content = styled('div', {
    ...shouldForwardProps('top', 'isSmall'),
})<{ top?: number; isSmall?: boolean }>(({ top, isSmall, theme }) => ({
    boxShadow: theme.cogShadows.base,
    borderRadius: 10,
    padding: theme.spacing(3, 4),
    display: isSmall ? 'none' : 'flex',
    flexFlow: 'column nowrap',
    maxWidth: 480,
    marginLeft: theme.spacing(6),
    top: top ? `${top}px` : 'initial',
    position: 'absolute',
}));

const Title = styled(({ children, ...props }: TypographyProps) => (
    <Typography {...props} variant='h3'>
        {children}
    </Typography>
))(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
}));

export const Arrow = styled('div', {
    ...shouldForwardProps('top'),
})<{ top?: number }>(({ top }) => ({
    height: 30,
    width: 30,
    position: 'absolute',
    left: -13,
    top,
    borderRadius: 4,
    transform: 'rotate(45deg) skew(-20deg, -20deg)',
    display: 'block',
    background: '#fff',
    boxShadow: '0px 0px 22px rgba(207, 216, 229, 0.2)',
    '&:after': {
        content: "''",
        height: 40,
        width: 35,
        background: '#fff',
        display: 'block',
        transform:
            'skew(20deg, 20deg) rotate(-45deg) translateX(18px) translateY(-5px)',
        position: 'absolute',
    },
}));

interface IOwnProps {
    isSmall?: boolean;
}

export const InfoBox: React.FC<IOwnProps> = ({ isSmall = false }) => {
    const nodeRef = useRef<HTMLDivElement>(null);
    const {
        state: { offsetTop, bottomGap, bubbleContent, id },
    } = useProfile();

    const theme = useTheme();

    const [top, setTop] = useState(-1000);
    const [arrowTop, setArrowTop] = useState(20);

    useEffect(() => {
        if (nodeRef?.current && offsetTop && bottomGap) {
            const height = nodeRef.current.offsetHeight;

            if (bottomGap < height) {
                const diff = height - bottomGap;
                setTop(offsetTop - diff);
                setArrowTop(20 + diff);
            } else {
                setTop(offsetTop);
                setArrowTop(20);
            }
        } else {
            setTop(-1000);
            setArrowTop(20);
        }
    }, [offsetTop, bottomGap, nodeRef?.current]);

    return (
        <SwitchTransition mode='out-in'>
            <CSSTransition
                key={id}
                nodeRef={nodeRef}
                addEndListener={(done: () => void) => {
                    nodeRef?.current?.addEventListener(
                        'transitionend',
                        done,
                        false
                    );
                }}
                classNames='bubble'
                in={!!offsetTop}
            >
                <Wrapper ref={nodeRef}>
                    {bubbleContent && (
                        <Content top={top} isSmall={isSmall}>
                            <Arrow top={arrowTop} />
                            <QuestionIcon
                                size={28}
                                style={{ marginBottom: theme.spacing(3) }}
                            />
                            <Title className='animate-up'>
                                {bubbleContent?.title}
                            </Title>
                            <Typography className='animate-up'>
                                {bubbleContent?.text}
                            </Typography>
                        </Content>
                    )}
                </Wrapper>
            </CSSTransition>
        </SwitchTransition>
    );
};
