import { ICognitiveDomainContent } from '../../types';
import imgExecutiveFunction from '../../assets/img/executive-function.png';
import imgLiteracy from '../../assets/img/literacy.png';
import imgNonVerbalMemory from '../../assets/img/non-verbal-memory.png';
import imgNumeracy from '../../assets/img/numeracy.png';
import imgReadingDecoding from '../../assets/img/reading-decoding.png';
import imgVerbalMemory from '../../assets/img/verbal-memory.png';
import imgVerbalReasoning from '../../assets/img/verbal-reasoning.png';
import imgVisualInformationProcessingSpeed from '../../assets/img/visual-information-processing-speed.png';
import imgVisualPerception from '../../assets/img/visual-perception.png';

export const cognitiveDomains: ICognitiveDomainContent[] = [
    {
        id: 1,
        domainExplanation: [
            'Verbal Memory is about recalling spoken or written information. This domain can include tasks like memorising information, remembering conversations, instructions or information given at meetings, attending appointments, meeting deadlines, and answering direct questions.',
        ],
        assessmentExplanation: [
            'You were shown a passage of text on the screen during the task that a narrator also read out. The text was then removed, and you were asked questions about the test passage later. In total, you were asked 20 questions related to this passage of text. ',
        ],
        assessmentScreenshot: {
            url: imgVerbalMemory,
            alt: 'A screenshot of the verbal memory section from the assessment',
        },
        links: [
            {
                url: '/1',
                text: 'Learning & Memory Strategies',
            },
        ],
    },
    {
        id: 2,
        domainExplanation: [
            "Literacy isn't solely about spelling, grammar and reading; it can relate to everything we associate with language. So, it includes skills like your reading fluency, vocabulary, comprehension, understanding and ability to follow instructions. When it comes to interacting with other people, language is an essential factor.",
        ],
        assessmentExplanation: [
            'During the task, you were presented with a word on the screen and asked to match it with one of four options that meant something similar. The words you were asked about got harder as you went on, many of them you may not have been familiar with. It takes a large vocabulary to know all of them. ',
        ],
        assessmentScreenshot: {
            url: imgLiteracy,
            alt: 'A screenshot of the literacy section from the assessment',
        },
        links: [
            {
                url: '/5',
                text: 'Communication Strategies',
            },
        ],
    },
    {
        id: 3,
        domainExplanation: [
            "When thinking of the word Numeracy, it's natural to think of numbers and sums only. To some extent, this is true, but the Numeracy task included in our assessment isn't the same as a maths knowledge test. Instead, it can indicate a capacity called working memory, which we use to hold and use information in mind, in real time. We use working memory for activities like remembering numbers, sentences, directions, or making calculations, and for things like finding your way around a complex building or contrasting pros and cons to make a purchasing decision.",
        ],
        assessmentExplanation: [
            'You were given a series of arithmetic questions that required you to complete different sums (addition, subtraction, multiplication, or division) in a short space of time. The questions increased in complexity, which demanded more of your working memory to complete them. Your score was determined by the number of calculations you were able to complete.',
        ],
        assessmentScreenshot: {
            url: imgNumeracy,
            alt: 'A screenshot of the numeracy section from the assessment',
        },
        links: [
            {
                url: '/2',
                text: 'Organisation & Planning Strategies',
            },
        ],
    },
    {
        id: 4,
        domainExplanation: [
            'Many of our daily tasks require processing visual information, and some require it to be done quickly. Visual Information Processing Speed can affect things like listening to a speaker and writing notes simultaneously, watching out for hazards during tasks like driving, or following visual demonstrations. ',
        ],
        assessmentExplanation: [
            'During the task, you were shown ten abstract shapes, each corresponding to a number between 0 and 9. One shape was displayed on the screen, with all ten shown below. You needed to enter the corresponding number using your keyboard. The shapes were shown randomly, and the task was time-limited. Your score was determined by the number of correct shape identifications in the given time. ',
        ],
        assessmentScreenshot: {
            url: imgVisualInformationProcessingSpeed,
            alt: 'A screenshot of the visual information processing speed section from the assessment',
        },
        links: [
            {
                url: '/3',
                text: 'Working Digitally Strategies',
            },
        ],
    },
    {
        id: 5,
        domainExplanation: [
            'Non-Verbal Memory is all about remembering visual information and using it to solve problems. The domain relates to your sense of direction, navigating new situations and analysing things like graphs and images.',
        ],
        assessmentExplanation: [
            'During the task, you were shown a series of images, for about one second each. After a delay, you were asked to identify which image you saw previously by picking it out from a selection of similar images. Your score was determined by the number of correct answers given from a limited number of questions. ',
        ],
        assessmentScreenshot: {
            url: imgNonVerbalMemory,
            alt: 'A screenshot of the non-verbal memory section from the assessment',
        },
        links: [
            {
                url: '/1',
                text: 'Learning & Memory Strategies',
            },
        ],
    },
    {
        id: 6,
        domainExplanation: [
            'Executive Function covers many high-level processes required to perform complex cognitive tasks, like maintaining and controlling attention, juggling several tasks at once and reasoning.',
            'It also includes cognitive flexibility, self-regulation and processes necessary for cognitive behaviour control, including regulating behaviour, mindfully planning actions and regulating your moods or emotions.',
        ],
        assessmentExplanation: [
            'During the task, you were presented with a ring of circles. A dot moved position around the circles, according to patterns of movement that changed without warning. You had to guess where the dot would move next by picking up the pattern the dot was following as you went. The difficulty of the patterns changed as you went.',
        ],
        assessmentScreenshot: {
            url: imgExecutiveFunction,
            alt: 'A screenshot of the executive function section from the assessment',
        },
        links: [
            {
                url: '/2',
                text: 'Organisation & Planning Strategies',
            },
            {
                url: '/4',
                text: 'Working with Others Strategies',
            },
        ],
    },
    {
        id: 7,
        domainExplanation: [
            "Verbal Reasoning is your ability to use logic and abstract concepts to help you learn new skills and understand information. Verbal Reasoning allows you to develop new ideas or draw conclusions. It's associated with constructive thinking and is required for problem-solving.",
        ],
        assessmentExplanation: [
            'During the task, you were shown two words that could be linked by a third word. The third word was present alongside three distractor words. You were required to identify the best link word in a short amount of time. ',
        ],
        assessmentScreenshot: {
            url: imgVerbalReasoning,
            alt: 'A screenshot of the verbal reasoning section from the assessment',
        },
        links: [
            {
                url: '/5',
                text: 'Communication Strategies',
            },
            {
                url: '/6',
                text: 'Problem-Solving Strategies',
            },
        ],
    },
    {
        id: 8,
        domainExplanation: [
            'Visual Perception enables us to organise and interpret information accurately. It relates to activities like hand/eye coordination, copying information, mental visualisation and working with non-verbal information generally.',
            "Visual Perception doesn't just mean how we see things. It also involves how we reason and solve complex or conceptual problems. In other words, how you form conclusions or solutions to a problem, based on the information or patterns given.",
        ],
        assessmentExplanation: [
            'During the task, you were briefly shown an image on the screen before it was separated into a specified number of randomly distributed pieces around the screen. You then had to put the images back in the correct order in the corresponding grid (2x2, 2x3, 3x2, 3x3) to rebuild the image. Your score was determined by the number of images that you correctly reassembled in the time limit. The images increased in difficulty as you went on.',
        ],
        assessmentScreenshot: {
            url: imgVisualPerception,
            alt: 'A screenshot of the visual perception section from the assessment',
        },
        links: [
            {
                url: '/6',
                text: 'Problem-Solving Strategies',
            },
        ],
    },
    {
        id: 9,
        domainExplanation: [
            'Reading Decoding involves your ability to process written words and language. Without decoding, words remain an abstract string of letters on a page with no meaning. So, decoding is the process of matching the combination of letters (graphemes) that make up a word to their sounds (phonemes). These then become recognisable patterns and syllables that blend together to form a word.',
            'Decoding quickly and accurately is important for reading fluently and comprehending what you are reading and is the foundation for learning to read.',
        ],
        assessmentExplanation: [
            'For this task, your lexical access was assessed, which involved identifying an actual word in the English language alongside five made-up words (pseudowords). You needed to identify the accurate word as quickly as you could. Your score was determined by how many words you correctly identified in 3 minutes. ',
        ],
        assessmentScreenshot: {
            url: imgReadingDecoding,
            alt: 'A screenshot of the reading decoding section from the assessment',
        },
        links: [
            {
                url: '/3',
                text: 'Working Digitally Strategies',
            },
        ],
    },
];
