import { CogInput, ErrorText } from '../../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../../pre-assessment/Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

interface IOwnProps {
    isSmall?: boolean;
}

export const JobRole: React.FC<IOwnProps> = ({ isSmall }) => {
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext<{ jobRole: string }>();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('jobRole', {
        maxLength: {
            value: 230,
            message:
                'You have gone over the 230 character limit for this field, please reduce it and try again.',
        },
    });

    const jobRole = watch('jobRole');

    return (
        <Field
            label='Job role'
            labelFor='jobRole'
            hasError={!!errors.jobRole}
            isSmall={isSmall}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={jobRole}
                    id='jobRole'
                    fullWidth
                    hasError={!!errors.jobRole}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='jobRole'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
