import { Link, Typography, alpha, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import mask from '../../assets/img/blob-mask1.svg';
import { pxToRem } from '../../utils/style-functions';

const Banner = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 1224,
    margin: theme.spacing(10, 'auto', 0),
    background: alpha(theme.palette.primary.main, 0.2),
    borderRadius: 20,
    boxShadow: theme.cogShadows.base,
    [theme.breakpoints.down(900)]: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
        "top"
        "bottom"
        `,
        margin: theme.spacing(4, 'auto'),
    },
}));

const TextContainer = styled('div')(({ theme }) => ({
    padding: '40px 40px',
    flexBasis: '50%',
    [theme.breakpoints.down(900)]: {
        gridArea: 'bottom',
    },
    [theme.breakpoints.down(400)]: {
        padding: 24,
    },
}));

const ImageContainer = styled('div')(({ theme }) => ({
    flexBasis: '50%',
    display: 'flex',
    [theme.breakpoints.down(900)]: {
        gridArea: 'top',
    },
    [theme.breakpoints.down(400)]: {
        display: 'none',
    },
}));

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    objectFit: 'cover',
    [theme.breakpoints.down(900)]: {
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
}));

const Mask = styled('div')(({ theme }) => ({
    display: 'flex',
    WebkitMaskImage: `url(${mask})`,
    maskRepeat: 'no-repeat',
    maskPosition: '0px -115px',
    maskSize: '150%',
    width: '100%',
    '& img': {
        width: '100%',
    },
    [theme.breakpoints.down(900)]: {
        WebkitMaskImage: 'none',
    },
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(24),
        lineHeight: '150%',
    },
}));

interface IOwnProps {
    title: string;
    body: string;
    link: string;
    location: string;
    image: string;
    alt: string;
}

export const BannerLink: React.FC<IOwnProps> = ({
    title,
    body,
    link,
    location,
    image,
    alt,
}) => {
    return (
        <Banner style={{ position: 'relative' }}>
            <TextContainer>
                <Title variant='h1' color='primary'>
                    {title}
                </Title>
                <Typography sx={{ fontSize: '1rem', mb: 3 }}>{body}</Typography>
                <Link target='_blank' href={location} rel='noreferrer'>
                    <Button text={link} />
                </Link>
            </TextContainer>
            <ImageContainer>
                <Mask>
                    <Image src={image} alt={alt} />
                </Mask>
            </ImageContainer>
        </Banner>
    );
};

export default BannerLink;
