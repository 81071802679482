import { CogInput, ErrorText } from '../../styled-components';
import { useEffect, useRef } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../main-app/pre-assessment/Field';
import { useFormContext } from 'react-hook-form';

export const FirstName: React.FC = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {
        formState: { errors },
        register,
    } = useFormContext<{ firstName: string }>();

    useEffect(() => {
        if (inputRef.current) {
            const element = inputRef.current;
            element.focus();
        }
    }, [inputRef.current]);

    const { ref, ...rest } = register('firstName', {
        required: 'First name is required',
        maxLength: {
            value: 230,
            message: 'Please enter a maximum of 230 characters.',
        },
    });

    return (
        <Field
            label='First name'
            labelFor='firstName'
            required={true}
            hasError={!!errors.firstName}
            hideTooltip={true}
        >
            <>
                <CogInput
                    {...rest}
                    required
                    id='firstName'
                    fullWidth
                    hasError={!!errors.firstName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
