import { DashboardGraph, IGraphConfig } from '../types';

export const graphConfig: IGraphConfig[] = [
    {
        title: 'Neurodiversity declared',
        infoText:
            'This chart shows the percentage of individuals who have taken the assessment with a diagnosed neurodiversity.',
        chartId: DashboardGraph.NeurodiversityDeclaredQuery,
        hideZeroValues: false,
    },
    {
        title: 'Declared breakdown - yes',
        infoText:
            'This chart shows the breakdown of the diagnosed neurodiversities that have been declared across the organisation. The percentages shown are of the total diagnoses declared, including data from where some individuals may have declared to having more than a single neurodiversity.',
        chartId: DashboardGraph.DeclaredBreakdownYesQuery,
        hideZeroValues: true,
    },
    {
        title: 'Declared breakdown - no but I believe I have a neurodiversity',
        infoText:
            'This chart shows the breakdown of the self-diagnosed neurodiversities that have been declared across the organisation. The percentages shown are of the total self-diagnoses declared, including data from where some individuals may have declared to believing they have more than a single neurodiversity.',
        chartId: DashboardGraph.DeclaredBreakdownBelieveQuery,
        hideZeroValues: true,
    },
    {
        title: 'Modelled breakdown - no',
        infoText:
            'This chart shows the breakdown of those who have been assessed, have not been diagnosed nor self diagnosed, however they have a diversity index of 60 or over which could indicate they have a neurodiversity.',
        chartId: DashboardGraph.DeclaredBreakdownNoQuery,
        hideZeroValues: true,
    },
    {
        title: 'Assessments',
        infoText:
            'This chart shows the assessment usage breakdown within your organisation including the percentage of assessment invitations sent and assessments completed.',
        chartId: DashboardGraph.AssessmentsQuery,
        hideZeroValues: false,
    },
    {
        title: 'Reports shared',
        infoText:
            'This chart shows the percentage breakdown of assessees who have chosen whether or not to share their assessment report.',
        chartId: DashboardGraph.ReportsSharedQuery,
        hideZeroValues: false,
    },
    {
        title: 'Evidence recorded',
        infoText:
            'This chart shows the breakdown of how many learners have had notes recorded against them over the last month.',
        chartId: DashboardGraph.EvidenceRecordedQuery,
        hideZeroValues: false,
    },
    {
        title: 'Module engagement',
        infoText:
            'This chart shows the breakdown of how many learners have completed a learning module over the last month.',
        chartId: DashboardGraph.ModuleEngagementQuery,
        hideZeroValues: false,
    },
    {
        title: 'Neuro-difference overview',
        infoText:
            'The percentage of your peers who have told us they have a neuro-difference.',
        chartId: DashboardGraph.NeurodifferenceRepresentation,
        hideZeroValues: false,
        binaryKeyValue: 'Disclosed neuro-difference',
        isBinary: true,
        happyKey: 'Disclosed neuro-difference',
    },
    {
        title: 'Neuro-difference unpacked',
        infoText: 'The neuro-differences amongst your peers.',
        chartId: DashboardGraph.NeurodifferenceRepresentationUnpacked,
        hideZeroValues: true,
    },
    {
        title: 'Cognitive assessments',
        chartId: DashboardGraph.AssessmentsCompleted,
        hideZeroValues: false,
        binaryKeyValue: 'Assessments completed',
        isBinary: true,
        happyKey: 'Completed',
    },
];
