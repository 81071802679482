import { IDashboardData, IParsedDashboardGraph } from '../../../../types';
import { Tooltip, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import ChartCard from './ChartCard';
import { QuestionIcon } from '../../../styled-components';
import { useParseGraphs } from '../../../../custom-hooks/useParseGraphs';

const CardsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    margin: -12,
    '> *': {
        flex: '1 1 50%',
        padding: '12px',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '50%',
            minWidth: 420,
        },
    },
}));

const Title = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(4),
}));

const TooltipWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
}));

const EMPTY_GRAPH: IParsedDashboardGraph = {
    chartId: 0,
    title: '',
    infoText: '',
    hideZeroValues: false,
    graphItems: [],
};

interface IOwnProps {
    dashboardData?: IDashboardData;
    title?: string;
    tooltip?: string;
}

export const Charts: React.FC<IOwnProps> = ({
    dashboardData,
    title,
    tooltip,
}) => {
    const theme = useTheme();

    const [requiredToUnlock, setRequiredToUnlock] = useState<number>(0);

    const parsedGraphs = useParseGraphs(dashboardData?.charts);

    useEffect(() => {
        if (dashboardData) {
            setRequiredToUnlock(
                dashboardData.threshold - dashboardData.assessments
            );
        }
    }, [dashboardData]);

    return (
        <>
            {title && (
                <Title>
                    <Typography variant='h2'>{title}</Typography>
                    {tooltip && (
                        <Tooltip
                            title={
                                <TooltipWrapper>
                                    <Typography>{tooltip}</Typography>
                                </TooltipWrapper>
                            }
                            arrow
                            enterTouchDelay={0}
                            leaveTouchDelay={10000}
                        >
                            <div
                                style={{ height: 24, width: 24, marginLeft: 8 }}
                            >
                                <QuestionIcon
                                    fill={theme.palette.text.primary}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </Title>
            )}
            <CardsWrapper id='graphs'>
                {!parsedGraphs
                    ? [...Array(4).keys()].map((item: number) => (
                          <div key={item}>
                              <ChartCard graph={EMPTY_GRAPH} />
                          </div>
                      ))
                    : parsedGraphs.map((graph) => {
                          return (
                              <div key={graph.title}>
                                  <ChartCard
                                      key={graph.chartId}
                                      graph={graph}
                                      cardLoading={false}
                                      assessmentsToUnlock={requiredToUnlock}
                                  />
                              </div>
                          );
                      })}
            </CardsWrapper>
        </>
    );
};
