import { useEffect, useState } from 'react';

import { Bio } from './fields/Bio';
import { IChampionDetails } from '..';
import { ProfilePicture } from './fields/ProfilePicture';
import { endpoints } from '../../../../api/endpoints';
import { pxToRem } from '../../../../utils/style-functions';
import { styled } from '@mui/material';
import { useSnackbar } from 'notistack';

export const Label = styled('label')(({ theme }) => ({
    marginBottom: theme.spacing(4),
    fontSize: pxToRem(24),
    fontWeight: 400,
    display: 'inline-block',
}));

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(4),
}));

const Section = styled('div')(() => ({}));

interface IOwnProps {
    isSmall: boolean;
    championDetails: IChampionDetails | null;
    refreshChampionBio: () => Promise<void>;
    updateChampionProfilePicture: (
        profilePictureFileName: string | null,
        profilePictureLastUpdateTimestamp: string | null
    ) => void;
}

export const ChampionDetails: React.FC<IOwnProps> = ({
    isSmall,
    championDetails,
    refreshChampionBio,
    updateChampionProfilePicture,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        uploadChampionProfilePicture,
        deleteChampionProfilePicture,
        getProfilePicture,
        patchChampionProfile,
    } = endpoints();

    const [pictureLoading, setPictureLoading] = useState<boolean>(false);
    const [bioLoading, setBioLoading] = useState<boolean>(false);
    const [profilePicture, setProfilePicture] = useState<string | null>(null);

    useEffect(() => {
        if (profilePicture) {
            URL.revokeObjectURL(profilePicture);
            setProfilePicture(null);
        }

        if (championDetails?.profilePictureFileName) {
            requestProfilePicture(championDetails?.profilePictureFileName);
        }
    }, [
        championDetails?.profilePictureFileName,
        championDetails?.profilePictureLastUpdateTimestamp,
    ]);

    useEffect(() => {
        return () => {
            if (profilePicture) {
                URL.revokeObjectURL(profilePicture);
            }
        };
    }, [profilePicture]);

    const requestProfilePicture = async (fileName: string) => {
        const { data, error } = await getProfilePicture(fileName);

        if (error) {
            enqueueSnackbar(
                'Could not retrieve the profile picture, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setProfilePicture(URL.createObjectURL(data));
        }
    };

    const uploadProfilePicture = async (profilePicture: File) => {
        const { data, error } =
            await uploadChampionProfilePicture(profilePicture);

        if (error) {
            enqueueSnackbar(
                'Could not upload the profile picture, please try again.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            updateChampionProfilePicture(
                data.profilePictureFileName,
                data.profilePictureLastUpdateTimestamp
            );
            enqueueSnackbar('Your profile picture has been uploaded.', {
                variant: 'success',
            });
        }

        setPictureLoading(false);
    };

    const removeProfilePicture = async () => {
        setPictureLoading(true);

        const { error } = await deleteChampionProfilePicture();

        if (error) {
            enqueueSnackbar(
                'Could not remove the profile picture, please try again.',
                {
                    variant: 'error',
                }
            );
        } else {
            updateChampionProfilePicture(null, null);
            enqueueSnackbar('Your profile picture has been removed.', {
                variant: 'success',
            });
        }

        setPictureLoading(false);
    };

    const saveBio = async (bio: string | null) => {
        setBioLoading(true);

        const { error } = await patchChampionProfile({ bio });

        if (error) {
            enqueueSnackbar('Could not update your bio, please try again.', {
                variant: 'error',
            });
        } else {
            await refreshChampionBio();
            enqueueSnackbar('Your bio has been updated.', {
                variant: 'success',
            });
        }

        setBioLoading(false);
    };

    if (!championDetails) {
        return null;
    }

    return (
        <Wrapper>
            <Section>
                <ProfilePicture
                    isSmall={isSmall}
                    championDetails={championDetails}
                    currentProfilePicture={profilePicture ?? undefined}
                    loading={pictureLoading}
                    setLoading={setPictureLoading}
                    removeProfilePicture={removeProfilePicture}
                    uploadProfilePicture={uploadProfilePicture}
                />
            </Section>
            <Section>
                <Bio
                    isSmall={isSmall}
                    loading={bioLoading}
                    championDetails={championDetails}
                    onSave={saveBio}
                />
            </Section>
        </Wrapper>
    );
};
