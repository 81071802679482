import { IconButton, Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { RefreshCcw } from 'react-feather';
import { Roles } from '../../../types';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useReturnUrl } from '../../../custom-hooks/useReturnUrl';

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    navExpand?: boolean;
}

export const ChangeClientButton: React.FC<IOwnProps> = ({
    navExpand = true,
}) => {
    const {
        state: {
            clientConfig: { name },
            userConfig: { roles, legacyReturnUrl },
        },
    } = useAuth();

    const backToCognassistHandler = useReturnUrl(legacyReturnUrl!);

    if (!roles?.includes(Roles.SysAdmin)) {
        return null;
    }

    return navExpand ? (
        <Wrapper>
            <Typography variant='caption' sx={{ mb: 1 }}>
                Viewing {name}
            </Typography>
            <Button
                text='Change client'
                color='inherit'
                startIcon={<RefreshCcw size={18} />}
                sx={{
                    paddingLeft: '32px !important',
                    paddingRight: '32px !important',
                    overflow: 'hidden',
                }}
                onClick={backToCognassistHandler}
            />
        </Wrapper>
    ) : (
        <IconButton
            aria-label='change client'
            size='large'
            onClick={backToCognassistHandler}
        >
            <RefreshCcw />
        </IconButton>
    );
};
