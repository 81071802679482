import { useEffect, useState } from 'react';

import { IProcessingBiasReference } from '../../../../types';
import { pxToRem } from '../../../../utils/style-functions';
import { styled } from '@mui/material';
import { useDebounce } from '../../../../custom-hooks/useDebounce';

const LegendContainer = styled('div')(({ theme }) => ({
    flexBasis: 270,
    alignSelf: 'center',
    [theme.breakpoints.down(1102)]: {
        flexBasis: 'auto',
        padding: theme.spacing(0, 2, 2),
    },
}));

const KeyHeading = styled('p')(({ theme }) => ({
    fontWeight: 600,
    fontSize: pxToRem(24),
    marginBottom: 20,
    [theme.breakpoints.down(600)]: {
        fontSize: pxToRem(18),
    },
}));

const LegendItemContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 8,
    [theme.breakpoints.down(1102)]: {
        gridTemplateColumns: '1fr 1fr',
        gap: '32px 27px',
    },
    [theme.breakpoints.down(600)]: {
        gridTemplateColumns: '1fr',
        gap: 16,
    },
}));

const LegendItem = styled('div')(() => ({
    display: 'flex',
    gap: 16,
    alignItems: 'center',
}));

const AreaColor = styled('div', {
    shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ color, theme }) => ({
    background: color,
    minWidth: 16,
    width: 16,
    height: 16,
    borderRadius: 2,
    border:
        color === '#FFFFFF' ? `${theme.palette.grey[300]} 1px solid` : 'none',
}));

const AreaName = styled('p')(({ theme }) => ({
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '150%',
    color: theme.palette.text.primary,
    paddingTop: 4,
    [theme.breakpoints.down(600)]: {
        fontSize: pxToRem(12),
    },
}));

interface IOwnProps {
    name?: string;
    areas: IProcessingBiasReference[];
}

export const Legend: React.FC<IOwnProps> = ({ areas }) => {
    const [isSmall, setIsSmall] = useState(window.innerWidth < 1102);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    const debounceResize = useDebounce(function handleResize() {
        setWindowWidth(window.innerWidth);
    }, 500);

    useEffect(() => {
        if (windowWidth < 1102 && windowWidth > 600) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    }, [windowWidth]);

    return (
        <LegendContainer id='processing-bias-legend'>
            <KeyHeading>Key</KeyHeading>
            <LegendItemContainer>
                {areas.map((item) => (
                    <LegendItem
                        key={`${item.title}-legend`}
                        style={{
                            order: isSmall
                                ? item.twoColumnOrder
                                : item.oneColumnOrder,
                        }}
                    >
                        <AreaColor color={item.color} />
                        <AreaName>{item.title}</AreaName>
                    </LegendItem>
                ))}
            </LegendItemContainer>
        </LegendContainer>
    );
};

export default Legend;
