import { ChampionStatus, Roles as RolesEnum } from '../../../../types';
import {
    Checkbox,
    FormControlLabel,
    Typography,
    alpha,
    styled,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
    IRoleDetail,
    useUsers,
} from '../../../../custom-providers/UsersProvider';

import AlertIcon from '../../../../assets/img/icons/alert.svg';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../../styled-components';
import { Field } from './Field';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useEffect } from 'react';
import { useIsAdmin } from '../../../../custom-hooks/useIsAdmin';
import { useIsManagerNotAdmin } from '../../../../custom-hooks/useIsManagerNotAdmin';

const RolesWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(-1.5),
    display: 'flex',
    flexFlow: 'column nowrap',
}));

const ChampionRoleWarningWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    backgroundColor: alpha(theme.palette.error.main, 0.05),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(1),
}));

export const Roles = () => {
    const {
        formState: { errors },
        watch,
        control,
    } = useFormContext<{ roles: string[] }>();

    const roles = watch('roles');

    const {
        state: { availableRoles, enterpriseUser, championRoleWarning },
        dispatch,
    } = useUsers();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const isManagerNotAdmin = useIsManagerNotAdmin();
    const isAdmin = useIsAdmin();

    useEffect(() => {
        const showWarning =
            !roles?.includes(RolesEnum.Champion) &&
            enterpriseUser?.roles?.includes(RolesEnum.Champion) &&
            enterpriseUser?.champion?.hasActiveSupportRequests;

        dispatch({
            type: 'SET_CHAMPION_ROLE_WARNING',
            payload: {
                showWarning: showWarning,
            },
        });
    }, [roles]);

    useEffect(() => {
        dispatch({
            type: 'SET_CHAMPION_ROLE_WARNING',
            payload: {
                disableSave:
                    championRoleWarning.showWarning &&
                    !championRoleWarning.warningOverridden,
            },
        });
    }, [
        championRoleWarning.showWarning,
        championRoleWarning.warningOverridden,
    ]);

    const isVolunteerChampion =
        enterpriseUser?.champion?.status === ChampionStatus.Volunteer;

    const isChecked = (availableRole: IRoleDetail, roles: string[]) => {
        if (isManagerNotAdmin || isDisabled(availableRole)) {
            // Don't show the Champion role as checked if the user is a volunteer champion
            if (availableRole.roleName === 'Champion' && isVolunteerChampion) {
                return false;
            }

            return enterpriseUser?.roles?.includes(availableRole.roleName);
        }

        return roles?.includes(availableRole.roleName);
    };

    const isDisabled = (availableRole: IRoleDetail) => {
        return (
            isManagerNotAdmin ||
            availableRole.roleIsDisabled ||
            (isAdmin &&
                availableRole.roleName === 'Admin' &&
                enterpriseUser?.roles?.includes('Admin') &&
                userId === enterpriseUser?.id) ||
            (availableRole.roleName === 'Champion' && isVolunteerChampion)
        );
    };

    const handleOnConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'SET_CHAMPION_ROLE_WARNING',
            payload: {
                warningOverridden: e.target.checked,
            },
        });
    };

    const handleOnChange = (
        availableRole: IRoleDetail,
        onChange: (...event: unknown[]) => void
    ) => {
        const newArray = [...roles];
        const item = availableRole.roleName;

        if (newArray.length > 0) {
            const index = newArray.findIndex((value) => value === item);

            if (index === -1) {
                newArray.push(item);
            } else {
                newArray.splice(index, 1);
            }
        } else {
            newArray.push(item);
        }

        onChange(newArray);
    };

    if (!availableRoles) {
        return null;
    }

    return (
        <>
            <Field label='Roles' labelFor='roles'>
                <RolesWrapper>
                    {availableRoles.map((availableRole) => (
                        <FormControlLabel
                            key={availableRole.roleName}
                            label={`${availableRole.roleName} ${availableRole.licensesRemaining}`}
                            control={
                                <Controller
                                    control={control}
                                    name='roles'
                                    defaultValue={[]}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...inputProps
                                        },
                                    }) => (
                                        <Checkbox
                                            {...inputProps}
                                            checked={isChecked(
                                                availableRole,
                                                value
                                            )}
                                            inputRef={ref}
                                            onChange={() =>
                                                handleOnChange(
                                                    availableRole,
                                                    onChange
                                                )
                                            }
                                            disabled={isDisabled(availableRole)}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 28,
                                                },
                                            }}
                                            id={`${availableRole.roleName}-role-checkbox`}
                                        />
                                    )}
                                />
                            }
                        />
                    ))}

                    <ErrorMessage
                        errors={errors}
                        name='roles'
                        render={({ message }) => (
                            <ErrorText>{message}</ErrorText>
                        )}
                    />
                </RolesWrapper>
            </Field>
            {championRoleWarning.showWarning && (
                <ChampionRoleWarningWrapper>
                    <img alt='' src={AlertIcon} />
                    <Typography>
                        This NI Champion has active support requests. If you
                        remove their role, the individuals with those requests
                        will no longer have access to support from this
                        Champion, and their support requests will be removed.
                        Are you sure you want to remove their role?
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleOnConfirmChange}
                                checked={championRoleWarning.warningOverridden}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label='I confirm'
                    />
                </ChampionRoleWarningWrapper>
            )}
        </>
    );
};
