import { useEffect, useRef, useState } from 'react';

export const useInterval = (callback: () => void, delayMs: number) => {
    const savedCallback = useRef(callback);
    const [i, setI] = useState<NodeJS.Timeout>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delayMs === 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delayMs);

        setI(id);

        return () => clearInterval(id);
    }, [delayMs]);

    return () => clearInterval(i);
};
