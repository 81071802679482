import ReactCrop, {
    PixelCrop,
    centerCrop,
    makeAspectCrop,
    type Crop,
} from 'react-image-crop';
import { useAuth } from '../../../../../custom-providers/AuthProvider';
import { Label } from '..';
import { Avatar, Typography, alpha, styled } from '@mui/material';
import { IChampionUserProfileResponse } from '../../../../../types';
import { useRef, useState } from 'react';
import { Button, Dialog } from '@cognassist/react-components';
import AlertIcon from '../../../../../assets/img/icons/alert.svg';
import { shouldForwardProps } from '../../../../../utils/shouldForwardProp';

const PIXEL_RATIO = 1; // Could also be set to window.devicePixelRatio, but that's only useful when using a preview canvas
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200;
const MAX_DIMENSION = 800;
const MAX_FILE_SIZE = 10000000;
const DIMENSION_ERROR = `Image dimension must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels. Please select a larger image.`;
const FILE_SIZE_ERROR =
    'File size cannot be above 10MB. Please select a smaller image.';
const FILE_TYPE_ERROR =
    'File type must be JPG, JPEG or PNG. Please select a different file.';
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    cropWidthInPercent: number
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

const ProfilePictureButtonWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall?: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    width: '100%',
}));

const ProfilePictureWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.shape.borderRadius,
}));

const ProfilePictureInnerWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall?: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
    alignItems: 'center',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const Instructions = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall?: boolean }>(({ isSmall, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    '& p': {
        textAlign: isSmall ? 'center' : 'left',
    },
}));

const HiddenInput = styled('input')({
    display: 'none',
});

const CropWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const ErrorWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall?: boolean }>(({ isSmall, theme }) => ({
    backgroundColor: alpha(theme.palette.error.main, 0.05),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    '& img': {
        maxWidth: 20,
        maxHeight: 20,
    },
}));

interface IOwnProps {
    isSmall: boolean;
    championDetails: IChampionUserProfileResponse;
    currentProfilePicture: string | undefined;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    removeProfilePicture: () => Promise<void>;
    uploadProfilePicture: (profilePicture: File) => Promise<void>;
}

export const ProfilePicture: React.FC<IOwnProps> = ({
    isSmall,
    championDetails,
    currentProfilePicture,
    loading,
    setLoading,
    removeProfilePicture,
    uploadProfilePicture,
}) => {
    const {
        state: {
            userConfig: { name },
        },
    } = useAuth();

    const [error, setError] = useState<string>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [crop, setCrop] = useState<Crop>();

    const [imgSrc, setImgSrc] = useState<string>('');

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const imgRef = useRef<HTMLImageElement | null>(null);

    const handleUploadedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = hiddenInputRef?.current?.files?.[0];
        const fileSize = file?.size;

        if (!file || !fileSize) {
            return;
        }

        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setError(FILE_TYPE_ERROR);
        } else if (fileSize > MAX_FILE_SIZE) {
            setError(FILE_SIZE_ERROR);
        } else {
            const imageUrl = URL.createObjectURL(file);
            const imageElement = new Image();
            imageElement.src = imageUrl;

            imageElement.onload = () => {
                const { naturalWidth, naturalHeight } = imageElement;

                if (
                    naturalWidth < MIN_DIMENSION ||
                    naturalHeight < MIN_DIMENSION
                ) {
                    setError(DIMENSION_ERROR);
                } else {
                    setError(undefined);
                    setCrop(undefined);
                    setImgSrc(imageUrl);
                    setDialogOpen(true);
                }
            };
        }

        e.target.value = '';
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
        setCrop(centerAspectCrop(width, height, cropWidthInPercent));
    };

    const onImageConfirm = async () => {
        const image = imgRef.current;

        if (image && completedCrop) {
            setLoading(true);

            const canvas = document.createElement('canvas');

            const _crop = completedCrop;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const cropX = _crop.x * scaleX;
            const cropY = _crop.y * scaleY;
            const cropWidth = _crop.width * scaleX;
            const cropHeight = _crop.height * scaleY;

            let quality = 1;
            let destinationWidth = cropWidth;
            let destinationHeight = cropHeight;
            let canvasWidth = Math.ceil(_crop.width * scaleX);
            let canvasHeight = Math.ceil(_crop.height * scaleY);

            // Automatically resize the image if it exceeds the maximum dimension because we don't need to store very large images
            if (canvasWidth > MAX_DIMENSION || canvasHeight > MAX_DIMENSION) {
                destinationWidth = MAX_DIMENSION;
                destinationHeight = MAX_DIMENSION;
                canvasWidth = MAX_DIMENSION;
                canvasHeight = MAX_DIMENSION;
                quality = 0.9;
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            const ctx = canvas.getContext('2d');

            if (!ctx) {
                throw new Error('No 2d canvas context available.');
            }

            ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                cropX, // Source x
                cropY, // Source y
                cropWidth, // Source width
                cropHeight, // Source height
                0, // Destination x
                0, // Destination y
                destinationWidth, // Destination width
                destinationHeight // Destination height
            );

            canvas.toBlob(
                async (blob) => {
                    if (blob) {
                        const file = new File([blob], 'profile-picture.jpeg', {
                            type: blob.type,
                        });

                        await uploadProfilePicture(file);
                        closeDialog();
                    }
                },
                'image/jpeg',
                quality
            );
        }
    };

    const closeDialog = () => {
        if (imgSrc) {
            URL.revokeObjectURL(imgSrc);
        }
        setDialogOpen(false);
    };

    return (
        <>
            <Label htmlFor='profilePictureInput'>Profile picture</Label>
            <ProfilePictureWrapper>
                <ProfilePictureInnerWrapper isSmall={isSmall}>
                    <Avatar
                        variant='rounded'
                        alt={name}
                        sx={{ bgcolor: '#D9D9D9', height: 80, width: 80 }}
                        src={currentProfilePicture}
                    >
                        {''}
                    </Avatar>
                    <HiddenInput
                        ref={hiddenInputRef}
                        type='file'
                        accept='.jpg,.jpeg,.png'
                        id='profilePictureInput'
                        onChange={handleUploadedFile}
                    />
                    <ProfilePictureButtonWrapper isSmall={isSmall}>
                        <Button
                            text='Remove'
                            color='inherit'
                            onClick={removeProfilePicture}
                            loading={loading}
                            disabled={!championDetails.profilePictureFileName}
                        />
                        <Button
                            text='Upload'
                            onClick={() => hiddenInputRef?.current?.click()}
                        />
                    </ProfilePictureButtonWrapper>
                </ProfilePictureInnerWrapper>
                {error && (
                    <ErrorWrapper isSmall={isSmall}>
                        <img alt='' src={AlertIcon} />
                        <div>
                            <Typography
                                display='inline'
                                sx={{ fontWeight: '600' }}
                            >
                                Error:{' '}
                            </Typography>
                            <Typography display='inline'>{error}</Typography>
                        </div>
                    </ErrorWrapper>
                )}
                <Instructions isSmall={isSmall}>
                    <Typography>File size cannot be above 10MB.</Typography>
                    <Typography>
                        Image dimension must be at least 200 x 200 pixels.
                    </Typography>
                    <Typography>File type must be JPG, JPEG or PNG.</Typography>
                </Instructions>
            </ProfilePictureWrapper>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                title='Edit profile picture'
                maxWidth='md'
                fullScreen={isSmall}
                content={
                    <CropWrapper>
                        <ReactCrop
                            crop={crop}
                            onChange={setCrop}
                            onComplete={(pixelCrop: PixelCrop) =>
                                setCompletedCrop(pixelCrop)
                            }
                            aspect={ASPECT_RATIO}
                            minHeight={MIN_DIMENSION}
                            minWidth={MIN_DIMENSION}
                            keepSelection
                        >
                            <img
                                ref={imgRef}
                                alt={name}
                                src={imgSrc}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </CropWrapper>
                }
                confirmButtonProps={{
                    text: 'Confirm',
                    loading: loading,
                    onClick: onImageConfirm,
                }}
                cancelButtonProps={{
                    color: 'inherit',
                    text: 'Cancel',
                    disabled: loading,
                    onClick: closeDialog,
                }}
                sx={{
                    '.MuiPaper-root': {
                        '.MuiDialogContent-root': {
                            pb: 1,
                        },
                        '.MuiDialogActions-root': {
                            padding: isSmall ? 2 : 3,
                        },
                    },
                }}
            />
        </>
    );
};
