import DrawerItem from './DrawerItem';
import { IReportSection } from '../../types';
import { styled } from '@mui/material';

const DrawerContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

interface IOwnProps {
    drawers: IReportSection[];
}

const Drawers: React.FC<IOwnProps> = ({ drawers }) => {
    return (
        <DrawerContainer>
            {drawers.map((item) => (
                <DrawerItem
                    key={item.name}
                    name={item.name}
                    element={item.element}
                />
            ))}
        </DrawerContainer>
    );
};

export default Drawers;
