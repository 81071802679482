import {
    CognitiveMapProvider,
    useCognitiveMap,
} from '../../custom-providers/CognitiveMapProvider';

import { CognitiveMapV2 } from '../../pages/authenticated/cognitive-map-v2';
import { ICognitiveMapV2 } from '../../types';
import { Outlet } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';
import { endpoints } from '../../api/endpoints';
import { styled } from '@mui/material';
import { useAuth } from '../../custom-providers/AuthProvider';
import { useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSnackbar } from 'notistack';

const Wrapper = styled('div')(() => ({
    background: '#F6F7FB',
    minHeight: '100vh',
}));

export const CognitiveMapLayout = () => {
    const featureCognitiveMapV1 = useFeatureIsOn('new-cognitive-map');
    const featureCognitiveMapV2 = useFeatureIsOn('new-cognitive-map-v2');

    if (!featureCognitiveMapV1 && !featureCognitiveMapV2) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <CognitiveMapProvider>
            <CognitiveMapData />
        </CognitiveMapProvider>
    );
};

const CognitiveMapData = () => {
    const {
        getCognitiveMapContent,
        getCognitiveMapContentV2,
        getProcessingBias,
    } = endpoints();
    const featureCognitiveMapV2 = useFeatureIsOn('new-cognitive-map-v2');
    const { enqueueSnackbar } = useSnackbar();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const {
        dispatch,
        state: { caiDrawerOpen },
    } = useCognitiveMap();

    useEffect(() => {
        if (!caiDrawerOpen && userId) {
            if (featureCognitiveMapV2) {
                getCognitiveMapDataV2(userId);
            } else {
                getCognitiveMapData(userId);
            }
        }
    }, [caiDrawerOpen]);

    const getCognitiveMapData = async (userId: string) => {
        const { data, error } = await getCognitiveMapContent({
            learnerUserId: userId,
        });

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
        if (data) {
            dispatch({ type: 'SET_COGNITIVE_MAP_V1', payload: data });
        }
    };

    const getCognitiveMapDataV2 = async (userId: string) => {
        const [
            { data: cognitiveMapData, error: cognitiveMapError },
            { data: processingBiasData, error: processingBiasError },
        ] = await Promise.all([
            getCognitiveMapContentV2({ learnerUserId: userId }),
            getProcessingBias({ learnerUserId: userId, reportType: 1 }),
        ]);

        if (cognitiveMapError || processingBiasError) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }

        if (cognitiveMapData && processingBiasData) {
            const fullData: ICognitiveMapV2 = {
                domains: [...cognitiveMapData.domains],
                staticContent: {
                    ...cognitiveMapData.staticContent,
                    processInformationVisual: {
                        ...cognitiveMapData.staticContent
                            .processInformationVisual,
                        scores: {
                            processingBiasZ: processingBiasData.processingBiasZ,
                            memoryIndexAdjustedZ:
                                processingBiasData.memoryIndexAdjustedZ,
                        },
                    },
                },

                cogType: { ...cognitiveMapData.cogType },
            };

            dispatch({
                type: 'SET_COGNITIVE_MAP_V2',
                payload: fullData,
            });
        }
    };

    return featureCognitiveMapV2 ? (
        <CognitiveMapV2 />
    ) : (
        <Wrapper>
            <Outlet />
        </Wrapper>
    );
};
