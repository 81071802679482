import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        customLegendWrapperMobile: {
            height: '135px',
            top: '160px',
            position: 'absolute',
            right: '0',
        },
        customLegendItemMobile: {
            listStyleType: 'none',
            fontWeight: 700,
            height: '100%',
            textAlign: 'center',
            padding: 5,
            display: 'grid',
            alignItems: 'center',
            width: 100,
            background: '#F1EA9E80',
        },
        customLegendWrapperDesktop: {
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            textAlign: 'center',
            marginLeft: 100,
        },
        customLegendItemDesktop: {
            listStyleType: 'none',
            fontWeight: 700,
            padding: 5,
            height: '100%',
            width: '30%',
            display: 'grid',
            alignItems: 'center',
            background: '#F1EA9E80',
        },
    })
);

interface IOwnProps {
    isMobile: boolean;
}

export const CustomLegend: React.FC<IOwnProps> = () => {
    const classes = useStyles();

    return (
        <ul className={classes.customLegendWrapperDesktop}>
            <li className={classes.customLegendItemDesktop}>Typical Range</li>
        </ul>
    );
};
