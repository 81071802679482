import { endpoints } from '../api/endpoints';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const useGrabLibraryLink = () => {
    const { getLearningUrl } = endpoints();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);

    const grabLibraryLink = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        redirectUrl?: string
    ) => {
        e.preventDefault();
        setLoading(true);

        const { data, error } = await getLearningUrl(redirectUrl);

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        } else if (data) {
            window.open(data.loginUrl);
        }
        setLoading(false);
    };

    return {
        grabLibraryLink,
        loading,
    };
};
