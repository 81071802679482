import { useEffect, useRef } from 'react';

import CaiconCircle from '../../../assets/img/icons/caiconcircle.svg';
import { Markdown } from '../../../components/Markdown';
import { nanoid } from 'nanoid';
import { styled } from '@mui/material';

const MessageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
    padding: theme.spacing(0, 4, 0, 1),
    maxHeight: 'calc(100vh - 168px)',
}));

const SentMessageWrapper = styled('div')(({ theme }) => ({
    alignSelf: 'flex-end',
    background: theme.palette.grey[50],
    borderRadius: theme.spacing(1, 1, 0, 1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
    maxWidth: '70%',
}));

const ReplyWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1, 1, 1, 0),
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
    maxWidth: '70%',
    '& ul, & ol': {
        marginTop: theme.spacing(2),
    },
    '& p, & li': {
        margin: theme.spacing(2, 0),
    },
    '& strong': {
        fontWeight: 600,
    },
}));

export interface IMessage {
    message?: string;
    answer?: string;
}

interface IOwnProps {
    messages: IMessage[];
}

export const CaiMessages: React.FC<IOwnProps> = ({ messages }) => {
    const messagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <MessageWrapper id='messages' ref={messagesRef} tabIndex={1}>
            {messages.map((msg) => {
                if (msg.answer) {
                    return (
                        <>
                            <img
                                src={CaiconCircle}
                                alt=''
                                style={{ marginBottom: 8 }}
                            />
                            <ReplyWrapper key={nanoid()}>
                                <Markdown mkdown={msg.answer} />
                            </ReplyWrapper>
                        </>
                    );
                } else if (msg.message) {
                    return (
                        <SentMessageWrapper key={nanoid()}>
                            <Markdown mkdown={msg.message} />
                        </SentMessageWrapper>
                    );
                } else {
                    return null;
                }
            })}
        </MessageWrapper>
    );
};
