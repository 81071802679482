import { CognitiveMapLevel, ICognitiveDomain } from '../../../types';
import {
    IconButton as MuiIconButton,
    Typography,
    alpha,
    styled,
} from '@mui/material';

import CheckPurpleIcon from '../../../assets/img/icons/checkpurple.svg';
import { ChevronRight } from 'react-feather';
import { ContentHelper } from './ContentHelper';
import { DomainDrawer } from './DomainDrawer';
import { DomainStrategies } from './DomainStrategies';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useEffect } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    minHeight: 28,
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(0.5, 0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        cursor: 'pointer',
    },
}));

const LeftWrapper = styled('div', {
    ...shouldForwardProps('isStrength'),
})<{ isStrength?: boolean }>(({ isStrength, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexFlow: 'row nowrap',
    width: '100%',
    borderLeft: isStrength
        ? `3px solid rgba(119, 192, 0, 0.2)`
        : '3px solid #FFEBCE',
    paddingLeft: theme.spacing(1),
    gap: theme.spacing(2),
    [theme.breakpoints.down(744)]: {
        flexFlow: 'column-reverse',
        alignItems: 'flex-start',
        gap: 8,
    },
}));

const DomainTitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
}));

const DomainTitle = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: pxToRem(16),
    maxWidth: 200,
}));

export const TopStrengthIndicator = styled('div')(({ theme }) => ({
    height: 24,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(24),
    background: 'rgba(119, 192, 0, 0.2)',
    borderRadius: 50,
    padding: theme.spacing(0, 1),
    fontWeight: 600,
    whiteSpace: 'nowrap',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    padding: theme.spacing(0.3),
    backgroundColor: theme.palette.grey[50],
    marginLeft: theme.spacing(2),
}));

const StrategiesWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, -3),
}));

interface IOwnProps {
    domain: ICognitiveDomain;
    topDomainText: string;
    isOpen: boolean;
}

export const Domain: React.FC<IOwnProps> = ({
    domain,
    topDomainText,
    isOpen,
}) => {
    const {
        dispatch,
        state: { openDomainDrawerId },
    } = useCognitiveMap();

    useEffect(() => {
        return () => {
            dispatch({
                type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                payload: 0,
            });
        };
    }, []);

    return (
        <>
            <Wrapper
                onClick={() => {
                    dispatch({
                        type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                        payload:
                            openDomainDrawerId === domain.domainId
                                ? 0
                                : domain.domainId,
                    });
                }}
            >
                <LeftWrapper
                    isStrength={domain.level === CognitiveMapLevel.High}
                >
                    <DomainTitleWrapper>
                        <DomainTitle>{domain.domainName}</DomainTitle>
                    </DomainTitleWrapper>
                    {domain.topDomain && (
                        <TopStrengthIndicator>
                            {topDomainText}
                        </TopStrengthIndicator>
                    )}
                </LeftWrapper>
                <IconButton aria-label='open domain'>
                    <ChevronRight />
                </IconButton>
            </Wrapper>
            <DomainDrawer
                id={`domain-${domain.domainId}-drawer`}
                title={domain.domainName}
                close={() => {
                    dispatch({
                        type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                        payload: 0,
                    });
                }}
                open={isOpen}
                isTopStrength={domain.topDomain}
                topDomainText={topDomainText}
            >
                <ContentHelper
                    content={domain.content}
                    liIcon={
                        <img
                            alt=''
                            src={CheckPurpleIcon}
                            style={{ marginRight: 8, minWidth: 24 }}
                        />
                    }
                    pStyles={{
                        mb: 2,
                    }}
                    ulStyles={{
                        pl: 0,
                    }}
                    liStyles={{
                        mb: 2,
                        listStyle: 'none',
                        alignItems: 'center',
                        display: 'flex',
                        verticalAlign: 'middle',
                    }}
                    listWrapperStyles={{
                        mt: 3,
                    }}
                />
                <StrategiesWrapper>
                    <ContentHelper
                        content={[domain.strategiesHeading]}
                        pStyles={{
                            fontWeight: 600,
                            padding: '0 24px',
                        }}
                    />
                    <DomainStrategies domain={domain} />
                </StrategiesWrapper>
            </DomainDrawer>
        </>
    );
};
