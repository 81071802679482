import { Chip, styled, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StrategyStatus } from '../../../types';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';

interface IOwnProps {
    status?: StrategyStatus;
}

const Trying = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFEA9A',
    fontWeight: 500,
}));

const New = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FEF4F8',
    fontWeight: 500,
}));

const Icon = styled(FontAwesomeIcon)(() => ({
    width: 12,
    height: 12,
}));

export const UserStrategyStatusTag: React.FC<IOwnProps> = ({ status }) => {
    const theme = useTheme();
    switch (status) {
        case StrategyStatus.TRYING:
            return (
                <Trying
                    label="I'm trying this"
                    icon={
                        <Icon
                            icon={byPrefixAndName.fas['circle']}
                            color='#F59C4C'
                        />
                    }
                />
            );
        case StrategyStatus.APPLIED:
        case StrategyStatus.NEW:
        default:
            return (
                <New
                    label='New'
                    icon={
                        <Icon
                            icon={byPrefixAndName.far['circle']}
                            color={theme.palette.error.main}
                        />
                    }
                />
            );
    }
};
