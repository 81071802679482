import { Avatar, Typography, styled } from '@mui/material';

import { Check } from 'react-feather';

const Wrapper = styled('div')(() => ({
    display: 'inline-flex',
    alignItems: 'center',
}));

interface IOwnProps {
    text: string;
}

export const DialogContentListItem: React.FC<IOwnProps> = ({ text }) => (
    <Wrapper>
        <Avatar
            sx={{
                bgcolor: 'success.main',
                width: '20px',
                height: '20px',
                mr: 1.5,
            }}
        >
            <Check size={14} strokeWidth={4} color='white' />
        </Avatar>
        <Typography>{text}</Typography>
    </Wrapper>
);
