export const getMinMaxGridColumns = (
    gapPixels: number,
    maxColumns: number,
    minWidthPixels: number
) => {
    const gapCount = maxColumns - 1;
    const totalGapWidth = gapCount * gapPixels;
    const maxWidth = `calc((100% - ${totalGapWidth}px) / ${maxColumns})`;

    return {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(max(${minWidthPixels}px, ${maxWidth}), 1fr))`,
        gap: gapPixels,
    };
};
