import { List, ListItem, styled } from '@mui/material';

export const Container = styled('div')(() => ({
    maxWidth: 1048,
    margin: 'auto',
}));

export const Section = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(6),
}));

export const GraphContainer = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: theme.cogShadows.lg,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(5, 4),
    margin: theme.spacing(4, 0, 4),
    [theme.breakpoints.down(744)]: {
        background: 'none',
        padding: theme.spacing(0),
        boxShadow: 'none',
        border: 'none',
        borderRadius: 0,
    },
}));

export const BulletList = styled(List)(({ theme }) => ({
    listStyleType: 'disc',
    padding: theme.spacing(0, 0, 0, 3),
    marginBottom: theme.spacing(2),
}));

export const Bullet = styled(ListItem)(({ theme }) => ({
    display: 'list-item',
    padding: theme.spacing(0),
}));
