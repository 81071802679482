import { CogInput, ErrorText } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

export const LastName: React.FC = () => {
    const {
        formState: { errors },
        register,
        getValues,
    } = useFormContext<{ lastName: string }>();

    const lastName = getValues('lastName');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('lastName', {
        required: 'Please enter a last name',
        maxLength: {
            value: 230,
            message:
                'You have entered too many characters, please reduce it and try again.',
        },
    });

    return (
        <Field
            label='Last name'
            labelFor='lastName'
            required={true}
            hasError={!!errors.lastName}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={lastName}
                    id='lastName'
                    fullWidth
                    hasError={!!errors.lastName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
