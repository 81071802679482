import { AuthAction, AuthState, IAuthContext } from '../types';
import React, { useContext, useMemo } from 'react';

import { getBaseUrl } from '../utils/url-config';
import { useImmerReducer } from 'use-immer';

export const initialState = {
    loginConfig: {
        isAuthenticated: undefined,
        hasCalledLogin: false,
    },
    userConfig: {
        role: undefined,
        isOnlyLearner: undefined,
        userId: undefined,
        name: undefined,
        initials: undefined,
        email: undefined,
        isFirstLogin: undefined,
        hasTakenAssessment: undefined,
        hasPendingAssessment: undefined,
        assessmentAccessCode: undefined,
        hasVerifiedEmail: undefined,
        championStatus: undefined,
        isChampion: undefined,
        legacyReturnUrl: undefined,
    },
    clientConfig: {
        clientId: undefined,
        items: undefined,
        hasReachedAssessmentThreshold: undefined,
        businessType: undefined,
        name: undefined,
        enabledRoles: undefined,
    },
};

export const reducer = (state: AuthState, action: AuthAction): void => {
    switch (action.type) {
        case 'SET_LOGIN_CONFIG':
            state.loginConfig = {
                ...state.loginConfig,
                ...action.payload,
            };
            break;
        case 'SET_USER_CONFIG':
            state.userConfig = {
                ...state.userConfig,
                ...action.payload,
            };
            break;
        case 'SET_CLIENT_CONFIG':
            state.clientConfig = {
                ...state.clientConfig,
                ...action.payload,
            };
            break;
        case 'SET_HAS_CALLED_LOGIN':
            state.loginConfig.hasCalledLogin = true;
            break;
        case 'SET_ASSESSMENT_ACCESS_CODE':
            state.userConfig.assessmentAccessCode =
                action.payload.assessmentAccessCode;
    }
};

export const AuthContext = React.createContext<IAuthContext>({
    state: initialState,
    dispatch: () => false,
    login: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<AuthState, AuthAction>(
        reducer,
        initialState
    );

    const login = async () => {
        const correctPath =
            window.location.pathname === '/'
                ? '/dashboard'
                : window.location.pathname;
        window.location.href = `${baseURL}auth/login?returnUrl=${window.location.origin}/login-redirect?redirectUrl=${correctPath}${window.location.search}`;
    };

    const val = useMemo(
        () => ({
            state,
            dispatch,
            login,
        }),
        [state, dispatch, login]
    );

    const baseURL = getBaseUrl();

    return <AuthContext.Provider value={val}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => useContext(AuthContext);
