import {
    Check as FCheck,
    HelpCircle,
    IconProps,
    Info,
    XCircle,
} from 'react-feather';

import { styled } from '@mui/material';

export const Check = styled(FCheck)(({ theme }) => ({
    backgroundColor: theme.palette.success.light,
    borderRadius: '50px',
    color: theme.palette.success.dark,
    padding: '2px',
}));

export const CheckDark = styled(FCheck)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    borderRadius: '50px',
    color: theme.palette.common.white,
    padding: '3px',
}));

export const QuestionIcon = styled((props: IconProps) => (
    <HelpCircle fill='#7458FF' {...props} />
))(({ theme }) => ({
    color: theme.palette.common.white,
}));

export const InfoIcon = styled((props: IconProps) => (
    <Info fill='#7458FF' {...props} />
))(({ theme }) => ({
    color: theme.palette.common.white,
}));

export const Close = styled((props: IconProps) => (
    <XCircle fill='#B7B7BF' {...props} />
))(({ theme }) => ({
    color: theme.palette.common.white,
}));
