import { CogInput, ErrorText } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

export const FirstName: React.FC = () => {
    const {
        formState: { errors },
        register,
        getValues,
    } = useFormContext<{ firstName: string }>();

    const firstName = getValues('firstName');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('firstName', {
        required: 'Please enter a first name',
        maxLength: {
            value: 230,
            message:
                'You have entered too many characters, please reduce it and try again.',
        },
    });

    return (
        <Field
            label='First name'
            labelFor='firstName'
            required={true}
            hasError={!!errors.firstName}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={firstName}
                    id='firstName'
                    fullWidth
                    hasError={!!errors.firstName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
