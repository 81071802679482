export enum TrackingEvent {
    TERMS_AND_CONDITIONS_OPENED = 'TERMS_AND_CONDITIONS_OPENED',
    TERMS_AND_CONDITIONS_CLOSED = 'TERMS_AND_CONDITIONS_CLOSED',
    TERMS_AND_CONDITIONS_BOTTOM_SCROLL = 'TERMS_AND_CONDITIONS_BOTTOM_SCROLL',
    PRIVACY_POLICY_OPENED = 'PRIVACY_POLICY_OPENED',
    PRIVACY_POLICY_CLOSED = 'PRIVACY_POLICY_CLOSED',
    PRIVACY_POLICY_BOTTOM_SCROLL = 'PRIVACY_POLICY_BOTTOM_SCROLL',
    ASSESSMENT_LIMIT_REACHED = 'ASSESSMENT_LIMIT_REACHED',
    SIGNUP_TERMS_AND_CONDITIONS_OPENED = 'SIGNUP_TERMS_AND_CONDITIONS_OPENED',
    SIGNUP_TERMS_AND_CONDITIONS_CLOSED = 'SIGNUP_TERMS_AND_CONDITIONS_CLOSED',
    SIGNUP_TERMS_AND_CONDITIONS_BOTTOM_SCROLL = 'SIGNUP_TERMS_AND_CONDITIONS_BOTTOM_SCROLL',
    SIGNUP_PRIVACY_POLICY_OPENED = 'SIGNUP_PRIVACY_POLICY_OPENED',
    SIGNUP_PRIVACY_POLICY_CLOSED = 'SIGNUP_PRIVACY_POLICY_CLOSED',
    SIGNUP_PRIVACY_POLICY_BOTTOM_SCROLL = 'SIGNUP_PRIVACY_POLICY_BOTTOM_SCROLL',
    SIGNUP_EMAIL_DOMAIN_NOT_ALLOWED = 'SIGNUP_EMAIL_DOMAIN_NOT_ALLOWED',
    SIGNUP_EMAIL_ALREADY_EXISTS = 'SIGNUP_EMAIL_ALREADY_EXISTS,',
    SIGNUP_EMAIL_NOT_INVITED = 'SIGNUP_EMAIL_NOT_INVITED,',
    SIGNUP_FIRST_NAME_ERROR = 'SIGNUP_FIRST_NAME_ERRROR,',
    SIGNUP_LAST_NAME_ERROR = 'SIGNUP_LAST_NAME_ERROR,',
    SIGNUP_PASSWORD_ERROR = 'SIGNUP_PASSWORD_ERROR,',
    SIGNUP_TERMS_AND_CONDITIONS_ACCEPTED = 'SIGNUP_TERMS_AND_CONDITIONS_ACCEPTED,',
    PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
}
