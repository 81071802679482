import { DialogActions, DialogContent, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Plus, X } from 'react-feather';

import { Button } from '@cognassist/react-components';
import { Email } from './Email';
import { endpoints } from '../../../../api/endpoints';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const DialogContentWrapper = styled(DialogContent)(({ theme }) => ({
    paddingTop: theme.spacing(0),
}));

const AddEmailDialogText = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const NonBreakingDashSpan = styled(`span`)(() => ({
    whiteSpace: 'nowrap',
}));

const AddButtonPlus = styled(Plus)(() => ({
    strokeWidth: 4,
}));

const AddEmailInputWrapper = styled(`div`)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
}));

const AddEmailInput = styled(Email)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
}));

const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

const InviteEmailAddressWrapper = styled(`div`)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const CloseIcon = styled(X)(({ theme }) => ({
    color: theme.palette.text.primary,
    cursor: 'pointer',
}));

export interface IFormFields {
    email: string;
}

const DEFAULT_FIELD_DATA = {
    email: '',
};

interface IOwnProps {
    validDomains: string[];
    restrictDomains: boolean;
    isManagerNotAdmin: boolean;
}

export const InvitationOnlyFlow: React.FC<IOwnProps> = ({
    validDomains,
    restrictDomains,
    isManagerNotAdmin,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { putSelfOnboardingInvitations } = endpoints();

    const [invitedEmailAddresses, setInvitedEmailAddresses] = useState<
        string[]
    >([]);

    const inviteEmailAddressesButtonText = `Send ${invitedEmailAddresses.length || ''} invite${invitedEmailAddresses.length === 1 ? '' : 's'}`;

    const methods = useForm<IFormFields>({
        defaultValues: DEFAULT_FIELD_DATA,
    });

    const { handleSubmit, reset } = methods;

    const handleAddInvitedEmailAddress = (values: IFormFields) => {
        if (!invitedEmailAddresses.includes(values.email)) {
            setInvitedEmailAddresses([...invitedEmailAddresses, values.email]);
            reset(DEFAULT_FIELD_DATA);
        }
    };

    const handleRemoveInvitedEmailAddress = (emailAddressToRemove: string) => {
        setInvitedEmailAddresses(
            invitedEmailAddresses.filter(
                (item) => item !== emailAddressToRemove
            )
        );
    };

    const handleSendInviteEmailsRequest = async () => {
        const { error } = await putSelfOnboardingInvitations({
            emailAddresses: invitedEmailAddresses,
        });

        if (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } else {
            enqueueSnackbar(
                `Successfully invited ${invitedEmailAddresses.length} email addresses`,
                { variant: 'success' }
            );
            reset(DEFAULT_FIELD_DATA);
            setInvitedEmailAddresses([]);
        }
    };

    return (
        <>
            <DialogContentWrapper>
                {isManagerNotAdmin ? (
                    <AddEmailDialogText>
                        Invite specific users by adding their email addresses to
                        the list below.
                    </AddEmailDialogText>
                ) : (
                    <AddEmailDialogText>
                        Enabling this allows <strong>only</strong> users who you
                        invite to access Cognassist. Disable if you wish to
                        utilise a{' '}
                        <NonBreakingDashSpan>company-wide</NonBreakingDashSpan>{' '}
                        onboarding link that can be shared and used by anyone
                        within your company.
                    </AddEmailDialogText>
                )}

                <AddEmailInputWrapper>
                    <FormProvider {...methods}>
                        <AddEmailInput
                            validDomains={validDomains}
                            restrictDomains={restrictDomains}
                        />
                        <Button
                            variant='text'
                            startIcon={<AddButtonPlus size={12} />}
                            text='Add'
                            onClick={() => {
                                handleSubmit(handleAddInvitedEmailAddress)();
                            }}
                        />
                    </FormProvider>
                </AddEmailInputWrapper>
                {invitedEmailAddresses.map((val) => (
                    <InviteEmailAddressWrapper id='addedEmail' key={val}>
                        <span>{val}</span>
                        <CloseIcon
                            size={16}
                            onClick={() => {
                                handleRemoveInvitedEmailAddress(val);
                            }}
                        />
                    </InviteEmailAddressWrapper>
                ))}
            </DialogContentWrapper>
            <DialogActionsWrapper>
                <Button
                    text={inviteEmailAddressesButtonText}
                    fullWidth
                    disabled={invitedEmailAddresses.length < 1}
                    onClick={handleSendInviteEmailsRequest}
                />
            </DialogActionsWrapper>
        </>
    );
};
