import {
    SnackbarProvider as NotistackSnackbarProvider,
    SnackbarKey,
} from 'notistack';
import React, { useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';

const StyledSnackbarProvider = styled(NotistackSnackbarProvider)(() => ({}));

interface IOwnProps {
    children: React.ReactNode;
}

const SnackbarProvider: React.FC<IOwnProps> = ({ children }) => {
    const notistackRef = useRef<NotistackSnackbarProvider>(null);
    const onClickDismiss = (key: SnackbarKey) => () => {
        notistackRef?.current?.closeSnackbar(key);
    };
    return (
        <StyledSnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            hideIconVariant={true}
            sx={{
                '&.SnackbarItem-variantSuccess': {
                    backgroundColor: '#7BAE02',
                },
                flexFlow: 'row nowrap !important',
                alignItems: 'flex-start !important',
            }}
            action={(key) => (
                <IconButton
                    data-testid='toaster-button-close'
                    sx={{ color: 'common.white' }}
                    disableRipple
                    disableFocusRipple
                    aria-label='delete'
                    edge='end'
                    size='small'
                    onClick={onClickDismiss(key)}
                >
                    <CloseIcon />
                </IconButton>
            )}
        >
            {children}
        </StyledSnackbarProvider>
    );
};

export default SnackbarProvider;
