import { BusinessType, ClientConfigItem, Roles } from '../types';

import capitaliseFirstLetter from '../utils/transformFirstLetter';
import { endpoints } from '../api/endpoints';
import { getBaseUrl } from '../utils/url-config';
import { getInitialsFromNames } from '../utils/getInitialsFromNames';
import { mapAssessmentUserConfig } from '../utils/assessmentStatusLookup';
import { parseClaims } from '../utils/parseClaims';
import { useAuth } from '../custom-providers/AuthProvider';
import { useConfig } from '../custom-providers/ConfigProvider';
import { useEffect } from 'react';

export const useSetUserState = () => {
    const { dispatch: configDispatch } = useConfig();
    const { dispatch: authDispatch } = useAuth();
    const { getUser, getUserChampionStatus } = endpoints();

    const baseURL = getBaseUrl();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { data } = await getUser();

        if (!data?.isAuthenticated) {
            window.location.href = `${baseURL}auth/login?returnUrl=${window.location.origin}/login-redirect?redirectUrl=${window.location.pathname}${window.location.search}`;
        } else if (data?.isAuthenticated) {
            const isSelfOnboarding =
                data.clientConfigItems?.find(
                    (item) => item.id === ClientConfigItem.SelfOnboarding
                )?.state === 1;

            if (isSelfOnboarding) {
                const champResponse = await getUserChampionStatus();

                if (champResponse.data?.status) {
                    authDispatch({
                        type: 'SET_USER_CONFIG',
                        payload: {
                            championStatus: champResponse.data.status,
                            isChampion: true,
                        },
                    });
                } else {
                    authDispatch({
                        type: 'SET_USER_CONFIG',
                        payload: {
                            isChampion: false,
                        },
                    });
                }
            }

            const {
                user_id: userId,
                client_id: clientId,
                email,
                email_verified: hasVerifiedEmail,
            } = parseClaims(data.claims);

            authDispatch({
                type: 'SET_LOGIN_CONFIG',
                payload: {
                    isAuthenticated: data.isAuthenticated,
                },
            });

            authDispatch({
                type: 'SET_USER_CONFIG',
                payload: {
                    roles: data.roles,
                    isOnlyLearner:
                        data.roles.length === 1 &&
                        data.roles.includes(Roles.Learner),
                    userId,
                    email,
                    isFirstLogin: data.isFirstLogin,
                    useSingleSignOn: data.useSingleSignOn,
                    hasVerifiedEmail: hasVerifiedEmail === 'true',
                    legacyReturnUrl: data.legacyReturnUrl,
                    ...mapAssessmentUserConfig(data.assessmentStatus),
                },
            });

            if (data.firstName && data.lastName) {
                authDispatch({
                    type: 'SET_USER_CONFIG',
                    payload: {
                        name: `${capitaliseFirstLetter(
                            data.firstName
                        )} ${capitaliseFirstLetter(data.lastName)}`,
                        initials: getInitialsFromNames(
                            data.firstName,
                            data.lastName
                        ),
                    },
                });
            }

            authDispatch({
                type: 'SET_ASSESSMENT_ACCESS_CODE',
                payload: {
                    assessmentAccessCode: data.assessmentAccessCode,
                },
            });

            authDispatch({
                type: 'SET_CLIENT_CONFIG',
                payload: {
                    clientId: clientId,
                    items: data.clientConfigItems,
                    name: data.clientName,
                    businessType: data.isEnterprise
                        ? BusinessType.ENTERPRISE
                        : BusinessType.APPS_ED,
                    enabledRoles: data.clientEnabledRoles,
                },
            });

            configDispatch({
                type: 'SET_APP_CONFIG',
                payload: {
                    environment: data.environment,
                    growthbookKey: data.growthbookKey,
                    applicationInsightsConnectionString:
                        data.applicationInsightsConnectionString,
                    assessmentBaseUrl: data.assessmentBaseUrl,
                    bffBaseUrl: data.bffBaseUrl,
                },
            });
        }
    };
};
