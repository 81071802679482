import { CogInput, ErrorText } from '../../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../../pre-assessment/Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

interface IOwnProps {
    isSmall?: boolean;
}

export const FirstName: React.FC<IOwnProps> = ({ isSmall }) => {
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext<{ firstName: string }>();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('firstName', {
        required: true,
        maxLength: {
            value: 230,
            message:
                'You have gone over the 230 character limit for this field, please reduce it and try again.',
        },
    });

    const firstName = watch('firstName');

    return (
        <Field
            label='First name'
            labelFor='firstName'
            required
            hasError={!!errors.firstName}
            isSmall={isSmall}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={firstName}
                    id='firstName'
                    fullWidth
                    hasError={!!errors.firstName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
