import { Box as MuiBox, Tab as MuiTab, styled } from '@mui/material';
import {
    TabList as MuiTabList,
    TabPanel as MuiTabPanel,
    TabContext,
} from '@mui/lab';
import { useEffect, useRef, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { CompletedModules } from './CompletedModules';
import { Filter } from 'react-feather';
import { FilterPopper } from './FilterPopper';
import { IActiveFilters } from '../../types';
import { UpcomingModules } from './UpcomingModules';
import { pxToRem } from '../../utils/style-functions';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';
import { useFLPAPI } from '../../custom-hooks/useFLPAPI';
import { useLearnerId } from '../../custom-hooks/useLearnerId';

const ModuleListSection = styled('div')(({ theme }) => ({
    flex: '1 1 auto',
    padding: theme.spacing(3, 4),
    overflowY: 'scroll',
    height: 'calc(100vh - 104px - 100px)',
}));

const TabSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    minHeight: 48,
}));
const Tabs = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '30px',
}));
const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    padding: '0 1px',
    marginRight: theme.spacing(2),
    fontWeight: 400,
    '&:last-of-type': {
        marginRight: theme.spacing(0),
    },
    '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontWeight: 600,
        padding: 0,
    },
}));
const TableSection = styled('div')(() => ({}));
const TabPanel = styled(MuiTabPanel)(() => ({
    padding: 0,
}));
const TabList = styled(MuiTabList)(() => ({
    minHeight: 30,
}));
const Box = styled(MuiBox)(() => ({
    borderBottom: 1,
    borderColor: 'divider',
}));

export const Modules: React.FC = () => {
    const [value, setValue] = useState('1');
    const learnerUserId = useLearnerId();
    const { getUpcomingModules } = useFLPAPI({ learnerUserId });
    const {
        dispatch,
        state: { upcomingModules },
    } = useFLP();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0 });
        }
    }, [upcomingModules]);

    const handleOpenFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleApplyFilters = (filters: IActiveFilters) => {
        getUpcomingModules({
            ...filters,
        });
        dispatch({
            type: 'SET_ACTIVE_FILTERS',
            payload: {
                ...filters,
            },
        });
        setAnchorEl(null);
    };

    return (
        <ModuleListSection ref={containerRef}>
            <TabContext value={value}>
                <TabSection>
                    <Tabs>
                        <Box>
                            <TabList
                                onChange={(_e, newValue) => setValue(newValue)}
                                aria-label='upcoming modules tabs'
                            >
                                <Tab
                                    disableRipple
                                    label='Upcoming modules'
                                    value='1'
                                />
                                <Tab
                                    disableRipple
                                    label='Accessed modules'
                                    value='2'
                                />
                            </TabList>
                        </Box>
                    </Tabs>
                    {value === '1' && (
                        <Button
                            color='inherit'
                            variant='outlined'
                            startIcon={<Filter size={18} />}
                            onClick={handleOpenFilters}
                            text='Filter'
                        />
                    )}
                </TabSection>
                <TableSection>
                    <TabPanel value='1'>
                        <UpcomingModules />
                    </TabPanel>
                    <TabPanel value='2'>
                        <CompletedModules />
                    </TabPanel>
                </TableSection>
            </TabContext>
            <FilterPopper
                anchorEl={anchorEl}
                handleCloseFilter={handleCloseFilter}
                handleApplyFilters={handleApplyFilters}
            />
        </ModuleListSection>
    );
};
