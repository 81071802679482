import { ICompletedCourse, Roles, TrainingType } from '../../../../types';
import { Typography, styled } from '@mui/material';
import { format, parse } from 'date-fns';
import { useEffect, useState } from 'react';

import DashboardCourseCard from './DashboardCourseCard';
import { endpoints } from '../../../../api/endpoints';
import neuroInclusionChampionImg from '../../../../assets/img/neuro-inclusive-champion.png';
import neuroInclusionImg from '../../../../assets/img/neuro-inclusive-employee.png';
import neuroInclusionManagerImg from '../../../../assets/img/neuro-inclusive-manager.png';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useSnackbar } from 'notistack';

const Wrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

const CourseCardWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gap: theme.spacing(2),
    },
}));

interface ICourse {
    title: string;
    id: string;
    image: string;
    appliesToRoles: Roles[];
    trainingType: TrainingType;
}

const courses: ICourse[] = [
    {
        title: 'Neuro-inclusion: Employee Awareness',
        id: 'neuro-inclusion-awareness-empowering-every-employee',
        image: neuroInclusionImg,
        appliesToRoles: [Roles.Learner],
        trainingType: TrainingType.EmployeeTraining,
    },
    {
        title: 'Neuro-inclusion: Manager Awareness',
        id: 'neuro-inclusion-manager-awareness-webinar-post-session',
        image: neuroInclusionManagerImg,
        appliesToRoles: [Roles.Tutor, Roles.Champion],
        trainingType: TrainingType.ManagerTraining,
    },
    {
        title: 'Neuro-inclusion: Champion',
        id: 'neuro-inclusion-champion-awareness-webinar-post-session',
        image: neuroInclusionChampionImg,
        appliesToRoles: [Roles.Champion],
        trainingType: TrainingType.ChampionTraining,
    },
];

interface IUserCourse extends ICourse {
    dateCompleted: string | null;
}

export const Courses: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { getUserCompletedCourses } = endpoints();
    const [userCourses, setUserCourses] = useState<IUserCourse[]>([]);
    const [completedUserCourses, setCompletedUserCourses] = useState<
        ICompletedCourse[]
    >([]);

    const {
        state: {
            userConfig: { roles, userId },
        },
    } = useAuth();

    useEffect(() => {
        if (userId) {
            getCourseCompletionDates(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (roles) {
            setUserCourses(parseCourses(roles));
        }
    }, [completedUserCourses, roles]);

    const getCourseCompletionDates = async (userId: string) => {
        const { data, error } = await getUserCompletedCourses(userId);

        if (error) {
            setCompletedUserCourses([]);
            enqueueSnackbar(
                'Could not load course completion data, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setCompletedUserCourses(data.completedCourses);
        }
    };

    const parseCourses = (roles: string[]) => {
        const coursesForRole = courses.reduce(
            (accum: ICourse[], currentCourse) => {
                if (
                    currentCourse.appliesToRoles.some((r) => roles.includes(r))
                ) {
                    accum.push(currentCourse);
                }
                return accum;
            },
            []
        );

        return coursesForRole.map((c) => ({
            ...c,
            dateCompleted: getCompletedDate(c.trainingType),
        }));
    };

    const getCompletedDate = (trainingType: TrainingType) => {
        const completedCourse = completedUserCourses.find(
            (c) => c.trainingType === trainingType
        );

        if (!completedCourse) {
            return null;
        }

        return format(
            parse(completedCourse.dateCompleted, 'yyyy-MM-dd', new Date()),
            'do MMM yy'
        );
    };

    return (
        <Wrapper>
            <Typography variant='h2'>My courses</Typography>
            <CourseCardWrapper>
                {userCourses.map((course: IUserCourse) => (
                    <DashboardCourseCard
                        key={course.id}
                        courseId={course.id}
                        courseTitle={course.title}
                        image={course.image}
                        dateCompleted={course.dateCompleted}
                    />
                ))}
            </CourseCardWrapper>
        </Wrapper>
    );
};
