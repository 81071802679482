import { Tab as MuiTab, Tabs as MuiTabs, styled } from '@mui/material';

import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';

export const TabsRow = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

export const TabRowsMaxWidth = styled('div', {
    ...shouldForwardProps('paddingTop'),
})<{ paddingTop?: string }>(({ paddingTop, theme }) => ({
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(1, 2, 0, 2),
    ...(paddingTop && { paddingTop }),
}));

export const Tabs = styled(MuiTabs)(() => ({
    '& .MuiTabs-indicator': {
        height: '1px',
    },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 'normal',
    '&.Mui-selected': {
        color: theme.palette.common.black,
        fontWeight: 600,
    },
}));
