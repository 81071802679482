import { Typography, styled } from '@mui/material';
import { useRef, useState } from 'react';

import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import useResizeObserver from '@react-hook/resize-observer';

const Wrapper = styled('div', {
    ...shouldForwardProps('backgroundImage', 'isSmall', 'isLarge'),
})<{ backgroundImage: string; isSmall: boolean; isLarge: boolean }>(
    ({ backgroundImage, isSmall, isLarge, theme }) => ({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
        minHeight: isLarge ? 300 : 260,
        borderRadius: theme.shape.borderRadius,
        backgroundImage: isSmall
            ? 'none'
            : `linear-gradient(to right, rgba(93, 96, 230, 1) 2%, rgba(178, 143, 255, 0) 160%), url(${backgroundImage})`,
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: theme.spacing(2.5, 3),
    })
);

const Text = styled(Typography, {
    ...shouldForwardProps('useFullWidth', 'useLargeText'),
})<{ useFullWidth: boolean; useLargeText: boolean }>(
    ({ useFullWidth, useLargeText, theme }) => ({
        color: theme.palette.common.white,
        fontSize: useLargeText ? pxToRem(32) : pxToRem(24),
        width: useFullWidth ? '100%' : '50%',
    })
);

const ActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    gap: theme.spacing(1),
}));

interface IOwnProps {
    text: string;
    image: string;
    actions: React.ReactElement;
}

export const Banner: React.FC<IOwnProps> = ({ text, image, actions }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<{
        isSmall: boolean;
        isLarge: boolean;
    }>({ isSmall: false, isLarge: false });

    useResizeObserver(wrapperRef, (entry) => {
        const { width } = entry.contentRect;

        setSize({ isSmall: width < 376, isLarge: width > 1200 });
    });

    return (
        <Wrapper
            backgroundImage={image}
            isSmall={size.isSmall}
            isLarge={size.isLarge}
            ref={wrapperRef}
        >
            <Text useFullWidth={size.isSmall} useLargeText={size.isLarge}>
                {text}
            </Text>
            <ActionsWrapper>{actions}</ActionsWrapper>
        </Wrapper>
    );
};
