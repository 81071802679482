import { PieChart } from '../../../PieChart';
import { IParsedChartData } from './ChartCard';

interface IChartProps {
    loading: boolean;
    parsedItems?: IParsedChartData[];
}

export const Chart: React.FC<IChartProps> = ({
    loading,
    parsedItems = [
        {
            title: '',
            value: 1,
            percentageValue: 1,
            actualValue: 1,
            color: '#CFD8E5',
            remainderValue: 0,
        },
    ],
}) => {
    return <PieChart data={parsedItems} cardLoading={loading} />;
};
