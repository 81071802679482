import {
    CloseIcon,
    CloseIconWrapper,
    Header,
} from '../ChampionDialogSharedComponents';
import {
    Dialog,
    DialogContent,
    Divider,
    Typography,
    styled,
} from '@mui/material';

import { Button } from '@cognassist/react-components';
import { endpoints } from '../../../../api/endpoints';
import img from '../../../../assets/img/champion-volunteer.jpeg';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const Image = styled('img')(() => ({
    maxWidth: 600,
    width: '100%',
}));

const TextWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    rowGap: theme.spacing(3),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 3),
}));

interface IOwnProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refreshVolunteerStatus: () => Promise<void>;
}

export const VolunteerDialog: React.FC<IOwnProps> = ({
    open,
    setOpen,
    refreshVolunteerStatus,
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const { volunteerAsChampion } = endpoints();

    const onConfirm = async () => {
        setLoading(true);

        const { error } = await volunteerAsChampion();

        if (error) {
            enqueueSnackbar('Something went wrong. Please try again.', {
                variant: 'error',
            });
        } else {
            await refreshVolunteerStatus();
            onClose();
            enqueueSnackbar(
                'You have successfully volunteered to be a Neuro-Inclusion Champion, your application is now being processed.',
                {
                    variant: 'success',
                }
            );
        }

        setLoading(false);
    };

    const onClose = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            maxWidth='sm'
            aria-labelledby='volunteer-dialog-title'
        >
            <DialogContent sx={{ p: 0 }}>
                <Header>
                    <CloseIconWrapper onClick={onClose}>
                        <CloseIcon size={19} strokeWidth={3} />
                    </CloseIconWrapper>
                    <Image alt='' src={img} />
                </Header>
                <TextWrapper>
                    <Typography variant='h2' id='volunteer-dialog-title'>
                        Are you passionate about championing neuro-inclusion in
                        your organisation and developing your skills?
                    </Typography>
                    <Typography>
                        Play a major part in driving neuro-difference awareness
                        and inclusion in your organisation. Volunteer to become
                        a Cognassist Certified Neuro-inclusion Champion and
                        empower your peers to thrive, whilst advancing your
                        career growth.
                    </Typography>
                    <Typography>
                        We will give you all of the training you need to:
                    </Typography>
                    <Typography component='ul' sx={{ pl: 4 }}>
                        <Typography component='li'>
                            Advocate for neurodiversity in the workplace
                        </Typography>
                        <Typography component='li'>
                            Embed a culture of inclusion and support
                        </Typography>
                        <Typography component='li'>
                            Create an environment where those experiencing
                            neuro-differences can thrive and contribute their
                            unique perspectives and talents
                        </Typography>
                    </Typography>
                    <Typography>
                        Once certified, you&apos;ll be available for 1-to-1
                        conversations with peers that reach out for support and
                        be a key driver in our Community of expert Champions.
                    </Typography>
                </TextWrapper>
                <Divider />
                <ButtonWrapper>
                    <Button
                        text='Confirm'
                        loading={loading}
                        onClick={onConfirm}
                        id='volunteer-as-champion-confirm-button'
                    />
                </ButtonWrapper>
            </DialogContent>
        </Dialog>
    );
};
