import {
    Bullet,
    BulletList,
    Container,
    GraphContainer,
    Section,
} from './styled-components';
import { ListItemText, Typography, styled } from '@mui/material';

import BannerLink from '../../components/assessment-report/BannerLink';
import { IProcessingBiasData } from '../../types';
import ProcessingBiasGraph from './graphs/processing-bias-graph';
import capitaliseFirstLetter from '../../utils/transformFirstLetter';
import man from '../../assets/img/banner-man.jpg';
import { useReportState } from '../../custom-providers/ReportProvider';

const PurpleSpan = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
}));

const BlueSpan = styled('span')(() => ({
    color: '#408EF5',
    fontWeight: 600,
}));

const BoldSpan = styled('span')(() => ({
    fontWeight: 600,
}));

interface IOwnProps {
    graphData: IProcessingBiasData;
}

export const ProcessingBias: React.FC<IOwnProps> = ({ graphData }) => {
    const {
        state: { learnerName, isViewingAsManager },
    } = useReportState();

    return (
        <>
            <Container id='processing-bias'>
                <Section>
                    <Typography
                        variant='h1'
                        sx={{ fontWeight: 500, fontSize: '2rem', mb: 5 }}
                    >
                        What is Processing Bias?
                    </Typography>
                    <Typography>
                        Processing Bias explains the type of cognitive processes
                        our brain naturally gravitates towards when processing
                        the world around us:
                    </Typography>
                    <BulletList sx={{ mb: 3 }}>
                        <Bullet>
                            <ListItemText>
                                <PurpleSpan>Language and Verbal</PurpleSpan> -
                                how we process written, verbal or numerical
                                information.
                            </ListItemText>
                        </Bullet>
                        <Bullet>
                            <ListItemText>
                                <BlueSpan>Visual and Fluid</BlueSpan> - how we
                                process visual information and manipulate
                                information in our minds in a fluid manner.
                            </ListItemText>
                        </Bullet>
                    </BulletList>
                    <Typography sx={{ mb: 3 }}>
                        Another important area of cognition is our memory, which
                        impacts how we use and recall information we have
                        processed. Most people remember information more
                        effectively if it&apos;s broken down into chunks and
                        repeated often. For some, chunking is important for most
                        everyday tasks but for others, chunking may only be
                        necessary for new or complex tasks. This can be thought
                        of as <BoldSpan>Memorisation Style</BoldSpan>.
                    </Typography>
                    <Typography>
                        The graph below combines the concepts of Processing Bias
                        and Memorisation Style to give you a greater
                        understanding of whether{' '}
                        {isViewingAsManager ? learnerName : 'you'} remember
                        {isViewingAsManager && 's'} information effectively when
                        it is broken down into smaller chunks with more
                        repetition, or if {isViewingAsManager ? 'they' : 'you'}{' '}
                        can process larger amounts of information in fewer
                        learning sessions. The graph also demonstrates if{' '}
                        {isViewingAsManager ? `${learnerName} has` : 'you have'}{' '}
                        a bias for processing information verbally or visually.
                        You can use this information to adapt how you work{' '}
                        {isViewingAsManager
                            ? `with ${learnerName} moving forward.`
                            : '.'}
                    </Typography>
                </Section>
                <Section>
                    <GraphContainer>
                        <ProcessingBiasGraph data={graphData} />
                    </GraphContainer>
                </Section>
                <Section>
                    <Typography sx={{ fontWeight: 700, mb: 4 }}>
                        Based on{' '}
                        {isViewingAsManager ? `${learnerName}'s` : 'your'}{' '}
                        Processing Bias, {isViewingAsManager ? 'they' : 'you'}{' '}
                        should consider:
                    </Typography>
                    <BulletList>
                        {graphData.processingBiasZText.map((item) => {
                            return (
                                <Bullet key={item}>
                                    <ListItemText>
                                        {capitaliseFirstLetter(item)}
                                    </ListItemText>
                                </Bullet>
                            );
                        })}
                        {graphData.memoryIndexAdjustedZText.map((item) => {
                            return (
                                <Bullet key={item}>
                                    <ListItemText>
                                        {capitaliseFirstLetter(item)}
                                    </ListItemText>
                                </Bullet>
                            );
                        })}
                    </BulletList>
                </Section>
            </Container>
            <BannerLink
                title='Ready to learn more about Processing Bias?'
                body='Find out what Processing Bias is and what it means for you in our comprehensive guide.'
                link='View Guide'
                location='https://rise.articulate.com/share/c8wMrIHElk5gWc5gWe00M6cxg1Y56aJj#/lessons/TOrLJNi2dYa3YUVSMk83Iz3mkJXRzXiT'
                image={man}
                alt='smiling man excited to learn about processing bias'
            />
        </>
    );
};

export default ProcessingBias;
