import {
    IActiveFilters,
    IAssignedModulesResponse,
    IFilters,
    IPostLearningPlanResponse,
    IPriorityModule,
} from '../types';
import React, { useContext, useMemo } from 'react';

import update from 'immutability-helper';
import { useImmerReducer } from 'use-immer';

interface IFLPContext {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    priorityModules: IPriorityModule[];
    priorityModuleTimestamp: string;
    initialPriorityModules: string;
    upcomingModules: IPostLearningPlanResponse | null;
    upcomingModulesPage: number;
    completedModules: IAssignedModulesResponse | null;
    currentModules: IAssignedModulesResponse | null;
    filters: IFilters | null;
    activeFilters: IActiveFilters | null;
};

export const initialState = {
    priorityModules: [],
    priorityModuleTimestamp: '',
    initialPriorityModules: '',
    upcomingModules: null,
    upcomingModulesPage: 1,
    completedModules: null,
    currentModules: null,
    filters: null,
    activeFilters: null,
};

type Action =
    | {
          type: 'ARRANGE_PRIORITY_MODULES';
          payload: {
              dragIndex: number;
              hoverIndex: number;
              item?: IPriorityModule;
          };
      }
    | {
          type: 'ADD_PRIORITY_MODULE';
          payload: IPriorityModule;
      }
    | {
          type: 'SET_PRIORITY_MODULES';
          payload: IPriorityModule[] | null;
      }
    | {
          type: 'SET_PRIORITY_MODULE_TIMESTAMP';
          payload: string;
      }
    | {
          type: 'SET_INITIAL_PRIORITY_MODULES';
          payload: string;
      }
    | {
          type: 'SET_UPCOMING_MODULES';
          payload: {
              pageNumber: number;
              modules: IPostLearningPlanResponse | null;
          };
      }
    | {
          type: 'SET_COMPLETED_MODULES';
          payload: IAssignedModulesResponse | null;
      }
    | {
          type: 'SET_CURRENT_MODULES';
          payload: IAssignedModulesResponse | null;
      }
    | {
          type: 'SET_FILTERS';
          payload: IFilters | null;
      }
    | {
          type: 'SET_ACTIVE_FILTERS';
          payload: IActiveFilters;
      }
    | {
          type: 'REMOVE_PRIORITY_MODULE';
          payload: string;
      }
    | {
          type: 'SET_PRIORITY_MODULES_UNHIDE';
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'ARRANGE_PRIORITY_MODULES': {
            const { item, dragIndex, hoverIndex } = action.payload;

            if (item) {
                state.priorityModules = update(state.priorityModules, {
                    $splice: [[hoverIndex, 0, Object.assign({}, item)]],
                });
            } else {
                state.priorityModules = update(state.priorityModules, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, state.priorityModules[dragIndex]],
                    ],
                });
            }
            break;
        }
        case 'ADD_PRIORITY_MODULE':
            state.priorityModules.push(action.payload);
            break;
        case 'SET_PRIORITY_MODULES':
            state.priorityModules = action.payload ?? [];
            break;
        case 'SET_PRIORITY_MODULE_TIMESTAMP':
            state.priorityModuleTimestamp = action.payload;
            break;
        case 'SET_INITIAL_PRIORITY_MODULES':
            state.initialPriorityModules = action.payload;
            break;
        case 'SET_UPCOMING_MODULES':
            state.upcomingModules = action.payload.modules;
            state.upcomingModulesPage = action.payload.pageNumber;
            break;
        case 'SET_COMPLETED_MODULES':
            state.completedModules = action.payload;
            break;
        case 'SET_CURRENT_MODULES':
            state.currentModules = action.payload;
            break;
        case 'SET_FILTERS':
            state.filters = action.payload;
            break;
        case 'REMOVE_PRIORITY_MODULE':
            state.priorityModules = state.priorityModules.filter(
                (module) => module.id !== action.payload
            );
            break;
        case 'SET_PRIORITY_MODULES_UNHIDE':
            state.priorityModules.forEach((module) => (module.hidden = false));
            break;
        case 'SET_ACTIVE_FILTERS':
            state.activeFilters = action.payload;
            break;
    }
};

export const FLPContext = React.createContext<IFLPContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const FLPProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const FLPContextMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <FLPContext.Provider value={FLPContextMemo}>
            {children}
        </FLPContext.Provider>
    );
};

export const useFLP = (): IFLPContext => useContext(FLPContext);
