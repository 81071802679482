import { Outlet } from 'react-router-dom';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { usePendo } from '../../custom-hooks/usePendo';
import { useUserPilot } from '../../custom-hooks/useUserPilot';

export const ExternamModuleSetup = () => {
    usePendo();
    useUserPilot();

    const gb = useGrowthBook();

    if (gb?.ready) {
        return <Outlet />;
    }

    return null;
};
