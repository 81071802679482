import { ClientConfigItem } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';

export const useIsSelfOnboardingClient = (): boolean => {
    const {
        state: {
            clientConfig: { items },
        },
    } = useAuth();

    return (
        items?.find((item) => item.id === ClientConfigItem.SelfOnboarding)
            ?.state === 1
    );
};
