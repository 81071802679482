import { Autocomplete, Chip as MuiChip, styled, useTheme } from '@mui/material';
import { CogInput, ErrorText } from '../../../styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import {
    ICoachDropdown,
    useUsers,
} from '../../../../custom-providers/UsersProvider';
import { useEffect, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { X as FeatherX } from 'react-feather';
import { Field } from './Field';
import { useIsManagerNotAdmin } from '../../../../custom-hooks/useIsManagerNotAdmin';

const TagWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const Chip = styled(MuiChip)(({ theme }) => ({
    margin: theme.spacing(0, 1, 1, 0),
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 600,
    boxShadow: theme.cogShadows.base,
}));

const X = styled(FeatherX)(() => ({
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

export const SecondaryManagers: React.FC = () => {
    const {
        formState: { errors, dirtyFields },
        setValue,
        control,
        watch,
    } = useFormContext<{
        secondaryManagerUserIds: string[];
        managerUserId: string;
    }>();

    const {
        state: { coaches, enterpriseUser },
    } = useUsers();

    const isManagerNotAdmin = useIsManagerNotAdmin();

    const theme = useTheme();

    const [inputValue, setInputValue] = useState<ICoachDropdown[]>([]);

    const [filteredCoaches, setFilteredCoaches] = useState<ICoachDropdown[]>(
        []
    );

    const watchManagerUserId = watch('managerUserId');

    useEffect(() => {
        if (coaches && enterpriseUser) {
            setFilteredCoaches(
                coaches.filter(
                    (coach) => coach.id !== enterpriseUser.manager?.id
                )
            );
        } else {
            setFilteredCoaches(coaches ?? []);
        }
    }, [coaches, enterpriseUser]);

    useEffect(() => {
        if (watchManagerUserId && coaches) {
            setFilteredCoaches(
                coaches.filter((coach) => coach.id !== watchManagerUserId)
            );
        }
    }, [watchManagerUserId, coaches]);

    useEffect(() => {
        if (enterpriseUser?.secondaryManagers) {
            if (!dirtyFields.managerUserId) {
                setInputValue(
                    enterpriseUser?.secondaryManagers.reduce(
                        (acc: ICoachDropdown[], current) => {
                            const sCoaches = filteredCoaches.filter(
                                (pc) => pc.id === current.id
                            );
                            acc.push(...sCoaches);
                            return acc;
                        },
                        []
                    )
                );
            }
        }
    }, [enterpriseUser, filteredCoaches]);

    useEffect(() => {
        setValue(
            'secondaryManagerUserIds',
            inputValue.map((iv) => iv.id),
            {
                shouldDirty: true,
            }
        );
    }, [inputValue]);

    if (!coaches && isManagerNotAdmin) {
        return (
            <Field label='Secondary managers'>
                <>
                    {enterpriseUser?.secondaryManagers.length ? (
                        enterpriseUser?.secondaryManagers.map((sm) => (
                            <Chip
                                key={sm.id}
                                variant='outlined'
                                label={sm.name}
                            />
                        ))
                    ) : (
                        <CogInput disabled />
                    )}
                </>
            </Field>
        );
    }

    if (!coaches) {
        return null;
    }

    return (
        <Field label='Secondary managers' labelFor='secondaryManagerUserIds'>
            <>
                <TagWrapper>
                    {inputValue.map((val) => (
                        <Chip
                            key={val.id}
                            variant='outlined'
                            label={val.label}
                            icon={
                                <X
                                    color={theme.palette.primary.main}
                                    onClick={() => {
                                        setInputValue((prevVal) =>
                                            prevVal.filter(
                                                (p) => p.id !== val.id
                                            )
                                        );
                                    }}
                                />
                            }
                        />
                    ))}
                </TagWrapper>
                <Controller
                    name='secondaryManagerUserIds'
                    control={control}
                    rules={{
                        validate: (values: string[], formValues) => {
                            return (
                                !values.includes(formValues.managerUserId) ||
                                'Managers and secondary managers cannot be the same.'
                            );
                        },
                    }}
                    render={({ field }) => (
                        <Autocomplete
                            multiple
                            disablePortal
                            id='secondaryManagerUserIds'
                            options={filteredCoaches}
                            getOptionLabel={(option) => option.label}
                            onChange={(_e, val) => setInputValue(val)}
                            value={inputValue}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <CogInput
                                        {...field}
                                        id='secondaryManagerUserIds'
                                        fullWidth
                                        hasError={
                                            !!errors.secondaryManagerUserIds
                                        }
                                        inputProps={params.inputProps}
                                    />
                                </div>
                            )}
                        />
                    )}
                />

                <ErrorMessage
                    errors={errors}
                    name='secondaryManagerUserIds'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
