import { styled } from '@mui/material';

const OuterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: '0 auto',
    alignItems: 'center',
    minHeight: 'calc(100vh - 16px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));

const InnerWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    width: 550,
    maxWidth: 550,
    padding: theme.spacing(3, 3, 0),
    border: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down(744)]: {
        width: '100%',
    },
}));

interface IOwnProps {
    children: React.ReactElement;
}

const OnboardingLayout: React.FC<IOwnProps> = ({ children }) => {
    return (
        <OuterWrapper>
            <InnerWrapper>{children}</InnerWrapper>
        </OuterWrapper>
    );
};

export default OnboardingLayout;
