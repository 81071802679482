import { styled } from '@mui/material';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { pxToRem } from '../../../../utils/style-functions';
import { animate } from './LoadingAnimation';

const KeyValue = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: pxToRem(12),
    lineHeight: '150%',
}));

export const KeyColor = styled('div', {
    ...shouldForwardProps('bgColor'),
})<{ bgColor?: string }>(({ bgColor }) => ({
    height: 16,
    width: 16,
    minWidth: 16,
    borderRadius: '2px',
    backgroundColor: bgColor ?? '#7F8FA6',
    marginRight: 14,
}));

const ColorTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '0 1 200px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flex: '1 1 auto',
    },
}));

const KeyTitle = styled('span')(() => ({
    minWidth: 75,
}));

const PercentValue = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

export const LoaderListItem = styled('div', {
    ...shouldForwardProps('width'),
})<{ width?: number }>(({ width }) => ({
    height: '14px',
    width: width ?? 96,
    borderRadius: '100px',
    ...animate('#CFD8E5'),
}));

interface IKeyValuePairProps {
    title?: string;
    color?: string;
    percentageValue?: number;
    remainderValue?: number;
    isLoading?: boolean;
}

export const KeyValuePair = ({
    title,
    color,
    percentageValue = 0,
    remainderValue = 0,
    isLoading,
}: IKeyValuePairProps): JSX.Element => {
    const getPercentageValue = (
        percentVal: number,
        remainderVal: number
    ): string => {
        if (
            (percentVal === 0 && remainderVal === 0) ||
            Number.isNaN(percentVal)
        ) {
            return '0%';
        }

        if (percentVal < 1) {
            return '<1%';
        }

        return `${percentVal}%`;
    };

    if (isLoading) {
        return (
            <KeyValue>
                <ColorTitle>
                    <KeyColor />
                    <LoaderListItem />
                </ColorTitle>
                <LoaderListItem width={24} />
            </KeyValue>
        );
    }

    return (
        <KeyValue>
            <ColorTitle>
                <KeyColor bgColor={color} />
                <KeyTitle>{title}</KeyTitle>
            </ColorTitle>
            {percentageValue !== undefined && (
                <PercentValue>
                    {getPercentageValue(percentageValue, remainderValue)}
                </PercentValue>
            )}
        </KeyValue>
    );
};

export default KeyValuePair;
