import React, { useContext, useMemo } from 'react';

import { IEnterpriseUser } from '../types';
import { IUserFilter } from '../components/main-app/users/FilterDrawer';
import { useImmerReducer } from 'use-immer';

export interface ICoachDropdown {
    label: string;
    id: string;
}

export interface IRoleDetail {
    roleName: string;
    roleIsDisabled: boolean;
    licensesRemaining: string;
}

interface IChampionRoleWarning {
    showWarning: boolean;
    warningOverridden: boolean;
    disableSave: boolean;
}

interface IEnterpriseUserWithProfilePicture extends IEnterpriseUser {
    profilePicture?: string;
}

interface IUsersContext {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    coaches: ICoachDropdown[] | null;
    availableRoles: IRoleDetail[] | null;
    enterpriseUser: IEnterpriseUserWithProfilePicture | null;
    validEmailDomains: string[] | null;
    restrictSelfOnBoardingDomains: boolean;
    activeFilters: IUserFilter[];
    championRoleWarning: IChampionRoleWarning;
};

export const initialState = {
    coaches: null,
    availableRoles: null,
    enterpriseUser: null,
    validEmailDomains: null,
    restrictSelfOnBoardingDomains: false,
    activeFilters: [],
    championRoleWarning: {
        showWarning: false,
        warningOverridden: false,
        disableSave: false,
    },
};

type Action =
    | {
          type: 'SET_COACHES';
          payload: ICoachDropdown[];
      }
    | { type: 'SET_AVAILABLE_ROLES'; payload: IRoleDetail[] }
    | { type: 'SET_VALID_EMAIL_DOMAINS'; payload: string[] }
    | { type: 'SET_RESTRICT_SELF_ONBOARDING_DOMAINS'; payload: boolean }
    | { type: 'ADD_ACTIVE_FILTER'; payload: IUserFilter }
    | { type: 'REMOVE_ACTIVE_FILTER'; payload: IUserFilter }
    | { type: 'CLEAR_ACTIVE_FILTERS' }
    | { type: 'SET_ENTERPRISE_USER'; payload: IEnterpriseUser }
    | { type: 'SET_PROFILE_PICTURE'; payload: string }
    | {
          type: 'SET_CHAMPION_ROLE_WARNING';
          payload: Partial<IChampionRoleWarning>;
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_COACHES':
            state.coaches = action.payload;
            break;
        case 'SET_AVAILABLE_ROLES':
            state.availableRoles = action.payload;
            break;
        case 'SET_VALID_EMAIL_DOMAINS':
            state.validEmailDomains = action.payload;
            break;
        case 'SET_RESTRICT_SELF_ONBOARDING_DOMAINS':
            state.restrictSelfOnBoardingDomains = action.payload;
            break;
        case 'SET_ENTERPRISE_USER':
            state.enterpriseUser = action.payload;
            break;
        case 'SET_PROFILE_PICTURE':
            if (state.enterpriseUser) {
                state.enterpriseUser.profilePicture = action.payload;
            }
            break;
        case 'ADD_ACTIVE_FILTER':
            state.activeFilters.push(action.payload);
            break;
        case 'REMOVE_ACTIVE_FILTER': {
            state.activeFilters = state.activeFilters.filter(
                (filter) => filter.tagName !== action.payload.tagName
            );
            break;
        }
        case 'CLEAR_ACTIVE_FILTERS':
            state.activeFilters = [];
            break;
        case 'SET_CHAMPION_ROLE_WARNING':
            state.championRoleWarning = {
                ...state.championRoleWarning,
                ...action.payload,
            };
            break;
    }
};

export const UsersContext = React.createContext<IUsersContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const UsersProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const UsersContextMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <UsersContext.Provider value={UsersContextMemo}>
            {children}
        </UsersContext.Provider>
    );
};

export const useUsers = (): IUsersContext => useContext(UsersContext);
