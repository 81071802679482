import { EnglishFirstLanguage } from '../types';

export const parseEnglishSecondLanguage = (
    esl: boolean | null | undefined
): EnglishFirstLanguage => {
    if (esl !== null && esl !== undefined) {
        return esl ? 'no' : 'yes';
    }

    return '';
};
