import CommunityEntry from './CommunityEntry';
import CommunityExplainer from './CommunityExplainer';
import { DisplayNameForm } from './DisplayNameForm';
import Guidelines from './Guidelines';
import OnboardingLayout from './OnboardingLayout';
import SubscribedCommunities from './SubscribedCommunities';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';
import { useEffect } from 'react';

export const OnboardingHandler: React.FC = () => {
    const {
        state: { onboardingPage, communityDisplayName },
        dispatch,
    } = useCommunityState();

    useEffect(() => {
        if (!communityDisplayName) {
            dispatch({
                type: 'SET_ONBOARDING_PAGE',
                payload: { onboardingPage: 1 },
            });
        } else {
            dispatch({
                type: 'SET_ONBOARDING_PAGE',
                payload: { onboardingPage: 5 },
            });
        }
    }, [communityDisplayName]);

    switch (onboardingPage) {
        case 1:
            return (
                <OnboardingLayout>
                    <CommunityExplainer />
                </OnboardingLayout>
            );
        case 2:
            return (
                <OnboardingLayout>
                    <Guidelines />
                </OnboardingLayout>
            );
        case 3:
            return (
                <OnboardingLayout>
                    <SubscribedCommunities />
                </OnboardingLayout>
            );
        case 4:
            return (
                <OnboardingLayout>
                    <DisplayNameForm />
                </OnboardingLayout>
            );
        case 5:
            return <CommunityEntry />;
        default:
            return <CommunityEntry />;
    }
};

export default OnboardingHandler;
