import { MinusSquare, PlusSquare } from 'react-feather';
import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { useReportState } from '../../custom-providers/ReportProvider';

const DrawerTitle = styled('div')(({ theme }) => ({
    display: 'grid',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    gridTemplateColumns: '1fr 20px',
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: 16,
    marginBottom: 24,
}));

const DrawerContent = styled('div')(() => ({
    marginBottom: 24,
}));

interface IOwnProps {
    name: string;
    element: JSX.Element;
}

const DrawerItem: React.FC<IOwnProps> = ({ name, element }) => {
    const {
        state: { activeTab },
        dispatch: reportDispatch,
    } = useReportState();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!activeTab) {
            reportDispatch({
                type: 'SET_ACTIVE_TAB',
                payload: { activeTab: name },
            });
        }
        if (activeTab === name) {
            setIsOpen(true);
        }
    }, [activeTab]);

    return (
        <div>
            <DrawerTitle
                onClick={() => {
                    setIsOpen((prevState) => !prevState);
                    reportDispatch({
                        type: 'SET_ACTIVE_TAB',
                        payload: { activeTab: name },
                    });
                }}
            >
                <Typography
                    color='primary'
                    sx={{ fontSize: '1.5rem', fontWeight: 600 }}
                >
                    {name}
                </Typography>
                {isOpen ? <MinusSquare /> : <PlusSquare />}
            </DrawerTitle>
            <DrawerContent hidden={!isOpen}>{element}</DrawerContent>
        </div>
    );
};

export default DrawerItem;
