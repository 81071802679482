import { Typography, styled } from '@mui/material';

import { HeroPageHeader } from '../styled-components/Typography';
import imgEmployeeHero from '../../assets/img/hero-bg.png';
import { pxToRem } from '../../utils/style-functions';

const HeroContainer = styled('div')(({ theme }) => ({
    background: `url(${imgEmployeeHero})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.down(744)]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
}));

const HeroGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 32,
    alignItems: 'center',
    justifyItems: 'center',
    padding: '0 32px 20px',
    [theme.breakpoints.down(900)]: {
        paddingTop: 40,
        gridTemplateColumns: '1fr',
    },
}));

const HeroImg = styled('img')(({ theme }) => ({
    maxWidth: 480,
    width: '100%',
    [theme.breakpoints.down(900)]: {
        display: 'none',
    },
}));

const HeroTextContainer = styled('div')(({ theme }) => ({
    maxWidth: 552,
    [theme.breakpoints.down(744)]: {
        maxWidth: '100%',
    },
}));

const HeroHeading = styled(HeroPageHeader)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down(744)]: {
        marginBottom: theme.spacing(2),
    },
}));

const HeroText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(27),
    },
}));

interface IOwnProps {
    title: string;
    heroImage: string;
    paragraphs: string[];
    supplementElement?: JSX.Element;
}

const Hero: React.FC<IOwnProps> = ({
    title,
    paragraphs,
    heroImage,
    supplementElement,
}) => {
    return (
        <HeroContainer id='top-panel'>
            <HeroGrid>
                <HeroTextContainer>
                    <HeroHeading>{title}</HeroHeading>
                    {paragraphs.map((item) => {
                        return (
                            <HeroText variant='h2' key={item}>
                                {item}
                            </HeroText>
                        );
                    })}
                </HeroTextContainer>
                <HeroImg src={heroImage} alt='image of a smiling person' />
            </HeroGrid>
            {supplementElement}
        </HeroContainer>
    );
};

export default Hero;
