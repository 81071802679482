import { DialogActions, DialogContent, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import CopyToClipboard from 'react-copy-to-clipboard';

const InviteLinkDialogText = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const InviteLinkValue = styled('div')(({ theme }) => ({
    display: 'flex',
    maxWidth: '100%',
    overflowWrap: 'anywhere',
    marginTop: theme.spacing(1),
}));

const InviteLinkCopyButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down(744)]: {
        width: '100%',
    },
}));

interface IOwnProps {
    inviteLink: string;
}

const notCopiedText = 'Copy link';
const copiedText = 'Copied';
const copyAriaLabel = 'Copy invite link to clipboard';

export const OpenInvitationFlow: React.FC<IOwnProps> = ({ inviteLink }) => {
    const [copyButtonText, setCopyButtonText] = useState<string>(notCopiedText);
    const [hasBeenCopied, setHasBeenCopied] = useState<boolean>(false);

    useEffect(() => {
        setCopyButtonText(hasBeenCopied ? copiedText : notCopiedText);
    }, [hasBeenCopied]);

    return (
        <>
            <DialogContent>
                <InviteLinkDialogText>
                    Copy the link below and share with your colleagues to invite
                    them to the Cognassist platform.
                </InviteLinkDialogText>
                <InviteLinkValue>{inviteLink}</InviteLinkValue>
            </DialogContent>
            <DialogActions>
                <CopyToClipboard
                    text={inviteLink}
                    onCopy={() => {
                        setHasBeenCopied(true);
                        setTimeout(() => {
                            setHasBeenCopied(false);
                        }, 2000);
                    }}
                    aria-label={copyAriaLabel}
                >
                    <InviteLinkCopyButton text={copyButtonText} />
                </CopyToClipboard>
            </DialogActions>
        </>
    );
};
