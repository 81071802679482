import { useEffect, useState } from 'react';

const DEFAULT_STATE = {
    passwordLength: false,
    lowercase: false,
    uppercase: false,
    number: false,
};

export const usePasswordStrength = () => {
    const [newPasswordStrength, setNewPasswordStrength] =
        useState(DEFAULT_STATE);
    const [newValidPassword, setNewValidPassword] = useState(false);

    useEffect(() => {
        setNewValidPassword(
            Object.values(newPasswordStrength).every((val) => val === true)
        );
    }, [newPasswordStrength]);

    const newHandlePasswordStrength = (value: string) => {
        setNewPasswordStrength(DEFAULT_STATE);
        const tempPasswordStrength = Object.assign({}, DEFAULT_STATE);

        if (value.length >= 8) {
            tempPasswordStrength.passwordLength = true;
        }

        if (RegExp(/(?=.*[a-z])/).exec(value)) {
            tempPasswordStrength.lowercase = true;
        }

        if (RegExp(/(?=.*[A-Z])/).exec(value)) {
            tempPasswordStrength.uppercase = true;
        }

        if (RegExp(/(?=.*\d)/).exec(value)) {
            tempPasswordStrength.number = true;
        }

        setNewPasswordStrength((prevState) => ({
            ...prevState,
            ...tempPasswordStrength,
        }));
    };

    return {
        newHandlePasswordStrength,
        newPasswordStrength,
        newValidPassword,
    };
};
