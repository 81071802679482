import { Avatar, Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { CombinedUser } from '../Edit';
import { useIsAdmin } from '../../../../custom-hooks/useIsAdmin';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const Wrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
}));

const AvatarWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(3),
}));

interface IOwnProps {
    handleRemoveProfilePictureDialog: (user?: CombinedUser) => void;
}

export const ProfilePicture: React.FC<IOwnProps> = ({
    handleRemoveProfilePictureDialog,
}) => {
    const {
        state: { enterpriseUser },
    } = useUsers();

    const isAdmin = useIsAdmin();

    const removeProfilePicture = () => {
        handleRemoveProfilePictureDialog(enterpriseUser);
    };

    if (!enterpriseUser?.profilePicture || !isAdmin) {
        return null;
    }

    return (
        <Wrapper>
            <Typography variant='h3' sx={{ mb: 3, mt: 4 }}>
                User&apos;s Profile Picture
            </Typography>
            <AvatarWrapper>
                <Avatar
                    variant='rounded'
                    alt={
                        enterpriseUser?.profilePicture
                            ? `${enterpriseUser.firstName} ${enterpriseUser.lastName}`
                            : undefined
                    }
                    src={enterpriseUser?.profilePicture}
                    sx={{ width: 80, height: 80 }}
                />
                <Button
                    text='Remove'
                    color='inherit'
                    onClick={removeProfilePicture}
                />
            </AvatarWrapper>
        </Wrapper>
    );
};
