import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { endpoints } from '../api/endpoints';
import { getBaseUrl } from '../utils/url-config';

export type IAPIError<T extends string = string> = {
    detail: string;
    errors: {
        [key in T]: string[];
    };
    instance: string;
    status: number;
    title: string;
    type: string;
};

export type UseApiFn = <R, T = unknown, E extends string = string>(
    config: AxiosRequestConfig<T>,
    windowMs?: number
) => Promise<{
    error: AxiosError<IAPIError<E>> | Error | null;
    data: AxiosResponse<R>['data'] | null;
}>;

const activeRequests = new Map<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { promise: Promise<any>; timestamp: number }
>();

const getRequestKey = (config: AxiosRequestConfig) => {
    const { method, url, params, data } = config;
    return `${method}:${url}:${JSON.stringify(params)}:${JSON.stringify(data)}`;
};

const isWithinTimeWindow = (timestamp: number, windowMs: number) => {
    return Date.now() - timestamp < windowMs;
};

const setRequestCleanup = (requestKey: string, windowMs: number) => {
    setTimeout(() => {
        activeRequests.delete(requestKey);
    }, windowMs);
};

export const callApi: UseApiFn = async (config, windowMs = 0) => {
    const baseURL = getBaseUrl();

    const requestKey = getRequestKey(config);
    const activeRequest = activeRequests.get(requestKey);

    if (
        activeRequest &&
        isWithinTimeWindow(activeRequest.timestamp, windowMs)
    ) {
        return activeRequest.promise;
    }

    const requestConfig = {
        ...config,
        baseURL,
    };

    const login = async () => {
        const correctPath =
            window.location.pathname === '/'
                ? '/dashboard'
                : window.location.pathname;
        window.location.href = `${baseURL}auth/login?returnUrl=${window.location.origin}/login-redirect?redirectUrl=${correctPath}${window.location.search}`;
    };

    const { getUser } = endpoints();

    const requestPromise = axios
        .request(requestConfig)
        .then((res) => {
            activeRequests.set(requestKey, {
                promise: Promise.resolve({ data: res.data, error: null }),
                timestamp: Date.now(),
            });
            return { data: res.data, error: null };
        })
        .catch((error) => {
            activeRequests.set(requestKey, {
                promise: Promise.resolve({
                    error: error as AxiosError<IAPIError>,
                    data: null,
                }),
                timestamp: Date.now(),
            });

            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 401) {
                    getUser().then((res) => {
                        if (res.data) {
                            if (!res.data.isAuthenticated) {
                                login();
                            }
                        }
                    });
                }
                return {
                    error: error as AxiosError<IAPIError>,
                    data: null,
                };
            }
            return { error: error as Error, data: null };
        });

    activeRequests.set(requestKey, {
        promise: requestPromise,
        timestamp: Date.now(),
    });

    setRequestCleanup(requestKey, windowMs);

    return requestPromise;
};
