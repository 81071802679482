const getApiUrl = () => {
    return `${window.location.origin}/bff/`;
};

export const getBaseUrl = (): string => {
    switch (window.location.hostname) {
        case '127.0.0.1':
            return `http://127.0.0.1:64690/`;
        case 'localhost':
            // return `https://qamy.cognassist.com/bff/`;
            return `http://localhost:64690/`;
        default:
            return getApiUrl();
    }
};
