import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { Link } from 'react-router-dom';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useLogoutUrl } from '../../../custom-hooks/useLogoutUrl';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(0, 3),
    maxWidth: 580,
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down(480)]: {
        maxWidth: 360,
    },
}));

const UnverifiedEmail: React.FC = () => {
    const {
        state: {
            userConfig: { email },
        },
    } = useAuth();

    const { logoutUrl } = useLogoutUrl();
    const { resendVerificationEmail } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const resendHandler = async () => {
        setLoading(true);

        const { error } = await resendVerificationEmail();

        if (error) {
            enqueueSnackbar(
                'Could not send the verification email, please try again.',
                {
                    variant: 'error',
                }
            );
        } else {
            setButtonDisabled(true);
            enqueueSnackbar(
                'A verification email has been successfully sent.',
                {
                    variant: 'success',
                }
            );
        }

        setLoading(false);
    };

    return (
        <Wrapper>
            <Typography variant='h1' mb={3}>
                Please verify your email
            </Typography>
            <Typography variant='h2'>
                You&apos;re almost there! We sent an email to
            </Typography>
            <Typography
                variant='h2'
                sx={{ fontWeight: '600', wordWrap: 'break-word' }}
            >
                {email}
            </Typography>
            <Typography variant='h4' component='h3' my={3}>
                Select the link in the email to complete your signup. If you
                don&apos;t see it, you may need to <b>check your spam</b>{' '}
                folder.
            </Typography>
            <Typography variant='h4' component='h3' mb={4}>
                Still can&apos;t find the email? Try resending it.
            </Typography>
            <Button
                text='Resend verification email'
                onClick={resendHandler}
                loading={loading}
                disabled={buttonDisabled}
            />
            <Typography mt={4}>
                Already verified your email? Click{' '}
                <Link to={logoutUrl}>here</Link> to log in again.
            </Typography>
        </Wrapper>
    );
};

export default UnverifiedEmail;
