import { createStyles, makeStyles } from '@mui/styles';

import NavBar from '../../../components/assessment-report/nav-bar';
import imgWarning from '../../../assets/img/warning.png';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            marginTop: '20vh',
            textAlign: 'center',
            '& h1': {
                marginTop: 24,
                textalign: 'center',
            },
            '& p': {
                marginTop: 24,
                textalign: 'center',
            },
        },
    })
);

export const ErrorWithMessage: React.FC = () => {
    const classes = useStyles();

    const location = useLocation();
    const errorMessage = location.state?.errorMessage;

    return (
        <div>
            <NavBar blank={true} />
            <div className={classes.wrapper}>
                <img src={imgWarning} alt='Triangle with exclaimation point' />
                <h1>Something went wrong</h1>
                <p>{errorMessage}</p>
            </div>
        </div>
    );
};

export default ErrorWithMessage;
