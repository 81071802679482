import {
    ChampionSupportRequestType,
    ISupportRequestResponse,
    SupportRequestStatus,
} from '../../../../types';
import { CircularProgress, Divider, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { LoadingWrapper } from '../ChampionTableSharedComponents';
import { Notes } from './Notes';
import { RequestNote } from '../fields/RequestNote';
import { SupportRequestProperty } from './SupportRequestProperty';
import { UsersDrawer } from '../../users/UsersDrawer';
import { endpoints } from '../../../../api/endpoints';
import { useSnackbar } from 'notistack';

const convertTypeEnumToFriendlyString = (type: ChampionSupportRequestType) => {
    switch (type) {
        case 1:
            return 'For myself';
        case 2:
            return 'For someone else';
        default:
            return '';
    }
};

const InnerWrapper = styled('div')(() => ({
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
}));

const TopContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 4, 3),
    '& div:last-of-type': {
        marginBottom: 0,
    },
}));

const BottomContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 3),
    display: 'grid',
    rowGap: theme.spacing(1),
}));

interface IFormFields {
    note: string;
}

const DEFAULT_FROM_VALUES: IFormFields = {
    note: '',
};

interface IOwnProps {
    activeSupportRequestId?: string;
    handleCloseDrawer: () => void;
    requestDataReload: () => void;
}

export const ChampionSupportRequestDetails: React.FC<IOwnProps> = ({
    activeSupportRequestId,
    handleCloseDrawer,
    requestDataReload,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [createNoteLoading, setCreateNoteLoading] = useState<boolean>(false);
    const [supportRequestData, setSupportRequestData] =
        useState<ISupportRequestResponse>();

    const {
        getSupportRequest,
        patchSupportRequestStatus,
        createChampionSupportRequestNote,
    } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm<IFormFields>({
        defaultValues: DEFAULT_FROM_VALUES,
        mode: 'onChange',
    });

    useEffect(() => {
        if (activeSupportRequestId) {
            requestSupportRequestDetails(activeSupportRequestId);
        }
    }, []);

    const requestSupportRequestDetails = async (supportRequestId: string) => {
        setLoading(true);

        const { data, error } = await getSupportRequest(supportRequestId);

        if (error) {
            setSupportRequestData(undefined);
            showError();
        }

        if (data) {
            setSupportRequestData(data);

            if (data.status == SupportRequestStatus.New) {
                const { error } = await patchSupportRequestStatus(
                    supportRequestId,
                    SupportRequestStatus.Viewed
                );

                if (error) {
                    showError();
                } else {
                    requestDataReload();
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
    };

    const showError = () => {
        enqueueSnackbar(
            'Could not load support request details, please try again.',
            {
                variant: 'error',
            }
        );
    };

    const { handleSubmit, reset } = methods;

    const handleSubmitNote = () => {
        handleSubmit(createNote)();
    };

    const createNote = async (values: IFormFields) => {
        if (!activeSupportRequestId) {
            return;
        }

        setCreateNoteLoading(true);

        const { error } = await createChampionSupportRequestNote(
            activeSupportRequestId,
            values.note
        );

        if (error) {
            enqueueSnackbar('Could not add a new note, please try again.', {
                variant: 'error',
            });
        } else {
            reset();
            requestSupportRequestDetails(activeSupportRequestId);
        }

        setCreateNoteLoading(false);
    };

    const title = supportRequestData
        ? `${supportRequestData.requestedBy.firstName} ${supportRequestData.requestedBy.lastName}`
        : '';

    return (
        <FormProvider {...methods}>
            <UsersDrawer
                title={title}
                id='champion-support-request-details'
                close={handleCloseDrawer}
            >
                <InnerWrapper>
                    {!loading && supportRequestData ? (
                        <>
                            <TopContent>
                                <SupportRequestProperty
                                    label='Who this request is for?'
                                    content={convertTypeEnumToFriendlyString(
                                        supportRequestData.type
                                    )}
                                />
                                <SupportRequestProperty
                                    label='Support request details:'
                                    content={supportRequestData.message ?? ''}
                                />
                                {supportRequestData.notes &&
                                    supportRequestData.notes.length > 0 && (
                                        <Notes
                                            notes={supportRequestData.notes}
                                        />
                                    )}
                            </TopContent>
                            <Divider />
                            <BottomContent>
                                <RequestNote />
                                <Button
                                    text='Submit'
                                    onClick={handleSubmitNote}
                                    loading={createNoteLoading}
                                />
                            </BottomContent>
                        </>
                    ) : (
                        <LoadingWrapper>
                            <CircularProgress />
                        </LoadingWrapper>
                    )}
                </InnerWrapper>
            </UsersDrawer>
        </FormProvider>
    );
};
