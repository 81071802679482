import { useEffect, useState } from 'react';

import Carousel from 'react-material-ui-carousel';
import SignupCarosel1 from '../../../../assets/img/SignupCarosel1.jpg';
import SignupCarosel3 from '../../../../assets/img/SignupCarosel3.jpg';
import noise from '../../../../assets/img/noise.svg';
import { pxToRem } from '../../../../utils/style-functions';
import { styled } from '@mui/material';
import { useDebounce } from '../../../../custom-hooks/useDebounce';

const CarouselWrapper = styled(Carousel)(({ theme }) => ({
    background: `radial-gradient(at center bottom, rgb(95 5 110 / 75%) 10%, rgb(85 95 255 / 75%)  100%), url(${noise})`,
    borderRadius: 20,
    padding: theme.spacing(6),
    height: 720,
    [theme.breakpoints.down(920)]: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        height: 'auto',
    },
}));

const ItemWrapper = styled('div')(() => ({
    height: '100%',
    '& > div': {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
    },
}));

const Heading = styled('h1')(({ theme }) => ({
    color: theme.palette.grey[50],
    fontSize: pxToRem(32),
    lineHeight: pxToRem(48),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(920)]: {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(40),
    },
}));

const Paragraph = styled('p')(({ theme }) => ({
    color: theme.palette.grey[50],
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(920)]: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(26),
    },
}));

const Image = styled('img')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    margin: `auto auto ${theme.spacing(3)}`,
}));

const Quote = styled('div')(({ theme }) => ({
    background: 'rgba(74, 76, 94, 0.4)',
    borderRadius: 20,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(36),
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    margin: `auto auto ${theme.spacing(3)}`,
    [theme.breakpoints.down(920)]: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(28),
    },
}));

interface ICarouselItem {
    title: string;
    text?: string;
    img?: string;
    alt?: string;
    quote?: string;
}

const carouselItems = [
    {
        title: 'Are you ready to increase your neuro-inclusion awareness?',
        text: 'Our certified neuro-inclusion courses will prepare you to support yourself and your colleagues in a neuro-inclusive work environment.',
        img: SignupCarosel1,
        alt: 'person using computer',
    },
    {
        title: 'Our customer, London Ambulance Service said...',
        quote: '"As a result of the Cognassist assessment, training and resources, we have created a safe space for everyone to identify their neuro-differences, cultivate neuro-inclusion and seek support where required."',
    },
    {
        title: 'Understand your cognition in just 30 minutes!',
        text: 'Our world-leading cognitive mapping tool helps you to understand how to work to your strengths.',
        img: SignupCarosel3,
        alt: 'person using computer',
    },
];

const SignupCarousel: React.FC = () => {
    return (
        <CarouselWrapper
            navButtonsAlwaysInvisible={true}
            interval={10000}
            indicatorIconButtonProps={{
                style: {
                    padding: '0 2px',
                    color: '#ffffff',
                    opacity: 0.6,
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: 'white',
                    opacity: 1,
                },
            }}
            indicatorContainerProps={{
                style: {
                    position: 'absolute',
                    bottom: 10,
                    left: 0,
                },
            }}
        >
            {carouselItems.map((item) => (
                <CarouselItem key={item.title} item={item} />
            ))}
        </CarouselWrapper>
    );
};

interface IOwnProps {
    item: ICarouselItem;
}

const CarouselItem: React.FC<IOwnProps> = ({ item }) => {
    const [isSmall, setIsSmall] = useState(window.innerWidth < 920);

    const debounceResize = useDebounce(function handleResize() {
        setIsSmall(window.innerWidth < 920);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    return (
        <ItemWrapper>
            <Heading>{item.title}</Heading>
            {item.text && <Paragraph>{item.text}</Paragraph>}
            {item.quote && <Quote>{item.quote}</Quote>}
            {!isSmall && item.img && <Image src={item.img} alt={item.alt} />}
        </ItemWrapper>
    );
};

export default SignupCarousel;
