export const barColour = (id: number) => {
    switch (id) {
        case 1:
            return '#77C000';
        case 2:
            return '#7458FF';
        case 3:
            return '#408EF5';
        case 4:
            return '#B2D9FF';
        case 5:
            return '#A5E63B';
        case 6:
            return '#66A5F7';
        case 7:
            return '#C1A5FF';
        case 8:
            return '#8CBBF9';
        case 9:
            return '#E0D2FF';
        default:
            return '#7458FF';
    }
};
