import { Banner } from '../Banner';
import { Button } from '@cognassist/react-components';
import reportImg from '../../../../assets/img/report-banner.jpg';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useNavigate } from 'react-router-dom';

const ReportBanner: React.FC = () => {
    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');

    const navigate = useNavigate();

    const handleOpenReport = () => {
        if (featureNewCognitiveMap) {
            return navigate('/cognitive-map');
        }

        return window.open(
            `/learner/assessmentreport?learnerUserId=${userId}`,
            '_blank',
            'noreferrer'
        );
    };

    return (
        <Banner
            text='View your Cognitive Map report and find out more about how you think and learn.'
            image={reportImg}
            actions={
                <Button
                    color='secondary'
                    text='View My Cognitive Map'
                    onClick={handleOpenReport}
                />
            }
        />
    );
};
export default ReportBanner;
