import { Tooltip, Typography, styled } from '@mui/material';

import ProcessingInformationBar from './ProcessingInformationBar';
import { pxToRem } from '../../../../utils/style-functions';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const VisualWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'row wrap',
    gap: theme.spacing(3),
}));

const IndexWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    flexBasis: '100%',
    gap: theme.spacing(3),
}));

const LabelWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: '100%',
    gap: theme.spacing(1),
}));

const Label = styled('p')(({ theme }) => ({
    background: '#F6F7FB',
    padding: theme.spacing(0.75),
    fontSize: pxToRem(18),
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
}));

const ProcessingInformationVisual: React.FC = () => {
    const {
        state: { cognitiveMapV2 },
    } = useCognitiveMap();

    if (!cognitiveMapV2?.staticContent) {
        return null;
    }

    const { processInformationVisual } = cognitiveMapV2.staticContent;

    return (
        <VisualWrapper id='graph-container'>
            <IndexWrapper>
                <LabelWrapper>
                    <Tooltip
                        title={
                            <Typography>
                                {
                                    cognitiveMapV2.staticContent
                                        .processInformationVisual.topLeftTooltip
                                }
                            </Typography>
                        }
                        arrow
                        placement='bottom'
                        enterTouchDelay={0}
                    >
                        <Label>{processInformationVisual?.topLeftTitle}</Label>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography>
                                {
                                    cognitiveMapV2.staticContent
                                        .processInformationVisual
                                        .topRightTooltip
                                }
                            </Typography>
                        }
                        arrow
                        placement='bottom'
                        enterTouchDelay={0}
                    >
                        <Label>{processInformationVisual?.topRightTitle}</Label>
                    </Tooltip>
                </LabelWrapper>
                <ProcessingInformationBar
                    score={
                        processInformationVisual?.scores?.processingBiasZ ?? 0
                    }
                />
            </IndexWrapper>
            <IndexWrapper>
                <LabelWrapper>
                    <Tooltip
                        title={
                            <Typography>
                                {
                                    cognitiveMapV2.staticContent
                                        .processInformationVisual
                                        .bottomLeftTooltip
                                }
                            </Typography>
                        }
                        arrow
                        placement='bottom'
                        enterTouchDelay={0}
                    >
                        <Label>
                            {processInformationVisual?.bottomLeftTitle}
                        </Label>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography>
                                {
                                    cognitiveMapV2.staticContent
                                        .processInformationVisual
                                        .bottomRightTooltip
                                }
                            </Typography>
                        }
                        arrow
                        placement='bottom'
                        enterTouchDelay={0}
                    >
                        <Label>
                            {processInformationVisual?.bottomRightTitle}
                        </Label>
                    </Tooltip>
                </LabelWrapper>
                <ProcessingInformationBar
                    score={
                        processInformationVisual?.scores
                            ?.memoryIndexAdjustedZ ?? 0
                    }
                />
            </IndexWrapper>
        </VisualWrapper>
    );
};

export default ProcessingInformationVisual;
