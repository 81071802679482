import { FormProvider, useForm } from 'react-hook-form';
import { ILegalDocument, ISignupDetails } from '../../../../types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DetailsEntry } from '../../../../components/self-onboarding/DetailsEntry';
import { EmailEntry } from '../../../../components/self-onboarding/EmailEntry';
import Logo from '../../../../assets/img/logo-new.svg';
import SignupCarousel from './SignupCarousel';
import { endpoints } from '../../../../api/endpoints';
import { styled } from '@mui/material';
import { useConfig } from '../../../../custom-providers/ConfigProvider';
import { useSnackbar } from 'notistack';

const Header = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
    boxShadow: '0px 8px 16px 0px #0000000A',
    position: 'relative',
}));

const SignupWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    minHeight: `calc(100vh - ${theme.spacing(15)})`,
    gap: theme.spacing(5),
    display: 'grid',
    gridTemplateColumns: 'minmax(400px, auto) minmax(370px, 650px)',
    alignItems: 'center',
    [theme.breakpoints.down(920)]: {
        minHeight: 0,
        gap: theme.spacing(3),
        gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down(480)]: {
        padding: theme.spacing(2),
        minHeight: 0,
        overflowY: 'inherit',
    },
}));

const MainContentOuter = styled('div')(() => ({
    background: '#F6F7FB',
}));

const MainContentInner = styled('div')(({ theme }) => ({
    height: 'calc(100vh - 110px)',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1440,
    margin: theme.spacing(0, 'auto'),
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down(744)]: {
        height: 'auto',
    },
}));

const FormWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(920)]: {
        paddingLeft: theme.spacing(0),
        maxWidth: '100%',
    },
}));

const SiteLogo = styled('img')(() => ({
    maxWidth: 140,
    alignItems: 'center',
    display: 'flex',
}));

export interface IFormFields {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    termsAndConditions: boolean;
}

export enum PAGE_STATE {
    EMAIL_ENTRY,
    DETAILS_ENTRY,
}

export type SignupDetailsLocal = Omit<
    ISignupDetails,
    'selfOnBoardingDomains'
> & {
    selfOnBoardingDomains: string[];
};

export const Signup = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { getSignupDetails } = endpoints();
    const navigate = useNavigate();
    const { clientId: friendlyClientId } = useParams<{ clientId: string }>();

    const [currentPage, setCurrentPage] = useState<PAGE_STATE>(0);

    const [signupDetails, setSignupDetails] = useState<SignupDetailsLocal>();
    const [legalDocuments, setLegalDocuments] = useState<ILegalDocument[]>([]);

    const { dispatch } = useConfig();

    useEffect(() => {
        document.title = 'Cognassist sign up';
        if (friendlyClientId) {
            getData(friendlyClientId);
        }
    }, [friendlyClientId]);

    const methods = useForm<IFormFields>({
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            termsAndConditions: false,
        },
    });

    const getData = async (id: string) => {
        const { data, error } = await getSignupDetails({
            shortId: id,
        });

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        } else if (data) {
            if (!data.signupDetails.isSelfOnBoardingClient) {
                navigate('/dashboard');
            } else {
                const domains = data.signupDetails.selfOnBoardingDomains
                    ? data.signupDetails.selfOnBoardingDomains
                          .split(',')
                          .map((domain) => domain.toLowerCase())
                    : [];

                setSignupDetails({
                    ...data.signupDetails,
                    selfOnBoardingDomains: domains,
                });

                setLegalDocuments(
                    data.legalDocuments.latestLegalDocuments ?? []
                );

                dispatch({
                    type: 'SET_APP_CONFIG',
                    payload: {
                        ...data.appConfig,
                    },
                });
            }
        }
    };

    if (!signupDetails) {
        return null;
    }

    return (
        <FormProvider {...methods}>
            <Header>
                <SiteLogo src={Logo} alt='Cognassist logo' />
            </Header>

            <MainContentOuter>
                <MainContentInner>
                    <SignupWrapper>
                        <FormWrapper>
                            {currentPage === PAGE_STATE.EMAIL_ENTRY ? (
                                <EmailEntry
                                    setCurrentPage={setCurrentPage}
                                    signupDetails={signupDetails}
                                />
                            ) : (
                                <DetailsEntry
                                    signupDetails={signupDetails}
                                    legalDocuments={legalDocuments}
                                />
                            )}
                        </FormWrapper>
                        <SignupCarousel />
                    </SignupWrapper>
                </MainContentInner>
            </MainContentOuter>
        </FormProvider>
    );
};
