import {
    CloseIcon,
    CloseIconWrapper,
    Header,
} from '../ChampionDialogSharedComponents';
import {
    Dialog,
    Avatar as MuiAvatar,
    DialogContent as MuiDialogContent,
    Skeleton,
    Typography,
    styled,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { IChampionProfileResponse } from '../../../../types';
import { endpoints } from '../../../../api/endpoints';
import { useSnackbar } from 'notistack';

const MAX_WIDTH = 340;

const DialogContent = styled(MuiDialogContent)(() => ({
    padding: 0,
}));

const PersonalInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    rowGap: theme.spacing(3),
    padding: theme.spacing(3),
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: '#D9D9D9',
    width: '100%',
    height: 'auto',
    '& .MuiAvatar-fallback': {
        width: '100%',
        height: 'auto',
    },
}));

const SkeletonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(3),
    paddingBottom: theme.spacing(3),
}));

interface IOwnProps {
    championUserId: string | null;
    open: boolean;
    onClose: () => void;
}

export const ChampionDetailsDialog: React.FC<IOwnProps> = ({
    championUserId,
    open,
    onClose,
}) => {
    const { getChampionProfile, getProfilePicture } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [championProfile, setChampionProfile] =
        useState<IChampionProfileResponse>();
    const [profilePicture, setProfilePicture] = useState<string>();

    useEffect(() => {
        if (championUserId) {
            requestChampionProfile(championUserId);
        }
    }, [championUserId]);

    const requestProfilePicture = async (fileName: string) => {
        const { data, error } = await getProfilePicture(fileName);

        if (error) {
            setProfilePicture(undefined);
            enqueueSnackbar(
                `Could not retrieve the champion's profile picture, please try again.`,
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setProfilePicture(URL.createObjectURL(data));
        }

        setLoading(false);
    };

    const requestChampionProfile = async (championUserId: string) => {
        setLoading(true);

        const { data, error } = await getChampionProfile(championUserId);

        if (error) {
            setChampionProfile(undefined);
            enqueueSnackbar(
                `Could not load the champion's profile, please try again.`,
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setChampionProfile(data);

            if (data.profilePictureFileName) {
                await requestProfilePicture(data.profilePictureFileName);
            } else {
                setLoading(false);
            }
        }
    };

    const handleDialogClose = () => {
        if (profilePicture) {
            URL.revokeObjectURL(profilePicture);
        }

        onClose();
    };

    const name = `${championProfile?.firstName} ${championProfile?.lastName}`;

    if (!championUserId) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            maxWidth={false}
            PaperProps={{ sx: { width: '100%', maxWidth: MAX_WIDTH } }}
            aria-labelledby='champion-profile-name'
            aria-describedby='champion-profile-bio'
        >
            <DialogContent>
                {loading ? (
                    <SkeletonWrapper>
                        <Header>
                            <CloseIconWrapper onClick={handleDialogClose}>
                                <CloseIcon size={19} strokeWidth={3} />
                            </CloseIconWrapper>
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={340}
                            />
                        </Header>
                        <Skeleton
                            variant='rounded'
                            animation='wave'
                            width='80%'
                            height={36}
                        />
                        <Skeleton
                            variant='rounded'
                            animation='wave'
                            width='80%'
                            height={72}
                        />
                    </SkeletonWrapper>
                ) : (
                    <>
                        <Header>
                            <CloseIconWrapper onClick={handleDialogClose}>
                                <CloseIcon size={19} strokeWidth={3} />
                            </CloseIconWrapper>
                            <Avatar
                                variant='square'
                                alt={profilePicture ? name : undefined}
                                src={profilePicture}
                            />
                        </Header>
                        <PersonalInfo>
                            <Typography variant='h2' id='champion-profile-name'>
                                {name}
                            </Typography>
                            {championProfile?.bio && (
                                <Typography id='champion-profile-bio'>
                                    {championProfile.bio}
                                </Typography>
                            )}
                        </PersonalInfo>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};
