import {
    Back,
    Forward,
    LoadingWrapper,
    StrategiesCard,
    StrategiesImage,
    StrategiesImageWrapper,
    StrategiesTitle,
} from '../../cognitive-map/strategies-components';
import {
    CircularProgress,
    IconButton as MuiIconButton,
    styled,
} from '@mui/material';

import { CaiStrategyButton } from '../../cognitive-map/CaiStrategyButton';
import { CogMapV2Tab } from '../../../../pages/authenticated/cognitive-map-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICognitiveDomain } from '../../../../types';
import { PinkOutlineButton } from '../../PinkOutlineButton';
import { UserStrategyStatusTag } from '../../cai/UserStrategyStatusTag';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { useCarousel } from '../../../../custom-hooks/useCarousel';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useNavigate } from 'react-router-dom';
import { useStrategyCard } from '../../../../custom-hooks/useStrategyCard';

const MAX_CARD_WIDTH = 350;

const CardWrapper = styled('div')(() => ({
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    display: 'flex',
}));

const StrategiesCardWrapper = styled('div')(({ theme }) => ({
    transition: 'transform 0.4s ease',
    width: '100%',
    flex: '0 0 auto',
    maxWidth: MAX_CARD_WIDTH,
    padding: theme.spacing(0, 1, 3, 2),
}));

const DotsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 2, 2, 2),
}));

const IconButton = styled(MuiIconButton)(() => ({
    padding: 0,
}));

const CarouselControls = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2, 3, 2),
}));

const FAIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 25,
    padding: '2px 6px',
    height: 16,
}));

interface IOwnProps {
    domain: ICognitiveDomain;
}

export const DomainStrategies: React.FC<IOwnProps> = ({ domain }) => {
    const navigate = useNavigate();
    const { dispatch } = useCognitiveMap();

    const caiStrategiesEnabled = useFeatureIsOn('cai-strategies');

    const { carouselNext, carouselPrev, Dots, ref, cardStyleProps } =
        useCarousel(domain.strategies.length, MAX_CARD_WIDTH);

    const { loading, handleClickStrategyCard } = useStrategyCard();

    const goToStrategiesTab = () => {
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: 0,
        });
        navigate(`/cognitive-map?tab=${CogMapV2Tab.Strategies}`, {
            state: { navigatedDomainId: domain.domainId },
        });
    };

    const showControls = domain.strategies.length > 1;

    return (
        <>
            <CardWrapper ref={ref}>
                {domain.strategies.map((s, i) => (
                    <StrategiesCardWrapper
                        key={s.courseUrl}
                        sx={cardStyleProps(i)}
                    >
                        <StrategiesCard
                            onClick={
                                caiStrategiesEnabled
                                    ? undefined
                                    : (e) =>
                                          handleClickStrategyCard(
                                              e,
                                              s.lmsSourceId
                                          )
                            }
                        >
                            <StrategiesImageWrapper>
                                <StrategiesImage
                                    className='strategiesImage'
                                    src={s.imageUrl}
                                    alt=''
                                />
                                {loading && (
                                    <LoadingWrapper>
                                        <CircularProgress />
                                    </LoadingWrapper>
                                )}
                            </StrategiesImageWrapper>
                            {caiStrategiesEnabled && (
                                <UserStrategyStatusTag
                                    status={s.userStrategyStatus}
                                />
                            )}
                            <StrategiesTitle className='strategiesTitle'>
                                {s.title}
                            </StrategiesTitle>
                            {caiStrategiesEnabled && (
                                <CaiStrategyButton course={s} />
                            )}
                        </StrategiesCard>
                    </StrategiesCardWrapper>
                ))}
            </CardWrapper>
            <CarouselControls id='carousel-controls'>
                {showControls && (
                    <Back>
                        <IconButton
                            onClick={carouselPrev}
                            aria-label='carousel back'
                        >
                            <FAIcon
                                icon={byPrefixAndName.fas['chevron-left']}
                            />
                        </IconButton>
                    </Back>
                )}
                <PinkOutlineButton
                    text='View more'
                    fullWidth
                    onClick={goToStrategiesTab}
                    endIcon={
                        <FontAwesomeIcon
                            icon={byPrefixAndName.far['arrow-right']}
                        />
                    }
                />
                {showControls && (
                    <Forward>
                        <IconButton
                            onClick={carouselNext}
                            aria-label='carousel forward'
                        >
                            <FAIcon
                                icon={byPrefixAndName.fas['chevron-right']}
                            />
                        </IconButton>
                    </Forward>
                )}
            </CarouselControls>
            <DotsWrapper>{showControls && <Dots />}</DotsWrapper>
        </>
    );
};
