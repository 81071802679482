import { CogInput, ErrorText } from '../../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../../pre-assessment/Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

interface IOwnProps {
    isSmall?: boolean;
}

export const LastName: React.FC<IOwnProps> = ({ isSmall }) => {
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext<{ lastName: string }>();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('lastName', {
        required: true,
        maxLength: {
            value: 230,
            message:
                'You have gone over the 230 character limit for this field, please reduce it and try again.',
        },
    });

    const lastName = watch('lastName');

    return (
        <Field
            label='Last name'
            labelFor='lastName'
            required
            hasError={!!errors.lastName}
            isSmall={isSmall}
        >
            <>
                <CogInput
                    {...rest}
                    defaultValue={lastName}
                    id='lastName'
                    fullWidth
                    hasError={!!errors.lastName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
