import {
    ICognitiveMapV2,
    IProcessingBiasData,
    IScreenSize,
} from '../../../types';

import { pxToRem } from '../../../utils/style-functions';

export const getScreenSizeFromWidth = (width: number): IScreenSize => ({
    isMobile: width < 480,
    isTablet: width >= 480 && width <= 890,
});

export const getFontSize = (isMobile: boolean) =>
    isMobile ? pxToRem(18) : pxToRem(24);

export const getLineHeight = (isMobile: boolean) =>
    isMobile ? pxToRem(24) : pxToRem(36);

export const mapCogMapV2Data = (
    cognitiveMapData: ICognitiveMapV2,
    processingBiasData: IProcessingBiasData
): ICognitiveMapV2 => {
    return {
        domains: [...cognitiveMapData.domains],
        staticContent: {
            ...cognitiveMapData.staticContent,
            processInformationVisual: {
                ...cognitiveMapData.staticContent.processInformationVisual,
                scores: {
                    processingBiasZ: processingBiasData.processingBiasZ,
                    memoryIndexAdjustedZ:
                        processingBiasData.memoryIndexAdjustedZ,
                },
            },
        },
        cogType: { ...cognitiveMapData.cogType },
    };
};
