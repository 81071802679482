import {
    AssessmentStatus as AssessmentStatusEnum,
    UserFilterType,
} from '../../../../types';

import { AutoCompleteInput } from '../styled-components';
import { Autocomplete } from '@mui/material';
import { Field } from '../fields/Field';
import { IUserFilter } from '../FilterDrawer';
import { useUserFilters } from '../../../../custom-hooks/useUserFilters';

const statuses: IUserFilter[] = [
    {
        name: 'Pending',
        value: AssessmentStatusEnum.Pending,
        tagName: 'Assessment status: Pending',
        filterType: UserFilterType.AssessmentStatus,
    },
    {
        name: 'Started',
        value: AssessmentStatusEnum.Started,
        tagName: 'Assessment status: Started',
        filterType: UserFilterType.AssessmentStatus,
    },
    {
        name: 'Complete',
        value: AssessmentStatusEnum.Complete,
        tagName: 'Assessment status: Complete',
        filterType: UserFilterType.AssessmentStatus,
    },
];

export const AssessmentStatus = () => {
    const { inputHandler, inputValue } = useUserFilters({
        filterType: UserFilterType.AssessmentStatus,
    });
    return (
        <Field label='Assessment status' labelFor='assessment-status'>
            <Autocomplete
                multiple
                disablePortal
                id='assessment-status'
                options={statuses ?? []}
                getOptionLabel={(option) => option.name}
                onChange={inputHandler}
                value={inputValue}
                filterSelectedOptions
                openOnFocus
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <AutoCompleteInput
                            inputProps={params.inputProps}
                            id='assessment-status'
                        />
                    </div>
                )}
            />
        </Field>
    );
};
