import { ICoach, UserFilterType } from '../../../../types';
import { useEffect, useState } from 'react';

import { AutoCompleteInput } from '../styled-components';
import { Autocomplete } from '@mui/material';
import { Field } from '../fields/Field';
import { IUserFilter } from '../FilterDrawer';
import { useUserFilters } from '../../../../custom-hooks/useUserFilters';

interface IOwnProps {
    coaches: ICoach[];
}

export const Manager: React.FC<IOwnProps> = ({ coaches }) => {
    const [filteredCoaches, setFilteredCoaches] = useState<IUserFilter[]>([]);
    const { inputHandler, inputValue } = useUserFilters({
        filterType: UserFilterType.Manager,
    });
    useEffect(() => {
        if (coaches) {
            setFilteredCoaches(
                coaches.map((coach) => {
                    return {
                        name: `${coach.firstName} ${coach.lastName}`,
                        value: coach.coachUserId,
                        tagName: `Manager: ${coach.firstName} ${coach.lastName}`,
                        filterType: UserFilterType.Manager,
                    };
                })
            );
        }
    }, [coaches]);

    return (
        <Field label='Manager' labelFor='manager'>
            <Autocomplete
                multiple
                disablePortal
                id='manager'
                options={filteredCoaches ?? []}
                getOptionLabel={(option) => option.name}
                onChange={inputHandler}
                value={inputValue}
                filterSelectedOptions
                openOnFocus
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <AutoCompleteInput
                            id='manager'
                            inputProps={params.inputProps}
                        />
                    </div>
                )}
            />
        </Field>
    );
};
