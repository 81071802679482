import { Theme, Typography, styled, useTheme } from '@mui/material';

import { IReportMenuItem } from '../../../types';
import { NavLink } from 'react-router-dom';
import { pxToRem } from '../../../utils/style-functions';

const mobileMenuItemStyles = (theme: Theme) => ({
    margin: theme.spacing(0, 0, 3),
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.text.primary,
});

const MobileMenuItemLink = styled(NavLink)(({ theme }) =>
    mobileMenuItemStyles(theme)
);

const MobileMenuItemAnchor = styled('a')(({ theme }) =>
    mobileMenuItemStyles(theme)
);

const MobileDeepDiveLinks = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: theme.spacing(1, 0, 3),
    '& a': {
        cursor: 'pointer',
        margin: 0,
        textDecoration: 'none',
        padding: theme.spacing(1, 0, 1, 3),
        borderLeft: `3px solid ${theme.palette.grey[300]}`,
    },
}));

interface IMobileNavLink {
    link: IReportMenuItem;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileNavBarLink: React.FC<IMobileNavLink> = ({
    link,
    setDrawerOpen,
}) => {
    const theme = useTheme();
    switch (link.type) {
        case 'navlink':
            return (
                <MobileMenuItemLink
                    to={link.location}
                    onClick={() => setDrawerOpen(false)}
                    style={({ isActive }) => ({
                        fontWeight: isActive ? 600 : 400,
                        color: isActive
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                    })}
                    end
                >
                    {link.displayText}
                </MobileMenuItemLink>
            );
        case 'anchor':
            return (
                <MobileMenuItemAnchor
                    onClick={() => setDrawerOpen(false)}
                    href={link.location}
                    target={link.target}
                    rel={link.rel}
                    aria-label={link.ariaLabel}
                >
                    {link.displayText}
                </MobileMenuItemAnchor>
            );
        case 'dropdown':
            return (
                <>
                    <Typography variant='h5'>{link.displayText}</Typography>
                    <MobileDeepDiveLinks>
                        {link.subMenu.map((link) => {
                            return (
                                <MobileMenuItemLink
                                    onClick={() => setDrawerOpen(false)}
                                    key={link.location}
                                    to={link.location}
                                    style={({ isActive }) => ({
                                        borderLeftColor: isActive
                                            ? theme.palette.primary.main
                                            : theme.palette.grey[200],
                                        fontWeight: isActive ? 600 : 400,
                                        color: isActive
                                            ? theme.palette.primary.main
                                            : theme.palette.text.primary,
                                    })}
                                >
                                    {link.displayText}
                                </MobileMenuItemLink>
                            );
                        })}
                    </MobileDeepDiveLinks>
                </>
            );
        default:
            return null;
    }
};

export default MobileNavBarLink;
