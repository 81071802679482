import { InputProps, styled } from '@mui/material';

import { ChevronDown } from 'react-feather';
import { Input } from '@cognassist/react-components';

export const AutoCompleteInput = styled((props: InputProps) => (
    <Input {...props} fullWidth endAdornment={<ChevronDown />} />
))(({ theme }) => ({
    color: theme.palette.text.primary,
    cursor: 'pointer',
}));
