import { useProfile } from '../components/main-app/pre-assessment/ProfileProvider';

export const useProfileFocus = () => {
    const { dispatch, negativeTopOffset } = useProfile();

    const setFocus = (e: Element, key: string) => {
        const container = document.getElementById('MainContent');
        const scrollTop = container?.scrollTop;
        const elementRect = e.getBoundingClientRect();
        const newOffsetTop =
            (scrollTop ?? 0) + elementRect.top - negativeTopOffset;

        dispatch({
            type: 'SET_CURRENT_OFFSET_TOP',
            payload: newOffsetTop,
        });
        dispatch({
            type: 'SET_CURRENT_BOTTOM_GAP',
            payload: window.innerHeight - elementRect.bottom,
        });
        dispatch({
            type: 'SET_CONTENT_BY_KEY',
            payload: key || null,
        });
    };

    const focusHandler = (
        e: React.FocusEvent<Element, Element>,
        bubbleKey: string
    ) => {
        dispatch({ type: 'RESET_FIELDS' });
        const element = e.target;
        setFocus(element, bubbleKey);
    };

    const blurHandler = () => {
        dispatch({ type: 'RESET_FIELDS' });
    };

    return {
        onFocus: focusHandler,
        onBlur: blurHandler,
        setFocus,
    };
};
