import { Userpilot } from 'userpilot';
import { endpoints } from '../api/endpoints';
import { useGrabLibraryLink } from './useGrabLibraryLink';
import { useState } from 'react';

export const useStrategyCard = () => {
    const { grabLibraryLink } = useGrabLibraryLink();
    const [loading, setLoading] = useState<boolean>(false);
    const { enrolUserToCourse } = endpoints();

    const handleClickStrategyCard = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        lmsSourceId: string
    ) => {
        if (loading) {
            return;
        }

        setLoading(true);
        const { error } = await enrolUserToCourse(lmsSourceId);

        if (!error) {
            Userpilot.track('Accessed a Course', { courseId: lmsSourceId });
            Userpilot.track(`Accessed - ${lmsSourceId}`);
            grabLibraryLink(e, `/course/${lmsSourceId}`);
        }

        setLoading(false);
    };

    return {
        handleClickStrategyCard,
        loading,
    };
};
