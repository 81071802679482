import { Graph } from './Graph';
import { IProcessingBiasData } from '../../../../types';
import Legend from './Legend';
import { styled } from '@mui/material';
import { useReportState } from '../../../../custom-providers/ReportProvider';

const refAreas = [
    {
        title: 'Significant Language and Verbal bias',
        color: '#9D8DDC',
        opacity: 0.75,
        xStart: -3,
        xEnd: -2,
        twoColumnOrder: 1,
        oneColumnOrder: 1,
    },
    {
        title: 'Moderate Language and Verbal bias',
        color: '#CEC6F0',
        opacity: 1,
        xStart: -2,
        xEnd: -1,
        twoColumnOrder: 3,
        oneColumnOrder: 2,
    },
    {
        title: 'Mild Language and Verbal bias',
        color: '#E9E5F8',
        opacity: 1,
        xStart: -0.5,
        xEnd: -1,
        twoColumnOrder: 5,
        oneColumnOrder: 3,
    },
    {
        title: 'No bias',
        color: '#FFFFFF',
        opacity: 1,
        xStart: -0.5,
        xEnd: 0.5,
        twoColumnOrder: 7,
        oneColumnOrder: 4,
    },
    {
        title: 'Mild Visual and Fluid bias',
        color: '#C1EBFC',
        opacity: 1,
        xStart: 0.5,
        xEnd: 1,
        twoColumnOrder: 2,
        oneColumnOrder: 5,
    },
    {
        title: 'Moderate Visual and Fluid bias',
        color: '#8AD5F4',
        opacity: 1,
        xStart: 1,
        xEnd: 2,
        twoColumnOrder: 4,
        oneColumnOrder: 6,
    },
    {
        title: 'Significant Visual and Fluid bias',
        color: '#3FBAED',
        opacity: 0.85,
        xStart: 2,
        xEnd: 3,
        twoColumnOrder: 6,
        oneColumnOrder: 7,
    },
];

const GraphContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    gap: '41px 82px',
    [theme.breakpoints.down(1102)]: {
        padding: theme.spacing(4, 1, 4),
    },
}));

interface IOwnProps {
    data: IProcessingBiasData;
}

export const ProcessingBiasGraph: React.FC<IOwnProps> = ({ data }) => {
    const {
        state: { isViewingAsManager, learnerName },
    } = useReportState();

    return (
        <GraphContainer>
            <Graph
                name={isViewingAsManager ? learnerName : 'You'}
                graphData={data}
                areas={refAreas}
            />

            <Legend
                name={isViewingAsManager ? learnerName : 'You'}
                areas={refAreas}
            />
        </GraphContainer>
    );
};

export default ProcessingBiasGraph;
