import { InputBase, TextField, styled } from '@mui/material';

import { shouldForwardProps } from '../../utils/shouldForwardProp';

export const Tooltips = styled('div')(() => ({
    flex: '1 1 auto',
    position: 'relative',
}));

export const FormFields = styled('div')(() => ({
    flex: '0 1 744px',
}));

export const CogInput = styled(InputBase, {
    ...shouldForwardProps('hasError', 'useThinnerBorder'),
})<{ hasError?: boolean; useThinnerBorder?: boolean }>(({
    hasError,
    useThinnerBorder,
    theme,
}) => {
    const borderWidth = useThinnerBorder ? 1 : 2;

    return {
        width: '100%',
        '& .MuiInputBase-input': {
            padding: '10px 14px',
            backgroundColor: theme.palette.common.white,
            borderRadius: useThinnerBorder
                ? theme.spacing(1)
                : theme.spacing(0.5),
            border: hasError
                ? `${borderWidth}px solid ${theme.palette.error.main}`
                : `${borderWidth}px solid ${theme.palette.grey[300]}`,
            '&:focus': {
                borderColor: hasError ? theme.palette.error.main : '#039FE0',
            },
        },
    };
});

export const TextArea = styled(TextField, {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: hasError
                ? `1px solid ${theme.palette.error.main}`
                : `1px solid ${theme.palette.grey[300]}`,
        },
        '&.Mui-focused fieldset': {
            borderColor: hasError ? theme.palette.error.main : '#039FE0',
            borderWidth: 1,
        },
    },
}));

export const ErrorText = styled('p')(({ theme }) => ({
    marginTop: 4,
    color: theme.palette.error.main,
}));
