import { ChevronDown, ChevronUp } from 'react-feather';
import {
    FormControlLabel as MuiFormControlLabel,
    Typography,
    styled,
} from '@mui/material';

import { CSSTransition } from 'react-transition-group';
import { Checkbox } from '@cognassist/react-components';
import { IFilter } from '../../types';

const FilterDropdownStyle = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    height: '44px',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.primary,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
}));

const DropdownBox = styled('div')(() => ({
    height: 170,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflowY: 'scroll',
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(0),
    padding: theme.spacing(2, 0, 0),
}));

interface IFilterDropdownProps {
    name: string;
    showDropdown: boolean;
    setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
    dropdownItems: IFilter[];
    checkboxHandler: (param: IFilter, name: string) => void;
    activeFilters: string[];
}

export const FilterDropdown: React.FC<IFilterDropdownProps> = ({
    name,
    showDropdown,
    setShowDropdown,
    dropdownItems,
    checkboxHandler,
    activeFilters,
}) => {
    return (
        <>
            <FilterDropdownStyle onClick={() => setShowDropdown(!showDropdown)}>
                <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    {name}
                </Typography>
                {showDropdown ? <ChevronUp /> : <ChevronDown />}
            </FilterDropdownStyle>
            <CSSTransition
                in={showDropdown}
                timeout={500}
                classNames='dropdown'
                unmountOnExit
            >
                <DropdownBox>
                    {dropdownItems.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            label={item.name}
                            control={
                                <Checkbox
                                    onChange={() => checkboxHandler(item, name)}
                                    checked={activeFilters.includes(item.id)}
                                />
                            }
                        />
                    ))}
                </DropdownBox>
            </CSSTransition>
        </>
    );
};
