import { Button } from '@cognassist/react-components';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.common.white,
}));

interface IOwnProps {
    save: () => void;
    saveText?: string;
    disableSave?: boolean;
    cancel?: () => void;
    loading?: boolean;
}

export const SaveCancel: React.FC<IOwnProps> = ({
    save,
    cancel,
    loading,
    disableSave,
    saveText = 'Save',
}) => {
    return (
        <Wrapper>
            {cancel && (
                <Button
                    text='Cancel'
                    color='inherit'
                    fullWidth
                    sx={{ mr: 1 }}
                    onClick={cancel}
                />
            )}
            <Button
                text={saveText}
                fullWidth
                sx={{ ml: 1 }}
                onClick={save}
                loading={loading}
                disabled={disableSave}
            />
        </Wrapper>
    );
};
