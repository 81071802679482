import { Container, GraphContainer, Section } from './styled-components';
import { Typography, alpha, styled, useTheme } from '@mui/material';

import BannerLink from '../../components/assessment-report/BannerLink';
import DiversityIndexGraph from './graphs/diversity-index-graph';
import { IDiversityIndexData } from '../../types';
import { InfoIcon } from '../../components/styled-components';
import man from '../../assets/img/banner-man.jpg';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useReportState } from '../../custom-providers/ReportProvider';

const DiversityIndexSection = styled(GraphContainer)(({ theme }) => ({
    display: 'grid',
    gridGap: 32,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(900)]: {
        gridTemplateColumns: '1.1fr 0.9fr',
    },
}));

const InfoBox = styled('div', {
    ...shouldForwardProps('isDesktop'),
})<{ isDesktop?: boolean }>(({ isDesktop, theme }) => ({
    background: alpha(theme.palette.primary.main, 0.1),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: isDesktop ? 'flex' : 'none',
    [theme.breakpoints.down(900)]: {
        marginTop: theme.spacing(4),
        display: isDesktop ? 'none' : 'flex',
    },
}));

interface IOwnProps {
    graphData: IDiversityIndexData;
}

export const DiversityIndex: React.FC<IOwnProps> = ({ graphData }) => {
    const {
        state: { learnerName, isViewingAsManager },
    } = useReportState();

    return (
        <>
            <Container id='diversity-index'>
                <Section>
                    <Typography variant='h1' sx={{ fontSize: '2rem', mb: 5 }}>
                        What is the Diversity Index?
                    </Typography>
                    <Typography sx={{ mb: 4 }}>
                        Everyone who takes the Cognassist cognitive assessment
                        is given a Diversity Index, represented by a number
                        between 0 and 100. This number is calculated based on
                        things like the range between{' '}
                        {isViewingAsManager ? "someone's" : 'your'} highest and
                        lowest scoring cognitive domains, and also the degree of
                        difference between domains that are often similar.
                    </Typography>
                    <Typography>
                        The Diversity Index helps expand our understanding of
                        how unique someone&apos;s overall thinking and learning
                        is, and is a good indication of cognitive diversity.
                    </Typography>
                </Section>
                <Section>
                    <DiversityIndexSection>
                        <div>
                            <Section>
                                <Typography
                                    variant='h1'
                                    sx={{ fontSize: '2rem', mb: 4 }}
                                >
                                    {isViewingAsManager
                                        ? `${learnerName}'s`
                                        : 'Your'}{' '}
                                    Diversity Index is{' '}
                                    {graphData.diversityIndexScore}
                                </Typography>
                                <Typography
                                    color='primary'
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '1.2rem',
                                        mb: 4,
                                    }}
                                >
                                    This graph shows{' '}
                                    {isViewingAsManager
                                        ? `${learnerName}'s`
                                        : 'your'}{' '}
                                    {!isViewingAsManager && 'unique'} Diversity
                                    Index and how much or how little{' '}
                                    {isViewingAsManager
                                        ? 'their'
                                        : 'your entire'}{' '}
                                    profile differs from the rest of people who
                                    have taken our assessment.{' '}
                                    {isViewingAsManager &&
                                        `It can give you a deeper
                                        understanding of how ${learnerName} should approach
                                        tasks and challenges at work.`}
                                </Typography>

                                {!isViewingAsManager && (
                                    <Typography sx={{ mb: 4 }}>
                                        Your Diversity Index describes how much
                                        diversity is in your cognitive profile,
                                        and can give you a deeper understanding
                                        of how you approach tasks and challenges
                                        at work.
                                    </Typography>
                                )}
                                <Typography sx={{ mb: 4 }}>
                                    The further to the right of the graph{' '}
                                    {isViewingAsManager ? "someone's" : 'your'}{' '}
                                    Diversity Index is, the more diverse{' '}
                                    {isViewingAsManager ? 'their' : 'your'}{' '}
                                    cognitive profile. A Diversity Index towards
                                    the left of the graph would indicate a more
                                    typical cognitive profile.
                                </Typography>
                                {!isViewingAsManager && (
                                    <Typography>
                                        Remember, having a low or a high
                                        Diversity Index is absolutely nothing to
                                        worry about. There&apos;s no
                                        &apos;perfect&apos; number.
                                    </Typography>
                                )}
                            </Section>
                            <Info
                                isDesktop={true}
                                text={graphData.diversityIndexText}
                            />
                        </div>
                        <DiversityIndexGraph
                            diversityIndex={graphData.diversityIndexScore}
                        />
                        <Info
                            isDesktop={false}
                            text={graphData.diversityIndexText}
                        />
                    </DiversityIndexSection>
                </Section>
            </Container>
            <BannerLink
                title='Want to know more about Diversity Index?'
                body='Find out what the Diversity Index is and how it expands our understanding of our thinking and learning in our detailed guide.'
                link='View Guide'
                location='https://rise.articulate.com/share/mpkP7rZ8ipARUcdcJ6o-_bur9ifQXGnK#/lessons/R2aSrK9Nz1DpAv1qTDKyMFi-QN30BD_O'
                image={man}
                alt='smiling man excited to learn about processing bias'
            />
        </>
    );
};

interface IInfoProps {
    isDesktop: boolean;
    text: string[];
}

const Info: React.FC<IInfoProps> = ({ isDesktop, text }) => {
    const theme = useTheme();
    return (
        <InfoBox isDesktop={isDesktop}>
            <div>
                {isDesktop && (
                    <InfoIcon
                        fill='none'
                        color={theme.palette.text.primary}
                        sx={{ mr: 1 }}
                    />
                )}
            </div>
            <Typography>{text}</Typography>
        </InfoBox>
    );
};

export default DiversityIndex;
