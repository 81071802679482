import { Divider, Typography, styled } from '@mui/material';

import { ISupportRequestNote } from '../../../../types';
import { Label } from './Label';
import { format } from 'date-fns';

interface IOwnProps {
    notes: ISupportRequestNote[];
}

const NoteWrapper = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
}));

const DateWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

export const Notes: React.FC<IOwnProps> = ({ notes }) => {
    return (
        <>
            <Label label='My notes' />
            {notes.map((note) => (
                <NoteWrapper key={note.id}>
                    <Typography sx={{ overflowWrap: 'anywhere' }}>
                        {note.note}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <DateWrapper>
                        <Typography>Date</Typography>
                        <Typography>
                            {format(new Date(note.createdDate), 'do MMM yyyy')}
                        </Typography>
                    </DateWrapper>
                </NoteWrapper>
            ))}
        </>
    );
};
