import { BottomWrapper, FooterLink } from './CogSideNav';
import { X as CloseMenu, Menu as HamburgerMenu, Settings } from 'react-feather';
import { Typography, styled, useTheme } from '@mui/material';

import { ChangeClientButton } from './ChangeClientButton';
import ImgLogo from '../../../assets/img/logo.svg';
import { MenuItems } from './MenuItems';
import { TNavItem } from '../../../types';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

const MenuWrapper = styled('nav')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    backgroundColor: theme.palette.common.white,
    width: '100vw',
    height: '62px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 200,
    padding: theme.spacing(0, 3),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const HamburgerWrapper = styled('button')(() => ({
    flex: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
}));

const Menu = styled('nav', {
    ...shouldForwardProps('navOpen'),
})<{ navOpen: boolean }>(({ navOpen, theme }) => {
    return {
        top: 62,
        position: 'fixed',
        background: '#FFFFFF',
        width: '100vw',
        transition: 'transform .5s ease',
        transform: navOpen ? 'translateY(0)' : `translateY(-100vh)`,
        height: 'calc(100vh - 62px)',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        zIndex: 200,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(4),
        overflowY: 'auto',
    };
});

const ChangeClientWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(3),
}));

interface IOwnProps {
    hasSiteBanner?: boolean;
    navItems: TNavItem[];
    setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
    navOpen: boolean;
}

export const CogMobileNav: React.FC<IOwnProps> = ({
    navItems,
    setNavOpen,
    navOpen,
}) => {
    const theme = useTheme();

    const accountSettings = {
        name: 'Account Settings',
        icon: <Settings />,
        to: `/account-settings${window.location.search}`,
    };

    return (
        <>
            <MenuWrapper id='mobileNav'>
                <img style={{ height: 27 }} src={ImgLogo} alt='company logo' />

                <HamburgerWrapper
                    onClick={() => setNavOpen((prevState) => !prevState)}
                >
                    {navOpen ? (
                        <>
                            <CloseMenu
                                style={{ color: theme.palette.text.primary }}
                            />
                            <span className='visually-hidden'>Close Menu</span>
                        </>
                    ) : (
                        <>
                            <HamburgerMenu
                                style={{ color: theme.palette.text.primary }}
                            />
                            <span className='visually-hidden'>Open Menu</span>
                        </>
                    )}
                </HamburgerWrapper>
            </MenuWrapper>
            <Menu id='mobileMenu' navOpen={navOpen}>
                <div style={{ width: '100%' }}>
                    <MenuItems
                        navExpand={true}
                        navItems={[accountSettings, ...navItems]}
                        isMobile={true}
                        setNavOpen={setNavOpen}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <ChangeClientWrapper>
                        <ChangeClientButton />
                    </ChangeClientWrapper>
                </div>
                <BottomWrapper navExpand={true}>
                    <Typography sx={{ fontSize: pxToRem(12), mb: 1 }}>
                        © {new Date().getFullYear()} Cognassist. All Rights
                        Reserved.
                    </Typography>
                    <div>
                        <FooterLink
                            href='https://cognassist.com/privacy-policy/'
                            aria-label='Read the Privacy Policy here'
                            target='_blank'
                        >
                            Privacy
                        </FooterLink>
                        <FooterLink
                            href='https://cognassist.force.com/support/s/contactsupport?language=en_US'
                            aria-label='Find Support here'
                            target='_blank'
                        >
                            Support
                        </FooterLink>
                    </div>
                </BottomWrapper>
            </Menu>
        </>
    );
};
