import { DomainsMap, IStrategy } from '../types';
import { useEffect, useState } from 'react';

import { endpoints } from '../api/endpoints';
import { useLocation } from 'react-router-dom';

export const useStrategies = (caiDrawerOpen: boolean) => {
    const { getUserDomainStrategies, getDomains } = endpoints();

    const { state: locationState } = useLocation();

    const [domain, setDomain] = useState<number>();
    const [domains, setDomains] = useState<DomainsMap>();
    const [strategies, setStrategies] = useState<IStrategy[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        getDomainData();
    }, []);

    useEffect(() => {
        if (domain && !caiDrawerOpen) {
            getData(domain, currentPage);
        }
    }, [domain, currentPage, caiDrawerOpen]);

    const getDomainData = async () => {
        const { data } = await getDomains();

        if (data) {
            if (locationState) {
                const domainReference = data.find(
                    (d) => d.id === locationState.navigatedDomainId
                );
                setDomain(domainReference?.id);
            } else {
                setDomain(data[0].id);
            }
            setDomains(data);
        }
    };

    const getData = async (domainId: number, pageIndex: number) => {
        const { data } = await getUserDomainStrategies({
            domainId: domainId.toString(),
            pageIndex,
        });

        if (data) {
            setTotalPages(data.totalPages);
            setStrategies(data.strategies);
        }
    };

    return {
        domain,
        setDomain,
        domains,
        strategies,
        currentPage,
        setCurrentPage,
        totalPages,
    };
};
