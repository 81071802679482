import { AssessmentStatus } from './general.types';
import { ChampionStatus } from './user.types';
import { Environment } from './app-config.types';
import { ILegalDocument } from './legal-document.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IAuthContext {
    state: AuthState;
    dispatch: React.Dispatch<AuthAction>;
    login: () => void;
}

export type UserResponse = {
    isAuthenticated: boolean;
} & (
    | {
          isAuthenticated: true;
          claims: IClaim[];
          roles: string[];
          isEnterprise: boolean;
          clientConfigItems: IClientConfigItem[];
          growthbookKey: string;
          environment: Environment;
          firstName: string;
          lastName: string;
          isFirstLogin: boolean;
          applicationInsightsConnectionString: string;
          assessmentAccessCode: string;
          clientName: string;
          assessmentBaseUrl: string;
          bffBaseUrl: string;
          clientEnabledRoles: IClientEnabledRole[];
          useSingleSignOn: boolean;
          hasVerifiedEmail: boolean;
          assessmentStatus: AssessmentStatus | null;
          legacyReturnUrl: string;
      }
    | {
          isAuthenticated: false;
          claims?: never;
          roles?: never;
          isEnterprise?: never;
          clientConfigItems?: never;
          growthbookKey?: never;
          environment?: never;
          firstName: never;
          lastName: never;
          isFirstLogin: never;
          applicationInsightsConnectionString?: never;
          assessmentAccessCode: never;
          clientName: never;
          assessmentBaseUrl: never;
          bffBaseUrl: never;
          clientEnabledRoles: never;
          hasVerifiedEmail: never;
          assessmentStatus: never;
          legacyReturnUrl: never;
      }
);

export enum BusinessType {
    ENTERPRISE = 'ENTERPRISE',
    APPS_ED = 'APPS_ED',
}

export enum Roles {
    SysAdmin = 'CognAssist Sysadmin',
    ClientAdmin = 'Client Admin',
    Tutor = 'Assessor',
    Learner = 'Learner',
    TutorManager = 'Assessor Manager',
    ReportViewer = 'Report Viewer',
    Champion = 'Champion',
}

export interface IClaim {
    type: string;
    value: string;
}

export interface IParsedClaims {
    userId?: string;
    clientId?: string;
    email?: string;
}

export interface IClientConfigItem {
    availableValues: {
        [key: number]: string;
    };
    description: string;
    id: ClientConfigItem;
    state: number;
}

export enum ClientConfigItem {
    LearnerReport = 10,
    DelayedRegistration = 20,
    RequireLearnerExplicitConsent = 30,
    RequireLearnerConsentToShareData = 40,
    FlexibleLearningPlan = 50,
    ShowAdminDashboard = 60,
    WeeklyAssessmentInvites = 70,
    ShowMigrationMicrositeLink = 80,
    SelfOnboarding = 90,
    EnterpriseLegacyClient = 100,
    DisableNeurodiversityDataCapture = 110,
    DisableNeurodifferenceCharts = 120,
    AppsAndEducationCognitiveMap = 140,
}

export interface IClientEnabledRole {
    roleId: string;
    roleName: string;
}

export interface ILoginConfig {
    isAuthenticated?: boolean;
    hasCalledLogin: boolean;
}

export interface IUserConfig {
    roles?: string[];
    isOnlyLearner?: boolean;
    userId?: string;
    name?: string;
    initials?: string;
    email?: string;
    isFirstLogin?: boolean;
    hasTakenAssessment?: boolean;
    hasPendingAssessment?: boolean;
    hasStartedAssessment?: boolean;
    assessmentAccessCode?: string;
    useSingleSignOn?: boolean;
    hasVerifiedEmail?: boolean;
    championStatus?: ChampionStatus;
    isChampion?: boolean;
    legacyReturnUrl?: string;
}

export interface IClientConfig {
    clientId?: string;
    items?: IClientConfigItem[];
    name?: string;
    businessType?: BusinessType;
    enabledRoles?: IClientEnabledRole[];
}

export type AuthState = {
    loginConfig: ILoginConfig;
    userConfig: IUserConfig;
    clientConfig: IClientConfig;
};

export type AuthAction =
    | { type: 'SET_LOGIN_CONFIG'; payload: Partial<ILoginConfig> }
    | { type: 'SET_USER_CONFIG'; payload: Partial<IUserConfig> }
    | { type: 'SET_CLIENT_CONFIG'; payload: Partial<IClientConfig> }
    | { type: 'SET_HAS_CALLED_LOGIN' }
    | { type: 'SET_ASSESSMENT_ACCESS_CODE'; payload: Partial<IUserConfig> };

export interface ISelfOnboardingResponse {
    signupDetails: ISignupDetails;
    legalDocuments: {
        latestLegalDocuments: ILegalDocument[] | null;
    };
    appConfig: IAppConfig;
}

export interface ISignupDetails {
    clientId: string;
    selfOnBoardingDomains: string | null;
    vanitySubDomain: string | null;
    isSelfOnBoardingClient: boolean;
    restrictSelfOnBoardingDomains: boolean;
    onlyAllowInvitedUsers: boolean;
    useSingleSignOn: boolean;
}

export interface IEmailValidationResponse {
    isDuplicate: boolean;
    isInvited: boolean;
}

export interface IParsedLegalDoc extends ILegalDocument {
    name: string;
    openDialog: () => void;
    isOpen: boolean;
    closeDialog: () => void;
    onScrollToBottom: () => void;
}

export interface IAppConfig {
    growthbookKey?: string;
    environment?: Environment;
    applicationInsightsConnectionString?: string;
}
