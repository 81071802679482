import { Avatar as MuiAvatar, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { endpoints } from '../../../../api/endpoints';
import { useInView } from 'react-intersection-observer';

const Avatar = styled(MuiAvatar)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: '#D9D9D9',
    width: 40,
    height: 40,
    [theme.breakpoints.down('sm')]: {
        width: 32,
        height: 32,
    },
}));

interface IOwnProps {
    alt: string;
    fileName: string | null;
}

export const LazyAvatar: React.FC<IOwnProps> = ({ alt, fileName }) => {
    const { getProfilePicture } = endpoints();

    const [src, setSrc] = useState<string>();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        if (fileName && inView) {
            requestProfilePicture(fileName);
        }
    }, [inView]);

    useEffect(() => {
        return () => {
            if (src) {
                URL.revokeObjectURL(src);
            }
        };
    }, [src]);

    const requestProfilePicture = async (fileName: string) => {
        const { data } = await getProfilePicture(fileName);

        if (data) {
            setSrc(URL.createObjectURL(data));
        }
    };

    return <Avatar alt={src ? alt : undefined} ref={ref} src={src} />;
};
