import { TrackingEvent } from '../types';
import { endpoints } from '../api/endpoints';
import { useAuth } from '../custom-providers/AuthProvider';
import { useConfig } from '../custom-providers/ConfigProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTrackEvent } from './useTrackEvent';

export const useStartAssessment = (hasStartedAssessment: boolean | null) => {
    const featureAssessmentRestart = useFeatureIsOn('assessment-restart');

    const trackEvent = useTrackEvent();
    const { putRegisterAssessment, putLearnerAssessmentRestart } = endpoints();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        state: {
            userConfig: { userId, email, name, assessmentAccessCode },
        },
        dispatch,
    } = useAuth();

    const {
        state: {
            appConfig: { assessmentBaseUrl },
        },
    } = useConfig();

    const [dialogContent, setDialogContent] = useState<{
        title: string;
        text: string;
    }>({ title: '', text: '' });

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [restartAssessmentDialogOpen, setRestartAssessmentDialogOpen] =
        useState<boolean>(false);
    const [assessmentRestartLoading, setAssessmentRestartLoading] =
        useState<boolean>(false);

    const handleStartAssessment = () => {
        if (hasStartedAssessment) {
            if (featureAssessmentRestart) {
                setRestartAssessmentDialogOpen(true);
            } else {
                setDialogContent({
                    title: 'Assessment has already been started',
                    text: 'Please contact your Cognassist Administrator or get in touch with us at support@cognassist.com',
                });
                setDialogOpen(true);
            }
        } else {
            checkForAccessCode();
        }
    };

    const restartAssessmentHandler = async () => {
        setAssessmentRestartLoading(true);
        const { data, error } = await putLearnerAssessmentRestart({
            accessCode: assessmentAccessCode,
        });

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });

            setAssessmentRestartLoading(false);
            return;
        }

        if (data) {
            dispatch({
                type: 'SET_ASSESSMENT_ACCESS_CODE',
                payload: {
                    assessmentAccessCode: data.newAccessCode,
                },
            });

            if (assessmentBaseUrl && data.newAccessCode) {
                window.open(
                    `${assessmentBaseUrl}/${data.newAccessCode}`,
                    '_blank',
                    'noreferrer'
                );
            }

            setAssessmentRestartLoading(false);
            setRestartAssessmentDialogOpen(false);
            navigate('/dashboard');
        }
    };

    const registerAssessment = async (uid: string) => {
        const { data, error } = await putRegisterAssessment({
            learnerUserId: uid,
        });

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });

            return;
        }

        if (data) {
            if (data.clientRegisteredAssessmentLimitReached) {
                trackEvent(TrackingEvent.ASSESSMENT_LIMIT_REACHED, {
                    userId,
                    email,
                    name,
                    dateAndTime: new Date(Date.now()).toISOString(),
                });
                setDialogContent({
                    title: 'Assessment limit has been reached for your account.',
                    text: 'Please speak to your administrator',
                });
                setDialogOpen(true);
            } else {
                dispatch({
                    type: 'SET_ASSESSMENT_ACCESS_CODE',
                    payload: { assessmentAccessCode: data.accessCode },
                });
                navigate('/pre-assessment');
            }
        }
    };

    const checkForAccessCode = () => {
        if (assessmentAccessCode) {
            navigate('/pre-assessment');
        } else if (userId) {
            registerAssessment(userId);
        }
    };

    return {
        dialogContent,
        handleStartAssessment,
        restartAssessmentHandler,
        dialogOpen,
        setDialogOpen,
        restartAssessmentDialogOpen,
        setRestartAssessmentDialogOpen,
        assessmentRestartLoading,
        setAssessmentRestartLoading,
    };
};
