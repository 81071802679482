import { ICoach, UserFilterType } from '../../../types';
import { Chip as MuiChip, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { AssessmentStatus } from './filters/AssessmentStatus';
import { Button } from '@cognassist/react-components';
import { Manager } from './filters/Manager';
import { MyTeam } from './filters/MyTeam';
import { Roles } from './filters/Roles';
import { SaveCancel } from './SaveCancel';
import { SecondaryManager } from './filters/SecondaryManager';
import { UsersDrawer } from './UsersDrawer';
import { X } from 'react-feather';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useIsManagerAndAdmin } from '../../../custom-hooks/useIsManagerAndAdmin';
import { useIsManagerNotAdmin } from '../../../custom-hooks/useIsManagerNotAdmin';
import { useSnackbar } from 'notistack';
import { useUsers } from '../../../custom-providers/UsersProvider';

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 253px)',
    overflowY: 'scroll',
    marginBottom: 105,
    padding: theme.spacing(0, 4),
}));

const TagWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 4, 0),
}));

const Chip = styled(MuiChip)(({ theme }) => ({
    margin: theme.spacing(0, 1, 1, 0),
    border: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.text.primary,
    boxShadow: theme.cogShadows.sm,
}));

const CloseIcon = styled(X)(({ theme }) => ({
    color: theme.palette.text.primary,
    cursor: 'pointer',
}));

export interface IUserFilter {
    name: string;
    value: string | number;
    tagName: string;
    filterType: UserFilterType;
}

interface IOwnProps {
    close: () => void;
}

export const FilterDrawer: React.FC<IOwnProps> = ({ close }) => {
    const { getCoaches } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const isManagerNotAdmin = useIsManagerNotAdmin();

    const [clientEnabledRoles, setClientEnabledRoles] = useState<string[]>([]);
    const [managerRoleEnabled, setManagerRoleEnabled] =
        useState<boolean>(false);

    const showManagerFilters = !isManagerNotAdmin && managerRoleEnabled;

    const [coaches, setCoaches] = useState<ICoach[]>([]);

    const {
        state: { activeFilters },
        dispatch,
    } = useUsers();

    const {
        state: {
            clientConfig: { enabledRoles },
        },
    } = useAuth();

    useEffect(() => {
        setClientEnabledRoles(
            enabledRoles !== undefined
                ? enabledRoles.map((role) => role.roleName)
                : []
        );
    }, [enabledRoles]);

    useEffect(() => {
        setManagerRoleEnabled(
            clientEnabledRoles.some((role) => role == 'Manager')
        );
    }, [clientEnabledRoles]);

    useEffect(() => {
        if (showManagerFilters) {
            getAvailableCoaches();
        }
    }, [showManagerFilters]);

    const getAvailableCoaches = async () => {
        const { data, error } = await getCoaches();

        if (error) {
            setManagerFilterErrorSnackbarMessage();
        }
        if (data) {
            setCoaches(data.coaches);
        }
    };

    const setManagerFilterErrorSnackbarMessage = () => {
        enqueueSnackbar(
            'Something went wrong. Filtering of managers unavailable at this time.',
            {
                variant: 'error',
            }
        );
    };

    const isManagerAndAdmin = useIsManagerAndAdmin();

    const myTeamFilterIsActive = () => {
        return activeFilters.some((f) => f.filterType == UserFilterType.MyTeam);
    };

    return (
        <UsersDrawer
            title='Filter your table'
            close={close}
            id='filter-user-container'
        >
            <TagWrapper>
                {activeFilters.map((val) => (
                    <Chip
                        key={val.tagName}
                        variant='outlined'
                        label={val.tagName}
                        icon={
                            <CloseIcon
                                size={18}
                                onClick={() => {
                                    dispatch({
                                        type: 'REMOVE_ACTIVE_FILTER',
                                        payload: val,
                                    });
                                }}
                            />
                        }
                    />
                ))}
            </TagWrapper>
            {!!activeFilters.length && (
                <Button
                    text='Clear all'
                    variant='text'
                    size='small'
                    color='inherit'
                    sx={{ alignSelf: 'flex-end', marginRight: 4 }}
                    onClick={() => dispatch({ type: 'CLEAR_ACTIVE_FILTERS' })}
                />
            )}
            <Wrapper>
                {isManagerAndAdmin && <MyTeam />}
                <Roles clientEnabledRoles={clientEnabledRoles} />
                <AssessmentStatus />
                {showManagerFilters && (
                    <>
                        {!myTeamFilterIsActive() && (
                            <Manager coaches={coaches} />
                        )}
                        <SecondaryManager coaches={coaches} />
                    </>
                )}
                <SaveCancel save={close} loading={false} saveText='Done' />
            </Wrapper>
        </UsersDrawer>
    );
};
