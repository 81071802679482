import { IClaim } from '../types';

export const parseClaims = (claims: IClaim[]) => {
    return claims.reduce((acc: { [key: string]: string }, current) => {
        const key = current.type.split('/').pop();

        if (key) {
            acc[key] = current.value;
        }

        return acc;
    }, {});
};
