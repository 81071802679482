import ReactMarkdown from 'react-markdown';
import { Typography } from '@mui/material';

interface IOwnProps {
    mkdown: string;
}

export const Markdown: React.FC<IOwnProps> = ({ mkdown }) => {
    return (
        <ReactMarkdown
            components={{
                h1: ({ children }) => (
                    <Typography variant='h1'>{children}</Typography>
                ),
                h2: ({ children }) => (
                    <Typography variant='h2'>{children}</Typography>
                ),
                h3: ({ children }) => (
                    <Typography variant='h3'>{children}</Typography>
                ),
                h4: ({ children }) => (
                    <Typography variant='h4'>{children}</Typography>
                ),
                p: ({ children }) => <Typography>{children}</Typography>,
            }}
        >
            {mkdown}
        </ReactMarkdown>
    );
};
