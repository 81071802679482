import { CogInput, ErrorText } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../Field';
import { useFormContext } from 'react-hook-form';
import { NeurodivergenceType } from '../../../../types';
import { useEffect } from 'react';

export const OtherNeurodivergenceTypeDetails: React.FC = () => {
    const {
        formState: { errors },
        register,
        watch,
        setValue,
        clearErrors,
    } = useFormContext<{
        otherNeurodivergenceTypeDetails: string | null;
        neurodivergenceTypes: NeurodivergenceType[];
    }>();

    const otherNeurodivergenceTypeDetails = watch(
        'otherNeurodivergenceTypeDetails'
    );

    const neurodivergenceTypes = watch('neurodivergenceTypes');

    const showOtherDetails = neurodivergenceTypes.includes(
        NeurodivergenceType.Other
    );

    useEffect(() => {
        if (!neurodivergenceTypes.includes(NeurodivergenceType.Other)) {
            setValue('otherNeurodivergenceTypeDetails', null);
            clearErrors('otherNeurodivergenceTypeDetails');
        }
    }, [neurodivergenceTypes]);

    if (!showOtherDetails) {
        return null;
    }

    return (
        <Field
            label='Please specify'
            labelFor='otherNeurodivergenceTypeDetails'
            required
            hasError={!!errors.otherNeurodivergenceTypeDetails}
        >
            <>
                <CogInput
                    {...register('otherNeurodivergenceTypeDetails', {
                        required: {
                            value: true,
                            message:
                                'If you have selected "Other", please specify.',
                        },
                        maxLength: {
                            value: 250,
                            message:
                                'You have gone over the 250 character limit for this field, please reduce it and try again.',
                        },
                    })}
                    defaultValue={otherNeurodivergenceTypeDetails}
                    hasError={!!errors.otherNeurodivergenceTypeDetails}
                    id='otherNeurodivergenceTypeDetails'
                    fullWidth
                    multiline
                    rows={3}
                />
                <ErrorMessage
                    errors={errors}
                    name='otherNeurodivergenceTypeDetails'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
