import { Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Accordion } from '../Accordion';
import { Button } from '@cognassist/react-components';
import { Section } from './styled-components';
import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';

const DrawerContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const ScoreBox = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    flexShrink: 0,
}));

const ScoreText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(14),
    },
}));

const Screenshot = styled('img', {
    ...shouldForwardProps('isMobile'),
})<{ isMobile: boolean }>(({ isMobile, theme }) => ({
    width: '100%',
    display: isMobile ? 'none' : 'block',
    [theme.breakpoints.down(744)]: {
        display: isMobile ? 'block' : 'none',
    },
}));

const Wrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down(744)]: {
        gridTemplateColumns: '100%',
    },
}));

interface IOwnProps {
    domainId: number;
    domainName: string;
    score: number;
    domainExplanation: string[];
    assessmentExplanation: string[];
    whatItMeans: string[];
    assessmentScreenshot: { url: string; alt: string };
    links: { url: string; text: string }[];
}
export const DomainDrawer: React.FC<IOwnProps> = ({
    domainName,
    score,
    domainExplanation,
    assessmentExplanation,
    whatItMeans,
    assessmentScreenshot,
    links,
}) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const ref = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (
            decodeURIComponent(window.location.hash).split('#')[1] ===
            ref.current?.id
        ) {
            window.scrollTo(0, ref.current.getBoundingClientRect().y - 150);
            setIsExpanded(true);
        }
    }, []);

    return (
        <DrawerContainer ref={ref} id={domainName}>
            <Accordion
                isOpen={isExpanded}
                title={domainName}
                titleContent={
                    <ScoreBox>
                        <ScoreText>SCORE: {score}</ScoreText>
                    </ScoreBox>
                }
                content={
                    <Wrapper>
                        <div>
                            <Section>
                                {domainExplanation.map((domainParagraph) => {
                                    return (
                                        <Typography
                                            sx={{ mb: 3 }}
                                            key={domainParagraph}
                                        >
                                            {domainParagraph}
                                        </Typography>
                                    );
                                })}
                            </Section>
                            <Screenshot
                                src={assessmentScreenshot.url}
                                alt={assessmentScreenshot.alt}
                                isMobile={true}
                            />
                            <Section>
                                <Typography
                                    color='primary'
                                    sx={{ fontWeight: 600, mb: 3 }}
                                >
                                    The assessment
                                </Typography>
                                {assessmentExplanation.map(
                                    (assessmentParagraph) => {
                                        return (
                                            <Typography
                                                key={assessmentParagraph}
                                            >
                                                {assessmentParagraph}
                                            </Typography>
                                        );
                                    }
                                )}
                            </Section>
                            <Section sx={{ mb: 0 }}>
                                <Typography
                                    color='primary'
                                    sx={{ fontWeight: 600, mb: 3 }}
                                >
                                    What it means
                                </Typography>
                                {whatItMeans.map((whatItMeansParagraph) => {
                                    return (
                                        <Typography
                                            sx={{ mb: 3 }}
                                            key={whatItMeansParagraph}
                                        >
                                            {whatItMeansParagraph}
                                        </Typography>
                                    );
                                })}
                                {links.map((link) => {
                                    return (
                                        <Button
                                            sx={{ mb: 1 }}
                                            key={link.text}
                                            text={link.text}
                                            onClick={() => {
                                                window.history.pushState(
                                                    'cognitiveDomainsNavBack',
                                                    document.title,
                                                    `${
                                                        window.location.pathname
                                                    }${search}#${encodeURIComponent(
                                                        domainName
                                                    )}`
                                                );
                                                navigate(
                                                    `/learner/assessmentreport/deep-dives${link.url}` +
                                                        search
                                                );
                                            }}
                                        ></Button>
                                    );
                                })}
                            </Section>
                        </div>
                        <Screenshot
                            src={assessmentScreenshot.url}
                            alt={assessmentScreenshot.alt}
                            isMobile={false}
                        />
                    </Wrapper>
                }
            />
        </DrawerContainer>
    );
};

export default DomainDrawer;
