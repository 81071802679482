import { createStyles, makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() =>
    createStyles({
        loadingWrapper: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
);

export const Loading = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.loadingWrapper}>
            <CircularProgress />
        </div>
    );
};
