import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { useAuth } from '../custom-providers/AuthProvider';
import { TrackingEvent } from '../types';

type TrackEventFn = (
    name: TrackingEvent,
    customProperties?: { [key: string]: string | number | undefined }
) => void;

export const useTrackEvent = (): TrackEventFn => {
    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const appInsights = useAppInsightsContext();

    return (name, customProperties) => {
        appInsights.trackEvent({
            name,
            properties: {
                ...(userId && { userId }),
                ...customProperties,
            },
        });
    };
};
