import { Typography, alpha, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { CSSTransition } from 'react-transition-group';
import { CheckDark } from '../styled-components';
import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';

const CurrentModuleSection = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '32px 24px 16px',
}));
const Header = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
}));

const ShowModulesButton = styled(Button)(() => ({}));

const CurrentModule = styled('div', {
    ...shouldForwardProps('complete'),
})<{ complete?: boolean }>(({ complete, theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    background: complete
        ? alpha(theme.palette.success.main, 0.2)
        : theme.palette.grey[50],
    borderColor: complete
        ? theme.palette.success.dark
        : theme.palette.grey[200],
    marginBottom: 8,
}));

const ModuleTitle = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

export const CurrentModules: React.FC = () => {
    const {
        state: { currentModules },
    } = useFLP();

    const [incompleteCount, setIncompleteCount] = useState<number>(0);

    useEffect(() => {
        if (currentModules) {
            const incompleteModules = currentModules.modules.reduce(
                (acc, current) => {
                    if (!current.dateCompleted) {
                        acc++;
                    }

                    return acc;
                },
                0
            );

            setIncompleteCount(incompleteModules);
        }
    }, [currentModules]);

    const [showModules, setShowModules] = useState<boolean>(true);
    return (
        <CurrentModuleSection>
            <Header>
                <Typography variant='h4' sx={{ fontWeight: 600 }}>
                    Current modules ({incompleteCount})
                </Typography>
                {Boolean(currentModules?.totalRecords) && (
                    <ShowModulesButton
                        onClick={() => setShowModules(!showModules)}
                        text={showModules ? 'Hide' : 'Show'}
                        size='small'
                        color='inherit'
                    />
                )}
            </Header>
            <CSSTransition
                in={showModules}
                timeout={500}
                classNames='alert'
                unmountOnExit
            >
                <div style={{ overflow: 'hidden' }}>
                    {currentModules?.modules.map((module) => (
                        <CurrentModule
                            key={module.id}
                            complete={!!module.dateCompleted}
                        >
                            <ModuleTitle
                                variant='body1'
                                sx={{ fontSize: pxToRem(14) }}
                            >
                                {module.title}
                            </ModuleTitle>
                            {!!module.dateCompleted && (
                                <div style={{ height: 20, width: 20 }}>
                                    <CheckDark size={20} />
                                </div>
                            )}
                        </CurrentModule>
                    ))}
                </div>
            </CSSTransition>
        </CurrentModuleSection>
    );
};
