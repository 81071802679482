import { Loading } from '../Loading';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../custom-providers/AuthProvider';

export const AuthLayout = () => {
    const { login } = useAuth();

    const {
        state: {
            loginConfig: { hasCalledLogin },
        },
    } = useAuth();

    if (!hasCalledLogin) {
        login();

        return <Loading />;
    }

    return <Outlet />;
};
