import { alpha, styled } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { TNavItem } from '../../../types';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

const Wrapper = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ theme, navExpand }) => ({
    display: navExpand ? 'flex' : 'none',
    flexFlow: 'column nowrap',
    marginTop: theme.spacing(-1),
    overflow: 'hidden',
}));

const NavItem = styled(NavLink, {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ theme }) => ({
    marginLeft: theme.spacing(3),
    fontWeight: 500,
    textDecoration: 'none',
    color: theme.palette.text.primary,
}));

const NavItemInner = styled('div', {
    ...shouldForwardProps('isActive'),
})<{ isActive?: boolean }>(({ isActive, theme }) => ({
    background: isActive ? 'red' : 'white',
    backgroundColor: isActive
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
}));

interface ISubMenuProps {
    item: TNavItem;
    navExpand: boolean;
    setNavOpen: (value: React.SetStateAction<boolean>) => void;
    isMobile?: boolean;
}

export const SubMenu: React.FC<ISubMenuProps> = ({
    item,
    navExpand,
    setNavOpen,
    isMobile,
}) => {
    return (
        <Wrapper navExpand={navExpand}>
            {item.items?.map((subItem) => (
                <NavItem
                    navExpand={navExpand}
                    key={subItem.to}
                    to={subItem.to}
                    end
                    onClick={() => {
                        if (isMobile) {
                            setNavOpen(false);
                        }
                    }}
                >
                    {({ isActive }) => (
                        <NavItemInner isActive={isActive}>
                            {subItem.name}
                        </NavItemInner>
                    )}
                </NavItem>
            ))}
        </Wrapper>
    );
};
