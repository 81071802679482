import { AssessmentStatus } from '../types';

export const assessmentStatusLookup = (status: AssessmentStatus) => {
    switch (status) {
        case AssessmentStatus.Pending:
            return 'Pending';
        case AssessmentStatus.Started:
            return 'Started';
        case AssessmentStatus.Complete:
            return 'Complete';
        case AssessmentStatus.NotInvited:
            return 'Not Invited';
        case AssessmentStatus.NoConsent:
            return 'No Consent';
        default:
            return null;
    }
};

export const mapAssessmentUserConfig = (status: AssessmentStatus | null) => {
    return {
        hasTakenAssessment: status == AssessmentStatus.Complete,
        hasPendingAssessment:
            status === null || status == AssessmentStatus.Pending,
        hasStartedAssessment: status === AssessmentStatus.Started,
    };
};
