import { ErrorText, TextArea } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';

export const RequestMessage: React.FC = () => {
    const {
        formState: { errors },
        register,
    } = useFormContext<{ supportRequestMessage: string | null }>();

    return (
        <Field
            label='Message'
            labelFor='supportRequestMessage'
            required={false}
            hasError={!!errors.supportRequestMessage}
        >
            <>
                <TextArea
                    {...register('supportRequestMessage', {
                        maxLength: {
                            value: 500,
                            message:
                                'You have gone over the 500 character limit for this field, please reduce it and try again.',
                        },
                    })}
                    multiline
                    rows={3}
                    hasError={!!errors.supportRequestMessage}
                    id='supportRequestMessage'
                />
                <ErrorMessage
                    errors={errors}
                    name='supportRequestMessage'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
