import {
    ContentWrapper,
    InnerContentWrapper,
} from '../../../components/main-app/cognitive-map-v2/shared-components';
import {
    OldReportBanner,
    OldReportBannerText,
} from '../../../components/main-app/cognitive-map/old-report-banner-components';
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from 'react';
import {
    Tab,
    TabRowsMaxWidth,
    Tabs,
    TabsRow,
} from '../../../components/styled-components/Tabs';
import { styled, useTheme } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ArrowUpRight } from 'react-feather';
import { CaiDrawer } from '../../../components/main-app/cai/CaiDrawer';
import CircularProgress from '@mui/material/CircularProgress';
import CognitionDeepDive from './deep-dive';
import CognitiveMapOverview from './overview';
import CognitiveMapStrategies from './strategies';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { RoleDrawer } from '../../../components/main-app/cognitive-map/RoleDrawer';
import { getScreenSizeFromWidth } from '../../../components/main-app/cognitive-map-v2/helpers';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useResizeObserver from '@react-hook/resize-observer';

const LoadingWrapper = styled('div')(() => ({
    height: 'calc(100vh - 16px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export enum CogMapV2Tab {
    Overview = 1,
    DeepDive = 2,
    Strategies = 3,
}

export const CognitiveMapV2 = () => {
    const [searchParams] = useSearchParams();
    const { state: locationState } = useLocation();

    const [wrapper, setWrapper] = useState<HTMLElement | null>(null);

    const featureCognitiveMapV2 = useFeatureIsOn('new-cognitive-map-v2');
    const featureOldReportToggle = useFeatureIsOn('old-report-toggle');

    const theme = useTheme();

    const [tab, setTab] = useState<CogMapV2Tab>(CogMapV2Tab.Overview);

    const {
        state: { cognitiveMapV2, screenSize },
        dispatch,
    } = useCognitiveMap();

    const {
        state: {
            userConfig: { userId, name },
        },
    } = useAuth();

    useEffect(() => {
        document.title = 'My Cognitive Map';
    }, []);

    useEffect(() => {
        const paramTab = Number(searchParams.get('tab'));
        if (paramTab in CogMapV2Tab) {
            setTab(paramTab);
        }
    }, [searchParams, locationState]);

    useLayoutEffect(() => {
        if (wrapper) {
            const { width } = wrapper.getBoundingClientRect();
            dispatch({
                type: 'SET_SCREEN_SIZE',
                payload: getScreenSizeFromWidth(width),
            });
        }
    }, [wrapper]);

    useResizeObserver(wrapper, (entry) => {
        const { width } = entry.contentRect;
        dispatch({
            type: 'SET_SCREEN_SIZE',
            payload: getScreenSizeFromWidth(width),
        });
    });

    const handleTabChange = (_event: SyntheticEvent, newValue: CogMapV2Tab) => {
        setTab(newValue);
    };

    const getTabContent = () => {
        switch (tab) {
            case CogMapV2Tab.Overview:
                return <CognitiveMapOverview fullName={name} />;
            case CogMapV2Tab.DeepDive:
                return <CognitionDeepDive />;
            case CogMapV2Tab.Strategies:
                return <CognitiveMapStrategies />;
            default:
                return null;
        }
    };

    if (!featureCognitiveMapV2) {
        return <PageNotFound showTopNav={false} />;
    }

    if (!cognitiveMapV2) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    return (
        <>
            {featureOldReportToggle && (
                <OldReportBanner>
                    <OldReportBannerText
                        to={`/learner/assessmentreport?learnerUserId=${userId}`}
                        target='_blank'
                        referrerPolicy='no-referrer'
                    >
                        You are viewing the new Cognitive Map, click here to
                        view the previous version.
                    </OldReportBannerText>
                    <ArrowUpRight color={theme.palette.common.white} />
                </OldReportBanner>
            )}
            <PageHeader title='My Cognitive Map' />
            <TabsRow>
                <TabRowsMaxWidth>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='cognitive map tabs'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <Tab value={CogMapV2Tab.Overview} label='Overview' />
                        <Tab
                            value={CogMapV2Tab.DeepDive}
                            label='Cognition Deep Dive'
                        />
                        <Tab
                            value={CogMapV2Tab.Strategies}
                            label='Strategies & Adjustments'
                        />
                    </Tabs>
                </TabRowsMaxWidth>
            </TabsRow>
            <ContentWrapper ref={setWrapper}>
                <InnerContentWrapper
                    isMobile={screenSize.isMobile}
                    isTablet={screenSize.isTablet}
                >
                    {getTabContent()}
                </InnerContentWrapper>
            </ContentWrapper>
            <RoleDrawer />
            <CaiDrawer />
        </>
    );
};
