import { IPriorityModule } from '../types';
import axios from 'axios';
import { endpoints } from '../api/endpoints';
import { getDefaultPageSize } from '../utils/tables';
import { useFLP } from '../custom-providers/FlexibleLearningPlan';
import { useSnackbar } from 'notistack';

interface IUseFLPAPIProps {
    learnerUserId: string;
}

interface IUseFLPAPIReturn {
    getUpcomingModules: ({
        pageIndex,
        pageSize,
        categories,
        skills,
    }: {
        pageIndex?: number;
        pageSize?: number;
        categories: string[];
        skills: string[];
    }) => Promise<void>;
    getCompletedModules: (props?: {
        pageIndex?: number;
        pageSize?: number;
    }) => Promise<void>;
    getCurrentModules: () => Promise<void>;
    getPriorityModules: () => Promise<void>;
    getFilters: () => Promise<void>;
    updatePriorityModules: (props: {
        timestamp: string;
        modules: IPriorityModule[];
    }) => Promise<boolean>;
}

export const useFLPAPI = ({ learnerUserId }: IUseFLPAPIProps) => {
    const { dispatch } = useFLP();
    const { enqueueSnackbar } = useSnackbar();

    const {
        getAssignedModules,
        getPriorityModules: getAPIPriorityModules,
        postLearningPlan,
        putPriorityModules,
        getFilterSkills,
        getFilterCategories,
    } = endpoints();

    const getUpcomingModules = async ({
        pageIndex,
        pageSize,
        categories,
        skills,
    }: {
        pageIndex?: number;
        pageSize?: number;
        categories: string[];
        skills: string[];
    }): Promise<void> => {
        if (!learnerUserId) {
            return;
        }

        const { data, error } = await postLearningPlan({
            learnerUserId,
            pageIndex: pageIndex || 1,
            pageSize:
                pageSize || getDefaultPageSize('upcomingModulesPageSize', 10),
            categories,
            skills,
        });

        if (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
            return;
        }

        dispatch({
            type: 'SET_UPCOMING_MODULES',
            payload: {
                pageNumber: pageIndex || 1,
                modules: data,
            },
        });
    };

    const getCompletedModules: IUseFLPAPIReturn['getCompletedModules'] = async (
        {
            pageIndex = 1,
            pageSize = getDefaultPageSize('completedModulesPageSize', 10),
        } = {
            pageIndex: 1,
            pageSize: getDefaultPageSize('completedModulesPageSize', 10),
        }
    ) => {
        if (!learnerUserId) {
            return;
        }

        const { data, error } = await getAssignedModules({
            learnerUserId,
            completedOnly: true,
            pageIndex,
            pageSize,
        });

        if (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
            return;
        }

        dispatch({ type: 'SET_COMPLETED_MODULES', payload: data });
    };

    const getCurrentModules = async () => {
        const { data, error } = await getAssignedModules({
            learnerUserId,
            currentMonthOnly: true,
            pageIndex: 1,
            pageSize: 100,
        });

        if (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
            return;
        }

        dispatch({ type: 'SET_CURRENT_MODULES', payload: data });
    };

    const getPriorityModules = async () => {
        const { data, error } = await getAPIPriorityModules(learnerUserId);

        if (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
            return;
        }

        if (data) {
            dispatch({
                type: 'SET_PRIORITY_MODULES',
                payload: data.modules || null,
            });
            dispatch({
                type: 'SET_INITIAL_PRIORITY_MODULES',
                payload: JSON.stringify(data?.modules),
            });
            dispatch({
                type: 'SET_PRIORITY_MODULE_TIMESTAMP',
                payload: data.timestamp,
            });
        }
    };

    const getFilters = async () => {
        const [
            { data: skillsData, error: skillsError },
            { data: categoriesData, error: categoriesError },
        ] = await Promise.all([getFilterSkills(), getFilterCategories()]);

        if (skillsError || categoriesError) {
            console.log(skillsError || categoriesError);
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
            return;
        }

        if (categoriesData && skillsData) {
            dispatch({
                type: 'SET_FILTERS',
                payload: Object.assign({}, skillsData, categoriesData),
            });
        }

        return;
    };

    const parseModules = (modules: IPriorityModule[]) => {
        return modules.reduce(
            (
                accum: Pick<IPriorityModule, 'id' | 'order'>[],
                current,
                index
            ) => {
                accum.push({
                    id: current.id,
                    order: index,
                });
                return accum;
            },
            []
        );
    };

    const updatePriorityModules: IUseFLPAPIReturn['updatePriorityModules'] =
        async ({ timestamp, modules }) => {
            const { data, error } = await putPriorityModules({
                learnerUserId,
                timestamp,
                modules: parseModules(modules),
            });

            if (error) {
                console.log(error);
                if (axios.isAxiosError(error)) {
                    if (error?.response?.status === 409) {
                        enqueueSnackbar(
                            'Your changes cannot be saved at this time due to a conflict. Please refresh your page and try again.',
                            {
                                variant: 'error',
                                autoHideDuration: 10000,
                            }
                        );
                        return false;
                    }
                }
                enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                });
                return false;
            }

            if (data) {
                dispatch({
                    type: 'SET_PRIORITY_MODULE_TIMESTAMP',
                    payload: data.timestamp,
                });
            }

            return true;
        };

    return {
        getUpcomingModules,
        getCompletedModules,
        getCurrentModules,
        getPriorityModules,
        getFilters,
        updatePriorityModules,
    };
};
