import { FormGroup, styled } from '@mui/material';

import { Input } from '@cognassist/react-components';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

export const ButtonWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: isSmall ? 'space-around' : 'flex-end',
    gap: theme.spacing(3),
    '& button': {
        flexGrow: isSmall ? 1 : 0,
    },
}));

export const AccountSettingsHeader = styled('h2')(({ theme }) => ({
    marginBottom: theme.spacing(4),
    fontSize: pxToRem(24),
    fontWeight: 400,
}));

export const AccountSettingsGroup = styled(FormGroup)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const AccountSettingsLabel = styled('label')(({ theme }) => ({
    fontSize: pxToRem(16),
    marginBottom: theme.spacing(2),
}));

export const AccountSettingsInput = styled(Input)(({ theme }) => ({
    background: theme.palette.common.white,
}));
