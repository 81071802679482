import { HTMLAttributes, useState } from 'react';
import { Menu, MenuItem as MuiMenuItem, styled } from '@mui/material';

import { ChevronDown } from 'react-feather';
import { IReportMenuItem } from '../types';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { pxToRem } from '../utils/style-functions';
import { shouldForwardProps } from '../utils/shouldForwardProp';

const DropdownWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const DropdownText = styled('span')(({ theme }) => ({
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    color: theme.palette.text.primary,
}));

const IconWrapper = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const Icon = styled(ChevronDown, {
    ...shouldForwardProps('isOpen'),
})<{ isOpen?: boolean }>(({ isOpen }) => ({
    cursor: 'pointer',
    transition: 'transform .3s ease-in-out',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg',
}));

const NavLink = styled(RouterNavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    padding: theme.spacing(1, 2),
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    padding: theme.spacing(0.5, 1),
}));

interface IOwnProps {
    mainItem: IReportMenuItem;
    menuItems: IReportMenuItem[];
}

export const CogMenu: React.FC<IOwnProps & HTMLAttributes<HTMLLIElement>> = ({
    children,
    mainItem,
    menuItems,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = (handler?: () => void) => {
        setAnchorEl(null);
        if (handler) {
            handler();
        }
    };

    return (
        <>
            <DropdownWrapper>
                <DropdownText onClick={(e) => handleClick(e)}>
                    {mainItem.displayText}
                </DropdownText>

                <IconWrapper onClick={(e) => handleClick(e)}>
                    <Icon
                        aria-controls='dropdown-menu'
                        aria-haspopup='true'
                        isOpen={!!anchorEl}
                    />
                </IconWrapper>
            </DropdownWrapper>
            <Menu
                id='dropdown-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                sx={{ mt: 4 }}
            >
                {menuItems.map((item) => (
                    <MenuItem key={item.displayText} disableRipple>
                        <NavLink
                            to={item.location}
                            onClick={() => handleClose()}
                        >
                            {item.displayText}
                        </NavLink>
                    </MenuItem>
                ))}
            </Menu>
            {children}
        </>
    );
};

export default CogMenu;
