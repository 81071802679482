import { SVGProps } from 'react';
import { WarningShapeCircle } from './WarningShapeCircle';
import { WarningShapeTriangle } from './WarningShapeTriangle';

interface IOwnProps extends SVGProps<SVGTextElement> {
    isMobile: boolean;
    pvtFailure: boolean;
    hasEnglishAsASecondLanguage: boolean;
    domainName: string;
}

export const CustomWarningShape: React.FC<IOwnProps> = ({
    isMobile,
    pvtFailure,
    hasEnglishAsASecondLanguage,
    domainName,
    ...props
}) => {
    return (
        <>
            {pvtFailure && (
                <WarningShapeTriangle isMobile={isMobile} {...props} />
            )}

            {hasEnglishAsASecondLanguage === true &&
                (domainName === 'Literacy' ||
                    domainName === 'Verbal Reasoning' ||
                    domainName === 'Reading Decoding') && (
                    <WarningShapeCircle
                        isMobile={isMobile}
                        pvtFailure={pvtFailure}
                        {...props}
                    />
                )}
        </>
    );
};
