import { Backdrop, IconButton as MuiIconButton, styled } from '@mui/material';

import { X } from 'react-feather';
import { shouldForwardProps } from '../utils/shouldForwardProp';

export const Wrapper = styled('div', {
    ...shouldForwardProps('isOpen', 'maxWidth'),
})<{ isOpen?: boolean; maxWidth?: number }>(
    ({ isOpen, theme, maxWidth = 444 }) => ({
        maxWidth: maxWidth,
        width: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        boxShadow: isOpen ? theme.cogShadows.lg : 'none',
        overflowY: 'auto',
        flexFlow: 'column nowrap',
        padding: theme.spacing(3),
        transform: isOpen ? 'translateX(0)' : `translateX(${maxWidth}px)`,
        background: theme.palette.common.white,
        zIndex: 100,
        transition: 'transform 0.3s ease',
        height: '100vh',
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 4, 8, 3),
            maxWidth: '100vw',
            transform: isOpen
                ? 'translateX(0) translateY(62px)'
                : 'translateX(100vw) translateY(62px)',
        },
    })
);

const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

const CloseIcon = styled(X)(() => ({
    cursor: 'pointer',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    background: theme.palette.grey[50],
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
}));

interface IOwnProps {
    close: () => void;
    children: React.ReactNode;
    id: string;
    open: boolean;
    TitleWrapper?: React.ReactElement;
    maxWidth?: number;
    style?: React.CSSProperties;
    topWrapperStyle?: React.CSSProperties;
}

export const Drawer: React.FC<IOwnProps> = ({
    close,
    children,
    id,
    open,
    TitleWrapper,
    maxWidth,
    style,
    topWrapperStyle,
}) => {
    return (
        <>
            <Wrapper id={id} isOpen={open} maxWidth={maxWidth} style={style}>
                <TopWrapper style={topWrapperStyle}>
                    {TitleWrapper ?? null}
                    <IconButton onClick={close} aria-label='close-drawer'>
                        <CloseIcon />
                    </IconButton>
                </TopWrapper>
                {children}
            </Wrapper>
            <Backdrop open={open} onClick={close} sx={{ zIndex: 99 }} />
        </>
    );
};
