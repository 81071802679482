export enum DashboardGraph {
    ReportsSharedQuery = 1,
    NeurodiversityDeclaredQuery = 2,
    DeclaredBreakdownYesQuery = 3,
    DeclaredBreakdownNoQuery = 4,
    DeclaredBreakdownBelieveQuery = 5,
    AssessmentsQuery = 6,
    EvidenceRecordedQuery = 7,
    ModuleEngagementQuery = 8,
    NeurodifferenceRepresentation = 9,
    NeurodifferenceRepresentationUnpacked = 10,
    AssessmentsCompleted = 11,
}

export interface IGraphDataResponse {
    items: IGraphData[];
}

export interface IGraphData {
    title: string;
    value: number;
}

export interface IGraphConfig {
    chartId: number;
    title: string;
    infoText?: string;
    hideZeroValues: boolean;
    binaryKeyValue?: string;
    isBinary?: boolean;
    happyKey?: string;
}
