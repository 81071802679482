import { AutoCompleteInput } from '../styled-components';
import { Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';

import { Field } from '../fields/Field';
import { IUserFilter } from '../FilterDrawer';
import { UserFilterType } from '../../../../types';
import { useUserFilters } from '../../../../custom-hooks/useUserFilters';

interface IOwnProps {
    clientEnabledRoles: string[];
}

export const Roles: React.FC<IOwnProps> = ({ clientEnabledRoles }) => {
    const { inputHandler, inputValue } = useUserFilters({
        filterType: UserFilterType.Role,
    });

    const [clientEnabledRoleFilters, setClientEnabledRoleFilters] = useState<
        IUserFilter[]
    >([]);

    useEffect(() => {
        setClientEnabledRoleFilters(
            clientEnabledRoles.map((role) => {
                return {
                    name: role,
                    value: role,
                    tagName: `Role: ${role}`,
                    filterType: UserFilterType.Role,
                };
            })
        );
    }, [clientEnabledRoles]);

    return (
        <Field label='Role' labelFor='filter-roles'>
            <Autocomplete
                multiple
                disablePortal
                id='filter-roles'
                options={clientEnabledRoleFilters ?? []}
                getOptionLabel={(option) => option.name}
                onChange={inputHandler}
                value={inputValue}
                filterSelectedOptions
                openOnFocus
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <AutoCompleteInput
                            id='filter-roles'
                            inputProps={params.inputProps}
                        />
                    </div>
                )}
            />
        </Field>
    );
};
