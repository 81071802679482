import {
    CircularProgress,
    DialogActions,
    DialogContent,
    Dialog as MuiDialog,
    styled,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import LogoIcon from '../../assets/img/logo-icon.svg';
import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useAuth } from '../../custom-providers/AuthProvider';
import { useDebounce } from '../../custom-hooks/useDebounce';

const Wrapper = styled('div')(({ theme }) => ({
    height: 40,
    width: 150,
    position: 'fixed',
    right: 20,
    top: 'calc((100vh / 2) + 75px)',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    boxShadow: theme.cogShadows.lg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transform: 'rotate(90deg) translateY(-5px)',
    transformOrigin: 'center right',
    cursor: 'pointer',
    zIndex: 100,
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'rotate(90deg) translateY(0px)',
    },
    [theme.breakpoints.down(744)]: {
        display: 'none',
    },
}));

const MobileWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    borderRadius: 100,
    boxShadow: theme.cogShadows.base,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 100,
    color: theme.palette.common.white,
    [theme.breakpoints.up(744)]: {
        display: 'none',
    },
}));

const MobileImage = styled('div', {
    ...shouldForwardProps('isScrolling'),
})<{ isScrolling?: boolean }>(({ isScrolling, theme }) => ({
    background: theme.palette.common.white,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    padding: 8,
    borderBottomRightRadius: isScrolling ? 100 : 0,
    borderTopRightRadius: isScrolling ? 100 : 0,
    transition: 'all .3s ease-out',
}));

const MobileText = styled('div', {
    ...shouldForwardProps('isScrolling'),
})<{ isScrolling?: boolean }>(({ isScrolling, theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    padding: isScrolling ? '8px 0' : 8,
    maxWidth: isScrolling ? 0 : 300,
    opacity: isScrolling ? 0 : 1,
    overflow: 'hidden',
    transition: 'all .3s ease-out',
}));

const Text = styled('div')(({ theme }) => ({
    fontSize: pxToRem(16),
    fontWeight: 600,
    color: theme.palette.common.white,
    flex: '1 1 auto',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: theme.shape.borderRadius,
    transform: 'rotate(180deg)',
    paddingBottom: 5,
}));

const Logo = styled('div')(({ theme }) => ({
    height: 40,
    width: 40,
    backgroundColor: theme.palette.common.white,
    borderBottomRightRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 5,
    '& img': {
        transform: 'rotate(-90deg)',
    },
}));

const IFrameContainer = styled('div', {
    ...shouldForwardProps('isLoading'),
})<{ isLoading: boolean }>(({ theme }) => ({
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(744)]: {
        width: 'auto',
    },
}));

const Loader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 320,
    minWidth: 400,
}));

const IFrame = styled('iframe', {
    ...shouldForwardProps('isLoading'),
})<{ isLoading: boolean }>(({ isLoading, theme }) => ({
    display: isLoading ? 'none' : 'block',
    border: 'none',
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
}));

interface IOwnProps {
    surveyMonkeyKey: string;
}

const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0, 0, 2),
    },
}));

export const SurveyMonkey: React.FC<IOwnProps> = ({ surveyMonkeyKey }) => {
    const {
        state: {
            userConfig: { userId },
            clientConfig: { clientId },
        },
    } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);

    const debounceScroll = useDebounce(() => {
        setIsScrolling(false);
    }, 1000);

    const scrollHandler = (e: unknown) => {
        if (!isScrolling) {
            setIsScrolling(true);
        }

        debounceScroll(e);
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    const handleSrcChange = () => {
        setIsLoading(false);
    };

    return (
        <>
            <Wrapper onClick={() => setOpenDialog(true)}>
                <Text>
                    <span>Feedback</span>
                </Text>
                <Logo>
                    <img src={LogoIcon} alt='Logo Icon' />
                </Logo>
            </Wrapper>
            <MobileWrapper onClick={() => setOpenDialog(true)}>
                <MobileImage isScrolling={isScrolling}>
                    <img src={LogoIcon} alt='Logo Icon' />
                </MobileImage>
                <MobileText isScrolling={isScrolling}>
                    <span>Feedback</span>
                </MobileText>
            </MobileWrapper>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth
            >
                <DialogContent>
                    <IFrameContainer
                        id='survey-container'
                        isLoading={isLoading}
                    >
                        {isLoading && (
                            <Loader>
                                <CircularProgress />
                            </Loader>
                        )}
                        <IFrame
                            isLoading={isLoading}
                            src={`https://www.surveymonkey.co.uk/r/${surveyMonkeyKey}?user_id=${userId}&client_id=${clientId}`}
                            onLoad={handleSrcChange}
                        />
                    </IFrameContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        text='Close Survey'
                        onClick={() => setOpenDialog(false)}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};
