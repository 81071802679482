import { Typography, styled } from '@mui/material';

import { Accordion } from '../../../Accordion';
import { IDeepDivePageContent } from '../../../../types';
import { parseApiContent } from '../../../../utils/displayFunctions';
import { pxToRem } from '../../../../utils/style-functions';

const Wrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    maxWidth: 1016,
    margin: theme.spacing(0, 'auto', 8),
    padding: theme.spacing(0, 4, 0),
}));

const StrategyItem = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));
const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    lineHeight: '150%',
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(24),
        fontWeight: 600,
    },
}));

interface IOwnProps {
    img: string;
    reverse?: boolean;
    content: IDeepDivePageContent;
}

export const Strategies: React.FC<IOwnProps> = ({ content }) => {
    return (
        <Wrapper id='strategies'>
            <Title variant='h1'>{content.strategiesHeader}</Title>

            {content.strategiesText.map((para) => (
                <Typography
                    key={para}
                    sx={{ mb: 2, '&:last-of-type': { mb: 4 } }}
                >
                    {para}
                </Typography>
            ))}

            {content.domains.map((domain) => (
                <StrategyItem key={domain.id}>
                    <Accordion
                        title={domain.strategiesHeader}
                        content={parseApiContent(domain.strategiesList)}
                    />
                </StrategyItem>
            ))}
        </Wrapper>
    );
};

export default Strategies;
