import {
    DialogActions,
    DialogTitle,
    Dialog as MuiDialog,
    DialogContent as MuiDialogContent,
    Typography,
    styled,
} from '@mui/material';

import { Button } from '@cognassist/react-components';
import { DialogContentListItem } from './DialogContentListItem';
import { pxToRem } from '../../../../utils/style-functions';

const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(3),
}));

const ContentList = styled('div')(({ theme }) => ({
    backgroundColor: '#F5F9FF',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(2),
}));

interface IOwnProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FindOutMoreDialog: React.FC<IOwnProps> = ({ open, setOpen }) => (
    <MuiDialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='champion-support-dialog-title'
    >
        <DialogTitle
            id='champion-support-dialog-title'
            sx={{
                py: 3,
                fontWeight: 400,
                fontSize: pxToRem(24),
                lineHeight: pxToRem(36),
            }}
        >
            Support from a Neuro-inclusion Champion
        </DialogTitle>
        <MuiDialogContent sx={{ pb: 1 }}>
            <ContentWrapper>
                <Typography>
                    Neuro-inclusion Champions are here to help you in various
                    ways. They understand your organisation&apos;s culture and
                    can offer great support while keeping your conversations
                    private.
                </Typography>
                <Typography>
                    This is an opportunity to notify a Neuro-inclusion Champion
                    and get the support you or your team need.
                </Typography>
                <ContentList>
                    <Typography
                        sx={{
                            lineHeight: pxToRem(30),
                            fontSize: pxToRem(20),
                        }}
                    >
                        What it is
                    </Typography>
                    <DialogContentListItem
                        text={`A safe space to explore how to work and thrive with your or your team members' neuro-difference.`}
                    />
                    <DialogContentListItem
                        text={`An opportunity to access reasonable adjustment strategies with an informed specialist.`}
                    />
                    <DialogContentListItem
                        text={`Action-focused and will look at adjustments that you can test day to day.`}
                    />
                </ContentList>
            </ContentWrapper>
        </MuiDialogContent>
        <DialogActions sx={{ padding: 3 }}>
            <Button text='Close' onClick={() => setOpen(false)} />
        </DialogActions>
    </MuiDialog>
);
