import { ArrowUpRight, HelpCircle } from 'react-feather';
import {
    OldReportBanner,
    OldReportBannerText,
} from '../../../components/main-app/cognitive-map/old-report-banner-components';
import { styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { CaiDrawer } from '../../../components/main-app/cai/CaiDrawer';
import { Button as CogButton } from '@cognassist/react-components';
import { CognassistantBanner } from '../../../components/main-app/cognitive-map/CognassistantBanner';
import { ContentHelper } from '../../../components/main-app/cognitive-map/ContentHelper';
import { DomainSidebar } from '../../../components/main-app/cognitive-map/DomainSidebar';
import { Loading } from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { RoleDrawer } from '../../../components/main-app/cognitive-map/RoleDrawer';
import SpineChart from '../../../components/main-app/cognitive-map/SpineChart';
import { WhatItMeansModal } from '../../../components/main-app/cognitive-map/WhatItMeansModal';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useResizeObserver from 'use-resize-observer';

const CognitiveMapMainContentInner = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(1040)]: {
        overflowX: 'auto',
    },
}));

const GraphAndHeadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(4),
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    justifyContent: 'center',
    [theme.breakpoints.down(774)]: {
        margin: theme.spacing(2),
    },
}));

const GraphHeading = styled('h3')(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: 400,
    marginBottom: theme.spacing(4),
}));

const PageWrapper = styled('div')(() => ({
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
}));

const MainWrapper = styled('div')(({ theme }) => ({
    flex: '1 1 100%',
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(1440)]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

export const CognitiveMap = () => {
    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');
    const featureOldReportToggle = useFeatureIsOn('old-report-toggle');
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const {
        state: { cognitiveMapV1, openDomainDrawerId },
        dispatch,
    } = useCognitiveMap();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    useEffect(() => {
        document.title = 'My Cognitive Map';
    }, []);

    const { width, ref } = useResizeObserver();

    const isWrapped = width && width <= 1124;

    const onSpineChartClick = (domainId: number) => {
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: openDomainDrawerId === domainId ? 0 : domainId,
        });
    };

    if (!cognitiveMapV1) {
        return <Loading />;
    }

    if (!featureNewCognitiveMap) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <PageWrapper ref={ref}>
            {featureOldReportToggle && (
                <OldReportBanner>
                    <OldReportBannerText
                        to={`/learner/assessmentreport?learnerUserId=${userId}`}
                        target='_blank'
                        referrerPolicy='no-referrer'
                    >
                        You are viewing the new Cognitive Map, click here to
                        view the previous version.
                    </OldReportBannerText>
                    <ArrowUpRight color={theme.palette.common.white} />
                </OldReportBanner>
            )}
            <PageHeader
                title='My Cognitive Map'
                subText={
                    <ContentHelper
                        sx={{ flex: '1 1 400px' }}
                        content={cognitiveMapV1.staticContent.mainHeading}
                    />
                }
                action={
                    <CogButton
                        text={cognitiveMapV1.staticContent.explainerButton}
                        color='secondary'
                        startIcon={<HelpCircle />}
                        onClick={() => setDialogOpen(true)}
                    />
                }
            />
            <MainWrapper>
                <GraphAndHeadingWrapper>
                    <GraphHeading>
                        {cognitiveMapV1.staticContent.chartTitle}
                    </GraphHeading>
                    <CognitiveMapMainContentInner>
                        <SpineChart
                            domains={cognitiveMapV1.domains}
                            legend={{
                                leftChartHeading:
                                    cognitiveMapV1.staticContent
                                        .leftChartHeading,
                                rightChartHeading:
                                    cognitiveMapV1.staticContent
                                        .rightChartHeading,
                            }}
                            onClick={onSpineChartClick}
                        />
                    </CognitiveMapMainContentInner>
                    {!isWrapped && <CognassistantBanner />}
                </GraphAndHeadingWrapper>
                <DomainSidebar
                    topDomainText={cognitiveMapV1.staticContent.topDomainText}
                />
                {isWrapped && <CognassistantBanner />}
            </MainWrapper>
            <WhatItMeansModal
                cognitiveMap={cognitiveMapV1}
                setDialogOpen={setDialogOpen}
                dialogOpen={dialogOpen}
            />
            <RoleDrawer />
            <CaiDrawer />
        </PageWrapper>
    );
};
