import { Typography, styled } from '@mui/material';

import { Check as FeatherCheck } from 'react-feather';
import { pxToRem } from '../../../utils/style-functions';

export const LoadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 16px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(24),
    lineHeight: pxToRem(36),
    marginBottom: theme.spacing(3),
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    margin: theme.spacing(0, -3),
    padding: theme.spacing(2, 3, 2, 0),
}));

export const ListItemCheck = styled(FeatherCheck)(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: 25,
    padding: theme.spacing(0.3),
    flexShrink: 0,
    marginRight: theme.spacing(1),
    width: 20,
    height: 20,
}));
