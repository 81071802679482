import { useEffect, useState } from 'react';

import { styled } from '@mui/material';
import { Checkbox } from '@cognassist/react-components';

import { UserFilterType } from '../../../../types';
import { IUserFilter } from '../FilterDrawer';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const MyTeamWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
}));

const MyTeamLabel = styled('p')(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const myTeamFilter: IUserFilter = {
    name: 'MyTeam',
    value: 'MyTeam',
    tagName: 'Show only my team',
    filterType: UserFilterType.MyTeam,
};

export const MyTeam = () => {
    const {
        dispatch,
        state: { activeFilters },
    } = useUsers();

    const myTeamFilterIsActive = () => {
        return activeFilters.some((f) => f.filterType == UserFilterType.MyTeam);
    };

    const [filterToMyTeam, setFilterToMyTeam] = useState<boolean>(
        myTeamFilterIsActive()
    );

    useEffect(() => {
        setFilterToMyTeam(myTeamFilterIsActive());
    }, [activeFilters]);

    useEffect(() => {
        if (filterToMyTeam === true && !myTeamFilterIsActive()) {
            dispatch({ type: 'ADD_ACTIVE_FILTER', payload: myTeamFilter });
        } else if (filterToMyTeam === false && myTeamFilterIsActive()) {
            dispatch({ type: 'REMOVE_ACTIVE_FILTER', payload: myTeamFilter });
        }
    }, [filterToMyTeam]);

    const checkBoxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setFilterToMyTeam(true);
        } else {
            setFilterToMyTeam(false);
        }
    };

    return (
        <MyTeamWrapper>
            <Checkbox
                id='my-team'
                inputProps={{
                    'aria-label': 'show-only-my-team',
                }}
                checked={filterToMyTeam}
                onChange={checkBoxHandler}
            />
            <MyTeamLabel>Show only my team</MyTeamLabel>
        </MyTeamWrapper>
    );
};
