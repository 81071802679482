import { ReactElement } from 'react';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    '&:last-child': {
        marginBottom: 0,
    },
}));

export const Required = styled('p', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    color: hasError ? theme.palette.error.main : 'inherit',
}));

export const Label = styled('label', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: '600',
    color: hasError ? theme.palette.error.main : 'inherit',
}));

export const FieldWrapper = styled('div', {
    ...shouldForwardProps('hide'),
})<{ hide?: boolean }>(({ hide }) => ({
    display: hide ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
}));

interface IOwnProps {
    label: string;
    children: ReactElement;
    required?: boolean;
    labelFor?: string;
    labelId?: string;
    hasError?: boolean;
    hide?: boolean;
}

export const Field: React.FC<IOwnProps> = ({
    label,
    children,
    required = false,
    labelFor,
    labelId,
    hasError,
    hide,
}) => {
    const dynamicProps: {
        htmlFor?: string;
        id?: string;
    } = {};

    if (labelFor) {
        dynamicProps.htmlFor = labelFor;
    }

    if (labelId) {
        dynamicProps.id = labelId;
    }

    return (
        <Wrapper>
            <FieldWrapper hide={hide}>
                <Label {...dynamicProps}>{label}</Label>
                {required && <Required hasError={hasError}>Required</Required>}
            </FieldWrapper>

            {children}
        </Wrapper>
    );
};
