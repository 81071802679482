import { CogInput, ErrorText } from '../../styled-components';
import { useEffect, useRef } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../main-app/pre-assessment/Field';
import { useFormContext } from 'react-hook-form';

export const LastName: React.FC = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {
        formState: { errors },
        register,
    } = useFormContext<{ lastName: string }>();

    useEffect(() => {
        if (inputRef.current) {
            const element = inputRef.current;
            element.focus();
        }
    }, [inputRef.current]);

    const { ref, ...rest } = register('lastName', {
        required: 'Last name is required',
        maxLength: {
            value: 230,
            message: 'Please enter a maximum of 230 characters.',
        },
    });

    return (
        <Field
            label='Last name'
            labelFor='lastName'
            required={true}
            hasError={!!errors.lastName}
            hideTooltip={true}
        >
            <>
                <CogInput
                    {...rest}
                    id='lastName'
                    fullWidth
                    hasError={!!errors.lastName}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
