import { SxProps, Theme, Typography, styled } from '@mui/material';

import { ICognitiveMapContent } from '../../../types';
import React from 'react';
import { nanoid } from 'nanoid';

export const Wrapper = styled('div')(() => ({}));
export const Ul = styled('ul')(() => ({}));
export const Li = styled('li')(() => ({}));

interface IOwnProps {
    content: ICognitiveMapContent[];
    sx?: SxProps<Theme>;
    liStyles?: SxProps<Theme>;
    liIcon?: React.ReactNode;
    ulStyles?: SxProps<Theme>;
    pStyles?: SxProps<Theme>;
    listTitleStyles?: SxProps<Theme>;
    listWrapperStyles?: SxProps<Theme>;
}

export const ContentHelper: React.FC<IOwnProps> = ({
    content,
    sx,
    listTitleStyles,
    liStyles,
    liIcon,
    ulStyles,
    pStyles,
    listWrapperStyles,
}) => {
    return (
        <Wrapper sx={sx}>
            {content.map((c) => {
                if (c.type === 'paragraph') {
                    return c.value.map((v) => (
                        <Typography sx={pStyles} key={nanoid()}>
                            {v}
                        </Typography>
                    ));
                } else if (c.type === 'list') {
                    return (
                        <Wrapper key={nanoid()} sx={listWrapperStyles}>
                            {c.title && (
                                <Typography variant='h2' sx={listTitleStyles}>
                                    {c.title}
                                </Typography>
                            )}
                            <Ul sx={ulStyles}>
                                {c.value.map((v) => (
                                    <Li
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            ...liStyles,
                                        }}
                                        key={nanoid()}
                                    >
                                        {liIcon}
                                        {v}
                                    </Li>
                                ))}
                            </Ul>
                        </Wrapper>
                    );
                } else {
                    return null;
                }
            })}
        </Wrapper>
    );
};
