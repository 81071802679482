import {
    Area,
    AreaChart,
    Label,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { alpha, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useReportState } from '../../../../custom-providers/ReportProvider';

const data = [
    { pv: 0, name: 0 },
    { pv: 0.007, name: 1 },
    { pv: 0.03, name: 2 },
    { pv: 0.062, name: 3 },
    { pv: 0.09, name: 4 },
    { pv: 0.107, name: 5 },
    { pv: 0.112, name: 6 },
    { pv: 0.108, name: 7 },
    { pv: 0.098, name: 8 },
    { pv: 0.084, name: 9 },
    { pv: 0.07, name: 10 },
    { pv: 0.055, name: 11 },
    { pv: 0.045, name: 12 },
    { pv: 0.035, name: 13 },
    { pv: 0.026, name: 14 },
    { pv: 0.019, name: 15 },
    { pv: 0.014, name: 16 },
    { pv: 0.01, name: 17 },
    { pv: 0.008, name: 18 },
    { pv: 0.006, name: 19 },
    { pv: 0.0045, name: 20 },
    { pv: 0.003, name: 21 },
    { pv: 0.002, name: 22 },
    { pv: 0.001, name: 23 },
    { pv: 0.0005, name: 24 },
    { pv: 0, name: 25 },
];

const diversityIndexLookup = [
    { name: 0, values: [0] },
    { name: 1, values: [1] },
    { name: 2, values: [2, 3, 4, 5] },
    { name: 3, values: [6, 7, 8, 9, 10, 11, 12, 13, 14] },
    { name: 4, values: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24] },
    {
        name: 5,
        values: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
    },
    { name: 6, values: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46] },
    { name: 7, values: [47, 48, 49, 50, 51, 52, 53, 54, 55, 56] },
    { name: 8, values: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66] },
    { name: 9, values: [67, 68, 69, 70, 71, 72, 73] },
    { name: 10, values: [74, 75, 76, 77, 78, 79] },
    { name: 11, values: [80, 81, 82, 83, 84] },
    { name: 12, values: [85, 86, 87, 88] },
    { name: 13, values: [89, 90, 91] },
    { name: 14, values: [92, 93] },
    { name: 15, values: [94, 95] },
    { name: 16, values: [96] },
    { name: 17, values: [97] },
    { name: 18, values: [98] },
    { name: 19, values: [] },
    { name: 20, values: [99] },
    { name: 21, values: [] },
    { name: 22, values: [] },
    { name: 23, values: [] },
    { name: 24, values: [] },
    { name: 25, values: [100] },
];

interface ILabelProps {
    viewBox: { x: number; y: number; width: number };
    diversityIndex: number;
}

export const CustomLabel = (props: ILabelProps): JSX.Element => {
    const theme = useTheme();
    const {
        state: { isViewingAsManager },
    } = useReportState();

    return (
        <svg
            width={isViewingAsManager ? 128 : 116}
            height='52'
            xmlns='http://www.w3.org/2000/svg'
            x={props.viewBox.x + 5}
            y={props.viewBox.y + 30}
        >
            <g fill='none' fillRule='evenodd'>
                <rect
                    fill={theme.palette.text.primary}
                    x='7'
                    width={isViewingAsManager ? 121 : 109}
                    height='52'
                    rx='3'
                />
                <path
                    fill={theme.palette.text.primary}
                    d='m7.071 19 7.071 7.071-7.07 7.071L0 26.071z'
                />
                <rect
                    fill={theme.palette.secondary.main}
                    x='20'
                    y='14'
                    width='25'
                    height='25'
                    rx='4'
                />
                <text
                    fontFamily='Poppins, Helvetica, Arial, sans-serif'
                    fontSize='14'
                    fontWeight='600'
                    fill={theme.palette.common.white}
                >
                    <tspan
                        x='53.392'
                        y='32'
                        style={{
                            fill: theme.palette.common.white,
                            fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
                        }}
                    >
                        {isViewingAsManager ? 'Score' : 'You'}:{' '}
                        {props.diversityIndex}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export const CustomLabelRight = (props: ILabelProps): JSX.Element => {
    const theme = useTheme();
    const {
        state: { isViewingAsManager },
    } = useReportState();

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            x={props.viewBox.x - (isViewingAsManager ? 132 : 120)}
            y={props.viewBox.y + 30}
        >
            <g fill='none' fillRule='evenodd'>
                <rect
                    fill={theme.palette.text.primary}
                    fillRule='nonzero'
                    width={isViewingAsManager ? 121 : 109}
                    height='52'
                    rx='3'
                />
                <path
                    fill={theme.palette.text.primary}
                    fillRule='nonzero'
                    d='m109.071 21 7.071 7.071-7.07 7.071L102 28.071z'
                    style={{
                        transform: `translateX(${
                            isViewingAsManager ? '12px' : '0'
                        })`,
                    }}
                />
                <rect
                    fill={theme.palette.secondary.main}
                    fillRule='nonzero'
                    x='13'
                    y='14'
                    width='25'
                    height='25'
                    rx='4'
                />
                <text
                    fontFamily='Poppins, Helvetica, Arial, sans-serif'
                    fontSize='14'
                    fontWeight='600'
                    fill={theme.palette.common.white}
                >
                    <tspan
                        x='46.392'
                        y='32'
                        style={{
                            fill: theme.palette.common.white,
                            fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
                        }}
                    >
                        {isViewingAsManager ? 'Score' : 'You'}:{' '}
                        {props.diversityIndex}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export const CustomXAxisLabel = (props: ILabelProps): JSX.Element => {
    const theme = useTheme();
    return (
        <text
            fontFamily='FS Me'
            fontWeight='700'
            fill={theme.palette.text.primary}
            fontSize='14'
        >
            <tspan x={30} y={430}>
                0
            </tspan>
            <tspan x={30} dy={20}>
                Low diversity
            </tspan>
            <tspan x={props?.viewBox.width + 5} y={430}>
                100
            </tspan>
            <tspan x={props?.viewBox.width - 61} dy={20}>
                High diversity
            </tspan>
        </text>
    );
};

const customLegendFormatter = (value: string) => {
    return (
        <span
            style={{
                color: '#000',
            }}
        >
            {value}
        </span>
    );
};

interface IOwnProps {
    diversityIndex: number;
}

export const DiversityIndexGraph: React.FC<IOwnProps> = ({
    diversityIndex,
}) => {
    const theme = useTheme();
    const [refLine, setRefLine] = useState(0);

    const {
        state: { learnerName, isViewingAsManager },
    } = useReportState();

    useEffect(() => {
        const val = diversityIndexLookup.find((lookup) =>
            lookup.values.includes(diversityIndex)
        );

        if (val) {
            setRefLine(val.name);
        }
    }, [diversityIndex]);

    const LabelComponentRight = useCallback(
        (props: Pick<ILabelProps, 'viewBox'>) => (
            <CustomLabelRight diversityIndex={diversityIndex} {...props} />
        ),
        [diversityIndex]
    );
    const LabelComponent = useCallback(
        (props: Pick<ILabelProps, 'viewBox'>) => (
            <CustomLabel diversityIndex={diversityIndex} {...props} />
        ),
        [diversityIndex]
    );

    return (
        <ResponsiveContainer width='99%' height={460}>
            <AreaChart data={data} margin={{ bottom: 20 }}>
                <XAxis
                    height={30}
                    tick={false}
                    stroke={theme.palette.grey[200]}
                    strokeWidth={5}
                    padding={{ left: 10 }}
                    dataKey='name'
                    label={CustomXAxisLabel}
                ></XAxis>
                <YAxis
                    tick={false}
                    width={30}
                    stroke={theme.palette.grey[200]}
                    strokeWidth={5}
                    padding={{ bottom: 10 }}
                >
                    <Label
                        value='Number of people'
                        angle={-90}
                        style={{
                            fill: theme.palette.text.primary,
                            fontFamily: 'FS Me',
                            fontWeight: 700,
                            fontSize: 14,
                        }}
                    />
                </YAxis>
                <Legend
                    verticalAlign='top'
                    align='right'
                    iconType='circle'
                    wrapperStyle={{ top: -5 }}
                    formatter={customLegendFormatter}
                    payload={[
                        {
                            value: 'Population',
                            color: theme.palette.primary.main,
                            type: 'circle',
                        },
                        {
                            value: isViewingAsManager ? learnerName : 'You',
                            type: 'circle',
                            color: theme.palette.secondary.main,
                        },
                    ]}
                />
                <Area
                    type='monotone'
                    dataKey='pv'
                    stroke={theme.palette.primary.main}
                    strokeWidth={3}
                    fillOpacity={1}
                    fill={alpha(theme.palette.primary.main, 0.2)}
                    name='stuff'
                />
                <ReferenceLine
                    x={refLine}
                    stroke={theme.palette.secondary.main}
                    strokeDasharray='5 5'
                    strokeWidth={3}
                    label={
                        diversityIndex > 70
                            ? LabelComponentRight
                            : LabelComponent
                    }
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default DiversityIndexGraph;
