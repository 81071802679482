import { Link, useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.up(906)]: {
        display: 'none',
    },
}));
const MobileMenuWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 4),
    boxSizing: 'border-box',
}));
const MobileMenuHeader = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    height: 85,
}));
const MenuLogoLink = styled(Link)(() => ({
    height: 22,
}));
const MenuLogo = styled('img')(() => ({
    width: 120,
}));

interface IOwnProps {
    logo: string;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

export const MobileMenu: React.FC<IOwnProps> = ({
    children,
    logo,
    drawerOpen,
    setDrawerOpen,
}) => {
    const { search } = useLocation();

    const toggleDrawer =
        () => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawerOpen(!drawerOpen);
        };

    return (
        <Wrapper>
            <MenuIcon onClick={toggleDrawer()} />
            <Drawer
                anchor='top'
                open={drawerOpen}
                onClose={toggleDrawer()}
                style={{ zIndex: 1000 }}
            >
                <MobileMenuWrapper>
                    <MobileMenuHeader>
                        <MenuLogoLink
                            to={`/learner/assessmentreport${search}`}
                            onClick={() => {
                                setDrawerOpen(false);
                            }}
                        >
                            <MenuLogo src={logo} alt='Cognassist Logo' />
                        </MenuLogoLink>

                        <CloseIcon onClick={toggleDrawer()} />
                    </MobileMenuHeader>
                    {children}
                </MobileMenuWrapper>
            </Drawer>
        </Wrapper>
    );
};

export default MobileMenu;
