import React, { useContext, useMemo } from 'react';

import { useImmerReducer } from 'use-immer';

interface IReportContext {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    learnerName: string | undefined;
    isViewingAsManager: boolean;
    activeTab: string | undefined;
};

export const initialState = {
    learnerName: undefined,
    isViewingAsManager: false,
    activeTab: undefined,
};

type Action =
    | {
          type: 'SET_LEARNER_NANE';
          payload: { learnerName: string };
      }
    | {
          type: 'SET_IS_VIEWING_AS_MANAGER';
          payload: { isViewingAsManager: boolean };
      }
    | {
          type: 'SET_INITIAL_STATE';
          payload: {
              isViewingAsManager: boolean;
              learnerName: string;
          };
      }
    | {
          type: 'SET_ACTIVE_TAB';
          payload: { activeTab: string };
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_LEARNER_NANE':
            state.learnerName = action.payload.learnerName;
            break;
        case 'SET_IS_VIEWING_AS_MANAGER':
            state.isViewingAsManager = action.payload.isViewingAsManager;
            break;
        case 'SET_INITIAL_STATE':
            state.learnerName = action.payload.learnerName;
            state.isViewingAsManager = action.payload.isViewingAsManager;
            break;
        case 'SET_ACTIVE_TAB':
            state.activeTab = action.payload.activeTab;
            break;
    }
};

export const ReportContext = React.createContext<IReportContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const ReportProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const ReportContextMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <ReportContext.Provider value={ReportContextMemo}>
            {children}
        </ReportContext.Provider>
    );
};

export const useReportState = (): IReportContext => useContext(ReportContext);
