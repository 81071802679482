import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export const validateReturnURL = (returnurl: string): boolean => {
    let url;
    try {
        url = new URL(returnurl);
        const hostArr = url.hostname.split('.');
        return (
            (hostArr[hostArr.length - 1] === 'com' &&
                hostArr[hostArr.length - 2] === 'cognassist') ||
            url.hostname === 'localhost'
        );
    } catch (e) {
        return false;
    }
};

export const useReturnUrl = (legacyReturnUrl: string) => {
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();

    const returnUrl = searchParams.get('returnurl') ?? legacyReturnUrl;

    const backToCognassistHandler = useCallback(() => {
        if (returnUrl) {
            const isValidReturnUrl = validateReturnURL(returnUrl);
            if (isValidReturnUrl) {
                window.location.href = returnUrl;
            } else {
                enqueueSnackbar('Something went wrong.', {
                    variant: 'error',
                });
            }
        } else {
            enqueueSnackbar('Something went wrong.', {
                variant: 'error',
            });
        }
    }, [returnUrl]);

    return backToCognassistHandler;
};
