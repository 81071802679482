import { useRef, useEffect, EffectCallback, DependencyList } from 'react';

export const useEffectAfterMount = (
    effect: EffectCallback,
    deps: DependencyList
) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return effect();
        else isMounted.current = true;
    }, deps);

    // reset on unmount; in React 18, components can mount again
    useEffect(
        () => () => {
            isMounted.current = false;
        },
        []
    );
};
