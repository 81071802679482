import { alpha, styled } from '@mui/material';

import { X } from 'react-feather';

export const Header = styled('div')(() => ({
    position: 'relative',
    width: '100%',
}));

export const CloseIconWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 100,
    backgroundColor: alpha(theme.palette.common.white, 0.7),
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const CloseIcon = styled(X)(({ theme }) => ({
    color: theme.palette.text.primary,
}));
