import {
    DashboardType,
    useHasDashboardAccess,
} from '../../../custom-hooks/useHasDashboardAccess';
import { useEffect, useState } from 'react';

import DashboardAdmin from '../../../components/main-app/dashboard/DashboardAdmin';
import DashboardSelfOnboardingLearner from '../../../components/main-app/dashboard/DashboardSelfOnboardingLearner';
import DashboardUnderDevelopment from '../../../components/main-app/dashboard/DashboardUnderDevelopment';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';

const Dashboard: React.FC = () => {
    const { dashboardType } = useHasDashboardAccess();
    const {
        state: {
            userConfig: { userId, name },
        },
    } = useAuth();

    const { getLearnerDetails } = endpoints();

    const [displayName, setDisplayName] = useState<string>('');

    const getPrefferedName = async (userId: string) => {
        const { data } = await getLearnerDetails(userId);
        setDisplayName(data?.preferredName ?? name?.split(' ')[0] ?? '');
    };

    useEffect(() => {
        document.title = 'Dashboard';

        if (userId) {
            getPrefferedName(userId);
        }
    }, []);

    const getDashboard = () => {
        switch (dashboardType) {
            case DashboardType.SYSADMIN:
                return <DashboardAdmin />;
            case DashboardType.CLIENTADMIN:
                return <DashboardAdmin />;
            case DashboardType.TUTORMANAGER:
                return <DashboardUnderDevelopment />;
            case DashboardType.TUTOR:
                return <DashboardUnderDevelopment />;
            case DashboardType.LEARNER:
                return <DashboardUnderDevelopment />;
            case DashboardType.SELFONBOARDINGLEARNER:
                return <DashboardSelfOnboardingLearner />;
            case DashboardType.SELFONBOARDINGOTHER:
                return <DashboardUnderDevelopment />;
            default:
                return <DashboardUnderDevelopment />;
        }
    };

    return (
        <>
            <PageHeader title={`Welcome ${displayName}`} />
            <MainContentInner>{getDashboard()}</MainContentInner>
        </>
    );
};

export default Dashboard;
