import { IDraggableModule, IPriorityModule } from '../../types';
import { Tooltip, Typography, styled, useTheme } from '@mui/material';

import { InfoIcon } from '../styled-components';
import { PriorityModuleItem } from './PriorityModuleItem';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';

const PriorityModulesSection = styled('div')(() => ({
    padding: '32px 24px 16px',
}));
const Header = styled('div')(() => ({
    marginBottom: 16,
}));
const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
}));

const Title = styled(Typography)(() => ({
    fontWeight: 600,
}));

const IconWrapper = styled('div')(({ theme }) => ({
    height: 24,
    width: 24,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
}));

export const DropLocation = styled('div', {
    ...shouldForwardProps('isOver'),
})<{ isOver?: boolean }>(({ isOver, theme }) => ({
    backgroundColor: isOver
        ? theme.palette.grey[50]
        : theme.palette.common.white,
    width: '100%',
    height: 315,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.cogShadows.base,
    border: '1px dashed #CFD8E6',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
}));

export const DropLocationInline = styled('div', {
    ...shouldForwardProps('isOver'),
})<{ isOver?: boolean }>(({ isOver, theme }) => ({
    height: '66px',
    width: '100%',
    backgroundColor: isOver ? theme.palette.grey[50] : 'transparent',
}));

export const PriorityModules: React.FC = () => {
    const {
        state: { priorityModules },
        dispatch,
    } = useFLP();

    const theme = useTheme();

    const removeModule = (id: string) => {
        dispatch({
            type: 'REMOVE_PRIORITY_MODULE',
            payload: id,
        });
    };

    const moveModule = useCallback(
        (dragIndex: number, hoverIndex: number, item?: IPriorityModule) => {
            dispatch({
                type: 'ARRANGE_PRIORITY_MODULES',
                payload: {
                    dragIndex,
                    hoverIndex,
                    item,
                },
            });
        },
        []
    );

    const [{ isOver }, drop] = useDrop<
        IDraggableModule,
        void,
        { isOver: boolean }
    >({
        accept: 'ROW',
        drop(item) {
            dispatch({
                type: 'ADD_PRIORITY_MODULE',
                payload: item,
            });
        },
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
            };
        },
        canDrop(item) {
            return !priorityModules.find((module) => module.id === item.id);
        },
    });

    return (
        <PriorityModulesSection>
            <Header>
                <TopWrapper>
                    <Title variant='h4'>
                        Priority modules ({priorityModules.length})
                    </Title>
                    <Tooltip
                        title='Modules removed from the priority modules list will appear in the upcoming modules table.'
                        arrow
                    >
                        <IconWrapper>
                            <InfoIcon fill={theme.palette.primary.light} />
                        </IconWrapper>
                    </Tooltip>
                </TopWrapper>
                <Typography variant='body1'>
                    Click and drag modules to reorder them.
                </Typography>
            </Header>
            <div id='priorityModulesWrapper'>
                {!priorityModules.length ? (
                    <DropLocation ref={drop} isOver={isOver}>
                        <span>Drop your module here</span>
                    </DropLocation>
                ) : null}
                {priorityModules.map((module, index) => (
                    <PriorityModuleItem
                        key={module.id}
                        index={index}
                        module={module}
                        removeModule={removeModule}
                        moveModule={moveModule}
                    />
                ))}
                {priorityModules.length ? (
                    <DropLocationInline ref={drop} isOver={isOver} />
                ) : null}
            </div>
        </PriorityModulesSection>
    );
};
