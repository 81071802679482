import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { styled } from '@mui/material';

export const ContentWrapper = styled('div')(() => ({
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
}));

export const InnerContentWrapper = styled('div', {
    ...shouldForwardProps('isMobile', 'isTablet'),
})<{ isMobile: boolean; isTablet: boolean }>(
    ({ isMobile, isTablet, theme }) => ({
        width: '100%',
        padding: isMobile
            ? theme.spacing(2)
            : isTablet
              ? theme.spacing(3, 2)
              : theme.spacing(4),
    })
);

export const DotsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}));

export const Dot = styled('div', {
    ...shouldForwardProps('active'),
})<{ active?: boolean }>(({ active, theme }) => ({
    width: 13,
    height: 13,
    borderRadius: 25,
    background: active ? theme.palette.text.primary : '#F6F7FB',
    cursor: 'pointer',
}));
