import { AccountSettingsInput, ButtonWrapper } from '../../shared-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@cognassist/react-components';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../../../styled-components';
import { IChampionUserProfileResponse } from '../../../../../types';
import { Label } from '..';
import { Typography } from '@mui/material';

const PLACEHOLDER =
    'E.g. "I have worked in several roles with neuro-distinct teams and helped to foster neuro-inclusive practices so that everyone, no matter their neuro-difference, can thrive in their roles. I recently received an ILM certification in the Cognassist Neuro-Inclusion: Champion training and I look forward to providing support and advice to those who need it."';

interface IFormFields {
    bio: string | null;
}

interface IOwnProps {
    isSmall: boolean;
    loading: boolean;
    championDetails: IChampionUserProfileResponse;
    onSave: (bio: string | null) => Promise<void>;
}

export const Bio: React.FC<IOwnProps> = ({
    isSmall,
    loading,
    championDetails,
    onSave,
}) => {
    const methods = useForm<IFormFields>({
        defaultValues: {
            bio: championDetails.bio,
        },
    });

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = methods;

    register('bio', {
        maxLength: {
            value: 500,
            message:
                'You have gone over the 500 character limit for this field, please reduce it and try again.',
        },
    });

    const save: SubmitHandler<IFormFields> = async (formData) => {
        await onSave(formData.bio);
    };

    return (
        <FormProvider {...methods}>
            <Label htmlFor='bio'>Bio</Label>
            <Typography sx={{ mb: 3 }}>
                Add a short description about yourself so your peers can learn
                more about you when searching for Champions to support them.
            </Typography>
            <AccountSettingsInput
                multiline
                hasError={!!errors.bio}
                id='bio'
                placeholder={PLACEHOLDER}
                minRows={7}
                sx={{
                    width: '100%',
                    padding: '17px',
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('bio', e.target.value, {
                        shouldValidate: true,
                    });
                }}
                defaultValue={championDetails.bio ?? undefined}
            />
            <ErrorMessage
                errors={errors}
                name='bio'
                render={({ message }) => <ErrorText>{message}</ErrorText>}
            />
            <ButtonWrapper isSmall={isSmall}>
                <Button
                    text='Save'
                    loading={loading}
                    disabled={!!errors.bio}
                    onClick={() => handleSubmit(save)()}
                />
            </ButtonWrapper>
        </FormProvider>
    );
};
