import { FormControl, MenuItem, Select } from '@mui/material';

import { ChampionSupportRequestType } from '../../../../types';
import { CogInput } from '../../../styled-components';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';

const options = [
    { label: 'To support myself', value: ChampionSupportRequestType.ForMyself },
    {
        label: 'To support someone else',
        value: ChampionSupportRequestType.ForSomeoneElse,
    },
];

export const RequestType = () => {
    const {
        formState: { errors },
        register,
    } = useFormContext<{ supportRequestType: ChampionSupportRequestType }>();

    return (
        <Field
            label='Who is your request for?'
            labelId='requestTypeLabel'
            required
            hasError={!!errors.supportRequestType}
        >
            <FormControl fullWidth>
                <Select
                    {...register('supportRequestType', {
                        validate: (v) => [1, 2].includes(v),
                    })}
                    defaultValue={''}
                    labelId='requestTypeLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby': 'requestTypeLabel',
                            }}
                            hasError={!!errors.supportRequestType}
                            useThinnerBorder
                        />
                    }
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Field>
    );
};
