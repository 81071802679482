import { styled } from '@mui/material';
import { CogInput, ErrorText } from '../../../styled-components';
import { useEffect, useRef } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
}));

interface IOwnProps {
    validDomains: string[];
    restrictDomains: boolean;
}

export const Email: React.FC<IOwnProps> = ({
    validDomains,
    restrictDomains,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {
        formState: { errors },
        register,
    } = useFormContext<{ email: string }>();

    useEffect(() => {
        if (inputRef.current) {
            const element = inputRef.current;
            element.focus();
        }
    }, [inputRef.current]);

    const { ref, ...rest } = register('email', {
        required: 'Please enter an email address to invite',
        maxLength: {
            value: 320,
            message: 'Please enter a maximum of 320 characters.',
        },
        validate: (val) => {
            const valArr = val.split('@');
            const domain = valArr[valArr.length - 1];

            if (
                !restrictDomains ||
                validDomains.includes(domain.toLowerCase())
            ) {
                return true;
            }

            return 'Please use a valid company email address';
        },
        pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Please enter a valid email address',
        },
    });

    return (
        <Wrapper>
            <label id='EmailLabel' htmlFor='email' className='visually-hidden'>
                Add email address to invite
            </label>
            <CogInput
                {...rest}
                id='email'
                fullWidth
                hasError={!!errors.email}
                placeholder='email address'
                ref={(e: HTMLInputElement) => {
                    ref(e);
                    inputRef.current = e;
                }}
                inputProps={{
                    id: 'email',
                    'aria-labelledby': 'EmailLabel',
                }}
            />
            <ErrorMessage
                errors={errors}
                name='email'
                render={({ message }) => <ErrorText>{message}</ErrorText>}
            />
        </Wrapper>
    );
};
