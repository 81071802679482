import { Check as FeatherCheck, X as FeatherCross } from 'react-feather';
import { styled, useTheme } from '@mui/material';

import { pxToRem } from '../../utils/style-functions';
import { shouldForwardProps } from '../../utils/shouldForwardProp';

const IsValidItem = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& p': {
        margin: 0,
        [theme.breakpoints.down(744)]: {
            fontSize: pxToRem(14),
            lineHeight: pxToRem(15),
        },
    },
}));

const FeedbackCircle = styled('div', {
    ...shouldForwardProps('backgroundColor'),
})<{ backgroundColor?: string }>(({ backgroundColor, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    backgroundColor: backgroundColor,
    borderRadius: '50px',
    marginRight: theme.spacing(1),
}));

interface INewPasswordStrength {
    passwordLength: boolean;
    lowercase: boolean;
    uppercase: boolean;
    number: boolean;
}

interface IOwnProps {
    passwordStrength: INewPasswordStrength;
    value: string;
}

export const NewPasswordStrength: React.FC<IOwnProps> = ({
    passwordStrength,
    value,
}) => {
    return (
        <div id='validation'>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.passwordLength}
                    value={value}
                />
                <p>At least 8 characters long.</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.lowercase}
                    value={value}
                />
                <p>At least one lowercase letter (a-z).</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.uppercase}
                    value={value}
                />
                <p>At least one upper case letter (A-Z).</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid isValid={passwordStrength.number} value={value} />
                <p>At least one number (0-9).</p>
            </IsValidItem>
        </div>
    );
};

const CheckValid: React.FC<{ isValid: boolean; value: string }> = ({
    isValid,
    value,
}) => {
    const theme = useTheme();

    if (value.length === 0) {
        return <FeedbackCircle backgroundColor={theme.palette.grey[100]} />;
    }
    return isValid ? (
        <FeedbackCircle backgroundColor={theme.palette.success.main}>
            <FeatherCheck color={theme.palette.common.white} size={18} />
        </FeedbackCircle>
    ) : (
        <FeedbackCircle backgroundColor={theme.palette.error.main}>
            <FeatherCross color={theme.palette.common.white} size={18} />
        </FeedbackCircle>
    );
};
