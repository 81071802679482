import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    styled,
} from '@mui/material';
import { EthnicGroup, Gender as IGender } from '../../../../types';

import { ChevronDown } from 'react-feather';
import { EthnicGroupSelect } from './fields/EthnicGroupSelect';
import { FirstLanguageRadio } from './fields/FirstLanguageRadio';
import { Gender as GenderSelect } from './fields/Gender';
import { pxToRem } from '../../../../utils/style-functions';

const Title = styled('h2')(() => ({
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    fontWeight: 'normal',
}));

interface IOwnProps {
    gender: IGender | '';
    ethnicGroup: EthnicGroup | '';
    isSmall?: boolean;
}

export const AdditionalInformation: React.FC<IOwnProps> = ({
    gender,
    ethnicGroup,
    isSmall = false,
}) => {
    return (
        <Accordion
            disableGutters
            elevation={0}
            sx={{
                mt: 4,
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls='additional-information-details'
                id='additional-information-title'
            >
                <Title>Additional information (optional)</Title>
            </AccordionSummary>
            <AccordionDetails>
                <FirstLanguageRadio isSmall={isSmall} />
                <GenderSelect defaultValue={gender} isSmall={isSmall} />
                <EthnicGroupSelect
                    defaultValue={ethnicGroup}
                    isSmall={isSmall}
                />
            </AccordionDetails>
        </Accordion>
    );
};
