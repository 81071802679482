import { formatISO, isValid } from 'date-fns';

const resetToUTC = (date: string) => {
    if (date.split('+').length > 1) {
        return `${date.split('+')[0]}Z`;
    } else {
        const dateArr = date.split('-');

        if (dateArr.length > 3) {
            dateArr.pop();
            return `${dateArr.join('-')}Z`;
        }
        return date;
    }
};

export const parseDob = (date: Date) => {
    const isoDate = formatISO(date);
    return resetToUTC(isoDate);
};

export const getDob = (dob: string | null | undefined) => {
    if (dob && isValid(new Date(dob))) {
        return new Date(dob);
    }
    return undefined;
};
