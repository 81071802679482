import { Bullet, BulletList } from '../styled-components';
import { IDiversityIndexData, IProcessingBiasData } from '../../../types';
import { ListItemText, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import CognitiveDomains from '../CognitiveDomains';
import DiversityIndex from '../DiversityIndex';
import Drawers from '../Drawers';
import Footer from '../Footer';
import Hero from '../Hero';
import { Loading } from '../../Loading';
import ProcessingBias from '../ProcessingBias';
import Tabs from '../Tabs';
import capitaliseFirstLetter from '../../../utils/transformFirstLetter';
import { endpoints } from '../../../api/endpoints';
import imgEmployeeHero from '../../../assets/img/afro-man.png';
import { useGetUsers } from '../../../custom-hooks/useGetUsers';
import { useLearnerId } from '../../../custom-hooks/useLearnerId';
import { useNavigate } from 'react-router-dom';
import { useReportState } from '../../../custom-providers/ReportProvider';

const SupplementContainer = styled('div')(({ theme }) => ({
    textAlign: 'center',
    maxWidth: 1082,
    margin: 'auto',
    padding: theme.spacing(0, 4, 4),
    [theme.breakpoints.down(744)]: {
        textAlign: 'left',
    },
}));

const And = styled((props) => <span {...props}>and</span>)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 400,
}));

export const LearnerHomepage: React.FC = () => {
    const { userData } = useGetUsers();

    const {
        state: { learnerName },
        dispatch,
    } = useReportState();

    const { getProcessingBias, getDiversityIndex, learnerReportViewed } =
        endpoints();

    const navigate = useNavigate();
    const learnerUserId = useLearnerId();

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [processingBiasData, setProcessingBiasData] =
        useState<IProcessingBiasData>();
    const [diversityIndexData, setDiversityIndexData] =
        useState<IDiversityIndexData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        document.title = 'Cognassist Learner Report';
        document.body.style.scrollPaddingTop = '150px';

        setIsMobile(window.innerWidth < 744);
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (userData.firstName) {
            dispatch({
                type: 'SET_LEARNER_NANE',
                payload: {
                    learnerName: userData.firstName,
                },
            });
        }
    }, [userData]);

    useEffect(() => {
        if (learnerUserId) {
            getPageData();
            learnerReportViewed(learnerUserId);
        }
    }, [learnerUserId]);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth < 744);
    };

    const getPageData = async () => {
        const [
            { data: pbResponse, error: pbError },
            { data: diResponse, error: diError },
        ] = await Promise.all([
            getProcessingBias({
                learnerUserId,
                reportType: 1,
            }),
            getDiversityIndex({
                learnerUserId,
                reportType: 1,
            }),
        ]);

        if (pbError || diError) {
            navigate('/something-went-wrong', { replace: true });
        }

        if (pbResponse && diResponse) {
            setProcessingBiasData(pbResponse);
            setDiversityIndexData(diResponse);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Hero
                    title={`${capitaliseFirstLetter(
                        learnerName ?? ''
                    )}, Welcome to your cognitive profile`}
                    heroImage={imgEmployeeHero}
                    paragraphs={[
                        'Cognition, or the way we think, process information and experience the world around us, is unique for everyone.',
                    ]}
                    supplementElement={
                        <SupplementContainer>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '2rem',
                                    mb: 3,
                                }}
                            >
                                Your unique cognitive profile consists of your:
                            </Typography>
                            {isMobile ? (
                                <BulletList>
                                    <Bullet>
                                        <ListItemText>
                                            Cognitive Domain Scores
                                        </ListItemText>
                                    </Bullet>
                                    <Bullet>
                                        <ListItemText>
                                            Diversity Index
                                        </ListItemText>
                                    </Bullet>
                                    <Bullet>
                                        <ListItemText>
                                            Processing Bias
                                        </ListItemText>
                                    </Bullet>
                                </BulletList>
                            ) : (
                                <Typography
                                    color='primary'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                        mb: 1,
                                    }}
                                >
                                    Cognitive Domain Scores, Diversity Index,{' '}
                                    <And /> Processing Bias.
                                </Typography>
                            )}
                            <Typography>
                                Together, this offers you practical insights
                                into how you process information and how you can
                                best work to your strengths.
                            </Typography>
                            <Typography>
                                Click the tabs below to view each part of your
                                profile.
                            </Typography>
                        </SupplementContainer>
                    }
                />
            )}

            {processingBiasData &&
                diversityIndexData &&
                (isMobile ? (
                    <Drawers
                        drawers={[
                            {
                                name: 'Cognitive Domains',
                                element: <CognitiveDomains />,
                            },
                            {
                                name: 'Diversity Index',
                                element: (
                                    <DiversityIndex
                                        graphData={diversityIndexData}
                                    />
                                ),
                            },
                            {
                                name: 'Processing Bias',
                                element: (
                                    <ProcessingBias
                                        graphData={processingBiasData}
                                    />
                                ),
                            },
                        ]}
                    />
                ) : (
                    <Tabs
                        tabs={[
                            {
                                name: 'Cognitive Domains',
                                element: <CognitiveDomains />,
                            },
                            {
                                name: 'Diversity Index',
                                element: (
                                    <DiversityIndex
                                        graphData={diversityIndexData}
                                    />
                                ),
                            },
                            {
                                name: 'Processing Bias',
                                element: (
                                    <ProcessingBias
                                        graphData={processingBiasData}
                                    />
                                ),
                            },
                        ]}
                    />
                ))}

            <Footer isNewFooter={true} />
        </>
    );
};

export default LearnerHomepage;
