import {
    Dot,
    DotsWrapper,
} from '../../../../components/main-app/cognitive-map-v2/shared-components';
import { Typography, TypographyProps, styled } from '@mui/material';
import {
    getFontSize,
    getLineHeight,
} from '../../../../components/main-app/cognitive-map-v2/helpers';

import { Button } from '@cognassist/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICognitiveDomainV2 } from '../../../../types';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';

const Card = styled('div', {
    ...shouldForwardProps('isMobile', 'show'),
})<{ isMobile: boolean; show: boolean }>(({ isMobile, theme, show }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: isMobile ? theme.spacing(2) : theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    display: show ? 'block' : 'none',
}));

const TopDomainBadge = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 25,
    padding: theme.spacing(0.25, 1),
    fontWeight: 600,
    fontSize: pxToRem(12),
    marginBottom: theme.spacing(2),
    display: 'inline-block',
}));

const CardTitle = styled(
    ({ children, ...props }: TypographyProps) => (
        <Typography {...props} variant='h3'>
            {children}
        </Typography>
    ),
    {
        ...shouldForwardProps('isMobile'),
    }
)<{ isMobile: boolean }>(({ isMobile, theme }) => ({
    fontWeight: 500,
    fontSize: getFontSize(isMobile),
    lineHeight: getLineHeight(isMobile),
    marginBottom: theme.spacing(2),
}));

const PaginationOuterWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const FAIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 25,
    padding: '2px 6px',
    cursor: 'pointer',
}));

interface IOwnProps {
    domain: ICognitiveDomainV2;
    show: boolean;
}

const DomainCard: React.FC<IOwnProps> = ({ domain, show }) => {
    const {
        state: {
            openDomainDrawerId,
            activeDomainExplainer,
            screenSize,
            cognitiveMapV2,
        },
        dispatch,
    } = useCognitiveMap();

    const exploreMore = (domainId: number) => {
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: openDomainDrawerId === domainId ? 0 : domainId,
        });
    };

    const domainOrder = cognitiveMapV2?.domains.reduce(
        (acc: number[], current) => {
            acc.push(current.domainId);
            return acc;
        },
        []
    );

    const handlePagination = (targetDomain: number) => {
        dispatch({
            type: 'SET_OPEN_DOMAIN_DRAWER_ID',
            payload: 0,
        });

        if (targetDomain === -1 && domainOrder) {
            dispatch({
                type: 'SET_ACTIVE_DOMAIN_EXPLAINER',
                payload:
                    domainOrder[
                        domainOrder.indexOf(activeDomainExplainer) - 1
                    ] || domainOrder[8],
            });
        } else if (targetDomain === 0 && domainOrder) {
            dispatch({
                type: 'SET_ACTIVE_DOMAIN_EXPLAINER',
                payload:
                    domainOrder[
                        domainOrder.indexOf(activeDomainExplainer) + 1
                    ] || domainOrder[0],
            });
        } else {
            dispatch({
                type: 'SET_ACTIVE_DOMAIN_EXPLAINER',
                payload: targetDomain,
            });
        }
    };

    return (
        <Card isMobile={screenSize.isMobile} show={show}>
            {domain.topDomain && (
                <TopDomainBadge>
                    <FontAwesomeIcon
                        icon={byPrefixAndName.far['star']}
                        style={{ marginRight: 4 }}
                    />
                    Top Domain
                </TopDomainBadge>
            )}
            <CardTitle isMobile={screenSize.isMobile}>
                {domain.domainName}
            </CardTitle>
            <Typography sx={{ mb: 2 }}>{domain.explanation}</Typography>
            <Button
                text='Explore this Domain'
                onClick={() => {
                    exploreMore(domain.domainId);
                }}
                sx={{ mb: 3 }}
            ></Button>
            <PaginationOuterWrapper>
                <div onClick={() => handlePagination(-1)}>
                    <FAIcon icon={byPrefixAndName.fas['chevron-left']} />
                </div>
                <DotsWrapper>
                    {domainOrder?.map((domainId) => (
                        <Dot
                            key={domainId}
                            active={activeDomainExplainer === domainId}
                            onClick={() => handlePagination(domainId)}
                        />
                    ))}
                </DotsWrapper>
                <div onClick={() => handlePagination(0)}>
                    <FAIcon icon={byPrefixAndName.fas['chevron-right']} />
                </div>
            </PaginationOuterWrapper>
        </Card>
    );
};

export default DomainCard;
