import { BusinessType } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';
import { useEffect } from 'react';
import { useIsSelfOnboardingClient } from './useIsSelfOnboardingClient';

export const usePendo = () => {
    const {
        state: {
            userConfig: {
                userId,
                roles,
                hasVerifiedEmail,
                championStatus,
                isChampion,
                hasTakenAssessment: assessmentComplete,
            },
            clientConfig: { clientId, businessType },
        },
    } = useAuth();

    const isSelfOnboardingClient = useIsSelfOnboardingClient();

    const isAutoTest = window.location.hostname === '127.0.0.1';

    useEffect(() => {
        if (
            userId &&
            businessType === BusinessType.ENTERPRISE &&
            !isAutoTest &&
            assessmentComplete !== undefined &&
            isChampion !== undefined
        ) {
            window.pendo.initialize({
                visitor: {
                    id: userId,
                    role: roles?.join(', '),
                    isSelfOnboardingClient,
                    assessmentComplete,
                    championStatus: isChampion
                        ? championStatus?.toString()
                        : null,
                    hasVerifiedEmail,
                    clientId,
                },

                account: {
                    id: clientId,
                },
            });
        }
    }, [
        userId,
        businessType,
        isAutoTest,
        assessmentComplete,
        championStatus,
        isChampion,
    ]);
};
