import { pxToRem } from '../../utils/style-functions';
import { styled } from '@mui/material';

const FooterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    gap: theme.spacing(4),
    [theme.breakpoints.down(744)]: {
        flexFlow: 'column',
        gap: 0,
        alignItems: 'center',
        paddingTop: theme.spacing(1.5),
    },
}));

const FooterParagraph = styled('p')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: pxToRem(12),
    lineHeight: '24px',
}));

const FooterLink = styled('a')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: pxToRem(12),
    lineHeight: '24px',
    textDecoration: 'none',
}));

export const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <FooterParagraph>
                © {new Date().getFullYear()} Cognassist. All Rights Reserved.
            </FooterParagraph>
            <FooterLink
                href='https://cognassist.com/privacy-policy/'
                aria-label='Read the Privacy Policy here'
                target='_blank'
            >
                Privacy
            </FooterLink>
            <FooterLink
                href='https://cognassist.force.com/support/s/contactsupport?language=en_US'
                aria-label='Find Support here'
                target='_blank'
            >
                Support
            </FooterLink>
        </FooterWrapper>
    );
};

export default Footer;
