import { BusinessType } from '../types';
import { Userpilot } from 'userpilot';
import { useAuth } from '../custom-providers/AuthProvider';
import { useConfig } from '../custom-providers/ConfigProvider';
import { useEffect } from 'react';
import { useIsSelfOnboardingClient } from './useIsSelfOnboardingClient';

export const useUserPilot = () => {
    const isSelfOnboardingClient = useIsSelfOnboardingClient();

    const isAutoTest = window.location.hostname === '127.0.0.1';

    const {
        state: {
            userConfig: {
                userId,
                roles,
                hasVerifiedEmail,
                championStatus,
                isChampion,
                hasTakenAssessment: assessmentComplete,
            },
            clientConfig: { clientId, businessType },
        },
    } = useAuth();

    const {
        state: {
            appConfig: { environment },
        },
    } = useConfig();

    useEffect(() => {
        if (
            environment &&
            userId &&
            businessType === BusinessType.ENTERPRISE &&
            !isAutoTest &&
            assessmentComplete !== undefined &&
            isChampion !== undefined
        ) {
            Userpilot.identify(userId, {
                clientId,
                role: roles?.join(', '),
                isSelfOnboardingClient,
                assessmentComplete,
                championStatus: isChampion ? championStatus?.toString() : null,
                hasVerifiedEmail,
                company: {
                    id: clientId,
                },
            });
        }
    }, [
        userId,
        businessType,
        isAutoTest,
        assessmentComplete,
        championStatus,
        environment,
        isChampion,
    ]);
};
