import React, { useContext, useMemo } from 'react';

import { useImmerReducer } from 'use-immer';

interface ILearnerReportProvider {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    learnerName?: string;
};

export const initialState = {
    learnerName: undefined,
};

type Action = {
    type: 'SET_LEARNER_NAME';
    payload: string;
};

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_LEARNER_NAME':
            state.learnerName = action.payload;
            break;
    }
};

export const LearnerReport = React.createContext<ILearnerReportProvider>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const LearnerReportProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const LearnerReportMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <LearnerReport.Provider value={LearnerReportMemo}>
            {children}
        </LearnerReport.Provider>
    );
};

export const useLearnerReport = (): ILearnerReportProvider => {
    return useContext(LearnerReport);
};
