import { CogInput, ErrorText } from '../../../styled-components';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

export const ClientReference: React.FC = () => {
    const {
        formState: { errors },
        register,
        getValues,
    } = useFormContext<{ clientReference: string }>();

    const clientReference = getValues('clientReference');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const { ref, ...rest } = register('clientReference', {
        maxLength: {
            value: 100,
            message:
                'You have entered too many characters, please reduce it and try again.',
        },
    });

    return (
        <Field label='Client reference' labelFor='clientReference'>
            <>
                <CogInput
                    {...rest}
                    defaultValue={clientReference}
                    id='clientReference'
                    fullWidth
                    hasError={!!errors.clientReference}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='clientReference'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
