import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const MainWrapper = styled('div')(() => ({
    width: '100%',
    height: '100vh',
    background:
        'linear-gradient(90deg, rgba(255,255,255,1) 8%, rgba(249,209,227,0.5) 51%, rgba(199,188,255,0.25) 79%, rgba(255,255,255,1) 100%)',
    display: 'flex',
    alignItems: 'center',
}));

export const CloseModal: React.FC = () => {
    return (
        <MainWrapper>
            <Typography sx={{ textAlign: 'center', fontSize: 24, pt: 4 }}>
                Thank you for your submission, you may now close this survey.
            </Typography>
        </MainWrapper>
    );
};

export default CloseModal;
