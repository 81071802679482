import React, { useContext, useMemo } from 'react';

import { IAppConfig } from '../types/app-config.types';
import { useImmerReducer } from 'use-immer';

interface IConfigContext {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    reportStickyNav: boolean;
    appConfig: IAppConfig;
};

export const initialState = {
    reportStickyNav: false,
    appConfig: {
        environment: undefined,
        growthbookKey: undefined,
        applicationInsightsConnectionString: undefined,
        assessmentBaseUrl: undefined,
        bffBaseUrl: undefined,
    },
};

type Action =
    | {
          type: 'SET_REPORT_STICKY_NAV';
          payload: boolean;
      }
    | { type: 'SET_APP_CONFIG'; payload: Partial<IAppConfig> };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_REPORT_STICKY_NAV':
            state.reportStickyNav = action.payload;
            break;
        case 'SET_APP_CONFIG':
            state.appConfig = {
                ...state.appConfig,
                ...action.payload,
            };
            break;
    }
};

export const ConfigContext = React.createContext<IConfigContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const ConfigProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const ConfigContextMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <ConfigContext.Provider value={ConfigContextMemo}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = (): IConfigContext => useContext(ConfigContext);
