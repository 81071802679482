import { createStyles, makeStyles } from '@mui/styles';

import { Button } from '@cognassist/react-components';
import NavBar from '../components/assessment-report/nav-bar';
import { TrackingEvent } from '../types';
import imgWarning from '../assets/img/warning.png';
import { pxToRem } from '../utils/style-functions';
import { useAuth } from '../custom-providers/AuthProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTrackEvent } from '../custom-hooks/useTrackEvent';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            marginTop: '20vh',
            textAlign: 'center',
            '& h1': {
                marginTop: 24,
                textalign: 'center',
            },
            '& p': {
                marginTop: 24,
                textalign: 'center',
            },
        },
        largeTitle: {
            fontSize: pxToRem(100),
            linHeight: pxToRem(110),
            marginTop: '50px !important',
            marginBottom: 20,
        },
        goBackButton: {
            marginTop: 20,
        },
    })
);

interface IOwnProps {
    showTopNav?: boolean;
}

export const PageNotFound: React.FC<IOwnProps> = ({ showTopNav = true }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();

    const {
        state: {
            userConfig: { userId, email },
            clientConfig: { clientId },
        },
    } = useAuth();

    useEffect(() => {
        document.title = 'Page not found';
        trackEvent(TrackingEvent.PAGE_NOT_FOUND, {
            userId,
            clientId,
            email,
            dateAndTime: new Date(Date.now()).toISOString(),
        });
    }, []);

    return (
        <div>
            {showTopNav && <NavBar blank={true} />}
            <div className={classes.wrapper}>
                <img src={imgWarning} alt='Triangle with exclaimation point' />
                <h1 className={classes.largeTitle}>404</h1>
                <h1>Something went wrong</h1>
                <p>Sorry we were unable to find that page.</p>
                <Button
                    size='large'
                    className={classes.goBackButton}
                    onClick={() => navigate(-1)}
                    text='Go back'
                />
            </div>
        </div>
    );
};

export default PageNotFound;
