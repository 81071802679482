import { Dialog } from '@cognassist/react-components';
import React from 'react';

interface IOwnProps {
    open: boolean;
    handleOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    saveAndCloseDialog: () => void;
    continueNoSave: () => void;
}

const CloseModal: React.FC<IOwnProps> = ({
    open,
    handleOpenDialog,
    continueNoSave,
}) => {
    return (
        <Dialog
            open={open}
            title='Warning'
            content={
                <p>
                    You have unsaved changes. You may cancel to continue editing
                    or discard your changes.
                </p>
            }
            confirmButtonProps={{
                text: 'Discard',
                onClick: continueNoSave,
                color: 'error',
            }}
            cancelButtonProps={{
                text: 'Cancel',
                onClick: () => handleOpenDialog(false),
            }}
        />
    );
};

export default CloseModal;
