import { Badge as MuiBadge, Theme, alpha, styled } from '@mui/material';
import React, { useState } from 'react';

import { CSSTransition } from 'react-transition-group';
import { ChevronDown as FChevronDown } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { SubMenu } from './SubMenu';
import { TNavItem } from '../../../types';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

export const MenuWrapper = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    padding: navExpand ? theme.spacing(0, 3) : theme.spacing(0, 1),
}));

export const Tag = styled('span', {
    ...shouldForwardProps('isActive'),
})<{ isActive?: boolean }>(({ isActive, theme }) => ({
    backgroundColor: isActive
        ? theme.palette.common.white
        : theme.palette.primary.main,
    borderRadius: '4px',
    color: isActive ? theme.palette.primary.main : theme.palette.common.white,
    textTransform: 'uppercase',
    fontSize: pxToRem(8),
    lineHeight: pxToRem(9),
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginLeft: 8,
    padding: '4px',
}));

const navItemBaseStyles = (theme: Theme, navExpand?: boolean) => ({
    height: 40,
    width: '100%',
    fontSize: pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all 300ms ease',
    overflow: 'hidden',
    color: theme.palette.text.primary,
    justifyContent: navExpand ? 'space-between' : 'center',
    padding: navExpand ? theme.spacing(0, 2) : 0,
    margin: theme.spacing(1, 0),
    '&:first-of-type': {
        margin: 0,
    },
});

const NavItemList = styled(NavLink, {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    ...navItemBaseStyles(theme, navExpand),
}));

export const NavItem = styled(NavLink, {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    ...navItemBaseStyles(theme, navExpand),
    '&.active': {
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        borderRadius: theme.shape.borderRadius,
        fontWeight: 500,
    },
}));

const NavItemInner = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const CollapseNavItemInner = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: navExpand ? 'space-between' : 'center',
    width: '100%',
}));

const CollapseNavItemInnerLeft = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const NavItemText = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontWeight: 500,
}));

export const ChevronDown = styled(FChevronDown, {
    ...shouldForwardProps('isOpen'),
})<{ isOpen?: boolean }>(({ isOpen }) => ({
    transition: 'transform .2s ease',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
}));

const Badge = styled(MuiBadge)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

const MenuItemNotification = () => <Badge variant='dot' color='primary' />;

interface IOwnProps {
    navExpand: boolean;
    navItems: TNavItem[];
    isMobile?: boolean;
    setNavOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MenuItems: React.FC<IOwnProps> = ({
    navExpand,
    navItems,
    isMobile = false,
    setNavOpen = () => false,
}) => {
    const [activeSubmenu, setActiveSubmenu] = useState<number>();

    return (
        <MenuWrapper navExpand={navExpand}>
            {navItems.map((item, index) =>
                item.items ? (
                    <React.Fragment key={item.name}>
                        <NavItemList
                            aria-label={item.name}
                            navExpand={navExpand}
                            onClick={() => {
                                !activeSubmenu
                                    ? setActiveSubmenu(index)
                                    : setActiveSubmenu(undefined);
                            }}
                            to={`${item.items[0].to}`}
                            id={item.elementId}
                        >
                            <CollapseNavItemInner navExpand={navExpand}>
                                <CollapseNavItemInnerLeft>
                                    {item.icon}
                                    {navExpand && (
                                        <NavItemText>{item.name}</NavItemText>
                                    )}
                                </CollapseNavItemInnerLeft>

                                {navExpand && (
                                    <ChevronDown
                                        isOpen={activeSubmenu === index}
                                    />
                                )}
                            </CollapseNavItemInner>
                            {item.hasNotifications && <MenuItemNotification />}
                        </NavItemList>

                        <CSSTransition
                            in={activeSubmenu === index}
                            timeout={500}
                            classNames='alert'
                            unmountOnExit
                        >
                            <SubMenu
                                item={item}
                                navExpand={navExpand}
                                isMobile={isMobile}
                                setNavOpen={setNavOpen}
                            />
                        </CSSTransition>
                    </React.Fragment>
                ) : (
                    <NavItem
                        aria-label={item.name}
                        navExpand={navExpand}
                        key={item.name}
                        to={item.to}
                        id={item.elementId}
                        onClick={(e) => {
                            setActiveSubmenu(undefined);
                            if (isMobile) {
                                setNavOpen(false);
                            }
                            if (item.onClick) {
                                item.onClick(e);
                            }
                        }}
                        target={item.newTab ? '_blank' : undefined}
                        end
                    >
                        {({ isActive }) => (
                            <>
                                <NavItemInner>
                                    {item.icon}
                                    {navExpand && (
                                        <NavItemText>{item.name}</NavItemText>
                                    )}
                                    {item.hasNotifications && (
                                        <MenuItemNotification />
                                    )}
                                    {item.isBeta && (
                                        <Tag isActive={isActive}>BETA</Tag>
                                    )}
                                </NavItemInner>
                            </>
                        )}
                    </NavItem>
                )
            )}
        </MenuWrapper>
    );
};
