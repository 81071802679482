import { createStyles, makeStyles } from '@mui/styles';

import { SVGProps } from 'react';
import { Tooltip } from '@mui/material';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            background: '#33383F',
            padding: 16,
            fontSize: pxToRem(14),
            lineHeight: pxToRem(15),
            fontFamily: 'FS Me',
        },
        arrow: {
            color: '#33383F',
        },
    })
);

interface IOwnProps extends SVGProps<SVGTextElement> {
    isMobile: boolean;
    pvtFailure: boolean;
}

export const WarningShapeCircle: React.FC<IOwnProps> = ({
    isMobile = true,
    pvtFailure,
    ...props
}) => {
    const classes = useStyles();

    const xIsDesktopPVTCheck = (pvtFailure: boolean) => {
        return pvtFailure
            ? (props.cx as number) + 54
            : (props.cx as number) + 14;
    };

    const yIsMobilePVTCheck = (pvtFailure: boolean) => {
        return pvtFailure
            ? (props.cy as number) - 74
            : (props.cy as number) - 34;
    };

    const yIsDesktopPVTCheck = (pvtFailure: boolean) => {
        return pvtFailure
            ? (props.cy as number) - 14
            : (props.cy as number) - 12;
    };

    return (
        <Tooltip
            title='You have told us English is not your first language. Your score on this domain may be affected by your familiarity with English. This score should be interpreted in relation to English only.'
            arrow
            placement={window.innerWidth < 600 ? 'bottom' : 'right'}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            enterTouchDelay={0}
        >
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                x={
                    isMobile
                        ? (props.cx as number) - 12
                        : xIsDesktopPVTCheck(pvtFailure)
                }
                y={
                    isMobile
                        ? yIsMobilePVTCheck(pvtFailure)
                        : yIsDesktopPVTCheck(pvtFailure)
                }
            >
                <circle
                    cx='12'
                    cy='12'
                    r='12'
                    transform='rotate(-180 12 12)'
                    fill='#808080'
                />
                <path
                    d='M12 16.9907V12.3966'
                    stroke='#ffffff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M12 7.80225H11.9889'
                    stroke='#ffffff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </Tooltip>
    );
};
