import {
    ICategoryDeepDiveResponse,
    IDeepDivePageContent,
    IDeepDivePageDomain,
} from '../../../../../../types';
import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Domain from '../../../../../../components/assessment-report/learner/deep-dive/Domain';
import Footer from '../../../../../../components/assessment-report/Footer';
import { Loading } from '../../../../../../components/Loading';
import Strategies from '../../../../../../components/assessment-report/learner/deep-dive/Strategies';
import { deepDives } from '../../../../../../content/learner-assessment-report/deepDives';
import { endpoints } from '../../../../../../api/endpoints';
import { findContent } from '../../../../../../utils/findContent';
import imgLaptop from '../../../../../../assets/img/laptop.jpg';
import { pxToRem } from '../../../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../../../utils/shouldForwardProp';
import { useLearnerId } from '../../../../../../custom-hooks/useLearnerId';

const OuterWrapper = styled('div')(() => ({
    background: '#F9F9F9',
}));

const Wrapper = styled('div')(() => ({
    maxWidth: '1440px',
    margin: '0 auto',
}));

export const HeaderWrapper = styled('div', {
    ...shouldForwardProps('bgImage'),
})<{ bgImage?: string }>(({ bgImage, theme }) => ({
    minHeight: 500,
    backgroundImage: `linear-gradient(to right, rgba(249, 249, 249, 1) 20%, rgba(255, 255, 255, 0.01) 60%), url(${bgImage})`,
    backgroundPosition: '200px 30%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down(1165)]: {
        backgroundImage: `none`,
        minHeight: '0',
    },
}));

const HeroWrapper = styled('div')(({ theme }) => ({
    backgroundImage: `radial-gradient(farthest-corner at center,rgba(240, 141, 185, .3), transparent 700px),radial-gradient(farthest-side at center 60px,rgba(127, 191, 255, .3), transparent 300px),radial-gradient(farthest-side at right 200px,rgba(116, 88, 255, .3), transparent 1200px)`,
    minHeight: 500,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(744)]: {
        minHeight: 0,
    },
}));

const HeroWrapperInner = styled('div')(() => ({
    maxWidth: 1440,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
}));

const HeroContent = styled('div')(({ theme }) => ({
    maxWidth: '60%',
    padding: theme.spacing(10, 14, 10),
    [theme.breakpoints.down(1165)]: {
        maxWidth: '100%',
        padding: theme.spacing(6),
    },
    [theme.breakpoints.down(744)]: {
        maxWidth: '100%',
        padding: theme.spacing(6, 3),
    },
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(60),
    lineHeight: '120%',
    fontWeight: 700,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(32),
    },
}));

const HeroText = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(20),
    fontWeight: 400,
    lineHeight: '150%',
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(16),
    },
}));

export const DeepDive: React.FC = () => {
    const learnerUserId = useLearnerId();
    const navigate = useNavigate();
    const { categoryId } = useParams<{ categoryId: string }>();
    const [currentPageData, setCurrentPageData] =
        useState<IDeepDivePageContent>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [parsedPageContent, setParsedPageContent] =
        useState<IDeepDivePageContent | null>(null);

    const { getCategoryDeepDive } = endpoints();

    useEffect(() => {
        setIsLoading(true);
        setCurrentPageData(getCurrentDeepDive());
    }, [categoryId]);

    useEffect(() => {
        if (currentPageData) {
            fetchData();
        }
    }, [currentPageData]);

    const getCurrentDeepDive = () =>
        deepDives.find((dd) => dd.id.toString() === categoryId);

    const fetchData = async () => {
        if (learnerUserId && categoryId) {
            const { data, error } = await getCategoryDeepDive({
                learnerUserId,
                categoryId,
            });

            if (error) {
                navigate('/something-went-wrong', { replace: true });
            } else if (data) {
                parsePageContent(data);
            }
        }
    };

    const parsePageContent = (data: ICategoryDeepDiveResponse) => {
        if (currentPageData) {
            const parsedDomains = currentPageData.domains.reduce(
                (acc: IDeepDivePageDomain[], current) => {
                    const currentDomain = data.category.domains.find(
                        (domain) => domain.domainId === current.id
                    );

                    if (!currentDomain) {
                        return acc;
                    }

                    acc.push({
                        ...current,
                        domainText: findContent(
                            'IntroductoryParagraph',
                            currentDomain.contents
                        ),
                        domainList: findContent(
                            'DomainContent',
                            currentDomain.contents
                        ),
                        strategiesList: findContent(
                            'Strategies',
                            currentDomain.contents
                        ),
                        score: currentDomain.score,
                    });

                    return acc;
                },
                []
            );

            setParsedPageContent({
                ...currentPageData,
                domains: parsedDomains,
            });

            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        parsedPageContent && (
            <OuterWrapper>
                <HeaderWrapper
                    id='header'
                    bgImage={parsedPageContent.heroBackground}
                >
                    <HeroWrapper>
                        <HeroWrapperInner>
                            <HeroContent>
                                <HeroTitle variant='h1'>
                                    {parsedPageContent.pageHeader}
                                </HeroTitle>

                                {parsedPageContent.introText.map((para) => (
                                    <HeroText key={para} sx={{ mb: 3 }}>
                                        {para}
                                    </HeroText>
                                ))}
                            </HeroContent>
                        </HeroWrapperInner>
                    </HeroWrapper>
                </HeaderWrapper>

                <Wrapper>
                    <Domain
                        reverse={true}
                        domains={parsedPageContent.domains}
                    />

                    <Strategies content={parsedPageContent} img={imgLaptop} />
                </Wrapper>

                <Footer />
            </OuterWrapper>
        )
    );
};

export default DeepDive;
