import { Navigate, Outlet } from 'react-router-dom';

import { ChampionNotificationsProvider } from '../../custom-providers/ChampionNotificationsProvider';
import { useAuth } from '../../custom-providers/AuthProvider';

export const EmailVerificationLayout = () => {
    const {
        state: {
            userConfig: { hasVerifiedEmail },
        },
    } = useAuth();

    if (hasVerifiedEmail) {
        return (
            <ChampionNotificationsProvider>
                <Outlet />
            </ChampionNotificationsProvider>
        );
    }

    return <Navigate to='/unverified-email' replace />;
};
