import { Controller, useFormContext } from 'react-hook-form';
import { FC, useState } from 'react';
import {
    FormControlLabel,
    FormGroup as MuiFormGroup,
    styled,
} from '@mui/material';
import {
    ILegalDocument,
    IParsedLegalDoc,
    LegalDocumentType,
    TrackingEvent,
} from '../../../types';

import { Checkbox } from '@cognassist/react-components';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styled-components';
import { Field } from '../../main-app/pre-assessment/Field';
import { LegalDocumentDialog } from '../LegalDocumentDialog';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useTrackEvent } from '../../../custom-hooks/useTrackEvent';

const FormGroup = styled(MuiFormGroup, {
    ...shouldForwardProps('errors'),
})<{ errors?: boolean }>(({ errors, theme }) => ({
    border: errors ? `2px solid ${theme.palette.error.main}` : 'none',
    borderRadius: 4,
    padding: errors ? theme.spacing(2) : 0,
}));

const TCLink = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
}));

interface IOwnProps {
    legalDocuments: ILegalDocument[];
    clientId: string;
}

export const TermsAndConditions: React.FC<IOwnProps> = ({
    legalDocuments,
    clientId,
}) => {
    const [currentDialog, setCurrentDialog] =
        useState<LegalDocumentType | null>(null);

    const {
        formState: { errors },
        control,
    } = useFormContext<{ termsAndConditions: boolean }>();

    const trackEvent = useTrackEvent();

    const eventProperties = {
        clientId,
    };

    const parsedLegalDocuments = legalDocuments
        .reduce((acc: IParsedLegalDoc[], doc) => {
            switch (doc.legalDocumentType) {
                case LegalDocumentType.PrivacyPolicy:
                    acc.push({
                        name: 'Privacy Policy',
                        isOpen:
                            currentDialog === LegalDocumentType.PrivacyPolicy,
                        openDialog: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_PRIVACY_POLICY_OPENED,
                                eventProperties
                            );
                            setCurrentDialog(LegalDocumentType.PrivacyPolicy);
                        },
                        closeDialog: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_PRIVACY_POLICY_CLOSED,
                                eventProperties
                            );
                            setCurrentDialog(null);
                        },
                        onScrollToBottom: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_PRIVACY_POLICY_BOTTOM_SCROLL,
                                eventProperties
                            );
                        },
                        ...doc,
                    });
                    break;
                case LegalDocumentType.TermsAndConditions:
                    acc.push({
                        name: 'Terms & Conditions',
                        isOpen:
                            currentDialog ===
                            LegalDocumentType.TermsAndConditions,
                        openDialog: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_TERMS_AND_CONDITIONS_OPENED,
                                eventProperties
                            );
                            setCurrentDialog(
                                LegalDocumentType.TermsAndConditions
                            );
                        },
                        closeDialog: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_TERMS_AND_CONDITIONS_CLOSED,
                                eventProperties
                            );
                            setCurrentDialog(null);
                        },
                        onScrollToBottom: () => {
                            trackEvent(
                                TrackingEvent.SIGNUP_TERMS_AND_CONDITIONS_BOTTOM_SCROLL,
                                eventProperties
                            );
                        },
                        ...doc,
                    });
                    break;
                default:
                    break;
            }
            return acc;
        }, [])
        .sort((a, b) => b.name.localeCompare(a.name));

    if (parsedLegalDocuments.length === 0) {
        return null;
    }

    return (
        <>
            <Field label='Terms' labelFor='termsAndConditions' hide={true}>
                <>
                    <FormGroup errors={!!errors.termsAndConditions}>
                        <FormControlLabel
                            control={
                                <Controller
                                    control={control}
                                    name='termsAndConditions'
                                    defaultValue={false}
                                    rules={{
                                        required: 'You must agree to continue.',
                                    }}
                                    render={({
                                        field: { value, ref, name, onChange },
                                    }) => (
                                        <Checkbox
                                            checked={value}
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            sx={{ mr: 1 }}
                                        />
                                    )}
                                />
                            }
                            label={
                                <CheckboxLabel
                                    legalDocuments={parsedLegalDocuments}
                                />
                            }
                        />
                    </FormGroup>

                    <ErrorMessage
                        errors={errors}
                        name='termsAndConditions'
                        render={({ message }) => (
                            <ErrorText>{message}</ErrorText>
                        )}
                    />
                </>
            </Field>
            {parsedLegalDocuments.map((legalDocument) => {
                return (
                    <LegalDocumentDialog
                        key={legalDocument.name}
                        legalDocument={legalDocument}
                    />
                );
            })}
        </>
    );
};

interface ICheckboxLabelProps {
    legalDocuments: IParsedLegalDoc[];
}

const CheckboxLabel: FC<ICheckboxLabelProps> = ({ legalDocuments }) => {
    return (
        <>
            I accept the{' '}
            {legalDocuments.map((legalDocument, index) => (
                <span key={legalDocument.name}>
                    <TCLink
                        tabIndex={0}
                        onClick={(e) => {
                            e.preventDefault();
                            legalDocument.openDialog();
                        }}
                    >
                        {legalDocument.name}
                    </TCLink>
                    {index !== legalDocuments.length - 1 ? ' and ' : '.'}
                </span>
            ))}
        </>
    );
};
