export interface IInputHelperText {
    key:
        | 'gender'
        | 'dateOfBirth'
        | 'englishFirstLanguage'
        | 'hasFormerNeurodiversityDiagnosis'
        | 'neurodivergenceTypes'
        | 'ethnicGroup';
    title: string;
    text: string;
}

const neurodiversityText = {
    title: 'Why do you need to know about my neuro-difference?',
    text: 'The more we know about you, the more this allows us to tailor your experience. We will never share this information in a way that identifies you.',
};

export const inputHelperText: IInputHelperText[] = [
    {
        key: 'gender',
        title: 'Why do you need to know my gender?',
        text: 'We only use this information for anonymised data analysis. This helps us to ensure our assessment is as accurate as possible and free from bias.',
    },
    {
        key: 'dateOfBirth',
        title: 'Why do you need my date of birth?',
        text: 'Your brain changes throughout your life. We use age to ensure your assessment results are as accurate as possible.',
    },
    {
        key: 'englishFirstLanguage',
        title: 'Why is this important?',
        text: 'We only use this information for anonymised data analysis. This helps us to ensure our assessment is as accurate as possible and free from bias.',
    },
    {
        key: 'hasFormerNeurodiversityDiagnosis',
        ...neurodiversityText,
    },
    {
        key: 'neurodivergenceTypes',
        ...neurodiversityText,
    },
    {
        key: 'ethnicGroup',
        title: 'Why is this important?',
        text: 'We only use this information for anonymised data analysis. This helps us to ensure our assessment is as accurate as possible and free from bias.',
    },
];
