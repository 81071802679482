import { getDefaultPageSize } from '../utils/tables';

type State = {
    startRecord: number;
    endRecord: number;
    currentPage: number;
    totalPage: number;
    totalRecords: number;
    recordsLoaded: boolean;
    pageSize: number;
    totalPages: number;
    currentPageRecords: number;
};

export const initialState = {
    startRecord: 1,
    endRecord: 0,
    currentPage: 1,
    totalPage: 0,
    totalRecords: 100,
    recordsLoaded: false,
    totalPages: 0,
    currentPageRecords: 0,
    pageSize: getDefaultPageSize('unknown', 10),
};

export type Action =
    | {
          type: 'UPDATE_PAGE_SIZE';
          payload: { pageSize: number; localStoragePageSize: string };
      }
    | { type: 'TOGGLE_PAGE'; payload: { location: string } }
    | { type: 'SET_TOTAL_RECORDS'; payload: number }
    | {
          type: 'SET_TOTAL_PAGES';
          payload: { endRecord: number; totalPages: number };
      }
    | { type: 'UPDATE_CURRENT_PAGE'; payload: { pageNumber: number } }
    | { type: 'SET_TOTAL_CURRENT_PAGE_RECORDS'; payload: number }
    | { type: 'SET_RECORDS_LOADED' };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_RECORDS_LOADED':
            state.recordsLoaded = true;
            break;
        case 'UPDATE_PAGE_SIZE':
            state.currentPage = 1;
            state.startRecord = 1;
            state.pageSize = action.payload.pageSize;
            localStorage.setItem(
                action.payload.localStoragePageSize,
                action.payload.pageSize.toString()
            );
            break;

        case 'TOGGLE_PAGE': {
            if (
                action.payload.location === 'previous' &&
                state.startRecord !== 1
            ) {
                state.currentPage = state.currentPage - 1;
                state.startRecord = state.startRecord - state.pageSize;
            } else if (
                action.payload.location === 'next' &&
                state.endRecord < state.totalRecords
            ) {
                state.currentPage = state.currentPage + 1;
                state.startRecord = state.startRecord + state.pageSize;
            }
            break;
        }
        case 'UPDATE_CURRENT_PAGE':
            state.currentPage = action.payload.pageNumber;
            state.startRecord =
                action.payload.pageNumber * state.pageSize -
                (state.pageSize - 1);
            break;
        case 'SET_TOTAL_PAGES':
            state.endRecord = action.payload.endRecord;
            state.totalPages = action.payload.totalPages;
            state.recordsLoaded = true;
            break;
        case 'SET_TOTAL_CURRENT_PAGE_RECORDS':
            state.currentPageRecords = action.payload;
            break;
        case 'SET_TOTAL_RECORDS':
            state.totalRecords = action.payload;
            break;
    }
};
