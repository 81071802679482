import { Button as CogButton } from '@cognassist/react-components';
import React from 'react';
import { styled } from '@mui/material';

const Button = styled(CogButton)(({ theme }) => ({
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,

    '&:hover': {
        backgroundColor: `${theme.palette.grey[50]} !important`,
    },
}));

interface IOwnProps {
    text: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const PinkOutlineButton: React.FC<IOwnProps> = ({
    text,
    startIcon,
    endIcon,
    fullWidth,
    onClick,
}) => {
    return (
        <Button
            text={text}
            startIcon={startIcon}
            endIcon={endIcon}
            fullWidth={fullWidth}
            onClick={onClick}
        />
    );
};
