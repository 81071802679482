import { Link } from 'react-router-dom';
import { pxToRem } from '../../../utils/style-functions';
import { styled } from '@mui/material';

export const OldReportBanner = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: theme.palette.primary.main,
    padding: theme.spacing(2),
}));

export const OldReportBannerText = styled(Link)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    textDecoration: 'none',
}));
