import { IMessage } from '../components/main-app/cai/CaiMessages';
import { endpoints } from '../api/endpoints';
import { useState } from 'react';

export const useCai = (initialQuery: IMessage) => {
    const [messages, setMessages] = useState<IMessage[]>([initialQuery]);
    const [messageDone, setMessageDone] = useState<boolean>(true);

    const { cognassistantQuery, getContextualisedStrategy } = endpoints();

    const textQuery = async (message: string, sessionId: string) => {
        setMessageDone(false);
        const { data } = await cognassistantQuery(message, sessionId);
        return parseResponse(data);
    };

    const contextualisedStrategyQuery = async (
        userId: string,
        lmsSourceId: string
    ) => {
        setMessageDone(false);
        const { data } = await getContextualisedStrategy(userId, lmsSourceId);
        return parseResponse(data);
    };

    const parseResponse = async (data: ReadableStream<Uint8Array> | null) => {
        const reader = data?.getReader();
        const decoder = new TextDecoder();

        if (reader) {
            setMessages((prevState) => [...prevState, { answer: '' }]);
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    setMessageDone(true);
                    break;
                }

                if (value) {
                    const chunk = decoder.decode(value, {
                        stream: true,
                    });

                    setMessages((prevState) => {
                        const msgs = [...prevState];
                        msgs[msgs.length - 1] = {
                            answer: `${msgs[msgs.length - 1].answer}${chunk}`,
                        };
                        return msgs;
                    });
                }
            }
        }

        return true;
    };

    return {
        textQuery,
        contextualisedStrategyQuery,
        messages,
        setMessages,
        messageDone,
    };
};
