import { CircularProgress, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { Button } from '@cognassist/react-components';
import { RemoteKeys } from '../../../../types';
import { endpoints } from '../../../../api/endpoints';
import { pxToRem } from '../../../../utils/style-functions';
import { useParams } from 'react-router-dom';
import { useRemoteValidation } from '../../../../custom-hooks/useRemoteValidation';

const ALREADY_VERIFIED_ERROR_MESSAGE =
    'User has already verified their email address.';

const VerifyEmailWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    padding: `0 ${theme.spacing(3)}`,
}));

const Heading1 = styled('h1')(({ theme }) => ({
    fontSize: pxToRem(36),
    lineHeight: pxToRem(54),
    fontWeight: 500,
    marginBottom: theme.spacing(3),
}));

const BodyText = styled('p')(({ theme }) => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(27),
    marginBottom: theme.spacing(3),
}));

const VerificationButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const loginHandler = () => {
    window.location.href = `${window.location.origin}/dashboard`;
};

const VerifyEmailValidationMap = {
    emailVerificationCode: 'EmailVerificationCode',
} as const;

type VerifyEmailRemoteKeys = RemoteKeys<typeof VerifyEmailValidationMap>;

const VerifyEmail: React.FC = () => {
    const { token } = useParams<{ token: string }>();

    const { verifyEmail } = endpoints();

    const [loading, setLoading] = useState<boolean>(true);
    const [apiError, setAPIError] = useState<boolean>(false);
    const [alreadyVerified, setAlreadyVerified] = useState<boolean>(false);

    const parseAPIErrors = useRemoteValidation<typeof VerifyEmailValidationMap>(
        VerifyEmailValidationMap
    );

    useEffect(() => {
        document.title = 'Verify email';

        if (token) {
            callVerifyEmail(token);
        }
    }, [token]);

    const callVerifyEmail = async (token: string) => {
        const { error } = await verifyEmail<VerifyEmailRemoteKeys>(token);

        if (error) {
            const apiErrors = parseAPIErrors(error);

            if (
                apiErrors.some(
                    (apiError) =>
                        apiError.message === ALREADY_VERIFIED_ERROR_MESSAGE
                )
            ) {
                setAPIError(false);
                setAlreadyVerified(true);
            } else {
                setAlreadyVerified(false);
                setAPIError(true);
            }
        }

        setLoading(false);
    };

    return (
        <VerifyEmailWrapper>
            {loading && <CircularProgress />}
            {!loading && apiError && (
                <>
                    <Heading1>Something went wrong!</Heading1>
                    <BodyText>
                        Your email could not be verified, please try refreshing
                        the page.
                    </BodyText>
                </>
            )}
            {!loading && alreadyVerified && (
                <>
                    <Heading1>Welcome back!</Heading1>
                    <BodyText>
                        Looks like you have already verified your email. Log in
                        to access the Cognassist platform.
                    </BodyText>
                    <VerificationButton text='Log in' onClick={loginHandler} />
                </>
            )}
            {!loading && !apiError && !alreadyVerified && (
                <>
                    <Heading1>Welcome to Cognassist!</Heading1>
                    <BodyText>
                        Your email has been successfully verified. Log in to get
                        started.
                    </BodyText>
                    <VerificationButton text='Log in' onClick={loginHandler} />
                </>
            )}
        </VerifyEmailWrapper>
    );
};

export default VerifyEmail;
