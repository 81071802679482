import HeroImage1 from '../../assets/img/HeroImage1.jpg';
import HeroImage2 from '../../assets/img/HeroImage2.jpg';
import HeroImage4 from '../../assets/img/HeroImage4.jpg';
import HeroImage5 from '../../assets/img/HeroImage5.jpg';
import HeroImage6 from '../../assets/img/HeroImage6.jpg';
import HeroImage8 from '../../assets/img/HeroImage8.jpg';
import { IDeepDivePageContent } from '../../types';

export const deepDives: IDeepDivePageContent[] = [
    {
        id: 1,
        pageRef: 'learningMemory',
        heroBackground: HeroImage1,
        pageHeader: 'Learning & Memory',
        pageSubheader: 'Your Learning & Memory Deep Dive',
        introHeader: 'What is Learning & Memory?',
        introText: [
            "One of the great things about the human mind is how it processes information and enables us to think - it's what makes us unique. ",
            'Learning and Memory can be affected by both Non-Verbal Memory and Verbal Memory. Although there is a lot of overlap, learning can be thought of as the acquisition of knowledge or gaining a new skill, while memory involves explaining or demonstrating the newly acquired knowledge or skill.',
        ],
        domains: [
            {
                id: 5,
                name: 'Non-Verbal Memory',
                header: 'Non-Verbal Memory & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Non-Verbal memory, you might consider or suggest:',
                strategiesList: null,
            },
            {
                id: 1,
                name: 'Verbal Memory',
                header: 'Verbal Memory & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader: 'For Verbal Memory, you can try or suggest:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Learning & Memory Strategies',
        strategiesText: [
            "Not everyone will feel comfortable with Non-Verbal Memory- and Verbal Memory-related tasks. Remember, that's what makes us who we are. ",
            "That's why we've created some strategies you can use every day to help you overcome any difficulties you experience with visual, spoken or written information.",
            'You may even wish to share these strategies with your work colleagues, friends or team members. Think of it like a cognition library where you can all share your coping strategies, helping each other to overcome daily challenges.',
        ],
    },
    {
        id: 2,
        pageRef: 'organisationPlanning',
        heroBackground: HeroImage2,
        pageHeader: 'Organisation & Planning',
        pageSubheader: 'Your Organisation and Planning Deep Dive',
        introHeader: 'What is Organisation & Planning?',
        introText: [
            'All jobs require some level of Organisation and Planning, even if it is just to plan the order of work for the day. But it can also incorporate things like staff rotas, project management or keeping your work environment clean and tidy. Ultimately, this important daily activity helps to prevent costly mistakes at work and ensures tasks are completed accurately and efficiently.',
            "Organisation and Planning can be affected by Executive Function and Numeracy. Numeracy can relate to your broader working memory which extends beyond numbers, like the ability to retain information in your mind and use it to complete tasks in the moment, perhaps to help you make decisions. Executive Function includes the processes required for carrying out complex cognitive tasks, like controlling your attention and deciding what you're going to work on. ",
        ],
        domains: [
            {
                id: 6,
                name: 'Executive Function',
                header: 'Executive Function & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Executive Function, useful tips include:',
                strategiesList: null,
            },
            {
                id: 3,
                name: 'Numeracy',
                header: 'Numeracy & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'Numeracy strategies you could try or suggest to colleagues include:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Organisation & Planning Strategies',
        strategiesText: [
            'Organisation and Planning activities are important in most workplaces. Whether these activities are a strength, something you need to work on or a bit of both, here are some helpful hints you can use every day to help you plan and complete tasks efficiently and effectively.',
        ],
    },
    {
        id: 5,
        pageRef: 'communication',
        heroBackground: HeroImage8,
        pageHeader: 'Communication',
        pageSubheader: 'Your Communication Deep Dive',
        introHeader: 'What is Communication?',
        introText: [
            'Communication is an essential everyday activity in our social and professional lives that involves sharing information verbally and non-verbally. It can be affected by two domains, Literacy and Verbal Reasoning. ',
            'Communication involves transferring information from one place or person to another and includes activities, like reading quickly and accurately, processing and understanding text when reading (comprehension), applying and using new information and understanding what new words mean.',
        ],
        domains: [
            {
                id: 2,
                name: 'Literacy',
                header: 'Literacy & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'You or your colleagues may find the following strategies beneficial for Literacy-based tasks:',
                strategiesList: null,
            },
            {
                id: 7,
                name: 'Verbal Reasoning',
                header: 'Verbal Reasoning & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'To help with Verbal Reasoning tasks, you could suggest to colleagues or try:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Communication Strategies',
        strategiesText: [
            "Communication plays a significant role in our everyday lives, especially at work. Whether you feel comfortable with communication-based tasks or find them more challenging, these strategies are available to help. Even if Communication is a strength, during times of a heavy workload or stress, you may find these hints helpful to help you stay on target. It's also important to remember that not everyone in your team will be comfortable with Communication activities, so these strategies may be useful to share with your colleagues.",
            "If you find Communication challenging, try using some of these tips to help reduce any anxiety you feel or to keep your workday running smoothly. Remember, these resources are available for everyone's use. You may identify methods that could make your day more streamlined or ease your workload. ",
        ],
    },
    {
        id: 6,
        pageRef: 'problemSolving',
        heroBackground: HeroImage4,
        pageHeader: 'Problem-Solving',
        pageSubheader: 'Your Problem-Solving Deep Dive',
        introHeader: 'What is Problem-Solving?',
        introText: [
            'Problem-Solving is all about how you identify, determine and solve a problem. Your approach to this may be influenced by the cognitive domains, Verbal Reasoning and Visual Perception.',
        ],
        domains: [
            {
                id: 7,
                name: 'Verbal Reasoning',
                header: 'Verbal Reasoning & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Verbal Reasoning, you may wish to suggest to your colleagues or consider:',
                strategiesList: null,
            },
            {
                id: 8,
                name: 'Visual Perception',
                header: 'Visual Perception & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'When considering Visual Perception, you or your colleagues may benefit from:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Problem-Solving Strategies',
        strategiesText: [
            'Whether tackling problems and providing solutions comes naturally to you or you feel less confident with Problem-Solving activities, there are strategies available that may be useful, especially at work. Equally, you may work alongside people who find Problem-Solving a challenge.',
            "That's why we've put together some valuable tips to help make Problem-Solving at work more manageable. If you already feel confident approaching these tasks, you may wish to share these tips with colleagues who may find it more challenging, to help them find solutions quickly and easily in the future. Equally, you may find them beneficial if you have an increased workload, feel under pressure or are just looking for new ideas to help streamline your workday. ",
        ],
    },
    {
        id: 3,
        pageRef: 'workingDigitally',
        heroBackground: HeroImage5,
        pageHeader: 'Working Digitally',
        pageSubheader: 'Your Working Digitally Deep Dive',
        introHeader: 'What is Working Digitally?',
        introText: [
            'The last few years have prompted an accelerated transition into the new digital world. Whether you work from home, in retail, medicine, education, are a tradesperson or are office-based, you will be required to use technology during your working day. The purpose of digital technology is to enable more efficient working practices and can involve tasks like sending and receiving emails, ordering online or entering customer or patient details. ',
            'Cognitively, Working Digitally can be affected by Reading Decoding and Visual Information Processing Speed, specifically your ability to maintain focus and attention. ',
        ],
        domains: [
            {
                id: 9,
                name: 'Reading Decoding',
                header: 'Reading Decoding & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Reading Decoding, you may find it helpful to:',
                strategiesList: null,
            },
            {
                id: 4,
                name: 'Visual Information Processing Speed',
                header: 'Visual Information Processing Speed & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Visual Information Processing Speed, you may find it helpful to know:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Working Digitally Strategies',
        strategiesText: [
            'The combination of Reading Decoding and Visual Information Processing Speed is especially beneficial if your work involves digital technology or communication.',
            'Working Digitally transcends many different work sectors and environments, including working in partner offices, sites, attending conferences or hybrid working.',
            "Whether you feel at ease with tasks requiring Reading Decoding or Visual Information Processing Speed, or feel like you have endless cognitive resources to handle the challenges of Working Digitally, it's essential to take care of your mental health and wellbeing as well as being mindful that not everyone will consider it to be a personal strength. ",
            "Alternatively, Working Digitally may have only just been identified as a development area for you. So, we've put together some strategies to help you when Working Digitally that you may find helpful for yourself and your colleagues. ",
        ],
    },
    {
        id: 4,
        pageRef: 'workingWithOthers',
        heroBackground: HeroImage6,
        pageHeader: 'Working with Others',
        pageSubheader: 'Your Working with Others Deep Dive',
        introHeader: 'What Does Working with Others Mean?',
        introText: [
            'Working as part of a team can be an exciting prospect because it allows us to showcase our skills, learn new ones from others, and see things differently. Ultimately, the end goal is to deliver a fantastic product or service, or close a project, where everyone involved feels that their input has been valued.',
            "Working with Others is a daily task that can be affected by processes related to Executive Function and the overall diversity of your cognitive profile. The Diversity Index can be associated with how similar or different your thought processes are to other people's, and can therefore influence how you work with others. ",
        ],
        domains: [
            {
                id: 6,
                name: 'Executive Function',
                header: 'Executive Function & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For Executive Function, some helpful tips include: ',
                strategiesList: null,
            },
            {
                id: -1,
                name: 'Diversity Index',
                header: 'The Diversity Index & You',
                score: 0,
                domainText: null,
                domainList: null,
                strategiesHeader:
                    'For the Diversity Index, you may find it helpful to know:',
                strategiesList: null,
            },
        ],
        strategiesHeader: 'Working with Others Strategies',
        strategiesText: [
            "Whether you feel confident about working in a team or feel like you need a little nudge in the right direction, our strategies can help you become the ultimate team member. Discussing these strategies and sharing them with your colleagues is a great way to increase awareness of everyone's strengths and areas that some people might need support, and to make sure everyone has the tools they need to succeed in a team.",
        ],
    },
];
