import { Typography, styled } from '@mui/material';

import { Drawer } from '../../Drawer';
import { TopStrengthIndicator } from './Domain';
import { pxToRem } from '../../../utils/style-functions';

const TitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
}));

const Title = styled(Typography)(() => ({
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(24),
    width: '100%',
}));

interface IOwnProps {
    close: () => void;
    children: React.ReactNode;
    title: string;
    id: string;
    isTopStrength?: boolean;
    open: boolean;
    topDomainText: string;
}

export const DomainDrawer: React.FC<IOwnProps> = ({
    close,
    children,
    id,
    title,
    isTopStrength,
    open,
    topDomainText,
}) => {
    return (
        <Drawer
            id={id}
            open={open}
            close={close}
            TitleWrapper={
                <TitleWrapper>
                    <Title variant='h1'>{title}</Title>
                    {isTopStrength && (
                        <TopStrengthIndicator>
                            {topDomainText}
                        </TopStrengthIndicator>
                    )}
                </TitleWrapper>
            }
        >
            {children}
        </Drawer>
    );
};
