import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ReferenceArea,
    ReferenceDot,
    ReferenceLine,
    ResponsiveContainer,
    Text,
    XAxis,
    YAxis,
} from 'recharts';

import { CustomLegend } from './CustomLegend';
import { CustomWarningShape } from './CustonWarningShape';
import { Fragment } from 'react';
import { IParsedDomainData } from '../../../../types/report.types';

interface ICustomAxisTick {
    x: number;
    y: number;
    payload: {
        coordinate: number;
        value: string;
        index: number;
        offset: number;
    };
}

export const CustomAxisTick = (props: ICustomAxisTick): JSX.Element => {
    return (
        <Text
            x={props.x - 90}
            y={props.y}
            width={90}
            textAnchor='start'
            verticalAnchor='middle'
            style={{ fontWeight: 'normal', fill: '#33383F' }}
        >
            {props.payload.value}
        </Text>
    );
};

interface IOwnProps {
    scores: IParsedDomainData[];
    hasEnglishAsASecondLanguage: boolean;
}

export const DesktopGraph: React.FC<IOwnProps> = ({
    scores,
    hasEnglishAsASecondLanguage,
}) => {
    return (
        <ResponsiveContainer width='100%' height={600}>
            <BarChart data={scores} layout='vertical' barSize={45}>
                <CartesianGrid strokeDasharray='0' horizontal={false} />

                <ReferenceLine
                    x='100'
                    stroke='#7F8FA6'
                    strokeDasharray='10 5'
                    strokeWidth={1.5}
                />
                <ReferenceLine
                    x='115'
                    stroke='#FD9B00'
                    strokeDasharray='10 5'
                    strokeWidth={1.5}
                />
                <ReferenceLine
                    x='85'
                    stroke='#FD9B00'
                    strokeDasharray='10 5'
                    strokeWidth={1.5}
                />

                <Legend
                    verticalAlign='top'
                    wrapperStyle={{
                        top: 0,
                    }}
                    content={<CustomLegend isMobile={false} />}
                />

                <ReferenceArea x1={50} x2={85} fill='#FFEBCE'></ReferenceArea>
                <ReferenceArea x1={85} x2={115} fill='#F1EA9E'></ReferenceArea>
                <ReferenceArea x1={115} x2={150} fill='#E9F9C5'></ReferenceArea>

                <XAxis
                    type='number'
                    tickCount={10}
                    interval='preserveEnd'
                    axisLine={false}
                    tickLine={false}
                    ticks={[50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]}
                    tickMargin={10}
                    domain={[50, 'dataMax']}
                    style={{ fontWeight: 'bold', fill: '#33383F' }}
                />
                <YAxis
                    dataKey='name'
                    type='category'
                    tickLine={false}
                    axisLine={false}
                    width={100}
                    tick={CustomAxisTick}
                />
                <Bar dataKey='score' radius={[0, 4, 4, 0]}>
                    {scores.map((entry) => (
                        <Fragment key={`cell-${entry.name}`}>
                            <Cell
                                style={
                                    entry.pvtFailure ? { display: 'none' } : {}
                                }
                                fill={entry.barColor}
                            ></Cell>
                        </Fragment>
                    ))}
                </Bar>
                {scores.map((data) => {
                    return (
                        <ReferenceDot
                            key={data.name}
                            x={data.score}
                            y={data.name}
                            shape={
                                <CustomWarningShape
                                    isMobile={false}
                                    pvtFailure={data.pvtFailure}
                                    hasEnglishAsASecondLanguage={
                                        hasEnglishAsASecondLanguage
                                    }
                                    domainName={data.name}
                                />
                            }
                        />
                    );
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};
