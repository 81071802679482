import { Typography, styled } from '@mui/material';

import { Label } from './Label';

const Wrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const ContentFrame = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
}));

interface IOwnProps {
    label: string;
    content: string;
}

export const SupportRequestProperty: React.FC<IOwnProps> = ({
    label,
    content,
}) => {
    return (
        <Wrapper>
            <Label label={label} />
            <ContentFrame>
                <Typography>{content}</Typography>
            </ContentFrame>
        </Wrapper>
    );
};
