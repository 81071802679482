import {
    CognitiveMapProvider,
    useCognitiveMap,
} from '../../custom-providers/CognitiveMapProvider';
import {
    LearnerReportProvider,
    useLearnerReport,
} from '../../custom-providers/LearnerReportProvider';
import { Outlet, useNavigate } from 'react-router-dom';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { ClientConfigItem } from '../../types';
import Logo from '../../assets/img/logo-new.svg';
import PageNotFound from '../../pages/PageNotFound';
import capitaliseFirstLetter from '../../utils/transformFirstLetter';
import { endpoints } from '../../api/endpoints';
import { mapCogMapV2Data } from '../main-app/cognitive-map-v2/helpers';
import { useAuth } from '../../custom-providers/AuthProvider';
import { useEffect } from 'react';
import { useLearnerId } from '../../custom-hooks/useLearnerId';
import { useLogoutUrl } from '../../custom-hooks/useLogoutUrl';

const Wrapper = styled('div')(() => ({
    display: 'grid',
    height: '100vh',
    gridTemplateRows: 'auto 1fr',
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const InnerHeaderWrapper = styled('div')(({ theme }) => ({
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const SiteLogo = styled('img')(() => ({
    maxWidth: 140,
    display: 'flex',
}));

const Content = styled('div')(() => ({
    background: '#F6F7FB',
}));

const NotYourReportBanner = styled('div')(({ theme }) => ({
    background: theme.palette.primary.dark,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
}));

const NotYourReportText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: 600,
}));

const LearnerReportLayout = () => {
    const {
        state: {
            clientConfig: { items },
        },
    } = useAuth();

    const isEnabled =
        items?.find(
            (configItem) =>
                configItem.id === ClientConfigItem.AppsAndEducationCognitiveMap
        )?.state === 1;

    if (!isEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <CognitiveMapProvider>
            <LearnerReportProvider>
                <LearnerReportData />
            </LearnerReportProvider>
        </CognitiveMapProvider>
    );
};

const LearnerReportData = () => {
    const learnerUserId = useLearnerId();
    const navigate = useNavigate();
    const { dispatch: cognitiveMapDispatch } = useCognitiveMap();
    const {
        state: { learnerName },
        dispatch: learnerReportDispatch,
    } = useLearnerReport();

    const {
        state: {
            userConfig: { name, userId, isOnlyLearner, useSingleSignOn },
        },
    } = useAuth();

    const { logoutHandler } = useLogoutUrl(
        `learner-report?learnerUserId=${userId}`
    );

    const { getCognitiveMapContentV2, getProcessingBias, getLearnerDetails } =
        endpoints();

    useEffect(() => {
        if (learnerUserId) {
            getCognitiveMapData(learnerUserId);

            if (userId && userId !== learnerUserId) {
                getLearnerData(learnerUserId);
            } else {
                setLearnerName(name);
            }
        }
    }, [learnerUserId]);

    const getCognitiveMapData = async (learnerUserId: string) => {
        const [
            { data: cognitiveMapData, error: cognitiveMapError },
            { data: processingBiasData, error: processingBiasError },
        ] = await Promise.all([
            getCognitiveMapContentV2({ learnerUserId }),
            getProcessingBias({ learnerUserId, reportType: 1 }),
        ]);

        if (cognitiveMapError || processingBiasError) {
            navigate('/something-went-wrong', { replace: true });
        }

        if (cognitiveMapData && processingBiasData) {
            const fullData = mapCogMapV2Data(
                cognitiveMapData,
                processingBiasData
            );

            cognitiveMapDispatch({
                type: 'SET_COGNITIVE_MAP_V2',
                payload: fullData,
            });
        }
    };

    const getLearnerData = async (learnerUserId: string) => {
        const { data } = await getLearnerDetails(learnerUserId);

        if (data) {
            setLearnerName(
                `${capitaliseFirstLetter(
                    data.firstName
                )} ${capitaliseFirstLetter(data.lastName)}`
            );
        }
    };

    const setLearnerName = (name?: string) => {
        if (name) {
            learnerReportDispatch({
                type: 'SET_LEARNER_NAME',
                payload: name,
            });
        }
    };

    const canShowLogOutButton =
        !useSingleSignOn && isOnlyLearner && userId === learnerUserId;

    return (
        <Wrapper>
            <HeaderWrapper>
                {userId !== learnerUserId && (
                    <NotYourReportBanner>
                        <NotYourReportText>
                            Reminder: This is not your own Cognitive Map, you
                            are currently viewing the one that belongs to{' '}
                            {learnerName}
                        </NotYourReportText>
                    </NotYourReportBanner>
                )}
                <InnerHeaderWrapper>
                    <SiteLogo src={Logo} alt='Cognassist logo' />
                    {canShowLogOutButton && (
                        <Button text='Log out' onClick={logoutHandler} />
                    )}
                </InnerHeaderWrapper>
            </HeaderWrapper>
            <Content>
                <Outlet />
            </Content>
        </Wrapper>
    );
};

export default LearnerReportLayout;
