import { Pagination, Table } from '@cognassist/react-components';
import { SelectChangeEvent, styled } from '@mui/material';
import { initialState, reducer } from '../../state/table-state';
import { useEffect, useState } from 'react';

import { getDefaultPageSize } from '../../utils/tables';
import { useFLP } from '../../custom-providers/FlexibleLearningPlan';
import { useFLPAPI } from '../../custom-hooks/useFLPAPI';
import { useImmerReducer } from 'use-immer';
import { useLearnerId } from '../../custom-hooks/useLearnerId';

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

export const UpcomingModules: React.FC = () => {
    const learnerUserId = useLearnerId();
    const { getUpcomingModules } = useFLPAPI({ learnerUserId });

    const [
        { startRecord, recordsLoaded, totalRecords, totalPages, currentPage },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const {
        state: {
            upcomingModules,
            upcomingModulesPage,
            activeFilters,
            priorityModules,
        },
        dispatch: FLPDispatch,
    } = useFLP();

    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('upcomingModulesPageSize', 10)
    );

    const [visibleModules, setVisibleModules] = useState<number>();

    useEffect(() => {
        dispatch({ type: 'UPDATE_CURRENT_PAGE', payload: { pageNumber: 1 } });
    }, [activeFilters]);

    useEffect(() => {
        getUpcomingModules({
            categories: activeFilters?.categories ?? [],
            skills: activeFilters?.skills ?? [],
        });
    }, [pageSize]);

    useEffect(() => {
        if (upcomingModulesPage !== currentPage) {
            getUpcomingModules({
                categories: activeFilters?.categories ?? [],
                skills: activeFilters?.skills ?? [],
                pageIndex: currentPage,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        if (upcomingModules) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(
                        upcomingModules.totalRecords / pageSize
                    ),
                    endRecord: startRecord + upcomingModules.modules.length - 1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: upcomingModules.totalRecords,
            });

            setVisibleModules(upcomingModules.modules.length);
        }
    }, [upcomingModules]);

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: value },
        });
    };

    const setRecordsPerPage = (evt: SelectChangeEvent<unknown>) => {
        setPageSize(parseInt(evt.target.value as string));
        localStorage.setItem(
            'upcomingModulesPageSize',
            evt.target.value as string
        );
        dispatch({ type: 'UPDATE_CURRENT_PAGE', payload: { pageNumber: 1 } });
    };

    if (!recordsLoaded || !upcomingModules) {
        return null;
    }

    return (
        <>
            <Table
                ariaTableLabel='Upcoming modules table'
                showTableInfo={true}
                showRecordsPerPage={true}
                isDraggable={true}
                visibleRows={visibleModules || 10}
                totalRows={totalRecords}
                rowsPerPage={pageSize}
                setRowsPerPage={setRecordsPerPage}
                tableHeadings={['Name', 'Skills', 'Categories']}
                rows={upcomingModules.modules.map((module) => ({
                    id: module.id,
                    rowData: [
                        module.name,
                        module.skills.map((skill) => skill.name).join(', '),
                        module.categories
                            .map((category) => category.name)
                            .join(', '),
                    ],
                    dropData: {
                        name: module.name,
                        index: -1,
                        hidden: true,
                    },
                }))}
                unDraggableRows={priorityModules}
                dropFail={(id: string) =>
                    FLPDispatch({
                        type: 'REMOVE_PRIORITY_MODULE',
                        payload: id,
                    })
                }
                dropSuccess={() =>
                    FLPDispatch({
                        type: 'SET_PRIORITY_MODULES_UNHIDE',
                    })
                }
            />
            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination
                        page={currentPage}
                        onChange={updatePageNumber}
                        count={totalPages}
                        boundaryCount={1}
                        siblingCount={1}
                    />
                </PaginationWrapper>
            )}
        </>
    );
};
