import React, { useContext, useMemo } from 'react';

import { inputHelperText } from '../../../content/profile/input-helper-text';
import { nanoid } from 'nanoid';
import { useImmerReducer } from 'use-immer';

interface IProfileContext {
    state: State;
    dispatch: React.Dispatch<Action>;
    negativeTopOffset: number;
}

type State = {
    id: string;
    offsetTop: number | null;
    bottomGap: number | null;
    bubbleContent: {
        title: string;
        text: string;
    } | null;
};

export const initialState = {
    id: nanoid(),
    offsetTop: null,
    bottomGap: null,
    bubbleContent: null,
    elementId: null,
};

type Action =
    | {
          type: 'SET_CURRENT_OFFSET_TOP';
          payload: number | null;
      }
    | {
          type: 'SET_CURRENT_BOTTOM_GAP';
          payload: number | null;
      }
    | {
          type: 'SET_CONTENT_BY_KEY';
          payload: string | null;
      }
    | {
          type: 'RESET_FIELDS';
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_CURRENT_OFFSET_TOP':
            state.offsetTop = action.payload;
            state.id = nanoid();
            break;
        case 'SET_CURRENT_BOTTOM_GAP':
            state.bottomGap = action.payload;
            break;
        case 'SET_CONTENT_BY_KEY': {
            const key = action.payload;

            const currentContent = inputHelperText.find(
                (content) => content.key === key
            );

            if (currentContent) {
                state.bubbleContent = {
                    title: currentContent.title,
                    text: currentContent.text,
                };
            } else {
                state.bubbleContent = {
                    title: '',
                    text: '',
                };
            }
            break;
        }
        case 'RESET_FIELDS': {
            state.offsetTop = null;
            state.bottomGap = null;
            state.bubbleContent = null;
            break;
        }
    }
};

export const ProfileContext = React.createContext<IProfileContext>({
    state: initialState,
    dispatch: () => false,
    negativeTopOffset: 0,
});

interface IOwnProps {
    children: React.ReactNode;
    negativeTopOffset: number;
}

export const ProfileProvider: React.FC<IOwnProps> = ({
    children,
    negativeTopOffset,
}) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const value = useMemo(() => {
        return {
            state,
            dispatch,
            negativeTopOffset,
        };
    }, [state, dispatch, negativeTopOffset]);

    return (
        <ProfileContext.Provider value={value}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = (): IProfileContext => useContext(ProfileContext);
