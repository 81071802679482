import { useEffect, useState } from 'react';

import { ILearnerUserData } from '../types';
import { endpoints } from '../api/endpoints';
import { useLearnerId } from './useLearnerId';

export const useGetUsers = () => {
    const learnerUserId = useLearnerId();
    const { getUserData } = endpoints();

    const [userData, setUserData] = useState<ILearnerUserData>({
        firstName: null,
        lastName: null,
    });

    const getUser = async () => {
        const { data } = await getUserData(learnerUserId);

        if (data) {
            setUserData(data);
        }
    };

    useEffect(() => {
        if (learnerUserId) {
            getUser();
        }
    }, [learnerUserId]);

    return {
        userData,
    };
};
