import {
    Badge,
    CircularProgress,
    Drawer as MuiDrawer,
    Pagination,
    SelectChangeEvent,
    Typography,
    styled,
} from '@mui/material';
import { Button, Table } from '@cognassist/react-components';
import {
    ISupportRequests,
    ISupportRequestsResponse,
    SupportRequestStatus,
} from '../../../types/user.types';
import {
    LoadingWrapper,
    PaginationWrapper,
    TableWrapper,
} from './ChampionTableSharedComponents';
import { initialState, reducer } from '../../../state/table-state';
import { useEffect, useState } from 'react';

import { ChampionSupportRequestDetails } from './request-details';
import { endpoints } from '../../../api/endpoints';
import { format } from 'date-fns';
import { getDefaultPageSize } from '../../../utils/tables';
import { useChampionNotifications } from '../../../custom-providers/ChampionNotificationsProvider';
import { useImmerReducer } from 'use-immer';
import { useIsChampionRoleEnabled } from '../../../custom-hooks/useIsChampionRoleEnabled';
import { useSnackbar } from 'notistack';

const TdUserName = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& .MuiDrawer-paper': {
            width: '100%',
        },
    },
}));

export const ChampionsProvideSupportTable = () => {
    const [supportRequestsLoading, setSupportRequestsLoading] =
        useState<boolean>(true);
    const [supportRequestsData, setSupportRequestsData] =
        useState<ISupportRequestsResponse>();
    const [activeSupportRequestId, setActiveSupportRequestId] =
        useState<string>();
    const [reloadTableData, setReloadTableData] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('championsProvideSupportPageSize', 10)
    );
    const [progressedLoading, setProgressedLoading] = useState<boolean>(false);

    const isChampionRoleEnabled = useIsChampionRoleEnabled();
    const { refreshState: refreshChampionNotifications } =
        useChampionNotifications();

    const convertStatusEnumToFriendlyString = (
        status: SupportRequestStatus
    ) => {
        switch (status) {
            case 10:
                return 'New';
            case 20:
                return 'Viewed';
            case 30:
                return 'Progressed';
            default:
                return 'Unknown';
        }
    };

    const { getSupportRequests, patchSupportRequestStatus } = endpoints();

    const [
        {
            startRecord,
            totalRecords,
            totalPages,
            currentPage,
            currentPageRecords,
        },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (currentPage && pageSize && isChampionRoleEnabled) {
            requestSupportRequests();
        }
    }, [currentPage, pageSize, isChampionRoleEnabled]);

    useEffect(() => {
        if (supportRequestsData) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(
                        supportRequestsData.totalRecords / pageSize
                    ),
                    endRecord:
                        startRecord +
                        supportRequestsData.championSupportRequests.length -
                        1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: supportRequestsData.totalRecords,
            });
            dispatch({
                type: 'SET_TOTAL_CURRENT_PAGE_RECORDS',
                payload: supportRequestsData.championSupportRequests.length,
            });
        }
    }, [supportRequestsData]);

    useEffect(() => {
        if (reloadTableData) {
            handleReloadTableData();
        }
    }, [reloadTableData]);

    const requestSupportRequests = async () => {
        setSupportRequestsLoading(true);

        const { data, error } = await getSupportRequests({
            page: currentPage.toString(),
            pageSize: pageSize.toString(),
        });

        if (error) {
            setSupportRequestsData(undefined);
            enqueueSnackbar(
                'Could not load support requests, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setSupportRequestsData(data);
        }

        setSupportRequestsLoading(false);
    };

    const getVisibleRows = (): number => {
        if (totalRecords < pageSize) {
            return totalRecords;
        }

        if (currentPageRecords < pageSize) {
            return supportRequestsData?.championSupportRequests.length ?? 0;
        }

        return pageSize;
    };

    const setRecordsPerPage = (evt: SelectChangeEvent<unknown>) => {
        const value = evt.target.value as string;
        setPageSize(parseInt(value));
        localStorage.setItem('championsProvideSupportPageSize', value);
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: 1 },
        });
    };

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: value },
        });
    };

    const getRow = (supportRequest: ISupportRequests) => {
        return {
            id: supportRequest.id,
            rowData: [
                <TdUserName
                    onClick={() => setActiveSupportRequestId(supportRequest.id)}
                    key={supportRequest.id}
                >
                    {supportRequest.requestedBy.firstName}{' '}
                    {supportRequest.requestedBy.lastName}
                    {supportRequest.status === SupportRequestStatus.New && (
                        <Badge variant='dot' color='primary' sx={{ ml: 2 }} />
                    )}
                </TdUserName>,
                `${supportRequest.requestedBy.email}`,
                `${convertStatusEnumToFriendlyString(supportRequest.status)}`,
                `${format(
                    new Date(supportRequest.dateRequested),
                    'dd MMM yy'
                )}`,
                supportRequest.dateProgressed ? (
                    `${format(new Date(supportRequest.dateProgressed), 'dd MMM yy')}`
                ) : (
                    <Button
                        key={supportRequest.id}
                        color='success'
                        text='Mark as progressed'
                        data-id={supportRequest.id}
                        onClick={() => actionSupportRequest(supportRequest.id)}
                        loading={progressedLoading}
                    />
                ),
            ],
        };
    };

    const actionSupportRequest = async (supportRequestId: string) => {
        setProgressedLoading(true);
        const { error } = await patchSupportRequestStatus(supportRequestId, 30);

        if (error) {
            enqueueSnackbar(
                'Could not update this support request, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        setProgressedLoading(false);
        setReloadTableData(true);
    };

    const handleReloadTableData = () => {
        requestSupportRequests();
        refreshChampionNotifications();
        setReloadTableData(false);
    };

    const handleCloseDrawer = () => {
        setActiveSupportRequestId(undefined);

        if (reloadTableData) {
            handleReloadTableData();
        }
    };

    const requestDataReload = () => {
        setReloadTableData(true);
    };

    if (supportRequestsLoading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (!supportRequestsData) {
        return null;
    }

    return (
        <>
            <TableWrapper>
                <Typography variant='h2'>Support requests</Typography>
                {supportRequestsData.championSupportRequests.length === 0 ? (
                    <Typography sx={{ mt: 3 }}>
                        There are currently no support requests. Please check
                        again later.
                    </Typography>
                ) : (
                    <>
                        <Table
                            ariaTableLabel='Provide support to someone'
                            showTableInfo
                            showRecordsPerPage
                            visibleRows={getVisibleRows()}
                            totalRows={totalRecords}
                            rowsPerPage={pageSize}
                            setRowsPerPage={setRecordsPerPage}
                            tableHeadings={[
                                'Name',
                                'Email',
                                'Status',
                                'Date requested',
                                'Actioned',
                            ]}
                            rows={supportRequestsData.championSupportRequests.map(
                                (supportRequest) => getRow(supportRequest)
                            )}
                            isDraggable={false}
                        />

                        {totalPages > 1 && (
                            <PaginationWrapper>
                                <Pagination
                                    page={currentPage}
                                    onChange={updatePageNumber}
                                    count={totalPages}
                                    boundaryCount={6}
                                />
                            </PaginationWrapper>
                        )}
                    </>
                )}
            </TableWrapper>

            <Drawer
                open={!!activeSupportRequestId}
                anchor='right'
                onClose={handleCloseDrawer}
            >
                <ChampionSupportRequestDetails
                    activeSupportRequestId={activeSupportRequestId}
                    handleCloseDrawer={handleCloseDrawer}
                    requestDataReload={requestDataReload}
                />
            </Drawer>
        </>
    );
};
