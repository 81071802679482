import { IDashboardGraphItem } from '../../../../types';
import KeyValuePair from './KeyValuePair';

interface IKeys {
    isLocked: boolean;
    paginatedKeys: IDashboardGraphItem[][];
    currentPage: number;
    isLoading?: boolean;
}

export const ChartKeys: React.FC<IKeys> = ({
    isLocked,
    isLoading,
    paginatedKeys,
    currentPage,
}) => {
    if (isLocked) {
        return (
            <>
                <KeyValuePair
                    isLoading={isLoading}
                    title='One'
                    color='#7458FF'
                />
                <KeyValuePair
                    isLoading={isLoading}
                    title='Two'
                    color='#FEDB00'
                />
                <KeyValuePair
                    isLoading={isLoading}
                    title='Three'
                    color='#F08DB9'
                />
                <KeyValuePair
                    isLoading={isLoading}
                    title='Four'
                    color='#7FBFFF'
                />
            </>
        );
    } else {
        return (
            <>
                {paginatedKeys[currentPage].map(
                    (graphItem: IDashboardGraphItem) => {
                        return (
                            <KeyValuePair
                                isLoading={isLoading}
                                key={graphItem.title}
                                title={graphItem.title}
                                color={graphItem.color}
                                percentageValue={graphItem.percentageValue}
                                remainderValue={graphItem.remainderValue}
                            />
                        );
                    }
                )}
            </>
        );
    }
};
