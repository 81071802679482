import { useAuth } from '../custom-providers/AuthProvider';
import { Roles } from '../types';

export const useIsChampionRoleEnabled = (): boolean => {
    const {
        state: {
            clientConfig: { enabledRoles },
        },
    } = useAuth();

    return (
        enabledRoles?.some((role) => role.roleName === Roles.Champion) ?? false
    );
};
