import { ButtonWrapper, ListItemCheck, Title } from './shared-components';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';

const CommunitiesWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
}));

const Community = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
}));

const SubscribedCommunities: React.FC = () => {
    const {
        state: { organisationCommunityDisplayName },
        dispatch,
    } = useCommunityState();

    return (
        <>
            <Title variant='h1'>
                We will automatically add you to these communities
            </Title>
            <CommunitiesWrapper>
                <Community>
                    <ListItemCheck color='white' />
                    <Typography>Cognassist General</Typography>
                </Community>
                {organisationCommunityDisplayName && (
                    <Community>
                        <ListItemCheck color='white' />
                        <Typography>
                            {organisationCommunityDisplayName}
                        </Typography>
                    </Community>
                )}
            </CommunitiesWrapper>
            <ButtonWrapper>
                <Button
                    color='inherit'
                    text='Back'
                    sx={{ mr: 2 }}
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 2 },
                        });
                    }}
                />
                <Button
                    text='Continue'
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 4 },
                        });
                    }}
                />
            </ButtonWrapper>
        </>
    );
};

export default SubscribedCommunities;
