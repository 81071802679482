import { Roles } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';

export const useIsManagerAndAdmin = () => {
    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    return (
        roles?.includes(Roles.Tutor) &&
        roles?.includes(Roles.ClientAdmin) &&
        !roles?.includes(Roles.SysAdmin)
    );
};
