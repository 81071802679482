import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-crop/dist/ReactCrop.css';
import './assets/styles/normalize.css';
import './assets/styles/index.css';
import './assets/styles/animation.css';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { AppInsightsProvider } from './custom-providers/AppInsightsProvider';
import { AuthProvider } from './custom-providers/AuthProvider';
import { CommunityProvider } from './custom-providers/CommunityProvider';
import { ConfigProvider } from './custom-providers/ConfigProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { DndProvider } from 'react-dnd';
import { FLPProvider } from './custom-providers/FlexibleLearningPlan';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MatomoProvider } from './custom-providers/MatomoProvider';
import React from 'react';
import { ReportProvider } from './custom-providers/ReportProvider';
import { RouterProvider } from 'react-router-dom';
import SnackbarProvider from './custom-providers/SnackbarProvider';
import { UsersProvider } from './custom-providers/UsersProvider';
import { createRoot } from 'react-dom/client';
import { lightTheme } from '@cognassist/react-components';
import reportWebVitals from './reportWebVitals';
import { router } from './router';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <SnackbarProvider>
                    <ConfigProvider>
                        <AuthProvider>
                            <FLPProvider>
                                <ReportProvider>
                                    <CommunityProvider>
                                        <DndProvider backend={HTML5Backend}>
                                            <MatomoProvider>
                                                <AppInsightsProvider>
                                                    <UsersProvider>
                                                        <RouterProvider
                                                            router={router}
                                                        />
                                                    </UsersProvider>
                                                </AppInsightsProvider>
                                            </MatomoProvider>
                                        </DndProvider>
                                    </CommunityProvider>
                                </ReportProvider>
                            </FLPProvider>
                        </AuthProvider>
                    </ConfigProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
