import { CogInput, ErrorText } from '../../../styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import {
    ICoachDropdown,
    useUsers,
} from '../../../../custom-providers/UsersProvider';
import { useEffect, useState } from 'react';

import { Autocomplete } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
import { Field } from './Field';
import { useIsManagerNotAdmin } from '../../../../custom-hooks/useIsManagerNotAdmin';

export const Manager: React.FC = () => {
    const {
        formState: { errors },
        getValues,
        setValue,
        control,
    } = useFormContext<{ managerUserId: string }>();

    const {
        state: { coaches, enterpriseUser },
    } = useUsers();

    const isManagerNotAdmin = useIsManagerNotAdmin();

    const managerUserId = getValues('managerUserId');

    const [inputValue, setInputValue] = useState<ICoachDropdown | null>(null);

    useEffect(() => {
        if (coaches) {
            setInputValue(
                coaches.find((manager) => manager.id === managerUserId) ?? null
            );
        }
    }, [coaches]);

    useEffect(() => {
        setValue('managerUserId', inputValue?.id ?? '');
    }, [inputValue]);

    if (!coaches && isManagerNotAdmin) {
        return (
            <Field label='Manager'>
                <>
                    <CogInput
                        disabled
                        value={enterpriseUser?.manager?.name ?? ''}
                    />
                </>
            </Field>
        );
    }

    if (!coaches) {
        return null;
    }

    return (
        <Field label='Manager' labelFor='managerUserId'>
            <>
                <Controller
                    name='managerUserId'
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            disablePortal
                            id='managerUserId'
                            options={coaches}
                            getOptionLabel={(option) => option.label}
                            onChange={(_e, val) => setInputValue(val)}
                            value={inputValue}
                            renderInput={({ inputProps, InputProps }) => {
                                return (
                                    <div ref={InputProps.ref}>
                                        <CogInput
                                            {...field}
                                            id='managerUserId'
                                            fullWidth
                                            hasError={!!errors.managerUserId}
                                            inputProps={inputProps}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                />

                <ErrorMessage
                    errors={errors}
                    name='managerUserId'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
