import { List, ListItem, Popover, Typography, styled } from '@mui/material';

const MoreInfoPopover = styled(Popover)(({ theme }) => ({
    top: 10,
    '.MuiPopover-paper': {
        overflow: 'visible',
        padding: theme.spacing(2),
        background: theme.palette.text.primary,
        color: theme.palette.common.white,
        maxWidth: 300,
        '&::before': {
            content: "''",
            width: 20,
            height: 20,
            background: theme.palette.text.primary,
            display: 'block',
            position: 'absolute',
            top: '-3px',
            left: 138,
            transform: 'rotate(40deg) skew(15deg, 15deg)',
            zIndex: -1,
        },
    },
}));

const BulletList = styled(List)(({ theme }) => ({
    listStyleType: 'disc',
    padding: theme.spacing(0, 0, 0, 3),
}));

const Bullet = styled(ListItem)(({ theme }) => ({
    display: 'list-item',
    padding: theme.spacing(0),
}));

interface IOwnProps {
    anchor: HTMLButtonElement | null;
    handleClose: () => void;
}

export const MoreInfoPopper: React.FC<IOwnProps> = ({
    anchor,
    handleClose,
}) => {
    return (
        <MoreInfoPopover
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography sx={{ fontSize: '1.125rem', mb: 2 }}>
                What you&apos;ll need:
            </Typography>
            <BulletList>
                <Bullet>Around 30 minutes</Bullet>
                <Bullet>
                    A laptop or desktop with good internet connection
                </Bullet>
                <Bullet>
                    Latest version of Chrome, Edge, Firefox or Safari browser
                </Bullet>
            </BulletList>
        </MoreInfoPopover>
    );
};
