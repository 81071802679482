import { ArrowRight, ChevronLeft, ChevronRight } from 'react-feather';
import {
    Back,
    Forward,
    LoadingWrapper,
    StrategiesCard,
    StrategiesImage,
    StrategiesImageWrapper,
    StrategiesTitle,
} from './strategies-components';
import {
    CircularProgress,
    IconButton as MuiIconButton,
    styled,
} from '@mui/material';

import { Button } from '@cognassist/react-components';
import { CaiStrategyButton } from './CaiStrategyButton';
import { ICognitiveDomain } from '../../../types';
import { UserStrategyStatusTag } from '../cai/UserStrategyStatusTag';
import { useCarousel } from '../../../custom-hooks/useCarousel';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useNavigate } from 'react-router-dom';
import { useStrategyCard } from '../../../custom-hooks/useStrategyCard';

const CardWrapper = styled('div')(() => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
}));

const StrategiesCardWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    transition: 'transform 0.4s ease',
    width: '100%',
}));

const ButtonWrapper = styled('div')(() => ({
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
}));

const CarouselControls = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 3),
}));

const DotsWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, -3),
}));

interface IOwnProps {
    domain: ICognitiveDomain;
}

export const DomainStrategies: React.FC<IOwnProps> = ({ domain }) => {
    const navigate = useNavigate();

    const caiStrategiesEnabled = useFeatureIsOn('cai-strategies');

    const { carouselNext, carouselPrev, Dots, ref, cardStyleProps } =
        useCarousel(domain.strategies.length);

    const { loading, handleClickStrategyCard } = useStrategyCard();

    const goToStrategiesPage = () => {
        navigate('/cognitive-map/my-strategies', {
            state: { navigatedDomainId: domain.domainId },
        });
    };

    const showControls = domain.strategies.length > 1;

    return (
        <>
            <CardWrapper ref={ref}>
                {domain.strategies.map((s, i) => (
                    <StrategiesCardWrapper
                        key={s.courseUrl}
                        sx={cardStyleProps(i)}
                    >
                        <StrategiesCard
                            onClick={
                                caiStrategiesEnabled
                                    ? undefined
                                    : (e) =>
                                          handleClickStrategyCard(
                                              e,
                                              s.lmsSourceId
                                          )
                            }
                        >
                            <StrategiesImageWrapper>
                                <StrategiesImage
                                    className='strategiesImage'
                                    src={s.imageUrl}
                                    alt=''
                                />
                                {loading && (
                                    <LoadingWrapper>
                                        <CircularProgress />
                                    </LoadingWrapper>
                                )}
                            </StrategiesImageWrapper>
                            {caiStrategiesEnabled && (
                                <UserStrategyStatusTag
                                    status={s.userStrategyStatus}
                                />
                            )}
                            <StrategiesTitle className='strategiesTitle'>
                                {s.title}
                            </StrategiesTitle>
                            {caiStrategiesEnabled && (
                                <CaiStrategyButton course={s} />
                            )}
                        </StrategiesCard>
                    </StrategiesCardWrapper>
                ))}
            </CardWrapper>
            <CarouselControls id='carousel-controls'>
                {showControls && (
                    <Back>
                        <MuiIconButton
                            onClick={carouselPrev}
                            aria-label='carousel back'
                        >
                            <ChevronLeft />
                        </MuiIconButton>
                    </Back>
                )}
                <ButtonWrapper>
                    <Button
                        text='View more'
                        endIcon={<ArrowRight />}
                        fullWidth
                        onClick={goToStrategiesPage}
                        color='secondary'
                    />
                    {showControls && (
                        <DotsWrapper>
                            <Dots />
                        </DotsWrapper>
                    )}
                </ButtonWrapper>
                {showControls && (
                    <Forward>
                        <MuiIconButton
                            onClick={carouselNext}
                            aria-label='carousel forward'
                        >
                            <ChevronRight />
                        </MuiIconButton>
                    </Forward>
                )}
            </CarouselControls>
        </>
    );
};
