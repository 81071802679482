import { Badge, styled } from '@mui/material';
import { ChampionStatus, Roles } from '../../../types';
import { useEffect, useState } from 'react';

import { Banner } from '../../../components/main-app/champion-support/banner';
import { ChampionsProvideSupportTable } from '../../../components/main-app/champion-support/ChampionsProvideSupportTable';
import { ChampionsRequestSupportTable } from '../../../components/main-app/champion-support/ChampionsRequestSupportTable';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useChampionNotifications } from '../../../custom-providers/ChampionNotificationsProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useIsChampionRoleEnabled } from '../../../custom-hooks/useIsChampionRoleEnabled';

const ChampionTabs = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
}));

const ChampionTab = styled('div', {
    ...shouldForwardProps('isActive'),
})<{ isActive: boolean }>(({ isActive, theme }) => ({
    border: `1px solid ${theme.palette.grey[100]}`,
    background: isActive ? '#FAF9FF' : theme.palette.common.white,
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:first-of-type': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1.5),
}));

const ChampionTabText = styled('p')(() => ({
    fontSize: pxToRem(14),
    fontWeight: 500,
    textAlign: 'center',
}));

export const ChampionSupport = () => {
    const isChampionRoleEnabled = useIsChampionRoleEnabled();
    const [activeTab, setActiveTab] = useState<string>('request');
    const [userCanVolunteerAsChampion, setUserCanVolunteerAsChampion] =
        useState<boolean>(false);
    const [userIsCertifiedChampion, setUserIsCertifiedChampion] =
        useState<boolean>(false);

    const { getUserChampionStatus, canUserVolunteerAsChampion } = endpoints();
    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    const {
        state: { hasNewSupportRequests },
    } = useChampionNotifications();

    const volunteerChampionsEnabled = useFeatureIsOn('volunteer-champions');

    const requestUserChampionStatus = async () => {
        const { data } = await getUserChampionStatus();

        if (data?.status === ChampionStatus.Certified) {
            setUserIsCertifiedChampion(true);
        } else {
            setUserIsCertifiedChampion(false);
        }
    };

    const checkUserCanVolunteerAsChampion = async () => {
        const { data } = await canUserVolunteerAsChampion();

        if (data?.canVolunteerAsChampion) {
            setUserCanVolunteerAsChampion(true);
        } else {
            setUserCanVolunteerAsChampion(false);
        }
    };

    const checkUserChampionStatus = async () => {
        if (roles?.includes(Roles.Champion)) {
            await requestUserChampionStatus();
        } else if (volunteerChampionsEnabled) {
            await checkUserCanVolunteerAsChampion();
        } else {
            setUserIsCertifiedChampion(false);
            setUserCanVolunteerAsChampion(false);
        }
    };

    useEffect(() => {
        document.title = 'Champion Support';

        checkUserChampionStatus();
    }, []);

    if (!isChampionRoleEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader title='Champion Support' />
            <MainContentInner>
                {userIsCertifiedChampion && (
                    <ChampionTabs>
                        <ChampionTab
                            isActive={activeTab === 'request'}
                            onClick={() => setActiveTab('request')}
                        >
                            <ChampionTabText>Request support</ChampionTabText>
                        </ChampionTab>
                        <ChampionTab
                            isActive={activeTab === 'provide'}
                            onClick={() => setActiveTab('provide')}
                        >
                            <ChampionTabText>Provide support</ChampionTabText>
                            {hasNewSupportRequests && (
                                <Badge variant='dot' color='primary' />
                            )}
                        </ChampionTab>
                    </ChampionTabs>
                )}

                {activeTab === 'request' && (
                    <>
                        <Banner
                            canVolunteer={userCanVolunteerAsChampion}
                            refreshVolunteerStatus={checkUserChampionStatus}
                        />
                        <ChampionsRequestSupportTable />
                    </>
                )}

                {activeTab === 'provide' && <ChampionsProvideSupportTable />}
            </MainContentInner>
        </>
    );
};
