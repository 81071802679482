import {
    Button as MuiButton,
    Tooltip as MuiTooltip,
    styled,
} from '@mui/material';

import { HelpCircle } from 'react-feather';
import { IInputHelperText } from '../../../content/profile/input-helper-text';
import { ReactElement } from 'react';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

const Wrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    '&:last-child': {
        marginBottom: 0,
    },
}));

export const Required = styled('p', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    color: hasError ? theme.palette.error.main : 'inherit',
}));

export const Asterisk = styled('span', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 'bold',
    marginLeft: theme.spacing(0.5),
    color: hasError ? theme.palette.error.main : 'inherit',
}));

export const Label = styled('label', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    color: hasError ? theme.palette.error.main : '#33383F',
}));

export const FieldWrapper = styled('div', {
    ...shouldForwardProps('hide'),
})<{ hide?: boolean }>(({ hide }) => ({
    display: hide ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
}));

const Tooltip = styled(MuiTooltip, {
    ...shouldForwardProps('isSmall'),
})<{ isSmall?: boolean }>(({ isSmall }) => ({
    display: isSmall ? 'block' : 'none',
}));

const TooltipContent = styled('div')(() => ({
    padding: '16px 8px',
}));

const TooltipTitle = styled('h1')(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    color: theme.palette.common.white,
    marginBottom: 16,
}));

const TooltipText = styled('p')(({ theme }) => ({
    fontSize: pxToRem(12),
    lineHeight: '150%',
    color: theme.palette.common.white,
}));

const Button = styled(MuiButton)(() => ({
    textTransform: 'none',
}));

interface IOwnProps {
    label: string;
    children: ReactElement;
    required?: boolean;
    labelFor?: string;
    labelId?: string;
    hasError?: boolean;
    content?: IInputHelperText;
    hide?: boolean;
    inputAction?: {
        name: string;
        action: () => void;
    };
    hideTooltip?: boolean;
    isSmall?: boolean;
}

export const Field: React.FC<IOwnProps> = ({
    label,
    children,
    required = false,
    labelFor,
    labelId,
    hasError,
    content,
    hide,
    inputAction,
    hideTooltip,
    isSmall = false,
}) => {
    const dynamicProps: {
        htmlFor?: string;
        id?: string;
    } = {};

    if (labelFor) {
        dynamicProps.htmlFor = labelFor;
    }

    if (labelId) {
        dynamicProps.id = labelId;
    }

    return (
        <Wrapper>
            <FieldWrapper hide={hide}>
                <Label {...dynamicProps}>
                    {label}
                    {required && isSmall && (
                        <Asterisk hasError={hasError}>*</Asterisk>
                    )}
                </Label>
                {required && !isSmall && (
                    <Required hasError={hasError}>Required</Required>
                )}
                {inputAction && (
                    <Button onClick={inputAction.action} color='inherit'>
                        {inputAction.name}
                    </Button>
                )}
                {!hideTooltip && content && (
                    <Tooltip
                        title={
                            <TooltipContent>
                                <TooltipTitle>{content?.title}</TooltipTitle>
                                <TooltipText>{content?.text}</TooltipText>
                            </TooltipContent>
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        isSmall={isSmall}
                    >
                        <HelpCircle size={14} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                )}
            </FieldWrapper>

            {children}
        </Wrapper>
    );
};
