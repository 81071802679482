import { styled } from '@mui/material';

export const PageWrapper = styled('div')(() => ({
    boxSizing: 'border-box',
    position: 'relative',
}));

export const MainContentInner = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    maxWidth: 1440,
    margin: '0 auto',
    [theme.breakpoints.down(744)]: {
        padding: theme.spacing(3, 1, 5),
    },
}));
