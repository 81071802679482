import { Client } from '@amityco/ts-sdk';
import { endpoints } from '../../../api/endpoints';

const CommunityNetworkTracking: React.FC = () => {
    const { keepAlive } = endpoints();

    Client.onNetworkActivities(() => {
        keepAlive();
    });

    return null;
};

export default CommunityNetworkTracking;
