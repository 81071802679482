import { IStandardScore } from '../../types';

export const domains = [
    { name: 'Literacy', keyName: 'L', id: 2, barColor: '#443872' },
    { name: 'Verbal Reasoning', keyName: 'VR', id: 7, barColor: '#806ECA' },
    { name: 'Reading Decoding', keyName: 'RD', id: 9, barColor: '#E9E5F8' },
    { name: 'Executive Function', keyName: 'EF', id: 6, barColor: '#027BAE' },
    { name: 'Numeracy', keyName: 'N', id: 3, barColor: '#039FE0' },
    { name: 'Visual Perception', keyName: 'VP', id: 8, barColor: '#3FBAED' },
    {
        name: 'Visual Information Processing Speed',
        keyName: 'VIPS',
        id: 4,
        barColor: '#C1EBFC',
    },
    { name: 'Verbal Memory', keyName: 'VM', id: 1, barColor: '#9FD916' },
    { name: 'Non-Verbal Memory', keyName: 'NVM', id: 5, barColor: '#D0F085' },
];

const getTopScore = (scores: IStandardScore[]): IStandardScore => {
    return scores.reduce((accum: IStandardScore, currentScore) => {
        if (accum.standardScore < currentScore.standardScore) {
            return currentScore;
        }

        return accum;
    });
};

export const getTopDomainName = (scores: IStandardScore[]): string => {
    const topScore = getTopScore(scores);
    return (
        domains.find((domain) => topScore.domainId === domain.id)?.name || ''
    );
};
