import {
    APICall,
    IGetLearnerResponse,
    IHealthDataLegalDocumentsResponse,
} from '../types';
import { useEffect, useState } from 'react';

import { endpoints } from '../api/endpoints';
import { useAuth } from '../custom-providers/AuthProvider';
import { useSnackbar } from 'notistack';

type UseLearnerProfile = () => {
    learnerDetails: APICall<IGetLearnerResponse>;
    legalDocuments: APICall<IHealthDataLegalDocumentsResponse>;
    refreshLearnerDetails: () => Promise<void>;
    refreshLegalDocuments: () => Promise<void>;
};

export const useLearnerProfile: UseLearnerProfile = () => {
    const [learnerDetails, setLearnerDetails] = useState<
        APICall<IGetLearnerResponse>
    >({
        data: null,
        loading: false,
        error: null,
    });
    const [legalDocuments, setLegalDocuments] = useState<
        APICall<IHealthDataLegalDocumentsResponse>
    >({
        data: null,
        loading: true,
        error: null,
    });

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const { getLearnerDetails, getHealthDataLegalDocuments } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getLearner();
    }, [userId]);

    useEffect(() => {
        getLegalDocuments();
    }, []);

    const showError = () => {
        enqueueSnackbar(
            'Could not retrieve data, please try refreshing the page.',
            {
                variant: 'error',
            }
        );
    };

    const getLearner = async () => {
        if (!userId) {
            return;
        }

        const { data, error } = await getLearnerDetails(userId);

        if (data) {
            setLearnerDetails({
                error: null,
                data,
                loading: false,
            });
        }

        if (error) {
            showError();
            setLearnerDetails({
                data: null,
                error,
                loading: false,
            });
        }
    };

    const getLegalDocuments = async () => {
        const { data, error } = await getHealthDataLegalDocuments();

        if (data) {
            setLegalDocuments({
                error: null,
                data,
                loading: false,
            });
        }

        if (error) {
            showError();
            setLegalDocuments({
                data: null,
                error,
                loading: false,
            });
        }
    };

    return {
        learnerDetails,
        legalDocuments,
        refreshLearnerDetails: getLearner,
        refreshLegalDocuments: getLegalDocuments,
    };
};
