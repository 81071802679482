import { RequestMessage } from './fields/RequestMessage';
import { RequestType } from './fields/RequestType';

export const ChampionSupportRequestForm = () => {
    return (
        <>
            <RequestType />
            <RequestMessage />
        </>
    );
};
