import { ClientConfigItem } from '../types';
import { useAuth } from '../custom-providers/AuthProvider';

export const useLegacyEnterpriseClient = () => {
    const {
        state: {
            clientConfig: { items },
        },
    } = useAuth();

    const legacyEnterpriseClient =
        items?.find(
            (item) => item.id === ClientConfigItem.EnterpriseLegacyClient
        )?.state === 1;

    return legacyEnterpriseClient;
};
